import appSagas from './sagas/app-sagas';
import sendMoneySagas from './sagas/send-money-sagas';
import transferSagas from './sagas/transfer-sagas';
import bankAccountSagas from './sagas/bank-account-sagas';
import cardAccountSagas from './sagas/card-account-sagas';
import userSagas from './sagas/user-sagas';
import walletSagas from './sagas/wallet-sagas';
import signUpSagas from './sagas/sign-up-sagas';
import recoverPasswordSagas from './sagas/recover-password-sagas';
import setNewPasswordSagas from './sagas/set-new-password-sagas';
import loginSagas from './sagas/login-sagas';
import transactionsSagas from './sagas/transactions-sagas';
import toastSagas from './sagas/toast-sagas';
import dashboardSagas from './sagas/dashboard-sagas';
import partnerSignupSagas from './sagas/partner-signup-sagas';
import loanOfferSaga from './sagas/loan-offer-sagas';
import gbSignupSaga from './sagas/gb-signup-sagas';

const sagaRunner = (store) => {
  const allSagas = [
    appSagas,
    sendMoneySagas,
    transferSagas,
    bankAccountSagas,
    cardAccountSagas,
    userSagas,
    walletSagas,
    signUpSagas,
    recoverPasswordSagas,
    setNewPasswordSagas,
    loginSagas,
    transactionsSagas,
    toastSagas,
    dashboardSagas,
    partnerSignupSagas,
    loanOfferSaga,
    gbSignupSaga,
  ];

  allSagas.forEach((sagas) => {
    sagas.forEach((saga) => {
      store.runSaga(saga);
    });
  });
};

export default sagaRunner;
