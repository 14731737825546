import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BalanceShape } from '../../../constants/prop-types';
import cn from 'classnames';
import Button from '../../button';
import Avatar from '../../avatar';
import CurrencyIcon from '../../currency-icon';
import { isSafari } from '../../../utils/string-utils';
import Svg from '../../svg';
import { getIconName } from '../../../constants/card-account-constants';

const PaymentSource = (props) => {
  const {
    className,
    sourceType,
    onClick,
    disabled,
    accountName,
    balanceText,
    clears,
    last4,
    description,
    mode,
    bank_name,
    balance = { currency: 'USD' },
  } = props;

  const [isInstant, setIsInstant] = useState(false);

  useEffect(() => {
    switch(sourceType) {
      case 'account':
      case 'recentTransfer':
      case 'card':
        setIsInstant(true);
        break;
      case 'bank':
        setIsInstant(mode === 'instant');
        break;
      default:
        setIsInstant(false);
    }
  }, [sourceType]);

  const getContent = (sourceType) => {
    switch (sourceType) {
      case 'bank':
        return (
          <div className="button_head -row">
            <Avatar className={cn('payment-source__icon')}>
              <CurrencyIcon 
                ext="svg"
                color="color"
                width={34}
                height={34}
                className="avatar_status-icon"
                fallback={
                  <Svg 
                    name='payment-source-bank'
                    className={cn('avatar_status-icon', isSafari && 'avatar_status-icon--safari')}
                  />
                }
              />
            </Avatar>
            <div className="-column">
              <div className="button_content-primary js-nickname">
                Transfer from Bank Account
              </div>
              <div className="button_content-secondary">
                <span className="secondary-text">
                  {description}&nbsp;&nbsp;
                  <span className="account-number-bullets">&bull;&bull;</span>{last4}
                </span>
              &nbsp;&nbsp;
                { mode === 'invalid' && <div className="blue-round">
                  <div className="font-weight-bold">?</div>
                </div> }
              </div>
            </div>
          </div>
        );
      case 'card':
        return (
          <div className="button_head -row">
            <Svg name={getIconName(bank_name)} className="icon cards-page__icon" />
            <div className="-column">
              <div className="button_content-primary js-nickname">
                {accountName || 'Credit Card'} 
              </div>
              <div className="button_content-secondary">
                <span className="secondary-text">
                  {bank_name}&nbsp;&nbsp;
                  <span className="account-number-bullets">&bull;&bull;</span>{last4}
                </span>
              </div>
            </div>
          </div>
        );
      case 'recentTransfer':
        return (
          <div className="button_head -row">
            <Avatar className={cn('payment-source__icon')}>
              <CurrencyIcon 
                ext="svg"
                color="color"
                width={34}
                height={34}
                className="avatar_status-icon"
                fallback={
                  <Svg 
                    name='payment-source-other'
                    className={cn('avatar_status-icon', isSafari && 'avatar_status-icon--safari')}
                  />
                }
              />
            </Avatar>
            <div className="-column">
              <div className="button_content-primary js-nickname">
                Recent transfer from your Bank Account
              </div>
              <div className="button_content-secondary">
                <span className="font-weight-bold">
                  {description}&nbsp;&nbsp;
                  <span className="account-number-bullets">&bull;&bull;</span>{last4}
                </span>
              &nbsp;&nbsp;
              </div>
            </div>
          </div>
        );
      case 'account':
        return (
          <div className="button_head -row">
            <Avatar className={cn('payment-source__icon')}>
              <CurrencyIcon 
                currency={balance.currency.toLowerCase()}
                ext="svg"
                color="color"
                width={34}
                height={34}
                className="avatar_status-icon"
                fallback={
                  <Svg 
                    name='currency-placeholder-small'
                    className={cn('avatar_status-icon', isSafari && 'avatar_status-icon--safari')}
                    viewBox="-16 -15 56 56" 
                  />
                }
              />
            </Avatar>
            <div className="-column">
              <div className="button_content-primary js-nickname">
                {accountName} Account
              </div>
              <div className="button_content-secondary">
                Balance:&nbsp; 
                <span className="font-weight-bold">
                  {balanceText}
                </span>
              </div>
            </div>
          </div>
        );
      default:
        return '';
    };
  };

  return (
    <Button
      transparency="full"
      xSize="full"
      className={cn('button-group_item payment-source -has-arrow -like-button -y-lg', {
        '-disabled': disabled,
        '-success': !disabled,
        [className]: !!className,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      {getContent(sourceType)}
      <div className="button_tail">
        <div className={
          cn('label', !isInstant ? '-alert' : '-success')}
        >
          {
            isInstant ?
              'Money delivered immediately' :
              clears ? `Money delivered: ${clears}` : ''
          }
        </div>
      </div>
    </Button>
  );
};

PaymentSource.propTypes = {
  className: PropTypes.string,
  sourceType: PropTypes.oneOf(['bank', 'recentTransfer', 'account', 'card']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  accountName: PropTypes.string,
  balanceText: PropTypes.string,
  clears: PropTypes.string,
  last4: PropTypes.string,
  description: PropTypes.string,
  mode: PropTypes.string,
  balance: BalanceShape,
  bank_name: PropTypes.string,
};

export default PaymentSource;