import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currencyFormat } from '../../utils/send-money-utils';
import Button from '../../components/button';
import Svg from '../../components/svg';
import { sendMoneyReducerTypes } from '../../constants/prop-types';

const PredefinedOrderSuccess = props => {
  const { 
    sendMoney,
    onClose,
    showPredefinedOrderResult
  } = props;
  const { transactionResult } = sendMoney;


  return (
    <Fragment>
      <div className="page_body">
        <div className="page_content">
          <div className="layer -centered -space-up-4xl">
            <form className="form new-transaction" action="">
              <div className="transaction-result -success">
                <Svg name="transaction-success" className="transaction-result_icon" />
                <div className="transaction-result_body">
                  <div className="transaction-result_amount">
                    <span className="transaction-result_value">
                      {
                        currencyFormat(transactionResult.gross_amount.sign +
                          transactionResult.gross_amount.ordinal + '.' + transactionResult.gross_amount.decimal)
                      }
                    </span>
                    <span className="transaction-result_unit">{transactionResult.gross_amount.currency}</span>
                  </div>
                  <div className="transaction-result_comment">
                      sent to <span className="word-break-word">{transactionResult.destination.name}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          transparency="full"
          onClick={() => {
            onClose();
            showPredefinedOrderResult(transactionResult);
          }}
        >
          Show Transaction Details
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </Fragment>
  );
};

PredefinedOrderSuccess.propTypes = {
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  onClose: PropTypes.func,
  showPredefinedOrderResult: PropTypes.func
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
});

export default connect(mapStateToProps)(PredefinedOrderSuccess);
