import CONST from '../constants/transfer-constants';

export const setField = (key, value) => ({
  type: CONST.SET_FIELD,
  payload: { key, value },
});

export const updateTransfer = data => ({
  type: CONST.UPDATE_TRANSFER,
  payload: { data },
});

export const getRate = () => ({
  type: CONST.GET_RATE,
});

export const setRate = rate => ({
  type: CONST.SET_RATE,
  payload: { rate },
});

export const makePayment = () => ({
  type: CONST.MAKE_PAYMENT,
});

export const setTransaction = transactionResult => ({
  type: CONST.SET_TRANSACTION,
  payload: { transactionResult },
});

export const setErrorMessage = message => ({
  type: CONST.SET_ERROR_MESSAGE,
  payload: { message },
});

export const clearTransaction = () => ({
  type: CONST.CLEAR_TRANSACTION,
});
