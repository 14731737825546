export const REGEXP_INTEGER = /^[0-9]+$/;

export const REGEXP_ALPHANUM = /^[^\W|_]{0,14}$/;

export const REGEXP_INTEGER_DOT = /^[0-9]+(\.){0,1}$/;

export const REGEXP_FC = /^[0-9]+(\.[0-9]{1,8}){0,1}$/;

export const REGEXP_USD = /^[0-9]+(\.[0-9]{1,2}){0,1}$/;

export const REGEXP_CURRENCY = /^[0-9]+(\.[0-9]+){0,1}$/;

export const REGEXP_HANDLE = /^[A-Za-z]{1}[A-Za-z0-9-]{2,49}$/;

