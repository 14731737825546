import { takeLatest, put, select } from 'redux-saga/effects';
import { path } from 'ramda';
import {
  getPendingTransactions,
  getCompletedTransactions,
  setPendingTransactions,
  setCompletedTransactions,
  appendCompletedTransactions,
  setField,
} from '../actions/transactions-actions';
import REQUESTS from '../utils/requests';
import CONST from '../constants/transactions-constants';
import { rejectEmptyParams } from '../utils/transaction-utils';
import moment from 'moment';

const BASE_COMPLETED_FILTER = {
  sort: 'initiated_at',
  order: 'desc',
  status: [
    CONST.TRANSACTION_STATUSES.COMPLETE,
    CONST.TRANSACTION_STATUSES.FAILED,
    CONST.TRANSACTION_STATUSES.FULLY_REFUNDED,
    CONST.TRANSACTION_STATUSES.PARTIALLY_REFUNDED
  ].join(','),
};

function* getPendingTransactionsFlow({ payload: { accountId } }) {
  try {
    const walletId = yield select(path(['wallet', 'wallet', 'wallet_id']));

    if (walletId) {
      let transactionFilters = yield select(path(['transactions', 'filters']));
      const user = yield select(path(['user', 'user']));

      if (transactionFilters.timeFrameValue === CONST.TIMEFRAME_INTERVALS.ANY) {
        transactionFilters.timeFrameDates.from_date = moment(user.created_at).subtract(1, 'd').toISOString();
        transactionFilters.timeFrameDates.to_date = moment().add(1, 'd').toISOString();
      }
      
      const { success, data } = yield REQUESTS.GET_TRANSACTIONS(walletId, rejectEmptyParams({
        ...BASE_COMPLETED_FILTER,
        search: transactionFilters.search,
        direction: transactionFilters.direction.length === 1 ? transactionFilters.direction[0] : [],
        type: transactionFilters.type.join(','),
        from_date: transactionFilters.timeFrameDates.from_date,
        to_date: transactionFilters.timeFrameDates.to_date,
        status: [CONST.TRANSACTION_STATUSES.PENDING, CONST.TRANSACTION_STATUSES.SUSPENDED].join(','),
        account_id: accountId,
        limit: 100,
      }));

      if (success) {
        yield put(setPendingTransactions(data));
      } else {
        yield put(setPendingTransactions([]));
      }
    } else {
      yield put(setPendingTransactions([]));
    }
  } catch (error) {
    throw error;
  }
}

function* getNextCompletedTransactionsFlow({ payload: { accountId } }) {
  try {
    const currentPaging = yield select(path(['transactions', 'transactionsCompleted', 'paging']));
    let transactionFilters = yield select(path(['transactions', 'filters']));
    const user = yield select(path(['user', 'user']));

    if (transactionFilters.timeFrameValue === CONST.TIMEFRAME_INTERVALS.ANY) {
      transactionFilters.timeFrameDates.from_date = moment(user.created_at).subtract(1, 'd').toISOString();
      transactionFilters.timeFrameDates.to_date = moment().add(1, 'd').toISOString();
    }

    const walletId = yield select(path(['wallet', 'wallet', 'wallet_id']));

    if (walletId) {
      const result = yield REQUESTS.GET_TRANSACTIONS(walletId, rejectEmptyParams({
        ...BASE_COMPLETED_FILTER,
        search: transactionFilters.search,
        direction: transactionFilters.direction.length === 1 ? transactionFilters.direction[0] : [],
        type: transactionFilters.type.join(','),
        from_date: transactionFilters.timeFrameDates.from_date,
        to_date: transactionFilters.timeFrameDates.to_date,
        offset: currentPaging.offset + CONST.COMPLETED_PAGE_LIMIT,
        account_id: accountId,
        limit: CONST.COMPLETED_PAGE_LIMIT,
      }));

      const { success, data, paging } = result;
      const { offset, count } = paging;

      if (success) {
        yield put(appendCompletedTransactions({ data, count, offset }));
      } else {
        yield put(appendCompletedTransactions({
          data,
          count: currentPaging.count,
          offset: currentPaging.offset
        }));
      }      
    } else {
      yield put(appendCompletedTransactions({
        data: [],
        count: currentPaging.count,
        offset: currentPaging.offset
      }));
    }
  } catch (error) {
    throw error;
  }
}

function* getCompletedTransactionsFlow({ payload: { accountId } }) {
  try {
    const walletId = yield select(path(['wallet', 'wallet', 'wallet_id']));
    let transactionFilters = yield select(path(['transactions', 'filters']));
    const user = yield select(path(['user', 'user']));

    if (transactionFilters.timeFrameValue === CONST.TIMEFRAME_INTERVALS.ANY) {
      transactionFilters.timeFrameDates.from_date = moment(user.created_at).subtract(1, 'd').toISOString();
      transactionFilters.timeFrameDates.to_date = moment().add(1, 'd').toISOString();
    }

    if (walletId) {
      const result = yield REQUESTS.GET_TRANSACTIONS(walletId, rejectEmptyParams({
        ...BASE_COMPLETED_FILTER,
        search: transactionFilters.search,
        direction: transactionFilters.direction.length === 1 ? transactionFilters.direction[0] : [],
        type: transactionFilters.type.join(','),
        from_date: transactionFilters.timeFrameDates.from_date,
        to_date: transactionFilters.timeFrameDates.to_date,
        offset: 0,
        account_id: accountId,
        limit: CONST.COMPLETED_PAGE_LIMIT,
      }));

      const { success, data, paging } = result;
      const { offset, count } = paging || {};

      if (success) {
        yield put(setCompletedTransactions({ data, count, offset }));
      } else {
        yield put(setCompletedTransactions({ data: [], count: null, offset: null }));
      }
    } else {
      yield put(setCompletedTransactions({ data: [], count: null, offset: null }));
    }
  } catch (error) {
    throw error;
  }
}

function* getTrasactionDetailsFlow ({ payload: { transactionId }}) {
  try {
    const walletId = yield select(path(['wallet', 'wallet', 'wallet_id']));
    const { data, success } = yield REQUESTS.GET_TRANSACTION_DETAILS(walletId, transactionId);

    if (success && data && data.length) {
      yield put(setField('transaction', data[0]));
    } else {
      yield put(setField('transaction', {}));
    }
  } catch (error) {
    throw error;
  }
}

function* getNext90Flow({ payload: { daysCount, accountId }}) {
  try {
    yield put(setField('next90IsLoading', true));

    const walletId = yield select(path(['wallet', 'wallet', 'wallet_id']));
    const pending = yield select(path(['transactions', 'transactionsPending', 'data']));
    let transactionFilters = yield select(path(['transactions', 'filters']));
    const user = yield select(path(['user', 'user'])); // eslint-disable-line no-unused-vars

    if (walletId) {
      const timeFrameDates = {
        from_date: moment(transactionFilters.timeFrameDates.from_date).subtract(daysCount, 'days').toISOString(),
        to_date: moment(transactionFilters.timeFrameDates.to_date).subtract(daysCount, 'days').toISOString(),
      };

      const result = yield REQUESTS.GET_TRANSACTIONS(walletId, rejectEmptyParams({
        ...BASE_COMPLETED_FILTER,
        search: transactionFilters.search,
        direction: transactionFilters.direction.length === 1 ? transactionFilters.direction[0] : [],
        type: transactionFilters.type.join(','),
        from_date: timeFrameDates.from_date,
        to_date: timeFrameDates.to_date,
        account_id: accountId,
        limit: CONST.COMPLETED_PAGE_LIMIT,
      }));

      const { success, data } = yield REQUESTS.GET_TRANSACTIONS(walletId, rejectEmptyParams({
        ...BASE_COMPLETED_FILTER,
        direction: transactionFilters.direction.length === 1 ? transactionFilters.direction[0] : [],
        type: transactionFilters.type.join(','),
        from_date: timeFrameDates.from_date,
        to_date: timeFrameDates.to_date,
        status: [CONST.TRANSACTION_STATUSES.PENDING, CONST.TRANSACTION_STATUSES.SUSPENDED].join(','),
        account_id: accountId,
        limit: CONST.COMPLETED_PAGE_LIMIT,
      }));

      if (success && result.success) {
        yield put(appendCompletedTransactions({
          data: result.data,
        }));

        yield put(setPendingTransactions(pending.concat(data)));
      }

      yield put(setField('next90IsLoading', false));

    }
  } catch (error) {
    throw error;
  }
}

function* applyFilters({ payload: { filters } }) {
  yield put(setField('filters', filters));
  yield put(getPendingTransactions());
  yield put(getCompletedTransactions());
}

function* reportFlow({ payload: { id, reason }}) {
  try {
    yield put(setField('reportIsSending', true));
    const { success } = yield REQUESTS.REPORT(id, reason);
    yield put(setField('reportIsSending', false));

    if (success) {
      yield put(setField('reportConfirmed', true));
    } else {
      yield put(setField('reportConfirmed', false));
    }
  } catch (e) {
    yield put(setField('reportIsSending', false));
    yield put(setField('reportConfirmed', false));
  }
}

function* showPredefinedOrderResultFlow({ payload: { transaction }}) {
  yield put(setField('transactionShown', true));
  yield getCompletedTransactionsFlow();
  yield getPendingTransactionsFlow();
  yield put(setField('transaction', transaction));
}

function* transactionsSaga() {
  yield takeLatest(CONST.APPLY_FILTERS, applyFilters);
  yield takeLatest(CONST.GET_COMPLETED_TRANSACTIONS, getCompletedTransactionsFlow);
  yield takeLatest(CONST.GET_PENDING_TRANSACTIONS, getPendingTransactionsFlow);
  yield takeLatest(CONST.GET_NEXT_COMPLETED_TRANSACTIONS, getNextCompletedTransactionsFlow);
  yield takeLatest(CONST.GET_TRANSACTION_DETAILS, getTrasactionDetailsFlow);
  yield takeLatest(CONST.GET_NEXT_90, getNext90Flow);
  yield takeLatest(CONST.REPORT, reportFlow);
  yield takeLatest(CONST.SHOW_PREDEFINED_ORDER_RESULT, showPredefinedOrderResultFlow);
}

export default [transactionsSaga];
