export const CONSTANTS = {
  SET_FIELD: '@partner-signup/SET_FIELD',
  START_SIGNUP: '@partner-signup/START_SIGNUP',
  AUTHETICATE_ACC: '@partner-signup/AUTHETICATE_ACC',
  SIGNUP: '@partner-signup/SIGNUP',
  PHONE_NUMBER_ENTERED: '@partner-signup/PHONE_NUMBER_ENTERED',
  OPEN_POPUP: '@partner-signup/OPEN_POPUP',

  // steps
  STEP_LOADING: '@partner-signup/STEP_LOADING',
  STEP_WELCOME: '@partner-signup/STEP_WELCOME',
  STEP_SIGNUP: '@partner-signup/STEP_SIGNUP',
  STEP_PHONE_NUMBER: '@partner-signup/STEP_PHONE_NUMBER',
  STEP_LINK_SENT: '@partner-signup/STEP_LINK_SENT',
  STEP_PAYPHONE_AUTODETECT: '@partner-signup/STEP_PAYPHONE_AUTODETECT',

  STEP_LINK_EXPIRED: '@partner-signup/STEP_LINK_EXPIRED',
  STEP_SOMETHING_WENT_WRONG: '@partner-signup/STEP_SOMETHING_WENT_WRONG',
  STEP_UNABLE_TO_SIGNUP: '@partner-signup/STEP_UNABLE_TO_SIGNUP',
};

export default CONSTANTS;
