import CONST from '../constants/transactions-constants';

export const getNextCompletedTransactions = (accountId) => ({
  type: CONST.GET_NEXT_COMPLETED_TRANSACTIONS,
  payload: { accountId }
});

export const appendCompletedTransactions = ({ offset, count, data }) => ({
  type: CONST.APPEND_COMPLETED_TRANSACTIONS,
  payload: { offset, count, data },
});

export const getPendingTransactions = (accountId) => ({
  type: CONST.GET_PENDING_TRANSACTIONS,
  payload: { accountId }
});

export const getTransactionDetails = transactionId => ({
  type: CONST.GET_TRANSACTION_DETAILS,
  payload: { transactionId },
});

export const getNext90 = (daysCount, accountId) => ({
  type: CONST.GET_NEXT_90,
  payload: { daysCount, accountId },
});

export const getCompletedTransactions = (accountId) => ({
  type: CONST.GET_COMPLETED_TRANSACTIONS,
  payload: { accountId }
});

export const setPendingTransactions = data => ({
  type: CONST.SET_PENDING_TRANSACTIONS,
  payload: { data },
});

export const setCompletedTransactions = ({ offset, count, data }) => ({
  type: CONST.SET_COMPLETED_TRANSACTIONS,
  payload: { offset, count, data },
});

export const setField = (key, value) => ({
  type: CONST.SET_FIELD,
  payload: { key, value },
});

export const applyFilters = filters => ({
  type: CONST.APPLY_FILTERS,
  payload: { filters },
});

export const clearFilters = () => ({
  type: CONST.CLEAR_FILTERS,
});

export const report = (id, reason) => ({
  type: CONST.REPORT,
  payload: { id, reason },
});

export const showPredefinedOrderResult = (transaction) => ({
  type: CONST.SHOW_PREDEFINED_ORDER_RESULT,
  payload: { transaction }
});