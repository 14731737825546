import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../../../constants/prop-types';
import { merge, filter, find, isEmpty, propEq } from 'ramda';
import { hasPermission, permissionAllowedLimit, permissionErrorMessage } from '../../../utils/permissions';
import Button from '../../button';
import NotificationBadge from '../../notification-badge';
import ExchangeMoneyInput from '../../send-money/exchange-money-input';
import TransferSelector from '../transfer-selector';
import TransferSelectorDropdownList from '../transfer-selector-dropdown-list';
import { minBalanceAccountSelector, maxBalanceAccountSelector, userBalanceSelector } from '../../../reducers/wallet-reducer';
import { Decimal } from 'decimal.js';
import {
  calcSourceBalance,
  balance,
  _convertCurrency,
  filterAccountsByOptions,
  getAccountFromCurrency
} from '../../../utils/send-money-utils';
import { permissionSelector } from '../../../reducers/user-reducer';
import TRANSFER_CONST from '../../../constants/transfer-constants';
import { updateTransfer, setField } from '../../../actions/transfer-actions';
import { setPaymentPopup } from '../../../actions/app-actions';
import Loader from '../../loader';

const TransferConfig = (props) => {
  const [state, setState] = useState(props.transfer);
  const dropdownRef = useRef(null);

  const changeState = (value) => {
    setState(Object.assign({}, state, value));
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const onChangeValue = (value) => {
    changeState({
      amount: value,
      errorMsg: '',
    });
  };

  const setTransferAccount = (account, placement) => {
    const field = placement === 'top' ? 'sourceAccount' : 'destinationAccount';
    const transactionCurrency = 'USD';

    if (field === 'sourceAccount') {
      changeState({
        dropdownOpened: state.dropdownOpened !== placement ? placement : null,
        sourceAccount: account,
        destinationAccount: {},
        transactionCurrency,
        errorMsg: '',
      });
      return;
    }
    changeState({
      dropdownOpened: state.dropdownOpened !== placement ? placement : null,
      destinationAccount: account,
      transactionCurrency,
      errorMsg: '',
    });
  };

  const getDropdownList = (placement) => {
    const { wallet: { bankAccounts, accounts }, configuration, currencyOptions } = props;
    const { sourceAccount } = state;
    const banks = filter(b => b.amounts_verified)(bankAccounts);
    const unusedCurrencyAccounts = getAccountFromCurrency(accounts, currencyOptions);


    if (placement === 'top') {
      return {
        accounts,
        bankAccounts: banks
      };
    }

    return filterAccountsByOptions(accounts.concat(unusedCurrencyAccounts), banks, sourceAccount, configuration);
  };

  const handleClickOutside = (event) => {
    const { dropdownOpened } = state;
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownOpened) {
      changeState({
        dropdownOpened: null,
      });
    }
  };

  const toggleDropdown = (placement) => {
    changeState({
      dropdownOpened: state.dropdownOpened !== placement ? placement : null,
      dropdownList: getDropdownList(placement),
    });
  };

  const throwError = (text, hasLimit = false) => {
    changeState({
      errorMsg: text,
      hasLimit: hasLimit,
    });
  };

  const validate = () => {
    const { wallet: { accounts }, transactionRate, permissions } = props;
    const { sourceAccount, destinationAccount, amount, transactionCurrency } = state;
    const account = find(acc => acc.account_id === sourceAccount.account_id, accounts);
    const accountCurrency = sourceAccount.type ? sourceAccount.balance.currency : 'USD';
    const accountBalance = account && (
      accountCurrency !== transactionCurrency 
        ? _convertCurrency(
          accountCurrency, transactionCurrency, balance(account.balance.ordinal, account.balance.decimal, account.balance.sign), transactionRate
        )
        : balance(account.balance.ordinal, account.balance.decimal, account.balance.sign)
    );
    const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');

    if (isEmpty(sourceAccount) || isEmpty(destinationAccount)) {
      throwError('Choose an account');
      return false;
    }
    if (parseFloat(amount) <= 0) {
      throwError('Amount should be greater than 0.00');
      return false;
    }
    if (parseFloat(amount) > accountBalance) {
      throwError('Not enough funds');
      return false;
    }
    if (
      (!destinationAccount.type && !hasPermission(permissions, 'can_withdraw_from_br'))
      || !hasPermission(permissions, 'can_transfer')
      || !hasPermission(permissions, 'resides_in_allowed_state')
    ) {
      return false;
    }
    if (
      ((!isEmpty(sourceAccount) && !sourceAccount.type) ||
      (!isEmpty(destinationAccount) && !destinationAccount.type)) &&
      parseFloat(maxAchAllowed) < parseFloat(amount)
    ) {
      throwError(`${maxAchAllowed} USD max for bank transfers. `, true);
      return false;
    }
    return confirmation();
  };

  const confirmation = () => {
    const { wallet: { accounts }, transactionRate } = props;
    const { sourceAccount, destinationAccount, transactionCurrency, amount } = state;
    const account = find(acc => acc.account_id === sourceAccount.account_id, accounts);
    const accountCurrency = sourceAccount.type ? account.balance.currency : 'USD';
    const destinationAccountCurrency = destinationAccount.type ? destinationAccount.balance.currency : 'USD';
    const amountValue = amount || '0.00';
    const amountCharge = _convertCurrency(transactionCurrency, accountCurrency, amountValue, transactionRate);
    const amountReceive = _convertCurrency(transactionCurrency, destinationAccountCurrency, amountValue, transactionRate);
    props.updateTransfer({
      ...state,
      type: 'Transfer',
      amountCharge,
      chargeCurrency: accountCurrency,
      amountReceive,
      receiveCurrency: destinationAccountCurrency,
    });
    props.setField('flowStep', TRANSFER_CONST.STEP_CONFIRMATION);
  };

  const changeCurrency = () => {
    const { transactionCurrency, destinationAccount, sourceAccount, amount } = state;
    const sourceAccountCurrency = sourceAccount.bank_account_id ? 'USD' : sourceAccount.balance.currency;
    const destinationAccountCurrency = destinationAccount.bank_account_id ? 'USD' : destinationAccount.balance.currency;
    const transactionCurrencyValue = transactionCurrency === sourceAccountCurrency ? destinationAccountCurrency : sourceAccountCurrency;
    changeState({
      transactionCurrency: transactionCurrencyValue,
      amount: amount ? amount : ''
    });
  };

  const useWholeBalance = () => {
    const { wallet: { accounts }, transactionRate } = props;
    const { sourceAccount, transactionCurrency } = state;
    const account = find(acc => acc.account_id === sourceAccount.account_id, accounts);
    const accountBalance = account && calcSourceBalance(account, transactionCurrency, transactionRate);
    changeState({
      amount: accountBalance,
      errorMsg: '',
    });
  };

  const checkPermissions = () => {
    const { permissions } = props;
    const { destinationAccount, sourceAccount } = state;
    const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
    if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'resides_in_allowed_state')}
        />
      );
    } else if (!hasPermission(permissions, 'can_transfer')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_transfer')}
        />
      );
    } else if (
      ((!isEmpty(sourceAccount) && !sourceAccount.type) ||
      (!isEmpty(destinationAccount) && !destinationAccount.type)) &&
      parseFloat(maxAchAllowed) <= 0
    ) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'max_ach_allowed')}
        />
      );
    } else if (
      !isEmpty(destinationAccount)
      && !destinationAccount.type
      && !hasPermission(permissions, 'can_withdraw_from_br')
    ) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_withdraw_from_br')}
        />
      );
    } 
  };

  const { transactionRate, isLoading, disabled, permissions, currencyInputDisabled: isNegative, setPaymentPopup } = props;
  const {
    sourceAccount,
    destinationAccount,
    amount,
    transactionCurrency,
    dropdownList,
    dropdownOpened,
    errorMsg,
    hasLimit,
  } = state;

  if (isLoading) {
    return <Loader size="sm" color="blue" className="-centered" />;
  }

  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
  const currencyList = ['USD', 'USD'];


  return (
    <Fragment>
      <div className="page_header">
        <h1 className="page_title">
          Transfer
        </h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          {checkPermissions()}
          <div ref={dropdownRef}>
            <TransferSelector
              sourceAccount={sourceAccount}
              destinationAccount={destinationAccount}
              dropdownOpened={dropdownOpened}
              dropdownList={dropdownList}
              transactionCurrency={transactionCurrency}
              transactionRate={transactionRate}
              setAccount={setTransferAccount}
              toggleDropdown={toggleDropdown}
              isLoading={isLoading}
              permissions={permissions}
              currencyInputDisabled={isNegative}
            >
              <TransferSelectorDropdownList
                dropdownOpened={dropdownOpened}
                currencyInputDisabled={isNegative}
                dropdownList={dropdownList}
                setAccount={setTransferAccount}
                permissions={permissions}
                transactionRate={transactionRate}
              />
            </TransferSelector>
          </div>
          <div className="layer -space-up-4xl-mobile -space-up-4xl-desktop">
            <ExchangeMoneyInput
              label="Transfer Amount"
              inputName="transfer-amount"
              className="-has-switch"
              invalid={!!errorMsg}
              errorMessage={errorMsg}
              amount={amount}
              currency={transactionCurrency}
              autoFocus
              disabled={isNegative && !hasPermission(permissions, 'can_deposit_to_br')}
              showLimits={hasLimit}
              onShowLimits={() => setPaymentPopup(true, false)}
              onEnter={validate}
              onChangeInput={v => onChangeValue(v)}
              onChangeCurrency={changeCurrency}
              disableCurrencyChange={currencyList[0] === currencyList[1] || isLoading || disabled}
              currencyList={currencyList}
            />
          </div>
          {sourceAccount.account_id && !disabled && (
            <div className="layer -space-up-md">
              <Button 
                className="js-transfer-use-whole-balance-button" 
                color="pale-blue" 
                ySize="xs"
                disabled={isNegative && !hasPermission(permissions, 'can_deposit_to_br')}
                onClick={useWholeBalance}
              >
                  Use whole balance
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          to="/dashboard"
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -cancel"
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          disabled={
            !(parseFloat(amount) > 0)
              || isEmpty(sourceAccount)
              || isEmpty(destinationAccount)
              || !hasPermission(permissions, 'resides_in_allowed_state')
              || !hasPermission(permissions, 'can_transfer')
              || (!isEmpty(destinationAccount) && !destinationAccount.type
                && !hasPermission(permissions, 'can_withdraw_from_br'))
              || (
                ((!isEmpty(sourceAccount) && !sourceAccount.type) ||
                (!isEmpty(destinationAccount) && !destinationAccount.type)) &&
                parseFloat(maxAchAllowed) <= 0
              )
              || disabled
          }
          onClick={validate}
        >
            Next
        </Button>
      </div>
    </Fragment>
  );
};

TransferConfig.propTypes = {
  transfer: types.transferReducerTypes.data,
  transactionRate: types.RatesShape,
  wallet: PropTypes.shape(types.walletReducerTypes),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  updateTransfer: PropTypes.func,
  setField: PropTypes.func,
  permissions: types.EnabledStatesShape,
  setPaymentPopup: PropTypes.func,
  currencyInputDisabled: PropTypes.bool,
  currencyOptions: PropTypes.arrayOf(types.CurrencyOptionShape),
  configuration: types.ApplicationConfigurationShape
};

function mapStateToProps(state) {
  const { wallet: { bankAccounts, accounts }, application: { options, configuration } } = state;
  const bankAccount = find(bank => bank.amounts_verified && bank.withdraw_status === 'allowed', bankAccounts) || {};
  const userBalance = userBalanceSelector(state);
  const permissions = permissionSelector(state);
  const maxBalanceAccount = maxBalanceAccountSelector(state);
  const minBalanceAccount = minBalanceAccountSelector(state);

  let predefinedTransfer = state.transfer.predefined === 'transfer'
    ? ({ sourceAccount: {}, destinationAccount: {} })
    : ({ sourceAccount: find(propEq('name', state.transfer.predefined))(accounts), destinationAccount: {} });
  
  const userBalanceNegative = userBalance < 0;

  if (userBalanceNegative) {
    const amount = new Decimal(userBalance).abs().toFixed(2, 3);
    predefinedTransfer = { sourceAccount: bankAccount, destinationAccount: minBalanceAccount, amount };
  }

  if(!hasPermission(permissions, 'can_deposit_to_br')) {
    predefinedTransfer = ({ sourceAccount: maxBalanceAccount, destinationAccount: bankAccount });
  }

  return {
    transfer: ((state.transfer.predefined && isEmpty(state.transfer.data.sourceAccount)) || userBalanceNegative)  
      ? merge(state.transfer.data, predefinedTransfer)
      : state.transfer.data,
    transactionRate: state.transfer.transactionRate,
    isLoading: state.transfer.isLoading,
    disabled: state.transfer.disabled,
    wallet: state.wallet,
    permissions,
    currencyInputDisabled: userBalanceNegative,
    currencyOptions: options && options.length > 0 && options[0].currencies,
    configuration
  };
}

export default connect(mapStateToProps, { updateTransfer, setPaymentPopup, setField })(TransferConfig);