import React from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import Modal from '../modal';


const CreditCardErrorModal = ({ handleContinue, handleChooseAnother, bankClears, ...props }) => {

  const closeModal = () => {
    props.onClose();
  };

  return (
    <Modal {...props} className="card-error-modal" isHalfling>
      <div className="modal_body">
        <h4>Backup payment method failed!</h4>
        <p>We’ve been unable to verify funds on the Card you selected. You can choose another payment and backup payment method or continue with no backup</p>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <div className="card-error-modal__actions">
            <Button
              color="blue"
              xSize="full"
              className="js-submit-modal-button modal_footer-control -choose-another"
              onClick={handleChooseAnother}
            >
            Choose another payment method
            </Button>
            <Button
              color="transparent"
              xSize="full"
              className="js-submit-modal-button modal_footer-control -continue"
              onClick={handleContinue}
            >
              <span>Continue with no backup</span>
              <span className="bank-clears">{bankClears ? `Money delivered: ${bankClears}` : 'Money delivered in 3-5 business days'}</span>
            </Button>
          </div>
          <Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            onClick={closeModal}
          >
              Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

CreditCardErrorModal.propTypes = {
  handleContinue: PropTypes.func,
  handleChooseAnother: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  bankClears: PropTypes.string
};

export default CreditCardErrorModal;
