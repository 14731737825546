const DEFAULT_ERROR_MESSAGES = {
  resides_in_allowed_state: `Sorry, ${process.env.REACT_APP_DOMAIN_STRING} is not yet available as a payment method at your location`,
  can_transfer: 'Transfers are not allowed at this time',
  can_send_money: 'Payments are not allowed at this time',
  can_order: 'Orders are not allowed at this time',
  can_withdraw_from_br: 'Withdrawals are not allowed at this time',
  max_send_amount: '',
  max_ach_allowed: 'Bank transfers are not allowed at this time',
};

const CONSTANTS = {
  DEFAULT_ERROR_MESSAGES,
};

export default CONSTANTS;
