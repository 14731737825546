import { assocPath, always, evolve } from 'ramda';
import CONST from '../constants/set-new-password-constants';

const INIT_STATE = {
  isLoading: false,
  password: '',
  passwordConfirmation: '',
  unable: false,
  validationErrors: {
    email: null,
    password: null,
  },
};

const setNewPassword = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.message, state);
    case CONST.SET_FIELD:
      return evolve({
        [action.payload.field]: always(action.payload.value),
        validationErrors: {
          [action.payload.field]: always(null),
        },
      }, state);
    default:
      return state;
  }
};

export default setNewPassword;
