import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginLayout from '../log-in-layout';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import { oauthSendConsent, setField } from '../../actions/login-actions'; 

const OAuthConsentPage = ({ oauthSendConsent, setField, agreement, isLoading }) => {

  return (
    <LoginLayout wrapperClassName="-x-md">
      <div className="page_header">
        <h1 className="js-page-title page_title">User Agreement</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              Prior to granting you access to BitRail, 
              we require that you agree to the latest version of our User Agreement.
              We take your information and privacy very seriously.
              We do not share your information to third parties unless you have given us your consent. 
              Please contact <a
                rel="noopener noreferrer"
                href={process.env.REACT_APP_HELP_DESK_URL}
                target="_blank"
              >
                Help Desk
              </a> if you need assistance.
            </p>
          </div>
          <div className="consent full-width">
            <Checkbox
              inputName="terms-and-condition"
              label={
                <span>
                  I agree with the
                </span>
              }
              className="-bordered"
              checked={agreement}
              onChange={({ target: { checked } }) => setField('agreement', checked)}
            />
                  &nbsp;<a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            >
              User Agreement
            </a>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          ySize="md"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={oauthSendConsent}
          disabled={!agreement || isLoading}
          loading={isLoading}
        >
          Continue
        </Button>
      </div>
    </LoginLayout>
  );
};

OAuthConsentPage.propTypes = {
  oauthSendConsent: PropTypes.func.isRequired,
  agreement: PropTypes.bool,
  setField: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  agreement: state.login.agreement,
  isLoading: state.login.isLoading
});

export default connect(mapStateToProps, { oauthSendConsent, setField })(OAuthConsentPage);