import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loanOfferReducerTypes } from '../../constants/prop-types';
import { connect } from 'react-redux';

import BasicLayout from '../../pages/basic-layout';
import Button from '../button';
import Svg from '../svg';

import { currencyFormat } from '../../utils/send-money-utils';

class LoanOfferSuccess extends Component {
  downloadPDF = (pdf) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = 'accepted-loan.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  render() {
    const { approvedLoan, acceptedAmount } = this.props.loanOffer;

    return (
      <BasicLayout wrapperClassName="-content-y-centered -content-x-centered -x-xs -no-page-header">
        <div className="page_body">
          <div className="page_content">
            <form className="form new-transaction" action="">
              <div className="transaction-result -success">
                <Svg name="transaction-success" className="transaction-result_icon" />
                <div className="transaction-result_body">
                  <div className="transaction-result_amount">
                    <h1>Loan approved</h1>
                  </div>
                  <div className="transaction-result_comment">
                    Congratulations, your loan is approved and&nbsp;
                    <strong>${currencyFormat(acceptedAmount / 100)}</strong> has been transferred to your BitRail account.
                    Your Loan Application was sent to you by email.
                  </div>
                  <span
                    onClick={() => this.downloadPDF(approvedLoan.loan_documents_base64)}
                    className="transaction-result_details-link font-weight-bold color-blue cursor-pointer"
                  >
                    Download Loan Application PDF
                  </span>
                  <div className="layer -space-up-lg">
                    <p className="font-size-secondary-responsive text-align-center">
                      Download will continue in your browser.<br />
                      Please do not close the page until the download is complete.
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="page_controls">
          <Button to="/dashboard" color="blue" xSize="full" className="page_control -submit">Continue</Button>
        </div>
      </BasicLayout>
    );
  }
}

LoanOfferSuccess.propTypes = {
  loanOffer: PropTypes.shape(loanOfferReducerTypes),
};

function mapStateToProps(state) {
  return {
    loanOffer: state.loanOffer,
  };
}

export default connect(mapStateToProps, null)(LoanOfferSuccess);
