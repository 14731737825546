import React from 'react';

import BasicLayout from '../../pages/basic-layout';
import Button from '../button';
import Svg from '../svg';

const LoanOfferFailure = () => {
  return (
    <BasicLayout wrapperClassName="-content-y-centered -content-x-centered -x-xs -no-page-header">
      <div className="page_body">
        <div className="page_content">
          <form className="form new-transaction" action="">
            <div className="transaction-result -failed">                
              <Svg name="circled-cross-colored" className="transaction-result_icon splash_icon" />
              <div className="transaction-result_body">
                <div className="transaction-result_amount">
                  <h1>Something went wrong</h1>
                </div>
                <div className="transaction-result_comment">
                    Unfortunately something went wrong and your loan application was not successful.
                </div>
                <p className="font-size-secondary-responsive">
                    Please contact U.S. Credit, Inc for more details
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button to="/dashboard" color="blue" xSize="full" className="page_control -submit">
            Continue
        </Button>
      </div>
    </BasicLayout>
  );
};

export default LoanOfferFailure;
