import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';
import { Link } from 'react-router-dom';
import ModalACH from '../components/modal-ach';

const BankAccountsInfoModal = ({ show, onClose, emailVerified, addBankAccountInstant, isLoading }) => {
  const [achModalShown, setAchModalShown] = useState(false);

  const toggleAchModal = () => {
    setAchModalShown(!achModalShown);
  };

  return (
    <Modal containerClassName="-white -x-sm -closebutton-top" closeButton show={show} onClose={onClose}>
      <div className="modal_header">
        <h1 className="js-modal-title">How to add and verify bank account</h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-up-xl">
          <p className="font-size-secondary-responsive">
            By adding a bank account, you agree with the 
            <a
              className="info-link"
              onClick={() => toggleAchModal()}
            >
              &nbsp;ACH Authorization
            </a>
          </p>
        </div>
        <div className="layer -space-up-xl">
          <p className="font-size-secondary-responsive">
            <b>Add and verify your Bank account instantly.</b>
            <br />
            You’ll be redirected to your bank institution page to complete a safe login. Bank account will be connected and verified instantly
          </p>
          <Button
            color="blue"
            xSize="full"
            disabled={isLoading}
            loading={isLoading}
            onClick={addBankAccountInstant}
          >
              Add Bank Account Instantly
          </Button>
        </div>
        <div className="layer -space-up-xxxl -space-down-xxl">
          <p className="font-size-secondary-responsive">
            Adding bank account manually. Takes up to 10 business days before you will be able to use it. Required steps: 
          </p>
          <ul className="font-size-secondary-responsive -ul-padding">
            { !emailVerified && (<li>Please verify your email address</li>) }
            <li>Add Bank Account details (routing and accounting numbers)</li>
            <li>We will send two to four deposits, all less than a dollar, to this account, usually overnight</li>
            <li>Enter these transactions amounts on the Bank Accounts page to verify ownership of your account</li>
            <li>Make sure these funds are still available and that your bank account settings allow withdrawal</li>
            <li>Wait for an attempt from us to withdraw these funds. This way we ensure the ability of withdrawal. 
              Otherwise you will only be able to transfer funds to that account</li>            
          </ul>
          <Link className="color-blue" to="/bank-accounts/new">
            Add manually
          </Link>
        </div>
        <ModalACH
          shown={achModalShown}
          onClose={() => toggleAchModal()}
        />
      </div>
      <div className="modal_footer-controls">
        <Button
          color="blue"
          xSize="full"
          transparency="full"
          className="modal_footer-control -submit"
          onClick={() => onClose()}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

BankAccountsInfoModal.propTypes = { 
  show: PropTypes.bool,
  onClose: PropTypes.func,
  emailVerified: PropTypes.bool,
  addBankAccountInstant: PropTypes.func,
  isLoading: PropTypes.bool 
};

export default BankAccountsInfoModal;
