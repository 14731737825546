import React from 'react';
import PropTypes from 'prop-types';
import { signUpReducerTypes } from '../../constants/prop-types';
import LoginLayout from '../../pages/log-in-layout';
import FormInput from '../form-input';
import Button from '../button';

const PartnerSignupStepPhoneNumber = ({ partnerSignup, partnerSignup: { phoneNumber }, setField, phoneNumberEntered }) => (
  <LoginLayout
    headerClassName="-y-lg"
    hideNav={true}
  >
    <div className="page_header -space-sm">
      <h1 className="page_title">Verify your Phone Number</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <form className="form" onSubmit={(e) => { e.preventDefault(); phoneNumberEntered(); }}>
          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              For your security, we need to make sure it&apos;s really you.
              We need your phone number to associate your account with 
              your mobile phone for security and identity verification.
              Please enter your mobile phone number to continue. 
            </p>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                transparency="half"
                className="full-width"
                label="Phone"
                value={phoneNumber || ''}
                onChange={(value) => {
                  setField(['errors', 'phoneNumber'], null);
                  setField('phoneNumber', value.replace(/[-()+\s_]/gi, ''));
                }}
                errorMessage={partnerSignup.errors.phoneNumber}
                invalid={!!partnerSignup.errors.phoneNumber}
                type="tel"
                inputName="phone-number"
                mask={process.env.REACT_APP_APPLICATION_ENV !== 'development' ? '(999) 999-9999' : '+9 (999) 999-9999'}
                autoFocus
              />
            </div>
          </div>

          <div className="layer -space-up-xxxl font-size-secondary-responsive">
            <p className="color-light-gray">
              We will send an authentication link to your phone.
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
                &nbsp;Need&nbsp;help?
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
    <div className="page_controls">
      <Button
        transparency="full"
        xSize="full"
        className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        onClick={() => {
          setField('phoneNumber', null);
          phoneNumberEntered();
        }}
      >
        Submit documentation instead
      </Button>
      <Button
        color="blue"
        xSize="full"
        className="page_control -submit"
        loading={partnerSignup.isLoading}
        disabled={!!partnerSignup.errors.phoneNumber || partnerSignup.isLoading}
        onClick={phoneNumberEntered}
      >
        Continue
      </Button>
    </div>
  </LoginLayout>
);

PartnerSignupStepPhoneNumber.propTypes = {
  partnerSignup: PropTypes.shape(signUpReducerTypes),
  setField: PropTypes.func,
  phoneNumberEntered: PropTypes.number
};

export default PartnerSignupStepPhoneNumber;
