import React, { useEffect }  from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Svg from './svg';

const PoweredBy = (props) => {
  const { isCustom, isFullWidth, className } = props;
  
  useEffect(() => {
    if(isCustom && isFullWidth) {
      document.querySelector('.page').classList.add('-powered-by');
      const steps = document.querySelector('.page_wizard-steps');
      if(steps) steps.classList.add('-powered-by');
    }

    return () => {
      document.querySelector('.page').classList.remove('-powered-by');
      const steps = document.querySelector('.page_wizard-steps');
      if(steps) steps.classList.remove('-powered-by');
    };
  });
  

  return isCustom ? isFullWidth ? (
    <div className={cn('powered-by -full-width', className ? className : '')}>
      Transactions processed by <Svg name="bitrail-logo-white-two-bars-small" className='powered-by__logo' />
    </div>
  ) : (
    <div className={cn('powered-by -sign', className ? className : '')}>
      <Svg name="powered-by" className='powered-by__logo' />
    </div>
  ) : null;
};

const mapStateToProps = ({ application }) => application && application.ui ? ({
  isCustom: application.ui.is_custom
}) : ({});

PoweredBy.propTypes = {
  isFullWidth: PropTypes.bool,
  isCustom: PropTypes.bool,
  className: PropTypes.string,
};

export default connect(mapStateToProps, {})(PoweredBy);