import React from 'react';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import LoginLayout from '../../pages/log-in-layout';
import FormInput from '../form-input';
import Button from '../button';
import Svg from '../svg';

const PartnerSignupStepWelcome = (props) => {
  const onSubmit = event => {
    event.preventDefault();

    const { gbPassword, isLoading, errors: { gbPassword: gbPasswordError } } = props.partnerSignup;

    if (!isLoading && !gbPasswordError && !!gbPassword ) {
      props.autheticateAcc();
    }
  };

  const { gbUser: { fname, lname, email }, gbPassword, isLoading, errors: { gbPassword: gbPasswordError } } = props.partnerSignup;

  return (
    <LoginLayout
      bgClassName="-full"
      headerClassName="-logo-big -y-xl -streamlined"
      wrapperClassName="-x-sm -content-y-centered -page-control-bottom"
      additionalLogo={(
        <div className="header_logo">
          <Svg name="gb-logo" className="header_logo-icon -gb" />
        </div>
      )}
    >
      <div className="page_header -space-sm">
        <h1 className="page_title">Login to GunBroker</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="layer">
            <h5 className="-inline"> Welcome, {`${fname} ${lname}`}! </h5>
            <p className="font-size-secondary-responsive color-light-gray -inline">
                Login to your GunBroker.com account to start accepting {process.env.REACT_APP_DOMAIN_STRING}.
            </p>
          </div>
          <form
            className="form -login-page-form"
            onSubmit={onSubmit}
          >
            <input type="submit" className="form-input_invisible"/>
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width -disabled-color"
                  label="GunBroker User"
                  type="text"
                  inputName="account-id"
                  value={ email || '' }
                  disabled={ true }
                />
              </div>
            </div>

            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  transparency="half"
                  className="full-width"
                  label="GunBroker Password"
                  type="password"
                  inputName="login-password"
                  value={ gbPassword || '' }
                  errorMessage={gbPasswordError}
                  onChange={value => {
                    props.setField('gbPassword', value);
                    props.setField(['errors', 'gbPassword'], null);
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={onSubmit}
          disabled={isLoading || !!gbPasswordError || !gbPassword }
          loading={isLoading}
        >
            Continue
        </Button>
      </div>
    </LoginLayout>
  );
};


PartnerSignupStepWelcome.propTypes = {
  partnerSignup: PropTypes.shape(types.signUpReducerTypes),
  setField: PropTypes.func,
  autheticateAcc: PropTypes.func
};

export default PartnerSignupStepWelcome;
