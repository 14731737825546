import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/header';
import Svg from '../components/svg';

const DashboardLayout = (props) => {
  const {
    children,
  } = props;

  return (
    <div className="page -dashboard">
      <Header coloredLogo={false} />
      <div className="page_bg -sm">
        <div className="page_bg-wrapper">
          <Svg name="bg-waves-sm" className="page_bg-image" />
        </div>
      </div>
      <div className="page_wrapper -x-lg -close-to-header -page-body-max">
        <div className="page_wrapper-inner">
          {children}
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

DashboardLayout.defaultProps = {
  children: null,
};

export default DashboardLayout;
