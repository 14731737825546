import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { setField, setValidationError } from '../actions/set-new-password-actions';
import CONST from '../constants/set-new-password-constants';
import REQUESTS from '../utils/requests';
import VALIDATION from '../utils/validation';
import { addNotification } from '../actions/toast-actions';
import { formatErrorMessage } from '../utils/string-utils';

import { history } from '../init-store';

function* setNewPassword({ payload: token }) {
  const { password, passwordConfirmation } = yield select(state => state.passwordRecovery.setNewPassword);

  const passwordValidation = VALIDATION.validatePasswordAndConfirmation(password, passwordConfirmation);

  if (passwordValidation.success) {
    yield put(setField('isLoading', true));

    const { success, errors } = yield REQUESTS.RESET_PASSWORD({ token, password });

    if (success) {
      yield put(addNotification('Your password is successfully updated', 200));  
      yield call([history, history.push], '/login');
    } else {
      if (errors) {
        yield all(errors.map(error => {
          return put(addNotification(formatErrorMessage(error)));
        }));
      }
    }
    
    yield put(setField('isLoading', false));
  } else {
    yield put(setValidationError('password', passwordValidation.message));
  }
}

function* setNewPasswordSaga() {
  yield takeLatest(CONST.SET_NEW_PASSWORD, setNewPassword);
}

export default [setNewPasswordSaga];
