import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../components/button';
import Modal from '../../components/modal';
import FormInput from '../../components/form-input';

import VALIDATION from '../../utils/validation';

const ModalEditAccountLabel = (props) => {
  const [user_description, setUserDescription] = useState('');
  const [validationError, setValidationError] = useState(null);
  
  useEffect(() => {
    if(!props.show) {
      setUserDescription(props.bankAccount.user_description);
    }
  }, [props.bankAccount]);

  const closeModal = () => {
    props.onClose();
    setValidationError(null);
  };

  const onFieldChange = (value) => {
    setUserDescription(value);
    setValidationError(null);
  };

  const saveAccountLabel = (bankAccountLabel) => {
    const bankAccountLabelValidation = VALIDATION.validateAccountLabel(bankAccountLabel);
    if (bankAccountLabelValidation.success) {
      props.changeBankAccountLabel(bankAccountLabel);
    } else {
      setValidationError(bankAccountLabelValidation.message);
    }
  };

  const accountLabel = user_description || '';

  return (
    <Modal {...props}>
      <div className="modal_header">
        <h1 className="js-modal-title">Edit Nickname</h1>
      </div>
      <div className="modal_body">
        <div className="form">
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="account-label"
                label="Nickname"
                value={accountLabel}
                className="full-width"
                onPressEnter={() => saveAccountLabel(accountLabel)}
                onChange={onFieldChange}
                errorMessage={validationError}
                invalid={!!validationError}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            disabled={props.isLoading}
            onClick={closeModal}
          >
              Cancel
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button modal_footer-control -submit"
            onClick={() => saveAccountLabel(accountLabel)}
            disabled={props.isLoading || validationError}
            loading={props.isLoading}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalEditAccountLabel.propTypes = {
  bankAccount: PropTypes.shape({
    user_description: PropTypes.string,
  }),
  label: PropTypes.string,
  changeBankAccountLabel: PropTypes.func,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

ModalEditAccountLabel.defaultProps = {
  bankAccount: {
    user_description: '',
  },
  isLoading: false,
};

export default ModalEditAccountLabel;
