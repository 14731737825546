import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';

const ModalAccountsDisclaimer = ({ onClose, shown }) => {
  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">Disclaimer</h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-down-xl">
          <p>
            Funds held in digital wallets powered by BitRail are representations of United States Dollars (USD) 
            for digital purposes only and are not actual USD. These digital representations serve as a convenient and secure way to 
            store and transfer value in a digital format. Once transactions are complete, the digital currencies will be converted to USD 
            and made available for withdrawal
          </p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button 
            color="blue"
            xSize="full"
            className="modal_footer-control"
            onClick={onClose}
          >
            Back to Dashboard
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalAccountsDisclaimer.propTypes = {
  onClose: PropTypes.func, 
  shown: PropTypes.bool,
};

ModalAccountsDisclaimer.defaultProps = {
  onClose: () => {},
  shown: false,
};

export default ModalAccountsDisclaimer;
