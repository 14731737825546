import CONST from '../constants/dashboard-constants';

export const sendVerificationEmail = () => ({
  type: CONST.SEND_VERIFICATION_EMAIL,
});

export const sendDocumentationEmail = () => ({
  type: CONST.SEND_DOCUMENTATION_EMAIL,
});

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});
