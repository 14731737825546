export const CONSTANTS = {
  SET_FIELD: '@gb-signup/SET_FIELD',
  END_STEP: '@gb-signup/END_STEP',
  SET_ERROR: '@gb-signup/SET_ERROR',
  CLEAN_ERRORS: '@gb-signup/CLEAN_ERRORS',
  CLEAR_STATE: '@gb-signup/CLEAR_STATE',
  
  // STEPS CONSTANTS
  STEP_PASSWORD: '@gb-signup/STEP_PASSWORD',
  STEP_ERROR: '@gb-signup/STEP_ERROR',
  STEP_SUCCESS: '@gb-signup/STEP_SUCCESS',
  STEP_BANKACCOUNT: '@gb-signup/STEP_BANKACCOUNT',
  STEP_YODLEE_MOBILE: '@gb-signup/STEP_YODLEE_MOBILE',
  STEP_YODLEE_DESKTOP: '@gb-signup/STEP_YODLEE_DESKTOP',
  STEP_YODLEE_SUCCESS: '@gb-signup/STEP_YODLEE_SUCCESS',
  STEP_WELCOME: '@gb-signup/STEP_WELCOME',
};

export default CONSTANTS;
