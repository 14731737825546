import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Notification = (props) => {
  const {
    children,
    className,
    status,
    onClose,
    onClick,
  } = props;

  const classNames = cn('notification', { [`-${status}`]: !!status, '-has-close-button': !!onClose }, className);

  return (
    <div className={classNames} onClick={onClick}>
      {children}
      {onClose &&
        <button className="notification_close-button" onClick={onClose} />
      }
    </div>
  );
};

Notification.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  status: PropTypes.oneOf(['danger', 'alert', 'success']),
};

Notification.defaultProps = {
  className: null,
  status: null,
  onClose: null,
  onClick: () => {},
};

export default Notification;
