import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Loader from './loader';
import ButtonBase from './button-base';

const Button = (props) => {
  const {
    color,
    className,
    children,
    xSize,
    ySize,
    transparency,
    loading,
    ...rest
  } = props;

  const resultClassName = cn({
    'button': true,
    [className]: !!className,
    '-loading': loading,
    [`-x-${xSize}`]: !!xSize,
    [`-y-${ySize}`]: !!ySize,
    [`-transparency-${transparency}`]: !!transparency,
    [`-color-${color}`]: !!color,
  });

  return (
    <ButtonBase className={resultClassName} {...rest}>
      <div className="button_wrapper">
        {loading ? <Loader color={transparency === 'full' ? color : undefined} size="xs" /> : children}
      </div>
    </ButtonBase>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  xSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'condensed-full', 'full']),
  ySize: PropTypes.oneOf(['min', 'xs', 'sm', 'md', 'lg', 'xl', 'condensed']),
  transparency: PropTypes.oneOf(['no', 'half', 'full']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  color: 'default',
  className: null,
  children: null,
  xSize: null,
  ySize: null,
  transparency: 'no',
  disabled: false,
  loading: false,
};

export default Button;
