export const CONSTANTS = {
  GET_WALLET_BY_SEARCH: '@send_money/GET_WALLET_BY_SEARCH',
  SET_SEARCHED_WALLET: '@send_money/SET_SEARCHED_WALLET',
  CLEAR_SEARCHED_WALLET: '@send_money/CLEAR_SEARCHED_WALLET',
  GET_RATE: '@send_money/GET_RATE',
  SET_RATE: '@send_money/SET_RATE',
  MAKE_PAYMENT: '@send_money/MAKE_PAYMENT',
  FETCH_HANDLE: '@send_money/FETCH_HANDLE',
  SET_TRANSACTION: '@send_money/SET_TRANSACTION',
  SET_ERROR_MESSAGE: '@send_money/SET_ERROR_MESSAGE',
  SET_FIELD: '@send_money/SET_FIELD',
  SET_FIELDS: '@send_money/SET_FIELDS',
  CLEAR_TRANSACTION: '@send_money/CLEAR_TRANSACTION',
  CANCEL_ORDER_PAYMENT: '@send_money/CANCEL_ORDER_PAYMENT',
  PAY_ORDER: '@send_money/PAY_ORDER',
  MAKE_REFUND: '@send_money/MAKE_REFUND',
  GET_REFUND: '@send_money/GET_REFUND',
  CONFIRM_REFUND: '@send_money/CONFIRM_REFUND',
  PAY_PREDEFINED_ORDER: '@send_money/PAY_PREDEFINED_ORDER',
  RETRY_PREFLIGHT: '@send_money/RETRY_PREFLIGHT',
  SET_FIELDS_FROM_QUERY_PARAMS: '@send_money/SET_FIELDS_FROM_QUERY_PARAMS',
  CLOSE_ORDER_PAYMENT: '@send_money/CLOSE_ORDER_PAYMENT',
  MAKE_PREFLIGHT: '@send_money/MAKE_PREFLIGHT',

  // Retry flow steps 
  STEP_ORDER_CONFIG: '@send_money/STEP_RETRY_PREDEFINED',
  STEP_ORDER_CONFIRM: '@send_money/STEP_RETRY_PREDEFINED_CONFIRM',
  STEP_ORDER_SUCCESS: '@send_money/STEP_ORDER_SUCCESS',

  // Flow steps
  STEP_FIND_WALLET: '@send_money/STEP_FIND_WALLET',
  STEP_SET_TRANSACTION_AMOUNT: '@send_money/STEP_SET_TRANSACTION_AMOUNT',
  STEP_SOURCE_CONFIG: '@send_money/STEP_SOURCE_CONFIG',
  STEP_CONFIRMATION: '@send_money/STEP_CONFIRMATION',
  STEP_COMPLETED: '@send_money/STEP_COMPLETED',
};

export default CONSTANTS;
