import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { sendMoneyReducerTypes } from '../constants/prop-types';
import BasicLayout from './basic-layout';

import CONST from '../constants/send-money-constants';
import { clearTransaction } from '../actions/send-money-actions';

import {
  SendMoneyFindWallet,
  SendMoneySetTransactionAmount,
  SendMoneySourceConfig,
  SendMoneyConfirmation,
  SendMoneyTransactionCompleted,
} from '../components/send-money/wizard/index';

const SendMoney = (props) => {
  const { wizardStep } = props.sendMoney;

  useEffect(() => () => {
    props.clearTransaction();
  }, []);

  return (
    <BasicLayout
      wrapperClassName={
        cn(wizardStep === CONST.STEP_COMPLETED && '-content-y-centered -content-x-centered -x-xs -no-page-header')
      }
    >
      {wizardStep === CONST.STEP_FIND_WALLET && (
        <SendMoneyFindWallet />
      )}
      {wizardStep === CONST.STEP_SET_TRANSACTION_AMOUNT && (
        <SendMoneySetTransactionAmount />
      )}
      {wizardStep === CONST.STEP_SOURCE_CONFIG && (
        <SendMoneySourceConfig />
      )}
      {wizardStep === CONST.STEP_CONFIRMATION && (
        <SendMoneyConfirmation />
      )}
      {wizardStep === CONST.STEP_COMPLETED && (
        <SendMoneyTransactionCompleted />
      )}
    </BasicLayout>
  );
};

SendMoney.propTypes = {
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  clearTransaction: PropTypes.func,
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
});

export default connect(mapStateToProps, { clearTransaction })(SendMoney);
