import React from 'react';
import PropTypes from 'prop-types';

const PhoneNumber = ({ number, isLink, className }) => {
  if (!number) return null;

  const modifiedNumber = number.replace(new RegExp('[^0-9]', 'g'), '');

  let formattedNumber;
  // Create more conditions if necessary
  if (modifiedNumber.length === 10) {
    formattedNumber = modifiedNumber
      .replace(/([0-9]{3})([0-9]{3})([0-9]{4})/, '($1) $2-$3');
  } else if (modifiedNumber.length === 11) {
    formattedNumber = modifiedNumber
      .replace(/([0-9])([0-9]{3})([0-9]{3})([0-9]{4})/, '($2) $3-$4');
  } else {
    formattedNumber = modifiedNumber;
  }

  if (isLink) {
    // +1 US dial_code, change it when necessary
    return (<a href={`tel:+1${modifiedNumber}`} className={className}>{formattedNumber}</a>);
  }
  return (<span className={className}>{formattedNumber}</span>);
};

PhoneNumber.propTypes = {
  number: PropTypes.string,
  isLink: PropTypes.bool,
  className: PropTypes.string,
};

PhoneNumber.defaultProps = {
  isLink: false,
};

export default PhoneNumber;
