import { assocPath, flatten } from 'ramda';
import CONST from '../constants/partner-signup-constants';

const INIT_STATE = {
  isLoading: false,
  step: CONST.STEP_LOADING,
  gbUser: {},
  gbPassword: '',
  handle: '',
  password: '',
  consent: false,
  mnoLink: '',
  payfoneIsLoading: false,
  lastPhoneDigits: '',
  maskedEmail: '',
  partner: '',
  token: '',
  expiration: 0,
  phoneNumber: '',
  errors: {
    gbPassword: null,
    phoneNumber: null,
  }
};

const partnerSignupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath(flatten([action.payload.field]), action.payload.value, state);
    default:
      return state;
  }
};

export default partnerSignupReducer;