import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';
import { formatPhoneNumber } from '../utils/string-utils';
import VALIDATION from '../utils/validation';

const PhoneModal = ({ shown, onClose, isUpdating, updateValues }) => {
  const [phone, setPhone] = useState('');
  const [errors, setErrors] = useState({ phone: null });
  const [step, setStep] = useState(0);

  useEffect(()=>{
    if (shown) {
      setPhone('');
      setErrors({ phone: null });
      setStep(0);  
    }
  }, [
    shown
  ]);

  const onUpdate = () => {
    setErrors({ phone: null });

    const validation = VALIDATION.validatePhoneNumber(phone);

    if (!validation.success) {
      setErrors({ phone: validation.message});
    } else {
      setStep(1);
    }
  };

  const onChange = (phone) => {
    setErrors({ phone: null });
    setPhone(phone.replace(/[-()+\s_]/gi, ''));
  };

  const onUpdatePhone = () => {
    updateValues(phone);
  };

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1 className="js-modal-title">Change your phone number</h1>
      </div>
      <div className="modal_body">
        { step === 0 && 
          (<form className="form" onSubmit={onUpdate}>
            <div className="layer form_row">
              <p>
                For your security you will be logged out and
                required to complete identity verification with
                new number.
              </p>
            </div>
            <div className="layer -space-up-md">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  className="full-width"
                  label="New phone number"
                  type="tel"
                  inputName="phonenum"
                  errorMessage={errors.phone}
                  invalid={!!errors.phone}
                  value={phone}
                  onChange={val => onChange(val)}
                  autoFocus
                  mask={process.env.REACT_APP_APPLICATION_ENV !== 'development' ? '(999) 999-9999' : '+9 (999) 999-9999'}
                />
              </div>
            </div>
            <div className="layer form_row -space-up-md">
              <p>
                Once you complete verification, you will start
                receiving verification links on your new phone
                number and you will be able to use it for login.
              </p>
            </div>
            <input type="submit" style={{ display: 'none' }} />
          </form>)
        }

        {
          step === 1 && 
          (
            <form className="form" onSubmit={onUpdatePhone}>
              <div className="layer">
                <div className="form-input-container">
                  <div className="layer form_row">
                    <h4>
                      Are your sure you want to change your phone number to
                      
                      &nbsp;<nobr>{formatPhoneNumber(phone)}?</nobr>
                    </h4>
                    <p>
                      You will be logged out and required to complete identity verification with your new
                      number in order to be able to access your wallet.
                    </p>
                    <p>
                      In some cases verification could take up to 2 business days.
                    </p>
                  </div>
                  <div className="layer form_row -space-up-lg">
                    <Button
                      color="blue"
                      xSize="full"
                      className="js-submit-modal-button modal_footer-control -submit"
                      onClick={onUpdatePhone}
                      disabled={isUpdating || !!errors.phone}
                      loading={isUpdating}
                    >
                      Verify new phone number
                    </Button>
                  </div>
                </div>
              </div>
              <input type="submit" style={{ display: 'none' }} />
            </form>
          )
        }
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          { step === 0 && 
          (<Button
            color="blue"
            xSize="full"
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={onUpdate}
            disabled={isUpdating || !!errors.phone}
            loading={isUpdating}
          >
            Change Phone Number
          </Button>)
          }
        </div>
      </div>
    </Modal>
  );
};

PhoneModal.propTypes = {
  shown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default PhoneModal;
