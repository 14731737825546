import CONST from '../constants/gb-signup-constants';

export const setField = (path, value) => ({
  type: CONST.SET_FIELD,
  payload: { path, value }
});

export const endStep = (step, account) => ({
  type: CONST.END_STEP,
  payload: { step, account },
});