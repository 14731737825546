import { takeLatest, put, select } from 'redux-saga/effects';
import REQUESTS from '../utils/requests';
import CONST from '../constants/wallet-constants';
import { addNotification } from '../actions/toast-actions';
import { setNamesForAccounts } from '../utils/send-money-utils';

import {
  setCurrentWallet,
  setWalletBankAccounts,
  setWalletAccounts,
  setWalletCardAccounts,
  setCurrencyPromos,
  setField,
  setRate
} from '../actions/wallet-actions';
import VALIDATION from '../utils/validation';

function* fetchAccounts() {
  try {
    const { wallet_id } = yield select(state => state.wallet.wallet);
    if(wallet_id) {
      const { options } = yield select(state => state.application);
      const currencies = Array.isArray(options) && options.length > 0 && options[0].currencies;
      const walletAccounts = yield REQUESTS.GET_WALLET_ACCOUNTS(wallet_id);
      const currencyPromos = yield REQUESTS.GET_CURRENCY_PROMOS(wallet_id);
  
      if (walletAccounts.success) {
        yield put(setWalletAccounts(setNamesForAccounts(walletAccounts.data, currencies)));
      } else {
        yield put(setWalletAccounts([]));
      }

      if (currencyPromos.success && currencyPromos.data.length > 0 && currencyPromos.data[0].accounts.length > 0) {
        yield put(setCurrencyPromos(currencyPromos.data[0].accounts));
      } else {
        yield put(setCurrencyPromos([]));
      }
    }
  } catch (error) {
    throw error;
  }
}

function* getTransactionRate() {
  try {
    const { success, data } = yield REQUESTS.GET_RATE();
    if (success) {
      yield put(setField('disabled', false));
      yield put(setRate(data[0]));
    } else {
      yield put(setField('disabled', true));
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    throw e;
  }
}

function* getCurrentWalletFlow({ payload }) {
  try {
    const { success, data } = yield REQUESTS.GET_USER_WALLETS(payload.userId);
    const rates = yield REQUESTS.GET_RATE();

    if (success) {
      const { wallet_id } = data[0];

      yield put(setCurrentWallet(data[0]));

      if (wallet_id) {
        const walletAccounts = yield REQUESTS.GET_WALLET_ACCOUNTS(wallet_id);
        const walletBankAccounts = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);
        const walletCardAccounts = yield REQUESTS.GET_WALLET_CARD_ACCOUNTS(wallet_id);
        const { options } = yield select(state => state.application);
        const currencies = Array.isArray(options) && options.length > 0 && options[0].currencies;

        if (walletAccounts.success) {
          yield put(setWalletAccounts(setNamesForAccounts(walletAccounts.data, currencies)));
        } else {
          yield put(setWalletAccounts([]));
        }

        if (walletBankAccounts.success) {
          yield put(setWalletBankAccounts(walletBankAccounts.data));        
        } else {
          yield put(setWalletBankAccounts([]));
        }

        if (walletCardAccounts.success) {
          yield put(setWalletCardAccounts(walletCardAccounts.data));        
        } else {
          yield put(setWalletCardAccounts([]));
        }
      } else {
        yield put(setCurrentWallet({}));
      }
      yield put(setField('isLoading', false));
    } else {
      yield put(setCurrentWallet({}));
      yield put(setField('isLoading', false));
    }

    if(rates.success) {
      yield put(setRate(rates.data[0]));
    } else {
      yield put(setRate({}));
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* updateWalletHandle({ payload }) {
  try {
    const { wallet } = yield select(state => state.wallet);
    const handleValidation = VALIDATION.validateHandle(payload.handle.handle);

    if (handleValidation.success) {
      const { success, data } = yield REQUESTS.UPDATE_WALLET_HANDLE(wallet.wallet_id, payload.handle);

      if (success && data[0]) {
        yield put(setCurrentWallet(data[0]));
      } else {
        yield put(setField('isUpdating', false));
      }
    } else if (!handleValidation.success) {
      yield put(setField('errorMessage', handleValidation.message));
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* updateTransactionAllocation({ payload }) {
  try {
    const { wallet } = yield select(state => state.wallet);
    const updateParams = { account: payload.walletAccounts };
    const { success } = yield REQUESTS.UPDATE_TRANSACTION_ALLOCATION(wallet.wallet_id, updateParams);

    if (success) {
      const walletAccounts = yield REQUESTS.GET_WALLET_ACCOUNTS(wallet.wallet_id);
      if (walletAccounts.success) {
        yield put(setWalletAccounts(walletAccounts.data));
        yield put(setField('isUpdating', false));
      }
    } else {
      yield put(setField('isUpdating', false));
    }
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* updateEIN({ payload: { ein } }) {
  try {
    const { wallet } = yield select(state => state.wallet);
    const { success, data } = yield REQUESTS.UPDATE_WALLET_EIN(wallet.wallet_id, ein);

    if (success && data[0]) {
      yield put(setCurrentWallet(data[0]));
      yield put(addNotification(`Your profile was updated. Thank you!`, 200));
    } else {
      yield put(setField('isUpdating', false));
    } 
  } catch (error) {
    yield put(setField('isUpdating', false));
  }
}

function* walletSaga() {
  yield takeLatest(CONST.GET_CURRENT_WALLET, getCurrentWalletFlow);
  yield takeLatest(CONST.UPDATE_WALLET_HANDLE, updateWalletHandle);
  yield takeLatest(CONST.UPDATE_TRANSACTION_ALLOCATION, updateTransactionAllocation);
  yield takeLatest(CONST.FETCH_ACCOUNTS, fetchAccounts);
  yield takeLatest(CONST.GET_RATE, getTransactionRate);
  yield takeLatest(CONST.UPDATE_EIN, updateEIN);
}

export default [walletSaga];
