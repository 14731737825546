import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { EnabledStatesShape, sendMoneyReducerTypes } from '../../../constants/prop-types';
import { connect } from 'react-redux';
import {
  setField,
  clearSearchedWallet,
} from '../../../actions/send-money-actions';
import { setPaymentPopup } from '../../../actions/app-actions';
import FormInput from '../../../components/form-input';
import Button from '../../../components/button';
import Svg from '../../../components/svg';
import UserCard from '../../../components/user-card';
import ExchangeMoneyInput from '../exchange-money-input';
import CONST from '../../../constants/send-money-constants';
import { hasPermission, permissionAllowedLimit, permissionErrorMessage } from '../../../utils/permissions';
import NotificationBadge from '../../notification-badge';

const  SendMoneySetTransactionAmount = (props) => {
  const [errorMsg, setErrorMsg] = useState('');
  const {
    sendMoney: {
      searchString,
      paymentAmount,
      paymentCurrency,
      paymentNotes,
      foundWallet,
      isLoading,
    },
    permissions,
    setPaymentPopup,
  } = props;


  const onFieldChange = (field) => {
    return (value) => {
      props.setField(field, value);
      setErrorMsg(field === 'paymentAmount' && '');
    };
  };

  const goToSourceConfiguration = () => {
    const limit = permissionAllowedLimit(permissions, 'max_send_amount');
    if (parseFloat(paymentAmount) > parseFloat(limit)) {
      setErrorMsg(`${limit + ' ' + paymentCurrency} max for a single transaction. `);
      return;
    }
    if (parseFloat(paymentAmount) > 0) {
      props.setField('paymentAmount', paymentAmount);
      props.setField('wizardStep', CONST.STEP_SOURCE_CONFIG);
    }
  };

  const changeCurrency = () => {
    props.setField('paymentCurrency', 'USD');
  };

  const clear = () => {
    const { isLoading } = props.sendMoney;
    if (!isLoading) {
      props.clearSearchedWallet();
    }
  };

  const checkPermissions = () => {
    const { permissions } = props;
    if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'resides_in_allowed_state')}
        />
      );
    } else if (!hasPermission(permissions, 'can_send_money')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_send_money')}
        />
      );
    }
  };

  return (
    <Fragment>
      <div className="page_header">
        <h1 className="page_title">
            Send Money
          <Svg name="arrow-right-long" className="page_title-icon" />
        </h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          {checkPermissions()}
          <div className="form new-transaction">
            <div className="new-transaction_user user-list">
              <UserCard
                avatarSrc={foundWallet.avatar_image_url}
                avatarType={foundWallet.type}
                className="user-list_item"
                contentMain={foundWallet.name}
                contentSecondary={<span className="word-break-word">{searchString}</span>}
                onClick={clear}
              />
            </div>
            <div className="layer">
              <ExchangeMoneyInput
                label="Payment Amount"
                inputName="payment-amount"
                invalid={!!errorMsg}
                errorMessage={errorMsg}
                showLimits={true}
                onShowLimits={() => setPaymentPopup(true, false)}
                disabled={isLoading}
                disableCurrencyChange={true}
                amount={paymentAmount}
                currencyList={['USD', 'USD']}
                currency={paymentCurrency}
                onEnter={goToSourceConfiguration}
                onChangeInput={onFieldChange('paymentAmount')}
                onChangeCurrency={changeCurrency}
              />
            </div>
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  label="Payment notes (optional)"
                  color="white"
                  disabled={isLoading}
                  inputName="payment-notes"
                  className="full-width"
                  value={paymentNotes}
                  onChange={onFieldChange('paymentNotes')}
                  onPressEnter={goToSourceConfiguration}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          disabled={!(parseFloat(paymentAmount) > 0) || isLoading}
          onClick={goToSourceConfiguration}
          loading={isLoading}
        >
            Next
        </Button>
      </div>
    </Fragment>
  );
};

SendMoneySetTransactionAmount.propTypes = {
  setField: PropTypes.func,
  clearSearchedWallet: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  permissions: EnabledStatesShape,
  setPaymentPopup: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    sendMoney: state.sendMoney,
    permissions: state.user.user.enabled_states,
  };
}


export default connect(mapStateToProps, { setField, clearSearchedWallet, setPaymentPopup })(SendMoneySetTransactionAmount);
