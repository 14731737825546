import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  retryPreflight,
} from '../actions/send-money-actions';
import Modal from './modal';
import PredefinedOrder from '../components/retry-flow/predefined-order';
import PredefinedConfirmation from '../components/retry-flow/predefined-order-confirmation';
import PredefinedSuccess from '../components/retry-flow/predefined-order-success';
import CONST from '../constants/send-money-constants';
import { setField } from '../actions/send-money-actions';
import { transactionReducerTypes, sendMoneyReducerTypes } from '../constants/prop-types';
import { 
  getPendingTransactions,
  getCompletedTransactions,
  showPredefinedOrderResult
} from '../actions/transactions-actions';

const ModalRetryTransaction = props => {
  const { 
    onClose,
    transaction,
    retryPreflight,
    sendMoney: { transactionRate, wizardStep },
    getPendingTransactions,
    getCompletedTransactions,
    showPredefinedOrderResult
  } = props;

  useEffect(() => {
    if (transaction) {
      retryPreflight(transaction);
    }
  }, [transaction]);

  const onCloseSuccess = () => {
    onClose();
    getPendingTransactions();
    getCompletedTransactions();
  };

  return (
    <Modal 
      {...props} 
      onClose={wizardStep === CONST.STEP_ORDER_SUCCESS ? onCloseSuccess : onClose}
      closeButton
    >
      { transaction && transactionRate &&
        (<Fragment>
          <div className="modal_body">
            { wizardStep === CONST.STEP_ORDER_CONFIG &&
              <PredefinedOrder transaction={transaction} onClose={onClose}/>
            }
            { wizardStep === CONST.STEP_ORDER_CONFIRM &&
              <PredefinedConfirmation />
            }
            { wizardStep === CONST.STEP_ORDER_SUCCESS &&
              <PredefinedSuccess onClose={onCloseSuccess} showPredefinedOrderResult={showPredefinedOrderResult} />
            }
          </div>

          <div className="modal_footer">
          </div>
        </Fragment>)
      }
    </Modal>
  );
};

ModalRetryTransaction.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  transaction: transactionReducerTypes.transaction,
  retryPreflight: PropTypes.func, 
  sendMoney: PropTypes.shape({ 
    transactionRate: sendMoneyReducerTypes.transactionRate,
    wizardStep: sendMoneyReducerTypes.wizardStep
  }),
  getPendingTransactions: PropTypes.func, 
  getCompletedTransactions: PropTypes.func,
  showPredefinedOrderResult: PropTypes.func
};

ModalRetryTransaction.defaultProps = {
  className: null,
  onClose: () => {},
};


const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
});

export default connect(
  mapStateToProps,
  { retryPreflight, setField, getPendingTransactions, getCompletedTransactions, showPredefinedOrderResult }
)(ModalRetryTransaction);