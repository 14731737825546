import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LoginLayout from '../../pages/log-in-layout';
import Button from '../button';
import Svg from '../svg';
import Loader from '../loader';
import CONST from '../../constants/login-constants';
import { formatTime } from '../../utils/string-utils';

const LoginStepSentLink = ({ login, setField }) => {
  const initialMinute = parseInt(login.expiration / 60, 10);
  const initialSeconds = login.expiration % 60;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [ seconds, setSeconds ] =  useState(initialSeconds);

  useEffect( () => {
    const startTime = new Date().getTime();

    let timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const diff = Math.ceil((currentTime - startTime)/1000);
      const secondsLeft = (seconds || 59) - diff % 60;
      const minutesLeft = Math.floor((minutes * 60 - diff) / 60);

      if (startTime + login.expiration * 1000 - currentTime <= 0) {
        clearInterval(timer);
        setField('step', CONST.STEP_ERROR_TRY_AGAIN);
        setField('payfoneIsLoading', false);
      }

      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 100);

    return function() {
      clearInterval(timer);
    };
  }, []);

  return (
    <LoginLayout
      wrapperClassName="-x-sm -content-y-centered -page-control-bottom -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            {login.lastPhoneDigits
              ? (
                <Fragment>
                  <Loader size="xxs" className="splash_loader -chat-bubble" />
                  <Svg name="chat-bubble" className="splash_icon" />
                </Fragment>
              )
              : (
                <Fragment>
                  <Loader size="xxs" className="splash_loader" />
                  <Svg name="letter" className="splash_icon" />
                </Fragment>
              )
            }
            <div className="js-sent-verification-alert splash_message">
              We have sent
              { login.lastPhoneDigits && login.maskedEmail ? ' authentication links ' : ' an authentication link ' }
              to
            </div>
            { login.lastPhoneDigits && (
              <div className="splash_message -phone-mask">
                <div
                  className="js-sent-verification-phone phone-mask"
                >
                  Phone (&middot;&middot;&middot;) &middot;&middot;&middot; &ndash; {login.lastPhoneDigits}
                </div>
              </div>
            )}
            { login.maskedEmail && (
              <div className="splash_message -phone-mask">
                <div
                  className="js-sent-verification-email phone-mask"
                >
                  Email {login.maskedEmail}
                </div>
              </div>
            )}
            <div className="layer -space-up-xl splash_message">Link expires in {formatTime(minutes)}:{formatTime(seconds)}</div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <div className="page_control color-light-gray">
          <p>
            Click on the link we’ve sent you. You’ll be moved through the log in process automatically.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_HELP_DESK_URL}
            >
            &nbsp;Need&nbsp;help?
            </a>
          </p>
        </div>
        <Button
          xSize="full"
          transparency="full"
          onClick={() => setField('step', CONST.STEP_EMAIL_PASSWORD)}
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
          Cancel
        </Button>
      </div>
    </LoginLayout>
  );
};


LoginStepSentLink.propTypes = {
  setField: PropTypes.func,
  login: PropTypes.shape({
    lastPhoneDigits: PropTypes.string,
    isLoading: PropTypes.bool,
    maskedEmail: PropTypes.string,
    expiration: PropTypes.number,
  }),
};

export default LoginStepSentLink;
