import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import SignupLayout from '../../pages/sign-up-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST from '../../constants/sign-up-constants';
import VALIDATION from '../../utils/validation';
import Checkbox from '../checkbox';
import { OnMobile, OnDesktop } from '../breakpoints';

const SignUpStepEmailPassword = (props) => {
  const [messageShown, setMessageShown] = useState(false);

  const updatePassword = (password) => {
    props.setField('password', password.substr(0, 100));
  };
  
  const generateMessage = (password, email) => {
    const reqs = VALIDATION.getPasswordRequirenments(password);
    const isValid = VALIDATION.validatePassword(password, email).success;
  
    if (!isValid) {
      return (<div className="input-message color-light-gray">
        <div className="input-message_text">Must be
          <span className={reqs.len ? 'color-green' : 'color-light-gray'}>
              &nbsp;8 or more characters&nbsp;
          </span>
            and include:
        </div>
        <div className="input-message-row">
          <ul className="input-message-column">
            <li className={reqs.lowercase ? 'checked' : 'neutral'}>lowercase letter</li>
            <li className={reqs.uppercase ? 'checked' : 'neutral'}>uppercase letter</li>
          </ul>
          <ul className="input-message-column">
            <li className={reqs.digit ? 'checked' : 'neutral'}>digit</li>
            <li className={reqs.symbol ? 'checked' : 'neutral'}>special symbol</li>
          </ul>
        </div>
      </div>);
    }
  
    return (<div className="layer color-blue input-message -space-down-md">✓ Good password</div>);
  };

  const { setField, endStep, signUp } = props;
  const isPartEmail = VALIDATION.isPartOfEmail(signUp.password, signUp.email);
  const passwordValid = VALIDATION.validatePassword(signUp.password, signUp.email).success;
  const onSubmit = (e) => {
    !signUp.isOAuth && e.preventDefault();
    if (passwordValid) {
      endStep(CONST.STEP_EMAIL_PASSWORD);
    }
  };

  return (
    <SignupLayout
      bgClassName="-full"
      headerClassName="-y-xl -logo-only -logo-big"
      wrapperClassName="-x-sm -content-x-centered-desktop -content-y-centered"
      twoLiner
      isBigLogo
      poweredByType="sign"
    >
      <div className="page_header -space-between -space-sm">
        <h1 className="js-page-title page_title">Sign Up</h1>
        <OnMobile>
          <div className="font-weight-bold">
            <span className="color-light-gray">
              <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HELP_DESK_URL}>
                  Need&nbsp;help?
              </a>
            </span>
          </div>
        </OnMobile>
        
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={onSubmit}>
            <div className="layer -space-down-xxxl">
              <p className="font-size-secondary-responsive color-light-gray">
                  Fast and secure financial transactions with  {process.env.REACT_APP_DOMAIN_STRING}. <br />
                  Let's create your account – it's fast and free!
              </p>
            </div>
            { signUp.validationErrors.serverError &&
                <div className="layer -space-down-sm">
                  <div className="badge -as-form-message -danger full-width">{signUp.validationErrors.serverError}</div>
                </div>
            }

            <div className="layer">
              <div className="form-input-container">
                <input type="text" name="login-email" id="autocomplete-hack-email" style={{ display: 'none' }} />
                <FormInput
                  type="email"
                  transparency="half"
                  className="full-width"
                  label="Email"
                  inputName="login-email"
                  value={signUp.email}
                  onChange={value => setField('email', value)}
                  errorMessage={signUp.validationErrors.email ? signUp.validationErrors.email : ''}
                  invalid={!!signUp.validationErrors.email}
                  autoFocus
                />
              </div>
            </div>

            <div className="layer">
              <div className="form-input-container">
                <input
                  type="password"
                  name="login-password"
                  id="autocomplete-hack-password"
                  style={{ display: 'none' }}
                />
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Password"
                  type="password"
                  onFocus={() => setMessageShown(true)}
                  inputName="login-password"
                  value={signUp.password}
                  onChange={updatePassword}
                  errorMessage={isPartEmail ? 'You cannot use any part of your email as a password' : ''}
                  isErrorAbsolute={isPartEmail}
                  invalid={!!signUp.validationErrors.password}
                />
              </div>
              {messageShown && generateMessage(signUp.password, signUp.email)}
            </div>
            <input type="submit" style={{ display: 'none' }} />
            <div className="consent full-width">
              <Checkbox
                inputName="terms-and-condition"
                label={
                  <span>
                        I agree with the
                  </span>
                }
                className="-bordered"
                checked={signUp.agreement}
                onChange={({ target: { checked } }) => setField('agreement', checked)}
              />
                  &nbsp;<a
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              >
                    User Agreement
              </a>
            </div>
          </form>
          
        </div>
        
      </div>
      <div className="page_controls -relative">
        <div className="page_control font-weight-bold">
          <span className="color-light-gray">
              Already have an account?
            {' '}
            <a href="/login">Log In</a>
          </span>
        </div>
        <OnDesktop>
          <div className="page_control font-weight-bold">
            <span className="color-light-gray">
              <a rel="noopener noreferrer" target="_blank" href={process.env.REACT_APP_HELP_DESK_URL}>
                  Need&nbsp;help?
              </a>
            </span>
          </div>
        </OnDesktop>
        
        <div className="page_control -submit">
          <Button
            color="blue"
            xSize="full"
            className={
              cn(
                'js-submit-button page_control-absolute',
              )
            }
            loading={signUp.isLoading}
            type="submit"
            disabled={!signUp.password || !passwordValid || !signUp.agreement}
            onClick={() => {
              endStep(CONST.STEP_EMAIL_PASSWORD);
            }}
          >
              Sign Up
          </Button>
        </div>
      </div>
    </SignupLayout>
  );
};

SignUpStepEmailPassword.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  signUp: PropTypes.shape({
    email: PropTypes.string,
    agreement: PropTypes.bool,
    password: PropTypes.string,
    isLoading: PropTypes.bool,
    validationErrors: PropTypes.shape({
      email: PropTypes.string,
      password: PropTypes.string,
      serverError: PropTypes.string,
    }),
    isOAuth: PropTypes.bool
  }),
};

export default SignUpStepEmailPassword;
