import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { cdnPath } from '../utils/api';


const CurrencyIcon = ({ 
  color, currency, size,
  ext, width, height, 
  className, fallback, ...props
}) => {
  const imgRef = useRef(null);
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ isError, setIsError ] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setIsError(false);
  }, [currency, color, ext, size]);

  return !isLoaded && isError && !!fallback ? fallback : (
    <img
      ref={imgRef}
      src={`${cdnPath}/img/c/${currency}/${currency}-${color}${ext === 'svg' ? '' : `-${size}`}.${ext}`}
      className={cn(`icon`, className)} 
      width={width} 
      height={height} 
      alt={`${currency} icon`} 
      onLoad={() => setIsLoaded(true)}
      onError={() => setIsError(true)}
      {...props}
    />
  );
};


CurrencyIcon.propTypes = {
  color: PropTypes.oneOf(['white', 'black', 'color']),
  currency: PropTypes.string,
  size: PropTypes.oneOf(['32', '128', '500']),
  ext: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  fallback: PropTypes.element
};

export default CurrencyIcon;