import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Svg from '../../components/svg';

const ErrorLayout = (props) => {
  const { children, wrapperClassName } = props;
  const isGunbrokerPay = process.env.REACT_APP_DOMAIN_NAME === 'GB_PAY';

  return (
    <div className="page -error">
      <div className="header -logo-only -y-xl -top-height">
        <div className="header_wrapper">
          <a href="/" className="header_logo">
            <Svg name="bitrail-logo-colorful" className="header_logo-icon" />
          </a>
        </div>
      </div>
      <div className={cn('page_wrapper', wrapperClassName)}>
        <div className="page_wrapper-inner">
          {children}
        </div>
      </div>
    </div>
  );
};

ErrorLayout.propTypes = {
  children: PropTypes.node,
  wrapperClassName: PropTypes.string,
};

ErrorLayout.defaultProps = {
  children: null,
  wrapperClassName: null,
};

export default ErrorLayout;
