import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pipe } from 'ramda';

import SetNewPassword from '../components/set-new-password';
import SetNewPasswordUnable from '../components/set-new-password-unable';

import { setField, setNewPassword } from '../actions/set-new-password-actions';

const SetNewPasswordPage = props => (
  props.setNewPasswordData.unable
    ? <SetNewPasswordUnable {...props} />
    : <SetNewPassword {...props} />
);

SetNewPasswordPage.propTypes = {
  setNewPasswordData: PropTypes.shape({
    unable: PropTypes.bool.isRequired,
  }),
};

const mapStateToProps = ({ passwordRecovery }, props) => ({
  setNewPasswordData: passwordRecovery.setNewPassword,
  token: props.match.params.token,
});

export default pipe(
  withRouter,
  connect(
    mapStateToProps,
    { setField, setNewPassword },
  ),
)(SetNewPasswordPage);
