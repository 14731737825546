import React from 'react';
import ErrorLayout from './error-layout';
import Button from '../../components/button';

const ErrorOAuth = () => {
  return (
    <ErrorLayout wrapperClassName="-content-y-centered -content-x-centered -x-md -no-page-header">
      <div className="page_body">
        <div className="page_content">
          <div className="page_error">
            <h1>Oh,&nbsp;No!</h1>
            <h2>There is an error with auth provider</h2>
            <p>
              Sorry, something went wrong
            </p>
          </div>
        </div>
      </div>
      <div className="page_controls -content-x-centered">
        <Button
          color="blue"
          ySize="sm"
          xSize="md"
          className="page_control -submit"
          to="/auth/login"
        >
            Return to Login Page
        </Button>
      </div>
    </ErrorLayout>
  );
};

export default ErrorOAuth;