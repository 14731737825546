import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Notification from './notification';

const PageNotification = props => (
  <Notification className={cn('page-notifications_item', props.ttl === -1 ? '-clicable' : '')} {...props} />
);

PageNotification.propTypes = {
  ttl: PropTypes.number
};

export default PageNotification;
