import React from 'react';
import Svg from './svg';

import SignupLayout from '../pages/sign-up-layout';
import Button from './button';

const SetNewPasswordUnable = () => (
  <SignupLayout
    headerClassName="-y-lg"
    wrapperClassName="-x-sm -content-y-centered -no-page-header"
  >
    <div className="page_body">
      <div className="page_content">
        <div className="page_splash splash">
          <Svg name="circled-cross" className="splash_icon" />
          <h1 className="splash_title">Unable to recover the password</h1>
          <div className="splash_message">
            <p className="font-size-secondary-responsive color-light-gray">
              Unfortunately, we were not able to recover the password for your profile.
              Please contact Customer Support to get further assistance.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="page_controls">
      {/* TODO: Add help-desk link */}
      <Button color="blue" xSize="full" className="page_control -submit">
        Open Customer Support
      </Button>
    </div>
  </SignupLayout>
);

export default SetNewPasswordUnable;
