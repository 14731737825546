import { __, always, assocPath, concat, evolve, merge, over, lensProp, assoc } from 'ramda';
import CONST from '../constants/transactions-constants';
import { predefinedTimeFrame } from '../utils/transaction-utils';

export const INIT_STATE = {
  transaction: null,
  transactionShown: false,
  next90IsLoading: false,
  reportIsSending: false,
  reportConfirmed: false,
  filters: {
    search: '',
    type: [],
    direction: [],
    timeFrameValue: CONST.TIMEFRAME_INTERVALS.LAST_90,
    timeFrameDates: predefinedTimeFrame(CONST.TIMEFRAME_INTERVALS.LAST_90),
  },
  transactionsPending: {
    data: [],
    isLoading: false,
  },
  transactionsCompleted: {
    data: [],
    isLoading: false,
    paging: {
      offset: null,
      count: null,
    },
  },
};

const transactionsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assoc([action.payload.key], action.payload.value, state);
    case CONST.CLEAR_FILTERS:
      return assoc(['filters'], INIT_STATE.filters, state);
    case CONST.SET_PENDING_TRANSACTIONS:
      return over(lensProp('transactionsPending'), merge(__, {
        isLoading: false,
        data: action.payload.data,
      }), state);
    case CONST.SET_COMPLETED_TRANSACTIONS:
      return over(lensProp('transactionsCompleted'), evolve({
        isLoading: always(false),
        data: always(action.payload.data),
        paging: merge(__, {
          offset: action.payload.offset,
          count: action.payload.count,
        }),
      }), state);
    case CONST.APPEND_COMPLETED_TRANSACTIONS:
      return over(lensProp('transactionsCompleted'), evolve({
        isLoading: always(false),
        data: concat(__, action.payload.data),
        paging: merge(__, {
          offset: action.payload.offset,
          count: action.payload.count,
        }),
      }), state);
    case CONST.GET_PENDING_TRANSACTIONS:
      return over(lensProp('transactionsPending'), merge(__, {
        isLoading: true,
        data: [],
      }), state);
    case CONST.GET_NEXT_COMPLETED_TRANSACTIONS:
      return assocPath(['transactionsCompleted', 'isLoading'], true, state);
    case CONST.GET_COMPLETED_TRANSACTIONS:
      return over(lensProp('transactionsCompleted'), merge(__, {
        isLoading: true,
        data: [],
      }), state);
    default:
      return state;
  }
};

export default transactionsReducer;
