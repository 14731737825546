import React from 'react';
import Svg from '../components/svg';
import SignupLayout from './sign-up-layout';
import Button from '../components/button';

const InvoiceComplete = () => {
  return (
    <SignupLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="check-broken-circle-large" className="splash_icon" />
            <h1 className="js-page-title splash_title">This invoice has been submitted earlier</h1>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                You can find more details in your BitRail Wallet
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          to="/dashboard"
        >
          Go To Dashboard
        </Button>
      </div>
    </SignupLayout>
  );
};

export default InvoiceComplete;
