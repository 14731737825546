import { assocPath } from 'ramda';
import CONST from '../constants/gb-signup-constants';

const INIT_STATE = {
  step: CONST.STEP_WELCOME,
  errorMessage: null,
  validationErrors: {
    serverError: '',
  },
  userId: '',
  password: '',
  token: '',
  userToken: '',
  isLoading: false,
  consent: false,
  walletId: null,
  yodleeUrl: '',
  yodleeJWT: '',
  yodleeServiceId: '',
  isYodleeDisabled: false,
  configName: '',
  addedAccountsLength: 0,
  isInitializing: false,
};

const gbSignupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath(action.payload.path, action.payload.value, state);

    default:
      return state;
  }
};

export default gbSignupReducer;