import moment from 'moment';

export const hasNotices = (notices) => {
  if(Array.isArray(notices) && notices.length > 0) {
    const now = moment().unix();
    const result = notices.filter(
      (notice) => !notice.acknowledged_at &&
        moment(notice.notice.start_at).unix() <= now &&
        moment(notice.notice.end_at).unix() > now
    );

    return result.length > 0 ? result : false;
  }

  return false;
};