import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import LoginLayout from '../../pages/log-in-layout';
import Button from '../button';
import Svg from '../svg';

const PartnerSignupStepPayphoneAutodetect = (props) => {
  return (
    <LoginLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
      hideNav={true}
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="reload" className={cn('splash_icon', props.partnerSignup.payfoneIsLoading ? '-spinner' : null)} />
            <h1 className="splash_title">Authentication</h1>
            <Button
              color="blue"
              xSize="full"
              disabled={props.partnerSignup.payfoneIsLoading}
              className="page_control layer -space-up-xl -space-down-sm"
              onClick={props.openPopup}
            >
                Continue Authentication
            </Button>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                Authentication will continue in a new window.
              </p>
            </div>
            <a
              className="font-weight-bold layer -space-up-sm -space-down-xxl"
              target="_blank"
              rel="noopener noreferrer"
              href="https://bitrail.zendesk.com/knowledge/articles/360026975972"
            >
                Is the authentication page secure?
            </a>
            <a
              className="font-weight-bold layer -space-up-sm -space-down-xxl"
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_HELP_DESK_URL}
            >
                Need&nbsp;help?
            </a>
          </div>
        </div>
      </div>
      <div className="page_controls"></div>
    </LoginLayout>
  );
};

PartnerSignupStepPayphoneAutodetect.propTypes = {
  partnerSignup: {
    payfoneIsLoading: PropTypes.bool
  },
  openPopup: PropTypes.func
};

export default PartnerSignupStepPayphoneAutodetect;
