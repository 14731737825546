import React from 'react';
import PropTypes from 'prop-types';

import Svg from '../../components/svg';
import Button from '../button';
import CONST from '../../constants/partner-signup-constants';
import LoginLayout from '../../pages/log-in-layout';

const PartnerSignupPleaseVerify = (props) => {
  const goToPhone = () => {
    props.setField('phoneNumber', '');
    props.setField('step', CONST.STEP_PHONE_NUMBER);
  };

  return (
    <LoginLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
      hideNav={true}
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="circled-cross" className="splash_icon" />
            <h1 className="js-page-title splash_title">Unable to Sign Up</h1>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                  To continue please verify your email and try again or try again with another phone number.
                <br />
                  If the error persists, please contact&nbsp;
                <a 
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                >
                    Customer Support
                </a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          xSize="full"
          transparency="full"
          href="/login"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={goToPhone}
        >
            Continue
        </Button>
      </div>
    </LoginLayout>
  );
};

PartnerSignupPleaseVerify.propTypes = {
  setField: PropTypes.func,
};

export default PartnerSignupPleaseVerify;
