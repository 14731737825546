import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { 
  walletReducerTypes,
  sendMoneyReducerTypes,
} from '../../../constants/prop-types';
import { connect } from 'react-redux';
import { find, sortWith, ascend, prop } from 'ramda';
import { trackEvent } from '../../../utils/ganalytics';
import Button from '../../../components/button';

import CONST from '../../../constants/send-money-constants';
import { setField, makePayment } from '../../../actions/send-money-actions';
import { 
  formatNumberText,
  _convertCurrency,
  convertBonusPoints,
  withCurrency as wc
} from '../../../utils/send-money-utils';

const SendMoneyConfirmation = (props) => {
  const confirm = () => {
    trackEvent('send_money_confirmed', 'Confirm Send Money');
    props.makePayment();
  };

  const stepBack = () => {
    props.setField('wizardStep', CONST.STEP_SOURCE_CONFIG);
    props.setField('error', null);
  };

  const showSourceAmount = (accountId) => {
    const { paymentSource, transactionRate } = props.sendMoney;
    const source = find(s => s.id === accountId, paymentSource);
    
    if(source.type === 'bank') {
      source.accountCurrency = source.currency;
    }
    
    return source.currency !== source.accountCurrency ?
      `-${wc(_convertCurrency(source.currency, source.accountCurrency, source.amount, transactionRate), source.accountCurrency)}` :
      `-${wc(source.amount, source.accountCurrency)}`;
  };

  const showBankAccountName = (bankId) => {
    const { bankAccounts } = props.wallet;
    const bank = find(a => a.bank_account_id === bankId, bankAccounts);
    return (
      <span className="font-weight-regular">
        {bank.user_description || 'Account Number'}:
        {' '}
        <span className="nowrap">
          <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
        </span>
      </span>
    );
  };

  const {
    foundWallet,
    searchString,
    paymentAmount,
    paymentCurrency,
    paymentSource,
    paymentNotes,
    transactionRate,
    isLoading,
    error,
    paymentBonusSource,
  } = props.sendMoney;

  const sortedSources = sortWith([
    ascend(prop('type')),
  ])(paymentSource);

  return (
    <Fragment>
      <div className="page_header">
        <h1 className="page_title">Confirmation</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form new-transaction" action="">
            { error &&
                <div className="layer -space-down-sm">
                  <div className="badge -as-form-message -danger full-width">{error}</div>
                </div>
            }
            <div className="description-list -fixed-title full-width">
              <div className="description-list_body">
                <div className="description-list_item">
                  <div className="description-list_title">Recipient</div>
                  <div className="description-list_value">
                    <div className="description-list_item-primary">{foundWallet.name}</div>
                    {searchString && <div className="description-list_item-secondary">{searchString}</div>}
                  </div>
                </div>
                <div className="description-list_item">
                  <div className="description-list_title">Amount</div>
                  <div className="description-list_value">
                    <div className="description-list_item-primary font-weight-bold">
                      {wc(paymentAmount, paymentCurrency)}
                    </div>
                    {paymentCurrency === 'USD' && (
                      <div className="description-list_item-secondary">
                        { formatNumberText(paymentAmount) }
                      </div>
                    )}
                  </div>
                </div>
                <div className="description-list_item">
                  <div className="description-list_title">Notes</div>
                  <div className="description-list_value">
                    <div className="description-list_item-primary">{paymentNotes}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="description-list -fixed-title full-width">
              <div className="description-list_header">
                <div className="description-list_header-item">Payment sources</div>
              </div>
              <div className="description-list_body">
                {sortedSources.map(source => { 
                  return (
                    <div className="description-list_group" key={source.id}>
                      <div className="description-list_item">
                        <div className="description-list_title">
                          {source.accountName} Account
                        </div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">
                            {source.type === 'bank' && (
                              <Fragment>
                                {showBankAccountName(source.id)}
                                <br />
                              </Fragment>
                            )}
                            <span className="font-weight-bold">
                              {wc(_convertCurrency(source.currency, paymentCurrency, source.amount, transactionRate), paymentCurrency)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );})}
                {
                  paymentBonusSource.selectedSource && paymentBonusSource.selectedSource.points_required
                    ? (<div className="description-list_group">
                      <div className="description-list_item">
                        <div className="description-list_title">
                          Reward points
                        </div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">
                            <span className="font-weight-bold">
                              {wc(paymentBonusSource.selectedSource.rewardAmountTransactionCurrency, paymentCurrency)}
                            </span>
                          </div>
                          <div className="description-list_item-secondary">
                            {paymentBonusSource.selectedSource.points_required} points
                          </div>
                        </div>
                      </div>
                      <div className="description-list_item">
                        <div className="description-list_title">Exch. Rate</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">
                            {wc(1, paymentCurrency)} = {convertBonusPoints(
                              paymentBonusSource.selectedSource.points_required,
                              paymentBonusSource.selectedSource.rewardAmountTransactionCurrency,
                            )}&nbsp;Points
                          </div>
                        </div>
                      </div>
                    </div>)
                    : null
                }
              </div>
            </div>

            <div className="layer -space-up-md text-align-center">
              <span className="font-size-secondary-responsive">
                  By continuing operation you agree with
                <br />
                  the <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                >
                  User Agreement
                </a>
              </span>
            </div>
          </form>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -cancel"
          disabled={isLoading}
          onClick={stepBack}
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={confirm}
          loading={isLoading}
          disabled={isLoading}
        >
            Send {wc(paymentAmount, paymentCurrency)}
        </Button>
      </div>
    </Fragment>
  );
};

SendMoneyConfirmation.propTypes = {
  setField: PropTypes.func,
  makePayment: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes),
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  wallet: state.wallet,
});

export default connect(mapStateToProps, { setField, makePayment })(SendMoneyConfirmation);
