import { assocPath, compose, merge } from 'ramda';
import CONST from '../constants/send-money-constants';

const INIT_STATE = {
  searchString: '',
  foundWallet: null,
  error: null,
  isLoading: false,
  paymentAmount: '',
  paymentCurrency: 'USD',
  paymentSource: [],
  paymentBonusSource: {
    selectedSource: null,
    allSources: [],
  },
  paymentNotes: '',
  transactionRate: null,
  wizardStep: CONST.STEP_FIND_WALLET,
  transactionResult: null,

  // Order Payment
  paymentProcessing: true,
  eventId: null,
  isPayment: false,
  destinationWalletHandle: null,
  orderToken: null,
  orderDescription: null,
  orderID: null,
  attributes: null,
  rewardsUsed: false,
  rewardsSource: null,
  paymentSourceType: '',
  paymentAccountId: '',
  accAmount: null,
  banksAvailabilty: [],
  recentTransfers: [],
  preflightIsLoading: false,

  //refund
  isRefund: false,
  refundStep: CONST.STEP_SOURCE_CONFIG,
  refundSources: []
};

const sendMoneyReducer = (state = INIT_STATE, action = { payload: {} }) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath([action.payload.key], action.payload.value, state);

    case CONST.SET_FIELDS:
      return merge(state, action.payload);

    case CONST.GET_WALLET_BY_SEARCH:
      return compose(
        assocPath(['isLoading'], true),
        assocPath(['error'], null),
        assocPath(['searchString'], action.payload.searchString),
      )(state);

    case CONST.SET_SEARCHED_WALLET:
      return compose(
        assocPath(['isLoading'], state.isPayment ? state.isLoading : false),
        assocPath(['error'], null),
        assocPath(['wizardStep'], state.isPayment ? CONST.STEP_SOURCE_CONFIG : CONST.STEP_SET_TRANSACTION_AMOUNT),
        assocPath(['foundWallet'], action.payload.wallet),
      )(state);

    case CONST.GET_RATE:
      return assocPath(['isLoading'], true, state);

    case CONST.SET_RATE:
      return compose(
        assocPath(['isLoading'], false),
        assocPath(['error'], action.payload.dontCleanErorrs ? state.error : null),
        assocPath(['transactionRate'], action.payload.rate),
      )(state);

    case CONST.MAKE_PAYMENT:
      return compose(
        assocPath(['isLoading'], true),
        assocPath(['error'], null),
      )(state);

    case CONST.SET_TRANSACTION:
      return compose(
        assocPath(['isLoading'], false),
        assocPath(['error'], null),
        assocPath(['wizardStep'], CONST.STEP_COMPLETED),
        assocPath(['transactionResult'], action.payload.transactionResult),
      )(state);

    case CONST.SET_ERROR_MESSAGE:
      return compose(
        assocPath(['isLoading'], false),
        assocPath(['error'], action.payload.message),
      )(state);

    case CONST.CLEAR_SEARCHED_WALLET:
      return compose(
        assocPath(['paymentSource'], INIT_STATE.paymentSource),
        assocPath(['wizardStep'], INIT_STATE.wizardStep),
      )(state);

    case CONST.CLEAR_TRANSACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default sendMoneyReducer;
