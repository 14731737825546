import { takeLatest, put, select, call } from 'redux-saga/effects';
import { setField } from '../actions/gb-signup-actions';
import { setField as setBankAccountField } from '../actions/bank-account-actions';
import CONST from '../constants/gb-signup-constants';
import REQUESTS from '../utils/requests';
import { setAuthToken, clearAuthToken } from '../utils/api';

function* gbSignup({ token, password, consent }) {
  try {
    yield put(setField(['isLoading'], true));
    const { success: tokenSuccess, data } = yield REQUESTS.OBTAIN_APP_TOKEN();

    if (tokenSuccess && data && data[0]) {
      setAuthToken(data[0].access_token);
    }

    const { success, data: gbData } = yield REQUESTS.SIGNUP_FROM_GB({ token, password, consent });

    if (success && gbData) {
      clearAuthToken();
      yield put(setField(['userId'], gbData[0].user_id));

      setAuthToken(gbData[0].access_token);
    }

    const { success: walletSuccess, data: walletData } = yield REQUESTS.GET_USER_WALLETS(gbData[0].user_id);
    const { success: yodleSuccess, data: yodleeData } = yield REQUESTS.OBTAIN_BANK_TOKEN(gbData[0].user_id);

    if (success && tokenSuccess && walletSuccess && yodleSuccess) {
      yield put(setField(['walletId'], walletData[0].wallet_id));
      yield put(setBankAccountField(['yodleeUrl'], yodleeData[0].create_url));
      yield put(setBankAccountField(['yodleeJWT'], yodleeData[0].credentials.jwt));
      yield put(setBankAccountField(['yodleeServiceId'], yodleeData[0].service_id));
      yield put(setBankAccountField(['configName'], yodleeData[0].config_name));

      yield put(setField(['isLoading'], false));
      
      yield put(setField(['step'], CONST.STEP_SUCCESS));
    } else {
      yield put(setField(['step'], CONST.STEP_ERROR));
      yield put(setField(['isLoading'], false));
      clearAuthToken();
    }
  } catch (error) {
    yield put(setField(['step'], CONST.STEP_ERROR));
    yield put(setField(['isLoading'], false));
    clearAuthToken();
    // throw error;
  }
};

function* addBankAccountFlow({
  bankAccountRouting,
  bankAccountNumber,
  bankAccountLabel,
  bankAccountType,
  bankAccountClass,
}) {
  try {
    const { walletId } = yield select(state => state.gbSignup);

    const { success } = yield REQUESTS.ADD_BANK_ACCOUNT(walletId, {
      account_type: bankAccountType,
      routing_number: bankAccountRouting,
      account_number: bankAccountNumber,
      user_description: bankAccountLabel,
      account_class: bankAccountClass,
    });

    if (success) {
      yield put(setField(['step'], CONST.STEP_COMPLETE));
      yield put(setField(['isLoading'], false));
    } else {
      yield put(setField(['step'], CONST.STEP_ERROR));
      yield put(setField(['isLoading'], false));
    }
  } catch (error) {
    yield put(setField(['step'], CONST.STEP_ERROR));
    yield put(setField(['isLoading'], false));
  }
}

function* endStepFlow({ payload }) {
  if (payload.step === CONST.STEP_PASSWORD) {
    const { token, userToken, password, consent } = yield select( store => store.gbSignup );
    
    if (consent) {
      yield call(gbSignup, { token, userToken, password, consent: 'user-agreement' });
    }
  }

  if (payload.step === CONST.STEP_SUCCESS) {
    yield put(setField(['step'], CONST.STEP_BANKACCOUNT));
  }

  if (payload.step === CONST.STEP_WELCOME) {
    yield put(setField(['step'], CONST.STEP_PASSWORD));
  }

  if (payload.step === CONST.STEP_BANKACCOUNT) {
    yield call(addBankAccountFlow, payload.account);
  }
}

function* gbSignupSaga() {
  yield takeLatest(CONST.END_STEP, endStepFlow);
}

export default [gbSignupSaga];