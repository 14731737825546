import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormTextArea from './form-textarea';
import Modal from './modal';
import Button from './button';
import Svg from './svg';

const ModalReportTransaction = props => {
  const { onReportTransaction, onClose, reportIsSending, reportConfirmed } = props;
  const [value, setValue] = useState('');
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (reportConfirmed) {
      setStep(2);
    }
  }, [reportConfirmed]);

  const closeModal = () => {
    onClose();
    setValue('');
    setStep(1);
  };

  return (
    <Modal {...props}>
      <div className={cn('modal_body', step === 2 ? '-centered' : '')}>
        {step === 2 && (<Fragment>
          <div className="layer -centered ">
            <h1>Activity Reported to Support</h1>
          </div>

          <div className="layer -centered -space-up-xxl">
            <div className="transaction-result -success">
              <Svg name="check-broken-circle" className="transaction-result_icon -large" />
            </div>
          </div>

          <div className="layer -space-up-sm text-align-center">
            <p>Thank you. Our support team has been notified and will investigate shortly.</p>
          </div>
        </Fragment>)}
        {step === 1 && (<Fragment>
          <div className="layer -centered">
            <h1 className="js-user-text-title">Report Suspicious Activity</h1>
          </div>
          <div className="layer -space-up-md">
            <FormTextArea
              autoFocus
              color="white"
              type="text"
              inputName="report-label"
              label="Describe the problem"
              value={value}
              className="full-width"
              onChange={(val) => setValue(val)}
            />
          </div>
        </Fragment>)}
      </div>

      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="js-remove-button modal_footer-control -cancel"
            onClick={()=> closeModal()}
          >
            Close
          </Button>
          {
            step === 1 && (
              <Button
                color="blue"
                xSize="full"
                className="js-remove-button modal_footer-control -submit"
                onClick={()=> onReportTransaction(value)}
                loading={reportIsSending}
                disabled={reportIsSending || !value}
              >
              Send Report
              </Button>
            )}
        </div>
      </div>
    </Modal>
  );
};


ModalReportTransaction.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onReportTransaction: PropTypes.func,
  onReportSeller: PropTypes.func,
  reportIsSending: PropTypes.bool,
  reportConfirmed: PropTypes.bool,
};

ModalReportTransaction.defaultProps = {
  className: null,
  onReportTransaction: () => {},
  onReportSeller: () => {},
  onClose: () => {},
};

export default ModalReportTransaction;
