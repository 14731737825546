import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';

const BankAccountsProceedToAddModal = ({ show, onClose, onManualLink, onInstant }) => {
  return (
    <Modal containerClassName="-white -x-sm -closebutton-top" closeButton show={show} onClose={onClose}>
      <div className="modal_header">
        <h1 className="js-modal-title">
          Adding bank account manually
          takes up to 3 business days
        </h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-up-sm">
          <p className="font-size-secondary-responsive">
            In order to make sure that your account is configured properly, we will send you 2-4 small deposits to your bank account. 
            This transfer requires <b>up to 3 banking days</b> to complete. 
            Combined with weekends and bank holidays, this could lead to a significant delay (up to a week) 
            before you can start using your bank account.
          </p>
        </div>
        <div className="layer -space-up-xxl">
          <p className="font-size-secondary-responsive">
            To add your account instantly, please use our Instant Account Addition feature.
          </p>
        </div>
        <div className="layer -space-up-lg">&nbsp;</div>
      </div>

      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            transparency="full"
            className="modal_footer-control -cancel"
            to={onManualLink}
          >
            Continue with adding account manually
          </Button>

          <Button
            color="blue"
            xSize="full"
            transparency="no"
            className="modal_footer-control -submit"
            onClick={() => onInstant()}
          >
            Add Bank Account Instantly
          </Button>
          
        </div>
      </div>
    </Modal>
  );
};

BankAccountsProceedToAddModal.propTypes = { 
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onManualLink: PropTypes.string,
  onInstant: PropTypes.func
};

export default BankAccountsProceedToAddModal;
