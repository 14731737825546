import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'ramda';

import { verifyBankAccount, clearForm } from '../../actions/bank-account-actions';

import Button from '../button';
import Modal from '../modal';
import CurrencyInput from '../currency-input';

const INIT_STATE = ['', '', '', ''];

const ModalAccountOwnershipValidation = (props) => {
  const [amounts, setAmount] = useState(INIT_STATE);

  useEffect(() => {
    if(!props.show) {
      setAmount(INIT_STATE);
    }
  }, [props.show]);

  const onFieldChange = field =>
    (value) => {
      const newAmounts = amounts.slice();
      newAmounts[field] = value;
      setAmount(newAmounts);
      props.clearForm();
    };

  const amountValues = () => filter(v => parseFloat(v) > 0, amounts);

  const verifyBankAccount = () => {
    const bankId = props.bankAccount.bank_account_id;
    props.verifyBankAccount(bankId, amountValues());
  };

  const { bankAccount, isLoading, errorMessage } = props;

  return (
    <Modal {...props}>
      <div className="modal_header">
        <h1 className="js-modal-title">Account Ownership Verification</h1>
      </div>
      <div className="modal_body">
        <h4>
          {bankAccount.user_description || bankAccount.bank_name || 'Bank Account'}&nbsp;
          <span className="account-number-bullets">&bull;&bull;</span>{bankAccount.account_number}
        </h4>
        <div className="layer -space-down-xl">
          <p className="font-size-secondary-responsive">
              We have made 2-4 small deposits into your account&nbsp;(less than $1.00 each).
              Please fill in&nbsp;amounts of&nbsp;many deposits as you received.<br />
              Fill amounts in any order.
          </p>
        </div>

        {errorMessage && (
          <div className="layer">
            <div className="badge -danger">{errorMessage}</div>
          </div>
        )}

        <div className="layer -space-up-sm">
          <form action="" className="form">
            {amounts.map((amount, index) => (
              <div className="layer full-width" key={`amount-input-${index}`}>
                <div className="form-input-container">
                  <CurrencyInput
                    className="js-currency-input-a1"
                    color="white"
                    inputName="account-label"
                    label={`Amount ${index + 1}`}
                    invalid={!!errorMessage}
                    disabled={isLoading}
                    value={amount}
                    onChange={onFieldChange(index)}
                    onPressEnter={verifyBankAccount}
                    autoFocus={index === 0}
                  />
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="layer -space-up-xl">
          <p className="font-size-secondary-responsive">
              Next, we will make an attempt to withdraw these funds from the account to ensure
              the account allows withdrawing of funds. If we cannot withdraw the funds,
              you will only be able to transfer funds to that bank account
          </p>
        </div>
      </div>

      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            onClick={props.onClose}
            disabled={isLoading}
          >
              Cancel
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button modal_footer-control -submit"
            onClick={verifyBankAccount}
            disabled={isLoading || (amountValues().length < 2)}
            loading={isLoading}
          >
              Verify Account Ownership
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalAccountOwnershipValidation.propTypes = {
  bankAccount: PropTypes.shape({
    bank_account_id: PropTypes.string,
    account_number: PropTypes.string,
    bank_name: PropTypes.string,
    user_description: PropTypes.string,
  }),
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  verifyBankAccount: PropTypes.func,
  clearForm: PropTypes.func,
  show: PropTypes.bool,
};

ModalAccountOwnershipValidation.defaultProps = {
  bankAccount: PropTypes.shape({
    bank_account_id: null,
    account_number: null,
    bank_name: null,
    user_description: null,
  }),
  errorMessage: null,
  isLoading: false,
  onClose: () => {},
  verifyBankAccount: () => {},
  clearForm: () => {},
};

export default connect(null, { verifyBankAccount, clearForm })(ModalAccountOwnershipValidation);
