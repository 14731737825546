import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Loader = ({ className, color, size }) => (
  <div className={cn('loader', { [`-${color}`]: !!color, [`-${size}`]: !!size }, className)}>
    <div className="loader_item" />
    <div className="loader_item" />
    <div className="loader_item" />
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'green', 'blue', 'red', 'orange', 'gray', 'dark-gray']),
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg', 'xl']),
};

Loader.defaultProps = {
  className: null,
  color: 'white',
  size: 'md',
};

export default Loader;
