import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import Modal from './modal';

const Promo = ({ promo }) => {

  return (
    <a target="_blank" href={promo.linkUrl} rel="noreferrer" key={promo.promoId} className="currency-promos__promo">
      <div className="currency-promos__promo-icon avatar_image-wrapper">
        <img src={promo.iconUrl} alt={promo.name} />
      </div>
      <div className="currency-promos__promo-content">
        <h3>{promo.name}</h3>
        <p>{promo.description}</p>
        <a target="_blank" href={promo.linkUrl} className="currency-promos__promo-link" rel="noreferrer">{promo.linkText}</a>
      </div>
    </a>
  );
};

Promo.propTypes = {
  promo: PropTypes.shape({
    promoId: PropTypes.string,
    priority: PropTypes.number,
    name: PropTypes.string,
    teaser: PropTypes.string,
    description: PropTypes.string,
    iconUrl: PropTypes.string,
    linkText: PropTypes.string,
    linkUrl: PropTypes.string
  })
};

const CurrencyPromosModal = (props) => {
  const { promos } = props;

  return (
    <Modal closeButton {...props} containerClassName={cn('currency-promos -x-sm')}>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">Our partners</h1>
      </div>
      <div className="modal_body currency-promos__body">
        <div className="currency-promos__banner">
          <h1>Our partners</h1>
          <p>
            Shop at our preferred merchants for great deals and benefits
          </p>
        </div>
        <div className="currency-promos__list">
          {promos.map((promo) => (
            <Promo key={promo.promoId} promo={promo} />
          ))}
        </div>
      </div>
    </Modal>
  );
};

CurrencyPromosModal.propTypes = {
  promos: types.walletReducerTypes.currencyPromos
};

export default CurrencyPromosModal;