import { assocPath, compose } from 'ramda';
import CONST from '../constants/card-account-constants';

const INIT_STATE = {
  isLoading: false,
  cardList: [],
  serviceId: null,
  editingCard: {
    account_number: '',
    verification_code: '',
    expiration: '',
    holder_fname: '',
    holder_lname: '',
    zip_code: '',
    user_description: '',
    account_type: 'creditcard',
    account_class: 'individual',
  },
  validationErrors: {
    account_number: null,
    verification_code: null,
    expiration: null,
    holder_fname: null,
    holder_lname: null,
    zip_code: null,
  }
};

const creditCardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.message, state);
    case CONST.SET_FIELD:
      const field = Array.isArray(action.payload.field) ? action.payload.field : [action.payload.field];
      return assocPath(field, action.payload.value, state);
    case CONST.CLEAR_FORM:
      return compose(
        assocPath(['editingCard'], INIT_STATE.editingCard),
        assocPath(['validationErrors'], INIT_STATE.editingCard)
      )(state);
    default:
      return state;
  }
};

export default creditCardReducer;