import { takeLatest, put, select } from 'redux-saga/effects';
import { pick } from 'ramda';
import CONST from '../constants/transfer-constants';
import REQUESTS from '../utils/requests';
import { setRate, setTransaction, setField } from '../actions/transfer-actions';
import { addNotification } from '../actions/toast-actions';

function* getTransactionRate() {
  try {
    const { success, data } = yield REQUESTS.GET_RATE();
    if (success) {
      yield put(setField('disabled', false));
      yield put(setRate(data[0]));
    } else {
      yield put(setField('disabled', true));
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    throw e;
  }
}

function* makePayment() {
  const { transfer, wallet: { wallet } } = yield select(state => state);

  const {
    sourceAccount,
    destinationAccount,
    chargeCurrency,
    amountCharge,
    receiveCurrency,
    amountReceive,
  } = transfer.data;

  const payment = {
    source_account: sourceAccount.type
      ? pick(['account_id'], sourceAccount)
      : pick(['bank_account_id'], sourceAccount),
    dest_account: destinationAccount.type
      ? pick(['account_id'], destinationAccount)
      : pick(['bank_account_id'], destinationAccount),
    amount: {
      ordinal: !sourceAccount.type || !destinationAccount.type
        ? amountCharge.split('.')[0]
        : amountReceive.split('.')[0],
      decimal: !sourceAccount.type || !destinationAccount.type
        ? amountCharge.split('.')[1] || '00'
        : amountReceive.split('.')[1] || '00',
      currency: !sourceAccount.type || !destinationAccount.type
        ? chargeCurrency
        : receiveCurrency,
    },
  };

  try {
    const { success, data } = yield REQUESTS.MAKE_TRANSFER(wallet.wallet_id, payment);

    if (success) {
      yield put(setTransaction(data[0]));
      yield put(setField('isLoading', false));
    } else {
      yield put(addNotification('Something went wrong', 400)); 
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* sendMoneySagas() {
  yield takeLatest(CONST.GET_RATE, getTransactionRate);
  yield takeLatest(CONST.MAKE_PAYMENT, makePayment);
}

export default [sendMoneySagas];
