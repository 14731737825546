import React, { useEffect, useState, } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { 
  walletReducerTypes,
  sendMoneyReducerTypes,
  EnabledStatesShape
} from '../../constants/prop-types';
import { find, filter, prepend, compose, path } from 'ramda';
import Modal from '../modal';
import Button from '../button';
import Svg from '../svg';
import ExchangeMoneyInput from './exchange-money-input';
import { setPaymentPopup } from '../../actions/app-actions';
import {
  balance,
  calcBalance,
  calcLeftToSource,
  calcSourceBalance,
  currencyFormat,
  truncatedValue,
  _convertCurrency,
  withCurrency
} from '../../utils/send-money-utils';
import { formatNumberUSD } from '../../utils/string-utils';
import { permissionAllowedLimit } from '../../utils/permissions';

const PaymentSourceModal = (props) => {
  const [sourceBalance, setSourceBalance] = useState('0.00');
  const [sourceCurrency, setSourceCurrency] = useState('USD');
  const [errorMsg, setErrorMsg] = useState('');
  const [hasLimit, setHasLimit] = useState(false);

  useEffect(() => {
    const source = find(payment => payment.id === props.accountId, props.sendMoney.paymentSource);
    updateState(source);
  }, [props.show]);

  const onChangeBalance = (value) => {
    setSourceBalance(value);
  };

  const onSave = () => {
    const {
      accountId,
      accountType,
      sendMoney: { paymentSource, transactionRate },
      wallet: { accounts },
    } = props;
    const account = find(a => a.account_id === accountId, accounts);
    const accountCurrency = accountType === 'account' ? account.balance.currency : 'USD';
    const accountBalance = account && calcSourceBalance(account, accountCurrency, transactionRate);
    const amount = parseFloat(sourceBalance).toFixed(2).valueOf();

    const source = {
      id: accountId,
      type: accountType,
      amount,
      currency: sourceCurrency,
      accountCurrency,
      accountName: accountType === 'account' ? account.name : 'Bank'
    };
    let newSource = [];
    if (sourceBalance <= 0) {
      newSource = filter(p => p.id !== accountId, paymentSource);
    } else {
      newSource = compose(prepend(source), filter(s => s.id !== source.id))(paymentSource);
    }
    if (validate(accountType, accountBalance, source)) {
      props.onClose();
      props.onSave(newSource);
    }
  };

  const throwError = (text, hasLimit = false) => {
    setErrorMsg(text);
    setHasLimit(hasLimit);
  };

  const validate = (accountType, accountBalance, source) => {
    const { paymentCurrency, transactionRate } = props.sendMoney;
    const { permissions } = props;
    const { amount, accountCurrency } = source;
    const limit = permissionAllowedLimit(permissions, 'max_ach_allowed');
    const maxAmount = formatNumberUSD(permissions.max_send_amount);

    const amountInAccountCurrency = sourceCurrency !== accountCurrency ? _convertCurrency(sourceCurrency, accountCurrency, amount, transactionRate) : amount;
    const amountInUSD = sourceCurrency !== 'USD' ? _convertCurrency(sourceCurrency, 'USD', amount, transactionRate) : amount;

    if (accountType === 'account' && parseFloat(amountInAccountCurrency) > accountBalance) {
      throwError('Not enough funds');
      return false;
    }
    if (
      (accountType === 'account') &&
      (parseFloat(amountInUSD) > leftToSource())
    ) {
      throwError('Amount is greater than the rest of transaction');
      return false;
    }
    if (
      (accountType === 'account') &&
      (parseFloat(amountInUSD) > leftToSource())
    ) {
      throwError('Amount is greater than the rest of transaction');
      return false;
    }
    if (accountType === 'bank' && parseFloat(amountInUSD) > leftToSource()) {
      throwError('Amount is greater than the rest of transaction');
      return false;
    }
    if (accountType === 'bank' && parseFloat(amountInUSD) > parseFloat(limit)) {
      throwError(
        `${limit + ' ' + paymentCurrency} max for a one time bank transfer. 
        Use BitRail accounts to send up to ${maxAmount} ${paymentCurrency}. Show all limits. `, true
      );
      return false;
    }
    return true;
  };

  const leftToSource = () => {
    const { accountId, sendMoney } = props;
    return calcLeftToSource(sendMoney, accountId);
  };

  const updateState = (source) => {
    const { paymentCurrency } = props.sendMoney;
    setSourceBalance(source ? source.amount : '0.00');
    setSourceCurrency(source ? source.currency : paymentCurrency);
    setErrorMsg('');
  };

  const useWholeBalance = () => {
    const { accountId, wallet: { accounts } } = props;
    const { transactionRate } = props.sendMoney;
    const account = find(acc => acc.account_id === accountId, accounts);
    const accountBalance = account && calcSourceBalance(account, sourceCurrency, transactionRate);
    setSourceBalance(accountBalance);
    setErrorMsg('');
  };

  const fulfillRest = () => {
    const { paymentCurrency, transactionRate } = props.sendMoney;
    const amount = leftToSource();
    // TODO Double check this
    const convertedUpValue = _convertCurrency(paymentCurrency, sourceCurrency, amount, transactionRate, 0).valueOf();
    const convertedDownValue = _convertCurrency(paymentCurrency, sourceCurrency, amount, transactionRate).valueOf();
    const revertedUpValue = _convertCurrency(sourceCurrency, paymentCurrency, convertedUpValue, transactionRate).valueOf();
    if (sourceCurrency !== paymentCurrency) {
      setSourceBalance(revertedUpValue > amount ? convertedDownValue : convertedUpValue);
      setErrorMsg('');
    } else {
      setSourceBalance(amount);
      setErrorMsg('');
    }
  };

  const clearSource = () => {
    setSourceBalance('0.00');
    setErrorMsg('');
  };

  const changeCurrency = () => {
    const sourceCurrencyValue = accountCurrency === sourceCurrency ? paymentCurrency : accountCurrency;

    setSourceCurrency(sourceCurrencyValue);
  };

  const renderChargeAmountText = () => {
    const { accountId, accountType, sendMoney: { paymentCurrency }, wallet: { accounts } } = props;
    const { transactionRate } = props.sendMoney;
    const account = find(acc => acc.account_id === accountId, accounts);
    const accountName = account.name ? account.name : 'Bank';
    const accountCurrency = accountType === 'account' ? account.balance.currency : 'USD';
    const amount = sourceBalance || '0.00';
    return paymentCurrency !== sourceCurrency
      ? (
        <span>
          Will source&nbsp;
          <span className="font-weight-bold color-primary">
            {_convertCurrency(sourceCurrency, paymentCurrency, amount, transactionRate)} {paymentCurrency}&nbsp;
          </span>
          to transaction
        </span>
      ) : (
        <span>
          We will charge your {accountName} Account&nbsp;
          <span className="font-weight-bold color-primary">
            {_convertCurrency(paymentCurrency, accountCurrency, amount, transactionRate)} {accountCurrency}
          </span>
        </span>
      );
  };

  const renderSourceAccount = () => {
    const {
      accountId,
      accountType,
      sendMoney: { paymentCurrency, transactionRate },
      wallet: { accounts, bankAccounts },
    } = props;
    if (accountType === 'account') {
      const account = find(a => a.account_id === accountId, accounts);
      return (
        <div className="well -y-xl -color-white full-width">
          <div className="well_head">
            {account.name ? account.name : 'Bank'} Account
            <span className="well_content-secondary">
              Balance:
              {calcBalance(
                paymentCurrency,
                account.balance.currency,
                account.balance.ordinal,
                account.balance.decimal,
                transactionRate,
              )}
            </span>
          </div>
        </div>
      );
    }
    const bank = find(a => a.bank_account_id === accountId, bankAccounts);
    return (
      <div className="well -y-xl -color-white full-width">
        <div className="well_head">
          Bank Account
          <span className="well_content-secondary">
            {bank.user_description || 'Account Number'}:
            {' '}
            <span className="nowrap">
              <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const {
    accountId,
    accountType,
    show,
    onClose,
    sendMoney: {
      paymentAmount,
      paymentCurrency,
    },
    wallet: {
      accounts,
    },
    setPaymentPopup,
  } = props;

  const { transactionRate } = props.sendMoney;

  if (!accountId) {
    return null;
  }

  const account = find(a => a.account_id === accountId, accounts);
  const accountCurrency = account && account.type ? account.balance.currency : 'USD';
  const accountBalance = account && calcSourceBalance(account, paymentCurrency, transactionRate);

  return (
    <Modal show={show} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1>Payment Source</h1>
      </div>
      <div className="modal_body">
        <div className="form">
          <div className="layer">
            {renderSourceAccount()}
          </div>
          <div className="layer -space-up-sm -space-down-lg">
            <span className="font-weight-bold font-size-secondary-responsive">
                Left to source: {withCurrency(currencyFormat(leftToSource()), paymentCurrency)}&nbsp;of&nbsp;
              {withCurrency(currencyFormat(paymentAmount), paymentCurrency)}
            </span>
          </div>
          <div className="layer">
            <ExchangeMoneyInput
              label="Transfer Amount"
              inputName="transfer-amount"
              invalid={!!errorMsg}
              errorMessage={errorMsg}
              showLimits={hasLimit}
              onShowLimits={() => setPaymentPopup(true, true)}
              amount={sourceBalance}
              currencyList={['USD', 'USD']}
              currency={sourceCurrency}
              onEnter={onSave}
              onChangeInput={v => onChangeBalance(v)}
              onChangeCurrency={changeCurrency}
              disableCurrencyChange={true}
            />
          </div>
          <div className="layer -space-up-md -space-down-lg">
            <div className="button-group -grid">
              {accountType === 'bank' && (
                <Button color="pale-blue" ySize="xs" onClick={fulfillRest} className="button-group_item">
                    Fulfill rest of transaction
                </Button>
              )}
              {((accountType === 'account') && (parseFloat(accountBalance) > leftToSource())) && (
                <Button color="pale-blue" ySize="xs" onClick={fulfillRest} className="button-group_item">
                    Fulfill rest of transaction
                </Button>
              )}
              {((accountType === 'account') && (parseFloat(accountBalance) <= leftToSource())) && (
                <Button color="pale-blue" ySize="xs" onClick={useWholeBalance} className="button-group_item">
                    Use whole balance
                </Button>
              )}
              <Button
                color="pale-blue"
                ySize="xs"
                className="-has-icon-before button-group_item"
                onClick={clearSource}
              >
                <Svg name="cross" />
                  Don’t use account
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            xSize="full"
            transparency="full"
            className="modal_footer-control -cancel"
            onClick={onClose}
          >
              Cancel
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="modal_footer-control -submit"
            onClick={onSave}
          >
              Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

PaymentSourceModal.propTypes = {
  accountId: PropTypes.string,
  accountType: PropTypes.string,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes),
  permissions: EnabledStatesShape,
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  setPaymentPopup: PropTypes.func
};

function mapStateToProps(state) {
  return {
    sendMoney: state.sendMoney,
    wallet: state.wallet,
    permissions: state.user.user.enabled_states,
  };
}

export default connect(mapStateToProps, { setPaymentPopup })(PaymentSourceModal);
