import React, { useState, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { AddressShape } from '../constants/prop-types';
import { assocPath, compose } from 'ramda';

import VALIDATION from '../utils/validation';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';

const AddressModal = (props) => {
  const [modalState, setModalState] = useState({
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    errors: {
      street1: '',
      city: '',
      state: '',
      zip: '',
    },
  });

  const changeState = (value) => {
    setModalState(Object.assign({}, modalState, value));
  };

  useEffect(() => {
    if(!props.shown) {
      setModalState({
        ...props.address,
        errors: {
          street1: '',
          city: '',
          state: '',
          zip: '',
        },
      });
    }
  }, [props.shown]);

  const onUpdate = (event) => {
    event.preventDefault();

    const {
      street1,
      street2,
      city,
      state,
      zip,
    } = modalState;

    const street1Validation = VALIDATION.validateAddressLine(street1);
    const street2Validation = street2 ? VALIDATION.validateAddressLine2(street2) : {
      success: true,
    };
    const cityValidation = VALIDATION.validateCity(city);
    const stateValidation = VALIDATION.validateState(state);
    const zipValidation = VALIDATION.validateZip(zip);

    if (
      street1Validation.success
      && street2Validation.success
      && cityValidation.success
      && zipValidation.success
      && stateValidation.success
    ) {
      props.updateValues({
        street1,
        street2,
        city,
        state,
        zip: parseInt(zip, 10),
      });
    } else {
      changeState({
        errors: {
          street1: !street1Validation.success ? street1Validation.message : '',
          street2: !street2Validation.success ? street2Validation.message : '',
          city: !cityValidation.success ? cityValidation.message : '',
          state: !stateValidation.success ? stateValidation.message : '',
          zip: !zipValidation.success ? zipValidation.message : '',
        },
      });
    }
  };

  const updateField = field =>
    (value) => {
      const state = compose(
        assocPath([field], value),
        assocPath(['errors', field], ''),
      )(modalState);

      changeState(state);
    };

  const { shown, onClose, isUpdating } = props;
  const { street1, street2, city, state, zip } = modalState;

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1 className="js-modal-title">Home Address</h1>
      </div>
      <div className="modal_body">
        <form className="form" onSubmit={onUpdate}>
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="address-1"
                label="Address 1"
                className="full-width"
                value={street1 || ''}
                onChange={updateField('street1')}
                errorMessage={modalState.errors.street1}
                invalid={!!modalState.errors.street1}
                autoFocus
              />
            </div>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="address-2"
                label="Address 2 (optional)"
                value={street2 || ''}
                errorMessage={modalState.errors.street2}
                onChange={updateField('street2')}
                className="full-width"
              />
            </div>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="city"
                label="City"
                value={city || ''}
                onChange={updateField('city')}
                className="full-width"
                errorMessage={modalState.errors.city}
                invalid={!!modalState.errors.city}
              />
            </div>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="state"
                label="State"
                value={state || ''}
                onChange={updateField('state')}
                className="full-width"
                errorMessage={modalState.errors.state}
                invalid={!!modalState.errors.state}
              />
            </div>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="zip"
                label="Zip"
                value={`${zip}` || ''}
                onChange={updateField('zip')}
                className="full-width"
                errorMessage={modalState.errors.zip}
                invalid={!!modalState.errors.zip}
                mask="99999"
                hideMask
              />
            </div>
          </div>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={onUpdate}
            disabled={isUpdating ||
                !!modalState.errors.street1 ||
                !!modalState.errors.street2 ||
                !!modalState.errors.city ||
                !!modalState.errors.state ||
                !!modalState.errors.zip
            }
            loading={isUpdating}
          >
              Update Address
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AddressModal.propTypes = {
  isUpdating: PropTypes.bool,
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  updateValues: PropTypes.func,
  address: AddressShape
};

export default AddressModal;
