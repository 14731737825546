import { combineReducers } from 'redux';

import appReducer from './reducers/app-reducer';
import sendMoneyReducer from './reducers/send-money-reducer';
import transferReducer from './reducers/transfer-reducer';
import bankAccountReducer from './reducers/bank-account-reducer';
import userReducer from './reducers/user-reducer';
import walletReducer from './reducers/wallet-reducer';
import signUpReducer from './reducers/sign-up-reducer';
import loginReducer from './reducers/login-reducer';
import transactionsReducer from './reducers/transactions-reducer';
import recoverPasswordReducer from './reducers/recover-password-reducer';
import setNewPasswordReducer from './reducers/set-new-password-reducer';
import toastReducer from './reducers/toast-reducer';
import dashboardReducer from './reducers/dashboard-reducer';
import partnerSignupReducer from './reducers/partner-signup-reducer';
import loanOfferReducer from './reducers/loan-offer-reducer';
import gbSignupReducer from './reducers/gb-signup-reducer';
import recurrentPaymentReducer from './reducers/recurrent-payment-reducer';
import creditCardReducer from './reducers/credit-card-reducer';

export default combineReducers({
  application: appReducer,
  sendMoney: sendMoneyReducer,
  transfer: transferReducer,
  bankAccount: bankAccountReducer,
  user: userReducer,
  wallet: walletReducer,
  signUp: signUpReducer,
  passwordRecovery: combineReducers({
    recoverPassword: recoverPasswordReducer,
    setNewPassword: setNewPasswordReducer,
  }),
  partnerSignup: partnerSignupReducer,
  login: loginReducer,
  transactions: transactionsReducer,
  toasts: toastReducer,
  dashboard: dashboardReducer,
  loanOffer: loanOfferReducer,
  gbSignup: gbSignupReducer,
  recurrentPayment: recurrentPaymentReducer,
  cardAccount: creditCardReducer
});
