import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormControl = ({ checked, onChange, onClick, className, disabled, label, inputName, type, value, invalid }) => (
  <label htmlFor={inputName} className={cn('form-control', invalid && '-is-invalid', className)}>
    <input
      checked={checked}
      className="form-control_input"
      disabled={disabled}
      id={inputName}
      name={inputName}
      type={type}
      value={value}
      onChange={onChange}
      onClick={onClick}
    />
    <span className="form-control_control" />
    <span className="form-control_text">{label}</span>
  </label>
);

FormControl.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  inputName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
};

FormControl.defaultProps = {
  onChange: () => {},
  checked: false,
  className: null,
  disabled: false,
  invalid: false,
  label: null,
};

export default FormControl;
