import qs from 'qs';
import crypto from 'crypto-js';
import store, { history } from '../init-store';
import { clearState } from '../actions/login-actions';
import { clearState as clearSignupState } from '../actions/sign-up-actions';
import { setUser as clearUserState } from '../actions/user-actions';
import { startsWith } from 'ramda';
import { GET, CLIENT_ID, basePath } from './api';

export const redirectToLogin = () => {
  const from = window.location.pathname;
  let query;

  store.dispatch(clearState());
  store.dispatch(clearSignupState());
  store.dispatch(clearUserState({ user: {} }));

  if (!startsWith('/login', from) && !startsWith('/signup', from) && !startsWith('/partner-signup', from)) {
    query = from && from !== '/' ? '?' + qs.stringify({ from }) + window.location.search : '';
  } else {
    if (startsWith('/partner-signup', from)) {
      query = '';
    } else {
      query = window.location.search;
    }
  }
  
  history.push(`/login${query}`);
};

const generateRandomString = (length) => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

const base64URL = (string) => {
  return string.toString(crypto.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
};

const getCodeVerifier = () => {
  const code_verifier = generateRandomString(128);

  localStorage.setItem('code_verifier', code_verifier);
  return code_verifier;
};

const getCodeChallenge = () => {
  const code_verifier = getCodeVerifier();

  return base64URL(crypto.SHA256(code_verifier));
};

export const redirectToOAuthLogin = () => {
  const { pathname, search } = window.location;
  const from = pathname.includes('/auth/') || pathname.includes('setup') || pathname.includes('login')  ? '/dashboard' 
    : `${pathname}${search.replaceAll('&', '§')}`;
  const { clientui } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || localStorage.getItem('clientui');

  store.dispatch(clearState());
  store.dispatch(clearSignupState());
  store.dispatch(clearUserState({ user: {} }));

  const queryParams = {
    response_type: 'code',
    scope: 'everything',
    state: generateRandomString(128),
    client_id: CLIENT_ID,
    redirect_uri: `${window.location.origin}/authcode?redir=${from}`,
    code_challenge: getCodeChallenge(),
    code_challenge_method: 'S256',
    clientui
  };
  window.location.href = `${basePath}/v1/auth/authorize?${qs.stringify(queryParams)}`;
};

export const redirectTo404 = () => {
  history.push('/404');
};

export const redirectToInvoiceComplete = () => {
  history.push('/invoice-complete');
};
