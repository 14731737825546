import React from 'react';
import PropTypes from 'prop-types';

import Svg from '../../components/svg';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import CONST from '../../constants/login-constants';

const LoginStepErrorTryAgain = (props) => {
  const endStep = () => {
    props.endStep(CONST.STEP_EMAIL_PASSWORD);
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="circled-cross" className="splash_icon" />
            <h1 className="js-page-title splash_title">Authentication Link Expired</h1>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                  Authentication links are only valid for {(props.login.expiration / 60).toFixed()} minutes.
                <br />
                  Generate a new link to continue
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          xSize="full"
          transparency="full"
          onClick={() => props.setField('step', CONST.STEP_EMAIL_PASSWORD)}
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={endStep}
        >
            Generate New Link
        </Button>
      </div>
    </SignupLayout>
  );
};


LoginStepErrorTryAgain.propTypes = {
  login: PropTypes.shape({
    expiration: PropTypes.number,
  }),
  endStep: PropTypes.func,
  setField: PropTypes.func,
};

export default LoginStepErrorTryAgain;
