import { takeLatest, put, select, call, take, fork } from 'redux-saga/effects';
import { delay, eventChannel } from 'redux-saga';
import { path } from 'ramda';
import qs from 'qs';
import { setAuthToken, clearAuthToken, helpDeskUrl } from '../utils/api';
import VALIDATION from '../utils/validation';
import { addNotification } from '../actions/toast-actions';
import { setField } from '../actions/partner-signup-actions';
import CONST from '../constants/partner-signup-constants';
import { formatMaskedEmail, formatErrorMessage } from '../utils/string-utils';
import { history } from '../init-store';

import REQUESTS from '../utils/requests';

const STATE_TYPES = {
  USER: 'user',
  FAIL: 'fail',
  EMPTY: '',
  TF_AUTH: 'tfa_auth',
  TF_LINK: 'tfa_link',
  TF_PENDING: 'tf_pending',
};

const CALL_DELAY = 3000;

function* pageEmitter(status, page) {
  yield put(setField('isLoading', false));

  if (status >= 400) {
    yield put(setField('step', page));
  }
}

function* toastEmitter(status, errors = []) {
  yield put(setField('isLoading', false));

  if (status >= 500) {
    yield put(addNotification(`Something went wrong. Please try again or 
      contact ${helpDeskUrl} if the error persists.`, 500));
  };

  if ([401, 408].indexOf(status) === -1 && status >= 400 && status < 500) {
    for(let i = 0; i < errors.length; i++) {
      yield put(addNotification(formatErrorMessage(errors[i])));
    }
  };
}

function* checkForAuthLink(numCalls, redirectPath) {
  try {
    const step = yield select(state => path(['partnerSignup', 'step'], state));

    if (step === CONST.STEP_LINK_SENT || step === CONST.STEP_PAYPHONE_AUTODETECT) {
      yield call(delay, CALL_DELAY);

      const { data, success, status } = yield REQUESTS.CHECK_SOFT_LINK();

      if (success) {
        const { state } = data[0];

        if (state === STATE_TYPES.FAIL) {
          yield put(setField('step', CONST.STEP_SOMETHING_WENT_WRONG));
          yield put(setField('payfoneIsLoading', false));

          return;
        }

        if(state === STATE_TYPES.EMPTY) {
          yield put(setField('step', CONST.STEP_LINK_EXPIRED));
          yield put(setField('payfoneIsLoading', false));

          return;
        }

        if (state === STATE_TYPES.USER) {
          yield put(setField('payfoneIsLoading', false));
          window.location.assign(redirectPath);
        }

        yield call(checkForAuthLink, numCalls + 1, redirectPath);
      } else {
        yield call(pageEmitter, status, CONST.STEP_SOMETHING_WENT_WRONG);
        yield put(setField('payfoneIsLoading', false));
      }

    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* phoneNumberFlow () {
  try {
    yield put(setField('isLoading', true));

    const { phoneNumber } = yield select(state => state.partnerSignup);
    const { success, data, status, errors } = yield REQUESTS.SOFT_LINK_AUTH(phoneNumber);
    const redirectPath = '/dashboard';

    if (success) {
      if (data[0].state && data[0].state === STATE_TYPES.USER) {

        window.location.assign(redirectPath);

        return;
      }

      if (data[0].state === STATE_TYPES.FAIL || data[0].state === STATE_TYPES.EMPTY) {
        yield put(setField('step', CONST.STEP_SOMETHING_WENT_WRONG));

        return;
      }

      yield put(setField('lastPhoneDigits', data[0].tfa_phone));
      yield put(setField('maskedEmail', data[0].tfa_email && formatMaskedEmail(data[0].tfa_email)));
      yield put(setField('expiration', data[0].tfa_expires));

      yield put(setField('step', CONST.STEP_LINK_SENT));
      yield put(setField('isLoading', false));

      yield call(checkForAuthLink, 0, redirectPath);
    } else {
      yield put(setField('isLoading', false));

      if ([401, 403, 408].indexOf(status) === -1 && status >= 400 && status < 500)  {
        yield put(setField('step', CONST.STEP_UNABLE_TO_SIGNUP));
      }

      if (status >= 500) {
        yield call(toastEmitter, status, errors);
      }
    }
  } catch (e) {
    console.log(e);
    yield put(setField('isLoading', false));
  }
}

function* authLinkFlow() {
  try {
    const { phoneNumber } = yield select(state => state.partnerSignup);

    if (phoneNumber !== null) {
      const phoneValidation = VALIDATION.validatePhoneNumber(phoneNumber);

      if (phoneValidation.success) {
        yield call(phoneNumberFlow);
      } else {
        yield put(setField(['errors', 'phoneNumber'], phoneValidation.message));
      }
    } else {
      yield call(phoneNumberFlow);
    } 
    
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}


function* signupFlow() {
  try {
    yield put(setField('isLoading', true));
    // partnerCode, token, password, consent, handle
    const { partner, token, password, consent, handle, gbUser: { email } } = yield select(state => state.partnerSignup);

    const {
      success,
      status,
      errors,
    } = yield REQUESTS.SIGNUP_FOR_BITRAIL(partner, token, password, consent, handle);

    if (success) {
      const {
        success: gotUserToken,
        data: userData,
        status: tokenStatus,
        errors: tokenErrors,
      } = yield REQUESTS.OBTAIN_TOKEN({ username: email, password });

      if (gotUserToken) {
        const userToken = userData[0].access_token;
        const { state } = userData[0];

        setAuthToken(userToken);

        switch (state) {
          case STATE_TYPES.USER:
            history.push('/dashboard');
            break;

          case STATE_TYPES.TF_LINK: {
            yield put(setField('step', CONST.STEP_PHONE_NUMBER));
            break;
          }

          case STATE_TYPES.TF_AUTH: {
            yield put(setField('isPayfone', true));
            yield put(setField('mnoLink', userData[0].tfa_auth));
            yield put(setField('step', CONST.STEP_PAYPHONE_AUTODETECT));
            break;
          }
          case STATE_TYPES.FAIL:
          case STATE_TYPES.EMPTY:
            yield put(setField('step', CONST.STEP_SOMETHING_WENT_WRONG));
            break;

          case STATE_TYPES.TF_PENDING:
          default: break;
        }
      } else {
        yield call(toastEmitter, tokenStatus, tokenErrors);
      }

      yield put(setField('isLoading', false));
    } else {
      yield call(toastEmitter, status, errors);
      yield put(setField('isLoading', false));
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* autheticateAccFlow() {
  try {
    yield put(setField('isLoading', true));

    const { gbPassword, partner, token } = yield select(state => state.partnerSignup);

    const {
      success,
      status,
      errors,
    } = yield REQUESTS.POST_PARTNER_USER(partner, token, gbPassword);

    if (success) {
      yield put(setField('step', CONST.STEP_SIGNUP));
      yield put(setField('isLoading', false));

    } else {
      if (status === 401) {
        yield put(setField(['errors', 'gbPassword'], 'Incorrect password'));
      } else {
        yield call(toastEmitter, status, errors);
      }

      yield put(setField('isLoading', false));
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}
  
function* startSignupFlow() {
  try {
    clearAuthToken();

    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const { token, partner } = query;

    yield put(setField('token', token));
    yield put(setField('partner', partner));

    const {
      success: appSuccess,
      data: appData,
      status: appStatus,
    } = yield REQUESTS.OBTAIN_APP_TOKEN();
    
    if (appSuccess) {
      const appToken = appData[0].access_token;
      setAuthToken(appToken);

      const {
        success: gbSuccess,
        data: gbData,
        status: gbStatus,
      } = yield REQUESTS.GET_PARTNER_USER(partner, token);

      if (gbSuccess) {
        yield put(setField('gbUser', gbData[0]));
        yield put(setField('handle', gbData[0].handle));
        yield put(setField('walletType', gbData[0].wallet_type));
        yield put(setField('step', CONST.STEP_WELCOME));
      } else {
        yield call(pageEmitter, gbStatus, CONST.STEP_SOMETHING_WENT_WRONG);
      }
    } else {
      yield call(pageEmitter, appStatus, CONST.STEP_SOMETHING_WENT_WRONG);
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}


function* createAndDeleteIframeListener(channel) {
  while (true) {
    yield take(channel);
    const redirectPath = '/dashboard'; 

    const {
      data,
      success,
    } = yield REQUESTS.CHECK_SOFT_LINK();

    if (success) {
      if (!data || !data[0]) {
        yield put(setField('step', CONST.STEP_LINK_EXPIRED));
        yield put(setField('payfoneIsLoading', false));

        return;
      }

      if (data[0].state === STATE_TYPES.USER) {
        yield put(setField('isLoading', false));
        yield put(setField('payfoneIsLoading', false));
        window.location.assign(redirectPath);

        return;
      }

      if (data[0].state === STATE_TYPES.FAIL) {
        yield put(setField('step', CONST.STEP_SOMETHING_WENT_WRONG));
        yield put(setField('payfoneIsLoading', false));

        return;
      }

      if (data[0].state === STATE_TYPES.TF_LINK || data[0].state === STATE_TYPES.TF_AUTH || data[0].state === STATE_TYPES.EMPTY) {
        yield put(setField('isLoading', false));
        yield put(setField('payfoneIsLoading', false));

        yield put(setField('step', CONST.STEP_PHONE_NUMBER));
      }
    } else {
      yield put(setField('step', CONST.STEP_SOMETHING_WENT_WRONG));
      yield put(setField('payfoneIsLoading', false)); 
    }
  }
}

function popupEmitter(mnoLink) {
  return eventChannel((emit) => {
    const url = `http://${window.location.host}/payfone?url=${btoa(mnoLink)}`;
    const payfoneWindow = window.open(url, 'Payfone','height=667,width=375');
    let timeout = null;
    let timer = null;

    timeout = setTimeout(function() {
      clearInterval(timer);
      clearTimeout(timeout);
      emit('close');
    }, process.env.REACT_APP_LOGIN_TIMEOUT);

    timer = setInterval(function() {
      if(payfoneWindow.closed) {
        clearInterval(timer);
        clearTimeout(timeout);
        emit('close');
      }
    }, 500);

    return () => {};
  });
}

function* openPayfonePopupFlow() {
  yield put(setField('payfoneIsLoading', true));
  const { mnoLink } = yield select(state => state.partnerSignup);
  const emitter = popupEmitter(mnoLink);
  
  yield fork(createAndDeleteIframeListener, emitter);
}


function* partnerSignupSaga() {
  yield takeLatest(CONST.START_SIGNUP, startSignupFlow);
  yield takeLatest(CONST.AUTHETICATE_ACC, autheticateAccFlow);
  yield takeLatest(CONST.SIGNUP, signupFlow);
  yield takeLatest(CONST.PHONE_NUMBER_ENTERED, authLinkFlow);
  yield takeLatest(CONST.OPEN_POPUP, openPayfonePopupFlow);
}

export default [partnerSignupSaga];
