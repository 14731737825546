import { takeLatest, put, select } from 'redux-saga/effects';
import { setField, setValidationError } from '../actions/card-account-actions';
import { setWalletCardAccounts } from '../actions/wallet-actions';
import CONST from '../constants/card-account-constants';
import REQUESTS from '../utils/requests';
import VALIDATION from '../utils/validation';
import { history } from '../init-store';

function* updateCardAccountFlow({ payload: { cardAccountId, cardAccount, callback } }) {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { service_id } = yield select(state => state.cardAccount);

    if(Object.keys(cardAccount).length > 2) {
      cardAccount.service_id = service_id;
    }

    const verificationCodeValidation = cardAccount.verification_code !== undefined ?
      VALIDATION.validateCardVerificationCode(cardAccount.verification_code) : { success: true };
    const expirationValidation = cardAccount.verification_code !== undefined ?
      VALIDATION.validateCardExpiration(cardAccount.expiration) : { success: true };
    const cardDetailsLabelValidation = cardAccount.user_description !== undefined ?
      VALIDATION.validateCardLabel(cardAccount.user_description) : { success: true };

    if(expirationValidation.success && verificationCodeValidation.success && cardDetailsLabelValidation.success) {
      const sanitizedExpiration = cardAccount.expiration ? 
        `20${cardAccount.expiration.split('/')[1].trim()}-${cardAccount.expiration.split('/')[0].trim()}` : undefined;
      const { success } = yield REQUESTS.UPDATE_CARD_ACCOUNT(wallet_id, cardAccountId, { ...cardAccount, expiration: sanitizedExpiration });

      if (success) {
        const walletCardAccounts = yield REQUESTS.GET_WALLET_CARD_ACCOUNTS(wallet_id);
        if (walletCardAccounts.success) {
          yield put(setWalletCardAccounts(walletCardAccounts.data));
          yield put(setField('isLoading', false));
        }
        if(callback) {
          callback();
        }
      } else {
        yield put(setField('isLoading', false));
      }
    } else {
      if (cardAccount.expiration !== undefined && !expirationValidation.success) {
        yield put(setValidationError('expiration', expirationValidation.message));
      }

      if (cardAccount.expiration !== undefined && !verificationCodeValidation.success) {
        yield put(setValidationError('verification_code', verificationCodeValidation.message));
      }

      if (cardAccount.user_description !== undefined && !cardDetailsLabelValidation.success) {
        yield put(setValidationError('user_description', cardDetailsLabelValidation.message));
      }

      yield put(setField('isLoading', false));
    }

  } catch (e) {
    console.error(e);
    yield put(setField('isLoading', false));
  }
}

function* removeCardAccount({ payload }) {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { success } = yield REQUESTS.REMOVE_CARD_ACCOUNT(wallet_id, payload.cardAccountId);

    if (success) {
      const walletCardAccounts = yield REQUESTS.GET_WALLET_CARD_ACCOUNTS(wallet_id);
      
      if (walletCardAccounts.success) {
        yield put(setWalletCardAccounts(walletCardAccounts.data));
        yield put(setField('isLoading', false));

        if(payload.callback) {
          payload.callback();
        }
      } else {
        yield put(setField('isLoading', false));  
      }
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* addCardAccountFlow({ payload }) {
  try {
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const {
      account_number,
      verification_code,
      expiration,
      holder_fname,
      holder_lname,
      zip_code,
      user_description,
    } = yield select(state => state.cardAccount.editingCard);
    const { callback } = payload;
    
    const accountNumberValidation = VALIDATION.validateCardNumber(account_number);
    const verificationCodeValidation = VALIDATION.validateCardVerificationCode(verification_code);
    const expirationValidation = VALIDATION.validateCardExpiration(expiration);
    const firstNameValidation = VALIDATION.validateCardName(holder_fname);
    const lastNameValidation = VALIDATION.validateCardName(holder_lname);
    const zipCodeValidation = VALIDATION.validateZip(zip_code);
    const cardDetailsLabelValidation = VALIDATION.validateCardLabel(user_description);

    const { service_id } = yield select(state => state.cardAccount);

    if (accountNumberValidation.success
      && verificationCodeValidation.success
      && expirationValidation.success
      && firstNameValidation.success
      && lastNameValidation.success
      && zipCodeValidation.success
      && cardDetailsLabelValidation.success
    ) {
      yield put(setField('isLoading', true));
      const sanitizedExpiration = `20${expiration.split('/')[1].trim()}-${expiration.split('/')[0].trim()}`;
      const { success, data } = yield REQUESTS.ADD_CARD_ACCOUNT(wallet_id, {
        account_number: account_number.replaceAll(' ', '').replaceAll('_', ''),
        verification_code: `${parseInt(verification_code, 10)}`,
        expiration: sanitizedExpiration,
        holder_fname,
        holder_lname,
        zip_code: `${parseInt(zip_code, 10)}`,
        user_description,
        account_class: 'individual',
        service_id,
        account_type: 'creditcard'
      });

      if (success) {
        const walletCardAccounts = yield REQUESTS.GET_WALLET_CARD_ACCOUNTS(wallet_id);

        yield put(setField('isLoading', false));

        if (walletCardAccounts.success) {
          yield put(setWalletCardAccounts(walletCardAccounts.data));
          yield put(setField('cardAccountCreated', true));
        } else {
          yield put(setField('isLoading', false));
        }

        if (!window._isIFrameCall) {
          history.push('/credit-cards');
        }

        if (callback) {
          callback(data[0].card_account_id);
        }
        
      } else {
        yield put(setField('isLoading', false));
      }
    } else {
      if (!accountNumberValidation.success) {
        yield put(setValidationError('account_number', accountNumberValidation.message));
      }

      if (!expirationValidation.success) {
        yield put(setValidationError('expiration', expirationValidation.message));
      }

      if (!verificationCodeValidation.success) {
        yield put(setValidationError('verification_code', verificationCodeValidation.message));
      }

      if (!firstNameValidation.success) {
        yield put(setValidationError('holder_fname', firstNameValidation.message));
      }

      if (!lastNameValidation.success) {
        yield put(setValidationError('holder_lname', lastNameValidation.message));
      }

      if (!zipCodeValidation.success) {
        yield put(setValidationError('zip_code', zipCodeValidation.message));
      }

      if (!cardDetailsLabelValidation.success) {
        yield put(setValidationError('user_description', cardDetailsLabelValidation.message));
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* getCardAccounts() {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { success, data } = yield REQUESTS.GET_WALLET_CARD_ACCOUNTS(wallet_id);

    yield put(setField('isLoading', false));

    if (success) {
      yield put(setWalletCardAccounts(data));
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* getCardDetailsFlow({ payload: { cardAccountId } }) {
  yield put(setField('isLoading', true));
  const { wallet: { wallet_id } } = yield select(state => state.wallet);
  const { success, data } = yield REQUESTS.GET_CARD_ACCOUNT_DETAILS(wallet_id, cardAccountId);

  yield put(setField('isLoading', false));

  if (success) {
    yield put(setField('cardDetails', data[0]));
  } else {
    yield put(setField('isLoading', false));
  }
}

function* getCardServiceFlow() {
  try {
    const { user_id: userId } = yield select(state => state.user.user);
    const { success, data } = yield REQUESTS.GET_CARD_SERVICE(userId);

    if (success) {
      yield put(setField('service_id', data[0].service_id));
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* cardAccountSagas() {
  yield takeLatest(CONST.UPDATE_CARD_ACCOUNT, updateCardAccountFlow);
  yield takeLatest(CONST.REMOVE_CARD_ACCOUNT, removeCardAccount);
  yield takeLatest(CONST.ADD_CARD_ACCOUNT, addCardAccountFlow);
  yield takeLatest(CONST.GET_CARD_ACCOUNTS, getCardAccounts);
  yield takeLatest(CONST.GET_CARD_DETAILS, getCardDetailsFlow);
  yield takeLatest(CONST.GET_CARD_SERVICE, getCardServiceFlow);
}

export default [cardAccountSagas];
