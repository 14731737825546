import React from 'react';
import PropTypes from 'prop-types';
import { signUpReducerTypes } from '../../constants/prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST from '../../constants/sign-up-constants';
import Radio from '../radio';
import { REGEXP_ALPHANUM } from '../../utils/regexp';

const SignupStepBankAccount = (props) => {
  const checkAccountNumber = (value) => {
    if (!REGEXP_ALPHANUM.test(value)) {
      return null;
    }

    props.setValidationError('bankAccountNumber', '');

    return props.setField('bankAccountNumber', value);
  };

  const { setField, endStep, signUp, setValidationError } = props;

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wizardStep={3}
    >
      <div className="page_header -space-sm">
        <h1 className="js-page-title page_title">Add Bank Account</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={(e) => { e.preventDefault(); endStep(CONST.STEP_BANK_ACCOUNT); }}>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                  Your {process.env.REACT_APP_DOMAIN_STRING} wallet has been created!
                <br />
                Adding a bank account will allow you to make transactions between your bank account and {process.env.REACT_APP_DOMAIN_STRING}.
              </p>
            </div>
            <div className="layer -space-up-xl -space-down-xxl">
              <div className="button-group -titled full-width">
                <div className="button-group_header">
                    Bank Account Type
                </div>
                <div className="button-group_body">
                  <Radio
                    className="button-group_item -like-button -white -semi-transparent -y-lg"
                    label="Checking"
                    value="checking"
                    inputName="isChecking"
                    checked={signUp.bankAccountType === 'checking'}
                    onChange={e => {
                      setValidationError('bankAccountType', '');
                      setField('bankAccountType', e.target.value);
                    }}
                    invalid={!!signUp.validationErrors.bankAccountType}
                  />
                  <Radio
                    className="button-group_item -like-button -white -semi-transparent -y-lg"
                    label="Savings"
                    value="savings"
                    inputName="isSavings"
                    checked={signUp.bankAccountType === 'savings'}
                    onChange={e => {
                      setValidationError('bankAccountType', '');
                      setField('bankAccountType', e.target.value);
                    }}
                    invalid={!!signUp.validationErrors.bankAccountType}
                  />
                  {!!signUp.validationErrors.bankAccountType && (
                    <div className="form-input">
                      <label htmlFor="accountType" className="form-input_message -error">
                        {signUp.validationErrors.bankAccountType}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="layer -space-up-xl -space-down-xxl">
              <div className="button-group -titled full-width">
                <div className="button-group_header">
                    Bank Account Class
                </div>
                <div className="button-group_body">
                  <Radio
                    className="button-group_item -like-button -white -semi-transparent -y-lg"
                    label="Individual"
                    value="individual"
                    inputName="isIndividual"
                    checked={signUp.bankAccountClass === 'individual'}
                    onChange={e => {
                      setValidationError('bankAccountClass', '');
                      setField('bankAccountClass', e.target.value);
                    }}
                    invalid={!!signUp.validationErrors.bankAccountClass}
                  />
                  <Radio
                    className="button-group_item -like-button -white -semi-transparent -y-lg"
                    label="Business"
                    value="business"
                    inputName="isBusiness"
                    checked={signUp.bankAccountClass === 'business'}
                    onChange={e => {
                      setValidationError('bankAccountClass', '');
                      setField('bankAccountClass', e.target.value);
                    }}
                    invalid={!!signUp.validationErrors.bankAccountClass}
                  />
                  {!!signUp.validationErrors.bankAccountClass && (
                    <div className="form-input">
                      <label htmlFor="accountType" className="form-input_message -error">
                        {signUp.validationErrors.bankAccountClass}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Routing Number"
                  inputName="routing"
                  type="tel"
                  onChange={value => {
                    setValidationError('bankAccountRouting', '');
                    setField('bankAccountRouting', value);
                  }}
                  value={signUp.bankAccountRouting}
                  errorMessage={signUp.validationErrors.bankAccountRouting}
                  invalid={!!signUp.validationErrors.bankAccountRouting}
                  mask="999999999"
                  autoFocus
                />
              </div>
            </div>
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Account Number"
                  inputName="account-number"
                  onChange={value => {
                    checkAccountNumber(value);
                  }}
                  value={signUp.bankAccountNumber}
                  errorMessage={signUp.validationErrors.bankAccountNumber}
                  invalid={!!signUp.validationErrors.bankAccountNumber}
                />
              </div>
            </div>

            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Nickname"
                  inputName="account-label"
                  onChange={value => {
                    setValidationError('bankAccountLabel', '');
                    setField('bankAccountLabel', value);
                  }}
                  value={signUp.bankAccountLabel}
                  errorMessage={signUp.validationErrors.bankAccountLabel}
                  invalid={!!signUp.validationErrors.bankAccountLabel}
                />
              </div>
            </div>
              
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="dark-gray"
          xSize="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          transparency="full"
          href="/dashboard"
        >
            Skip for now
        </Button>
        <Button
          color="blue"
          xSize="full"
          loading={signUp.isLoading}
          className="js-submit-button page_control -submit"
          disabled={
            !!signUp.validationErrors.bankAccountRouting ||
              !!signUp.validationErrors.bankAccountNumber ||
              !!signUp.validationErrors.bankAccountLabel ||
              !!signUp.validationErrors.bankAccountType
          }
          onClick={() => endStep(CONST.STEP_BANK_ACCOUNT)}
        >
            Continue
        </Button>
      </div>
    </SignupLayout>
  );
};

SignupStepBankAccount.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  setValidationError: PropTypes.func,
  signUp: PropTypes.shape(signUpReducerTypes),
};

export default SignupStepBankAccount;
