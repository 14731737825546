import { takeLatest, put, select, all, call } from 'redux-saga/effects';
import { zipObj } from 'ramda';
import { setField, setValidationError } from '../actions/bank-account-actions';
import { setField as setDashboardField } from '../actions/dashboard-actions';
import { setWalletBankAccounts } from '../actions/wallet-actions';
import CONST from '../constants/bank-account-constants';
import REQUESTS from '../utils/requests';
import VALIDATION from '../utils/validation';
import { history } from '../init-store';

function* addBankAccountYodle () {
  try {
    yield put(setField('isLoading', true));
    yield put(setDashboardField('yodleeInProgress', true));

    const { user_id: userId } = yield select(state => state.user.user);
    const { yodleeJWT, yodleeUrl, yodleeServiceId, configName } = yield select(state => state.bankAccount);

    if (!yodleeJWT || !yodleeUrl || !yodleeServiceId || !configName) {
      const { success, data } = yield REQUESTS.OBTAIN_BANK_TOKEN(userId);

      yield put(setDashboardField('yodleeInProgress', false));
  
      if (success && data && data[0]) {
        yield put(setField('yodleeUrl', data[0].create_url));
        yield put(setField('yodleeJWT', data[0].credentials.jwt));
        yield put(setField('yodleeServiceId', data[0].service_id));
        yield put(setField('configName', data[0].config_name));
        history.push('/bank-accounts/instant');
      }
    } else {
      history.push('/bank-accounts/instant');
    }

    yield put(setField('isLoading', false));
  } catch (e) {
    yield put(setField('isLoading', false));
    console.log(e);
  }
}

function* addBankAccountOAuth () {
  try {
    yield put(setField('isLoading', true));
    yield put(setDashboardField('yodleeInProgress', true));

    const { user_id: userId } = yield select(state => state.user.user);
    const { yodleeJWT, yodleeUrl, yodleeServiceId, configName } = yield select(state => state.bankAccount);
    if (!yodleeJWT || !yodleeUrl || !yodleeServiceId || !configName) {
      const { success, data } = yield REQUESTS.OBTAIN_BANK_TOKEN(userId);

      if (success && data && data[0]) {
        yield put(setField('yodleeUrl', data[0].create_url));
        yield put(setField('yodleeJWT', data[0].credentials.jwt));
        yield put(setField('yodleeServiceId', data[0].service_id));
        yield put(setField('configName', data[0].config_name));
      }
    } 

    yield put(setDashboardField('yodleeInProgress', false));

    yield put(setField('isLoading', false));
  } catch (e) {
    yield put(setField('isLoading', false));
    console.log(e);
  }
}


function* updateAccountLabel({ payload: { bankAccountId, label } }) {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { success } = yield REQUESTS.UPDATE_BANK_ACCOUNT(wallet_id, bankAccountId, { user_description: label });

    if (success) {
      const walletBankAccounts = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);
      if (walletBankAccounts.success) {
        yield put(setWalletBankAccounts(walletBankAccounts.data));
        yield put(setField('isLoading', false));
      }
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* verifyBankAccount({ payload }) {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { bankAccountId, amountValues } = payload;
    const amount = zipObj(['amount_1', 'amount_2', 'amount_3', 'amount_4'], amountValues);
    const { success } = yield REQUESTS.VERIFY_BANK_ACCOUNT(wallet_id, bankAccountId, amount);

    if (success) {
      const walletBankAccounts = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);
      if (walletBankAccounts.success) {
        yield put(setWalletBankAccounts(walletBankAccounts.data));
        yield put(setField('isLoading', false));
      }
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* removeBankAccount({ payload }) {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { success } = yield REQUESTS.REMOVE_BANK_ACCOUNT(wallet_id, payload.bankAccountId);

    if (success) {
      const walletBankAccounts = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);
      
      if (walletBankAccounts.success) {
        yield put(setWalletBankAccounts(walletBankAccounts.data));
        yield put(setField('isLoading', false));
      } else {
        yield put(setField('isLoading', false));  
      }
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* addSingleBankAccountYodle (bank, wallet_id, yodleeServiceId) {
  return yield REQUESTS.ADD_BANK_ACCOUNT(wallet_id, {
    service_id: yodleeServiceId,
    account_id: bank.accountId,
    provider_id: bank.providerId,
    provider_account_id: bank.providerAccountId,
  });
};

function* addBankAccountMassiveFlow({ payload: { bankAccounts: sites, withoutRedirect } }) {
  try {
    /*
      service_id = the service_id returned in the previous call
      account_id = site accountId
      provider_id = site providerId
      provider_account_id = site providerAccountId
    */
    if (sites) {
      yield put(setField('isLoading', true));
      yield put(setField('isYodleeDisabled', true));

      const bankAccounts = sites.filter((s) => s.status === 'SUCCESS' && !!s.accountId);
      const { wallet: { wallet_id } } = yield select(state => state.wallet);
      const { yodleeServiceId } = yield select(state => state.bankAccount);

      const result = yield all(bankAccounts.map(
        bank => call(addSingleBankAccountYodle, bank, wallet_id, yodleeServiceId)
      ));

      if(result.some((item) => item.success === false)) {
        yield put(setField('yodleeModalShown', true));
      }

      yield put(setField('isLoading', false));
      yield put(setField('isYodleeDisabled', false));

      !withoutRedirect && history.push('/bank-accounts/');
    }
    
  } catch (e) {
    console.log(e);
  }
}

function* addBankAccountFlow() {
  try {
    const { wallet: { wallet_id }, bankAccounts } = yield select(state => state.wallet);
    const {
      bankAccountRouting,
      bankAccountNumber,
      bankAccountLabel,
      bankAccountType,
      bankAccountClass,
    } = yield select(state => state.bankAccount);
    const bankAccountIsUniq = VALIDATION.validateAccountUniqueness(bankAccountRouting, bankAccountNumber, bankAccounts);
    const bankAccountRoutingValidation = VALIDATION.validateAccountRouting(bankAccountRouting);
    const bankAccountNumberValidation = VALIDATION.validateAccountNumber(bankAccountNumber);
    const bankAccountLabelValidation = VALIDATION.validateAccountLabel(bankAccountLabel);
    const bankAccountTypeValidation = VALIDATION.validateAccountType(bankAccountType);

    if (bankAccountRoutingValidation.success
      && bankAccountNumberValidation.success
      && bankAccountLabelValidation.success
      && bankAccountTypeValidation.success
      && bankAccountIsUniq.success
    ) {
      yield put(setField('isLoading', true));
      // here will be checking bank account
      const { success } = yield REQUESTS.ADD_BANK_ACCOUNT(wallet_id, {
        account_type: bankAccountType,
        routing_number: bankAccountRouting,
        account_number: bankAccountNumber,
        user_description: bankAccountLabel,
        account_class: bankAccountClass,
      });

      if (success) {
        const walletBankAccounts = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);

        yield put(setField('isLoading', false));

        if (walletBankAccounts.success) {
          yield put(setWalletBankAccounts(walletBankAccounts.data));
          yield put(setField('bankAccountCreated', true));
        } else {
          yield put(setField('isLoading', false));
        }
      } else {
        yield put(setField('isLoading', false));
      }
    } else {
      if (!bankAccountIsUniq.success) {
        yield put(setValidationError('bankAccountIsUniq', bankAccountIsUniq.message));
      }

      if (!bankAccountRoutingValidation.success) {
        yield put(setValidationError('bankAccountRouting', bankAccountRoutingValidation.message));
      }

      if (!bankAccountNumberValidation.success) {
        yield put(setValidationError('bankAccountNumber', bankAccountNumberValidation.message));
      }

      if (!bankAccountLabelValidation.success) {
        yield put(setValidationError('bankAccountLabel', bankAccountLabelValidation.message));
      }

      if (!bankAccountTypeValidation.success) {
        yield put(setValidationError('bankAccountType', bankAccountTypeValidation.message));
      }
    }
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* getBankAccounts() {
  try {
    yield put(setField('isLoading', true));
    const { wallet: { wallet_id } } = yield select(state => state.wallet);
    const { success, data } = yield REQUESTS.GET_WALLET_BANK_ACCOUNTS(wallet_id);

    yield put(setField('isLoading', false));

    if (success) {
      yield put(setWalletBankAccounts(data));
    } else {
      yield put(setField('isLoading', false));
    }
  } catch (e) {
    yield put(setField('isLoading', false));
  }
}

function* bankAccountSagas() {
  yield takeLatest(CONST.UPDATE_BANK_ACCOUNT, updateAccountLabel);
  yield takeLatest(CONST.VERIFY_BANK_ACCOUNT, verifyBankAccount);
  yield takeLatest(CONST.REMOVE_BANK_ACCOUNT, removeBankAccount);
  yield takeLatest(CONST.ADD_BANK_ACCOUNT, addBankAccountFlow);
  yield takeLatest(CONST.GET_BANK_ACCOUNTS, getBankAccounts);
  yield takeLatest(CONST.ADD_BANK_ACCOUNT_INSTANT, addBankAccountYodle);
  yield takeLatest(CONST.ADD_BANK_ACCOUNTS_MASSIVE, addBankAccountMassiveFlow);
  yield takeLatest(CONST.ADD_BANK_ACCOUNT_OAUTH, addBankAccountOAuth);
}

export default [bankAccountSagas];
