import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import Header from '../components/header';
import WizardSteps from '../components/wizard-steps';
import { canAddBankAccountSelector } from '../reducers/app-reducer';

const SignupLayout = (props) => {
  const {
    children,
    headerClassName,
    wrapperClassName,
    wizardStep,
    twoLiner,
    innerWrapperClassName,
    isBigLogo,
    poweredByType
  } = props;

  const wizardSteps = props.wizardSteps || ['Sign Up', 'Verify Identity', 'Wallet Type', 'Add Bank Account'];

  return (
    <div className="page -signup">
      {
        (wizardStep || wizardStep === 0) &&
          <WizardSteps className="page_wizard-steps" currentStep={wizardStep} steps={wizardSteps} />
      }

      <Header 
        className={cn(headerClassName, twoLiner ? '-twoliner' : null)} 
        hideNav={true}
        coloredLogo={true}
        isBigLogo={isBigLogo}
        poweredByType={poweredByType}
      />
      <div className={cn('page_wrapper', wrapperClassName)}>
        <div className={cn('page_wrapper-inner', innerWrapperClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};

SignupLayout.propTypes = {
  children: PropTypes.node,
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  wizardStep: PropTypes.number,
  twoLiner: PropTypes.bool,
  innerWrapperClassName: PropTypes.string,
  wizardSteps: PropTypes.arrayOf(PropTypes.string),
  isBigLogo: PropTypes.bool,
  poweredByType: PropTypes.oneOf(['full', 'sign']),
};

SignupLayout.defaultProps = {
  children: null,
  bgClassName: null,
  headerClassName: null,
  wrapperClassName: null,
  wizardStep: null,
  twoLiner: false,
  canAddBankAccount: true,
  poweredByType: 'full'
};

const mapStateToProps = (state) => ({
  canAddBankAccount: canAddBankAccountSelector(state)
});

export default connect(mapStateToProps, {})(SignupLayout);
