import CONST from '../constants/toast-constants';

const guid = () => {
  function _p8(s) {
    const p = (Math.random().toString(16) + '000000000').substr(2, 8);
    return s ? '' + p.substr(0, 4) + '-' + p.substr(4, 4) : p;
  }
  return _p8() + _p8(true) + _p8(true) + _p8();
};

const STANDART_TIMEOUT = 10 * 1000;

export const addNotification = (text, code = 400, ttl = STANDART_TIMEOUT, onClick = () => {}, id = guid()) => ({
  type: CONST.ADD_NOTIFICATION,
  payload: { text, ttl, id, code, onClick },
});

export const cleanNotifications = () => ({
  type: CONST.CLEAN_NOTIFICATIONS,
});

export const removeNotification = id => ({
  type: CONST.REMOVE_NOTIFICATION,
  payload: { id },
});
