import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { startSignup, setField, autheticateAcc, signup, phoneNumberEntered, openPopup } from '../actions/partner-signup-actions';

import CONST from '../constants/partner-signup-constants';
import PartnerSignupWelcome from '../components/partner-signup/partner-signup-step-welcome';
import PartnerSignupSignup from '../components/partner-signup/partner-signup-step-signup';
import PartnerSignupLinkSent from '../components/partner-signup/partner-signup-link-sent';
import PartnerSignupStepPhoneNumber from '../components/partner-signup/partner-signup-phone-number';
import PartnerSignupStepPayphoneAutodetect from '../components/partner-signup/partner-signup-payfone-autodetect';
import PartnerSignupStepLoading from '../components/partner-signup/partner-signup-loading';

import PartnerSignupTryAgain from '../components/partner-signup/partner-signup-try-again';
import PartnerSignupSomethingWentWrong from '../components/partner-signup/partner-signup-something-went-wrong';
import PartnerSignupUnableToSignup from '../components/partner-signup/partner-signup-please-verify';

const PartnerSignup = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.partnerSignup.step]);

  switch (props.partnerSignup.step) {    
    case CONST.STEP_LOADING: return <PartnerSignupStepLoading {...props} />;
    case CONST.STEP_WELCOME: return <PartnerSignupWelcome {...props} />;
    case CONST.STEP_SIGNUP: return <PartnerSignupSignup {...props} />;
    case CONST.STEP_PHONE_NUMBER: return <PartnerSignupStepPhoneNumber {...props} />;
    case CONST.STEP_LINK_SENT: return <PartnerSignupLinkSent {...props} />;
    case CONST.STEP_PAYPHONE_AUTODETECT: return <PartnerSignupStepPayphoneAutodetect {...props} />;

      // errors
    case CONST.STEP_LINK_EXPIRED: return <PartnerSignupTryAgain {...props} />;
    case CONST.STEP_SOMETHING_WENT_WRONG: return <PartnerSignupSomethingWentWrong {...props} />;
    case CONST.STEP_UNABLE_TO_SIGNUP: return <PartnerSignupUnableToSignup {...props} />;

    default: return <PartnerSignupStepLoading {...props} />;
  }
};

PartnerSignup.propTypes = {
  partnerSignup: PropTypes.shape({
    step: PropTypes.string
  })
};

const mapStateToProps = ({ partnerSignup }) => ({ partnerSignup });
export default connect(mapStateToProps, { startSignup, setField, autheticateAcc, signup, phoneNumberEntered, openPopup })(PartnerSignup);
