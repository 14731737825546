import React, { useState, useEffect, Fragment } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { userReducerTypes, walletReducerTypes, signUpReducerTypes } from '../../constants/prop-types';
import cn from 'classnames';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import Radio from '../radio';
import NameModal from '../name-modal';
import AddressModal from '../address-modal';
import BusinessNameModal from '../business-name-modal';
import CONST from '../../constants/sign-up-constants';

const SignupStepWalletType = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [canEditAddress, setCanEditAddress] = useState(false);

  useEffect(() => {
    const { wallet: { wallet: currentWallet }, user: { user: currentUser } } = props;

    setCanEditAddress(isEmpty(signUp.homeAddress) || (!isEmpty(signUp.homeAddress) && !signUp.homeAddress.verified));

    currentWallet && currentWallet.type === 'business' && setIsDisabled(true);

    if (currentWallet && currentWallet.wallet_id && currentUser.address) {
      const {
        fname,
        lname,
        address: {
          street1,
          street2,
          city,
          state,
          zip,
          verified,
        },
      } = currentUser;

      props.setField('name', { fname, lname });
      props.setField('isIndividual', currentWallet.type !== 'business');
      props.setField('homeAddress', {
        street1,
        street2,
        city,
        state,
        zip,
        verified,
      });
    }
  }, []);

  const onContinueButtonClick = (event) => {
    const { signUp: { isIndividual }, endStep } = props;

    event.preventDefault();

    if (isIndividual === true || isDisabled) {
      endStep(CONST.STEP_WALLET_TYPE);
    } else {
      props.setPopup('businessNamePopupShown', true);
    }
  };

  const createAddressString = () => {
    const { signUp } = props;
    const local = [signUp.homeAddress.street1, signUp.homeAddress.street2].filter(val => !!val).join(', ');
    const glob = [
      signUp.homeAddress.city,
      signUp.homeAddress.state,
      signUp.homeAddress.zip,
    ].filter(val => !!val).join(', ');

    return (
      <Fragment>
        {local}
        {local && glob ? <br /> : null}
        {glob}
      </Fragment>
    );
  };

  const updateValues = name => (value) => {
    switch (name) {
      case 'businessName':
        props.setField('businessName', value.businessName);
        props.setField('ein', value.ein);
        props.setPopup('businessNamePopupShown', false);
        props.endStep(CONST.STEP_WALLET_TYPE);
        break;

      case 'name':
        props.setField('name', value);
        props.setPopup('namePopupShown', false);
        break;

      case 'homeAddress':
        props.setField('homeAddress', value);
        props.setPopup('addressPopupShown', false);
        break;

      default: break;
    }
  };

  const isContinueDisabled = () => {
    const { signUp } = props;

    if (signUp.isIndividual === null) {
      return true;
    }

    if (!signUp.name.fname) {
      return true;
    }

    if (!signUp.name.lname) {
      return true;
    }

    if (!signUp.isPayfone) {
      if (!signUp.homeAddress.street1) {
        return true;
      }

      if (!signUp.homeAddress.city) {
        return true;
      }

      if (!signUp.homeAddress.state) {
        return true;
      }

      if (!signUp.homeAddress.zip) {
        return true;
      }
    }

    return false;
  };

  const {
    setField,
    signUp,
    signUp: {
      popups: {
        namePopupShown,
        addressPopupShown,
        businessNamePopupShown,
        popupIsLoading,
      },
    },
    setPopup,
  } = props;

  const isBusinessOnly = signUp.isIndividual === false && isDisabled;

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wizardStep={2}
    >
      <div className="page_header -space-sm">
        {
          !isBusinessOnly
            ? (<h1 className="js-page-title page_title">Choose Wallet Type</h1>)
            : (<h1 className="js-page-title page_title">Your Wallet Type</h1>)
        }
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={onContinueButtonClick}>
            <div className="layer -space-up-sm -space-down-xxxl">
              {
                !isBusinessOnly
                  ? (<h4>How do you plan to use your {process.env.REACT_APP_DOMAIN_STRING}?</h4>)
                  : (<h4>You already use <span className="color-green">Business</span> plan in {process.env.REACT_APP_DOMAIN_STRING}</h4>)
              }
              <div className="button-group full-width">
                <div className="button-group_body">
                  {
                    !isBusinessOnly &&
                      (<Fragment>
                        <Radio
                          className={
                            cn(
                              'button button-group_item -x-full',
                              '-transparency-no',
                              '-color-white',
                              '-like-button',
                              'font-weight-regular',
                            )
                          }
                          label="Individual"
                          disabled={isDisabled}
                          inputName="isIndividualRadio"
                          checked={signUp.isIndividual === true}
                          onChange={() => setField('isIndividual', true)}
                        />
                        <Radio
                          className={
                            cn(
                              'button button-group_item -x-full',
                              '-transparency-no',
                              '-color-white',
                              '-like-button',
                              'font-weight-regular',
                            )
                          }
                          label="Business"
                          inputName="isCorpRadio"
                          checked={signUp.isIndividual === false}
                          onChange={() => setField('isIndividual', false)}
                        />
                      </Fragment>)
                  }
                </div>
              </div>
            </div>
            <div className="layer -space-down-sm font-size-secondary-responsive">
              { canEditAddress ?
                <p className="color-light-gray">
                  Please fill in your personal information.
                </p>
                :
                <p className="color-light-gray">
                  Please review your personal information.
                </p>
              }
            </div>
            <div className="button-group full-width">
              <div className="button-group_body">
                <Button
                  className="js-name-popup button-group_item -has-arrow"
                  xSize="full"
                  ySize="lg"
                  color='white'
                  onClick={() => setPopup('namePopupShown', true)}
                >
                  <div className="button_head">
                    <div className="button_content-secondary">Name</div>
                    <div className="js-name-field button_content-primary">
                      {signUp.name.fname} {signUp.name.lname}
                      {signUp.validationErrors.name}
                    </div>
                  </div>
                </Button>
                <Button
                  className={cn('js-address-popup button-group_item', { '-has-arrow': canEditAddress })}
                  color='white'
                  xSize="full"
                  ySize="lg"
                  onClick={() => canEditAddress && setPopup('addressPopupShown', true)}
                >
                  <div className="button_head">
                    <div className="button_content-secondary">Home address</div>
                    <div className="js-address-field button_content-primary">
                      {createAddressString()}
                      {signUp.validationErrors.homeAddress}
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            { !canEditAddress && (
              <p className="js-alert-text color-light-gray">
                To update address please contact
                <a
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                  target="_blank"
                >
                  {' Customer Support'}
                </a>
              </p>
            )}
            <input type="submit" style={{ display: 'none' }} />
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          loading={signUp.isLoading}
          disabled={isContinueDisabled()}
          onClick={onContinueButtonClick}
        >
          Continue
        </Button>
      </div>
      <BusinessNameModal
        shown={businessNamePopupShown}
        onClose={() => setPopup('businessNamePopupShown', false)}
        name={signUp.name}
        updateValues={updateValues('businessName')}
      />
      <NameModal
        shown={namePopupShown}
        onClose={() => setPopup('namePopupShown', false)}
        isUpdating={popupIsLoading}
        name={signUp.name}
        updateValues={updateValues('name')}
      />
      <AddressModal
        shown={addressPopupShown}
        isUpdating={popupIsLoading}
        onClose={() => setPopup('addressPopupShown', false)}
        address={signUp.homeAddress}
        updateValues={updateValues('homeAddress')}
      />
    </SignupLayout>
  );
};

SignupStepWalletType.propTypes = {
  setField: PropTypes.func,
  setPopup: PropTypes.func,
  endStep: PropTypes.func,
  signUp: PropTypes.shape(signUpReducerTypes),
  user: PropTypes.shape(userReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes)
};

export default SignupStepWalletType;
