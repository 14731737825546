import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';
import VALIDATION from '../utils/validation';

const ModalSSN = (props) => {
  const { className, isLoading, onClose, updateSSN } = props;
  const [ssnDigits, setDigits] = useState('');
  const [validationError, setValidationError] = useState({});

  const updateProfile = () => {
    const sanitizedDigits = ssnDigits.split('-').reduce((part, result) => `${part}${result}`, '');
    const validation = VALIDATION.validateFullSSN(sanitizedDigits);
    if(!validation.success) {
      setValidationError(validation);
    } else {
      setValidationError({});
      updateSSN(sanitizedDigits);
      onClose();
    }
    
  };

  return (
    <Modal {...props} containerClassName={cn('-x-sm -closebutton-top', className)}>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">Please provide your SSN</h1>
      </div>
      <div className="modal_body  ">
        <div className="layer -space-down-xl">
          <p>
            According to the new U.S. Tax Reporting Requirements, BitRail is required to collect your SSN for tax reporting purposes.
            Please provide your SSN in the field below. Users with no SSN on file might lose the ability to receive funds. 
            You can find more details in this
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_HELP_DESK_URL}
            >
              &nbsp;Help center article.
            </a>
          </p>
        </div>
        <div className="layer -space-down-xxl ">
          <div className="form-input-container">
            <FormInput
              type="tel"
              className="full-width"
              label="SSN"
              inputName="ssn"
              value={ssnDigits}
              onChange={value => {
                setDigits(value);
                setValidationError({});
              }}
              errorMessage={validationError.message}
              invalid={!!validationError.success}
              mask="999-99-9999"
              autoFocus
            />
          </div>
        </div>
        <div className="layer -space-down-xl font-size-secondary">
          <p>
            This certification helps ensure your information is accurate for tax reporting purposes.
          </p>
          <p>
            Under penalties of perjury, I certify that:
          </p>
          <p>
            1. The taxpayer identification number (e.g., social security number or employer identification number) previously provided is correct.
          </p>
          <p>
            2. I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I 
            have not been notified by the IRS that I am subject to backup withholding as a result of a failure to report all interest
            or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding.
          </p>
          <p>
            3. I am a US citizen or permanent resident or other US person for tax purposes.
          </p>
          <p>
            4. The FATCA code(s) entered on this form (IF ANY) indicating that I am exempt from FATCA reporting is correct 
            (Note: We are legally required to present this here but it is not expected to be applicable to US-based accounts.)
          </p>
          <p>
            I certify the above to be true
          </p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button 
            color="transparent"
            xSize="full"
            className="modal_footer-control"
            onClick={onClose} 
            disabled={isLoading}>
            Not now
          </Button>
          <Button 
            color="blue"
            xSize="full"
            className="modal_footer-control"
            onClick={updateProfile}
            disabled={isLoading}
          >
            Update Profile
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalSSN.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func, 
  updateSSN: PropTypes.func,
  validationError: PropTypes.shape({
    success: PropTypes.bool,
    message: PropTypes.string
  })
};

ModalSSN.defaultProps = {
  className: null,
  onClose: () => {},
  updateSSN: () => {},
  isLoading: false,
};

export default ModalSSN;
