import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import BasicLayout from './basic-layout';
import Button from '../components/button';
import Svg from '../components/svg';
import SmartTransactionsList from '../components/transactions-list-smart';
import { find, propEq, isEmpty, path } from 'ramda';
import { balance, getConvertibleTotal, withCurrency } from '../utils/send-money-utils';
import { history } from '../init-store';
import { setField } from '../actions/transfer-actions';
import { currencyOptionsSelector, isTransferDisabledSelector, isSendMoneyDisabledSelector } from '../reducers/app-reducer';
import { BREAKPOINTS } from '../components/breakpoints';
import CurrencyIcon from '../components/currency-icon';

const AccountPage = ({ accounts, currencyOptions, accountName, rates, bankAccounts, setField, isTransferDisabled, isSendMoneyDisabled }) => {
  const account = !isEmpty(accounts) ? find(propEq('type', accountName))(accounts) : { balance: {} };
  const { balance: { ordinal, decimal, sign, currency = '' } } = account;
  const currencySettings = !isEmpty(currencyOptions) && currency ? find(propEq('code', currency))(currencyOptions) : {};
  const currencyName = currencySettings && currencySettings.name;
  const isMobile = window.innerWidth < BREAKPOINTS.desktop;
  const readableBalance = balance(ordinal, decimal, sign);
  const convertibleTotal = !!path([currency, 'USD'], rates) 
    ? `${withCurrency(getConvertibleTotal(account, rates), 'USD')}` 
    : `$${readableBalance}`;
  const onTransfer = () => {
    const bankAccount = find(bank => bank.amounts_verified && bank.withdraw_status === 'allowed', bankAccounts) || {};

    if (isEmpty(bankAccount)) {
      history.push('/bank-accounts/new');
    } else {
      setField('predefined', account.name);
      history.push('/transfer');
    }
  };

  return (
    <BasicLayout innerWrapperClassName="account-page__wrapper">
      <main className="account-page">
        <header>
          <h1>{currencyName}</h1>
          <div className="account-widgets__total">
            <p>{convertibleTotal}</p>
          </div>
          <div className="account-page__buttons">
            {!isTransferDisabled && (
              <Button
                color="blue"
                className="button"
                xSize="full"
                onClick={onTransfer}
              >
                Transfer
              </Button>
            )}
            {!isSendMoneyDisabled && (
              <Button
                color="blue"
                className="button"
                xSize="full"
                to="/send-money"
              >
                Send
              </Button>
            )}
            <CurrencyIcon 
              ext="svg"
              color="black"
              width={isMobile ? '230' : '150'}
              height={isMobile ? '230' : '150'}
              className="account-page__logo"
              currency={currency.toLowerCase()}
              alt={`${currencyName} icon`}
              fallback={<Svg name="currency-placeholder" className="account-page__logo-placeholder" viewBox={'0 -20 230 216'}/>}
            />
          </div>
        </header>
        <SmartTransactionsList filterByAccount={account.account_id} withConversion={true} />
      </main>
    </BasicLayout>
  );
};

AccountPage.propTypes = {
  accounts: PropTypes.arrayOf(types.AccountShape),
  bankAccounts: PropTypes.arrayOf(types.BankAccountShape),
  currencyOptions: PropTypes.arrayOf(types.CurrencyOptionShape),
  accountName: PropTypes.string,
  rates: types.RatesShape,
  setField: PropTypes.func,
  isTransferDisabled: PropTypes.bool,
  isSendMoneyDisabled: PropTypes.bool
};

const mapStateToProps = (state, props) => ({
  accounts: state.wallet.accounts,
  bankAccounts: state.wallet.bankAccounts,
  currencyOptions: currencyOptionsSelector(state),
  accountName: props.match.params.currency,
  rates: state.wallet.transactionRate,
  isTransferDisabled: isTransferDisabledSelector(state),
  isSendMoneyDisabled: isSendMoneyDisabledSelector(state)
});

export default connect(mapStateToProps, { setField })(AccountPage);