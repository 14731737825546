import React from 'react';
import { BREAKPOINTS } from './breakpoints';
import { pathsWithoutUser } from '../application';

const Version = () => {
  const { pathname } = window.location;

  return (
    <div className="version-text">
      <span className={
        (window.innerWidth < BREAKPOINTS.desktop || pathsWithoutUser.find(path => pathname.indexOf(path) !== -1)) 
          ? 'color-light-gray' 
          : 'color-light-gray'
      }>
        {`v. ${process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : ''}`}
      </span>
    </div>
  );
};

export default Version;
