import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setField } from '../../actions/wallet-actions';
import VALIDATION from '../../utils/validation';

import Button from '../button';
import Modal from '../modal';
import FormInput from '../form-input';

const ModalWalletHandle = (props) => {
  const [walletHandle, setWalletHandle] = useState('');
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if(!props.show) {
      setWalletHandle(props.wallet.wallet.handle);
    }
  }, [props.show]);

  const onUpdate = () => {
    const { wallet: { wallet } } = props;
    if (walletHandle !== wallet.handle) {
      const handleValidation = VALIDATION.validateHandle(walletHandle);
      if (handleValidation.success) {
        props.updateValues({ handle: walletHandle });
      } else {
        setValidationError(handleValidation.message);
      }
    } else {
      props.onClose();
    }
  };

  const onClose = () => {
    props.setField('errorMessage', '');
    props.onClose();
  };

  const updateField = (value) => {
    setWalletHandle(value);
    setValidationError(null);
  };

  const { show, wallet: { errorMessage, isUpdating } } = props;

  return (
    <Modal show={show} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1>Wallet Handle</h1>
      </div>
      <div className="modal_body">
        <div className="form">
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="account-handle"
                label="Wallet Handle"
                value={walletHandle}
                onChange={updateField}
                onPressEnter={onUpdate}
                errorMessage={errorMessage || validationError}
                className="full-width"
                invalid={!!validationError}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            className="modal_footer-control -submit"
            onClick={onUpdate}
            disabled={isUpdating}
            loading={isUpdating}
          >
              Update Wallet Handle
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalWalletHandle.propTypes = {
  wallet: PropTypes.shape({
    wallet: PropTypes.shape({
      handle: PropTypes.string
    }),
    errorMessage: PropTypes.string,
    isUpdating: PropTypes.bool,
  }),
  show: PropTypes.bool,
  onClose: PropTypes.func,
  setField: PropTypes.func,
  updateValues: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    wallet: state.wallet,
  };
}

export default connect(mapStateToProps, { setField })(ModalWalletHandle);
