import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import { signUpReducerTypes } from '../../constants/prop-types';
import CONST from '../../constants/sign-up-constants';
import Loader from '../loader';
import cn from 'classnames';

function showServiceTypeHtml(svc, svcId, onLoaded, onError, showError) {
  const serviceDom = document.getElementById('kyc_service');
  serviceDom.innerHTML = (svc.elem.message || '')+'<br/>'+ (svc.elem.html || '');
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.text = svc.elem.javascript;
  serviceDom.appendChild(script);

  const content = document.getElementById('kyc_svc_content');
  if (!svc.elem.javascript || svc.elem.javascript.trim() === '') {
    return;
  } else {
    // cleanup - function provided by the script load above
    window.bitrailkyc.services[svcId].cleanup();
    content.innerHTML = '';

    // start service - function provided by the script load above
    window.bitrailkyc.services[svcId].start({
      contentDom: content,
      useModal: false,
      onClose: function(a) {
        // be sure to let us know of event - function provided by script load above
        window.bitrailkyc.services[svcId].onClose(a);
      },
      onSuccess: function(a) {
        onLoaded();
        // be sure to let us know of event - function provided by script load above
        window.bitrailkyc.services[svcId].onSuccess(a);
      },
      onError: function(a) {
        // be sure to let us know of event - function provided by script load above
        window.bitrailkyc.services[svcId].onError(a);
        onError();
      }
    })
      .catch(err => {
        console.log('error', 'serviceFunc.html.' + svcId, err);
        onError();
      })
      .finally(()=> {
        try {
          window.bitrailkyc.services[svcId].cleanup();
          !document.getElementById('kyc_svc_content').children[0] && showError(true);
        } catch (err) {
          console.error(err);
        }
      });
  }
};

const SignUpStepSingularKey = ({ setField, endStep, signUp: { singularKeyData, isAwaitForSingularKey } }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showDefaultError, setShowDefaultError] = useState(false);
  const [isBrowserUnsupported, setBrowserUnsupported] = useState(false);

  const onSuccess = () => {
    setIsLoaded(false);
    setField('isLoading', true);
    endStep(CONST.STEP_SINGULAR_KEY);
  };

  const onError = () => {
    setIsLoaded(true);
  };

  const onLoaded = () => {
    const skHtml = document.getElementById('kyc_svc_content');
    const skContent = skHtml.children[0] && skHtml.children[0].children[0];

    skContent && Array.prototype.includes.call(skContent.classList, 'reactSingularKey_bodyContainer') ?
      onSuccess() :
      onError();
  };

  useEffect(() => {
    if(window.document.documentMode) {
      setIsLoaded(true);
      setBrowserUnsupported(true);
    } else if(Array.isArray(singularKeyData) && isLoaded === false) {
      showServiceTypeHtml({ elem: singularKeyData[0] }, 3, onLoaded, onError, setShowDefaultError);
    }
  }, [singularKeyData, isLoaded]);

  return (
    <SignupLayout
      headerClassName="-y-lg"
      bgClassName="-full"
      wrapperClassName="-x-lg"
      wizardStep={2}
    >
      <div className="page_body">
        <div className="page_content">
          <div className={cn('singular-key_loader', isAwaitForSingularKey && '-transparent', isLoaded && '-hidden')}>
            <Loader
              color={isAwaitForSingularKey ? 'white' : 'blue'}
              size="sm"
            />
            {isAwaitForSingularKey && (
              <h2>
                <b>Verifying your identity, please wait</b>
                <br />
                This may take several minutes
              </h2>
            )}
          </div>
          <div id="kyc_service"></div>
          <div id='kyc_svc_content' className={cn(isLoaded ? 'browser-not-supported' : '')}>
            {!isAwaitForSingularKey && showDefaultError && (
              <p>
                Unfortunately, something went wrong.
                <br />
                Please, choose another verification method or try again later.
              </p>
            )}
            {isBrowserUnsupported && (
              <p>
                Unfortunately, this identity provider does not support your browser. Please use a recent version of Firefox, Chrome, or Safari or go back and pick a different identity method. {/* eslint-disable-line */}
              </p>
            )}
          </div>
          {!isAwaitForSingularKey && (
            <Button
              color="dark-gray"
              xSize="full"
              className="page_control -no-border -cancel font-weight-bold"
              transparency="full"
              onClick={() => {
                setIsLoaded(false);
                setShowDefaultError(false);
                setField('step', CONST.STEP_VERIFICATION_SELECT);
              }}
            >
              Choose another verification method
            </Button>
          )}
        </div>
      </div>
    </SignupLayout>
  );
};

SignUpStepSingularKey.propTypes = {
  signUp: PropTypes.shape(signUpReducerTypes),
  setField: PropTypes.func,
  endStep: PropTypes.func,
};

export default SignUpStepSingularKey;


