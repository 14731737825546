import { assoc, merge } from 'ramda';
import CONST from '../constants/transfer-constants';

const INIT_STATE = {
  data: {
    sourceAccount: {},
    destinationAccount: {},
    amount: '',
    amountCharge: '0.00',
    chargeCurrency: '',
    amountReceive: '0.00',
    receiveCurrency: '',
    transactionCurrency: 'USD',
  },
  error: null,
  isLoading: false,
  disabled: false,
  predefined: null,
  transactionRate: {},
  transactionResult: null,
  flowStep: CONST.STEP_CONFIG_TRANSFER,
};

const transferReducer = (state = INIT_STATE, action = { payload: {} }) => {
  switch (action.type) {
    case CONST.UPDATE_TRANSFER:
      return assoc(['data'], action.payload.data, state);

    case CONST.SET_FIELD:
      return assoc([action.payload.key], action.payload.value, state);

    case CONST.GET_RATE:
      return assoc(['isLoading'], true, state);

    case CONST.SET_RATE:
      return merge(state, {
        isLoading: false,
        error: null,
        transactionRate: action.payload.rate,
      });

    case CONST.MAKE_PAYMENT:
      return merge(state, {
        isLoading: true,
        error: null,
      });

    case CONST.SET_TRANSACTION:
      return merge(state, {
        isLoading: false,
        error: null,
        flowStep: CONST.STEP_COMPLETE,
        transactionResult: action.payload.transactionResult,
      });

    case CONST.SET_ERROR_MESSAGE:
      return merge(state, {
        isLoading: false,
        error: action.payload.message,
      });

    case CONST.CLEAR_TRANSACTION:
      return INIT_STATE;

    default:
      return state;
  }
};

export default transferReducer;
