import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from './svg';

const CurrencySwitch = (props) => {
  const [checked, setChecked] = useState(props.currency === props.currencyList[1]); 

  useEffect(() => {
    setChecked(props.currency === props.currencyList[1]);
  }, [props]);

  const onToggle = () => {
    props.onToggle(!checked);
    setChecked(!checked);
  };

  const {
    className,
    disabled,
    hideSwitcher,
    currencyList
  } = props;

  return (
    <div className={cn('form-input-switch', disabled && '-disabled', className)}>
      {!hideSwitcher && !disabled
        ? <Svg name="arrows-fat" className="form-input-switch_icon" />
        : null
      }
      <input
        type="checkbox"
        id="currency"
        disabled={disabled}
        className="js-transfer-switch-currency form-input-switch_checkbox"
        onChange={onToggle}
        checked={checked}
      />
      {currencyList.map((currency, index) => (
        <label key={`${currency}-${index}`} className="form-input-switch_option" htmlFor="currency">{currency}</label>
      ))}
    </div>
  );
};

CurrencySwitch.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.string,
  hideSwitcher: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  currencyList: PropTypes.arrayOf(PropTypes.string)
};

CurrencySwitch.defaultProps = {
  className: '',
  currency: '',
  disabled: false,
  hideSwitcher: false,
};

export default CurrencySwitch;
