import React, { useEffect } from 'react';
import qs from 'qs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOAuthToken } from '../../actions/login-actions';
import Loader from '../../components/loader';
import ErrorLayout from '../errors/error-layout';
import Button from '../../components/button';

const OAuthCodePage = ({ getOAuthToken }) => {

  useEffect(() => {
    getOAuthToken();
  }, []);

  const { error_description, error } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return !error ? (
    <div className="page -basic">
      <div className="page_wrapper -y-full -content-x-centered -content-y-centered">
        <div className="page_wrapper-inner">
          <div className="page_body">
            <div className="page_content">
              <Loader color="blue" size="sm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ErrorLayout>
      <div className="page_body">
        <div className="page_content">
          <div className="page_error">
            <h1>Oh,&nbsp;No!</h1>
            <h2>There is an error with auth provider</h2>
            <p>
              {error_description ? `Error message: ${error_description}.` : 'Sorry, something went wrong'}
            </p>
          </div>
        </div>
      </div>
      <div className="page_controls -content-x-centered">
        <Button
          color="blue"
          ySize="sm"
          xSize="md"
          className="page_control -submit"
          to="/auth/login"
        >
            Return to Login Page
        </Button>
      </div>
    </ErrorLayout>
  );
};

OAuthCodePage.propTypes = {
  getOAuthToken: PropTypes.func.isRequired
};

export default connect(null, { getOAuthToken })(OAuthCodePage);