import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Avatar from './avatar';

const UserCard = ({
  avatar,
  avatarLabel,
  avatarSrc,
  avatarType,
  avatarClassName,
  className,
  contentMain,
  contentSecondary,
  onClick,
}) => {
  const Element = onClick ? 'button' : 'div';

  return (
    <Element className={cn('user-card', className)} type={onClick ? 'button' : null} onClick={onClick} >
      {avatar || (
        <Avatar
          imageSrc={avatarSrc}
          label={avatarLabel}
          className={cn('user-card_avatar', avatarClassName)}
          type={avatarType}
        />
      )}

      <div className="user-card_body">
        <div className="user-card_content">
          { contentMain && (
            <div className="user-card_main">
              {contentMain}
            </div>
          )}

          { contentSecondary && (
            <div className="user-card_secondary">
              {contentSecondary}
            </div>
          )}
        </div>
      </div>
    </Element>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.node,
  avatarLabel: PropTypes.node,
  avatarSrc: PropTypes.string,
  avatarType: PropTypes.string,
  avatarClassName: PropTypes.string,
  className: PropTypes.string,
  contentMain: PropTypes.string,
  contentSecondary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onClick: PropTypes.func,
};

UserCard.defaultProps = {
  avatar: null,
  avatarLabel: null,
  avatarSrc: null,
  avatarType: null,
  avatarClassName: null,
  className: null,
  contentMain: null,
  contentSecondary: null,
  onClick: null,
};

export default UserCard;
