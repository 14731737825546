import CONST from '../constants/user-constants';

export const loginGBUser = (email, password) => ({
  type: CONST.LOGIN_GB_USER,
  payload: { email, password },
});

export const getUnlinkConfirmation = () => ({
  type: CONST.SET_UNLINK_CONFIRMATION,
});

export const skipLinking = () => ({
  type: CONST.SKIP_LINKING,
});

export const getUserGBStatus = () => ({
  type: CONST.GET_USER_GB_STATUS,
});

export const unlinkGBUser = () => ({
  type: CONST.UNLINK_GB_USER,
});

export const getUserSimple = userId => ({
  type: CONST.GET_USER_SIMPLE,
  payload: { userId },
});

export const setField = (path, value) => ({
  type: CONST.SET_FIELD,
  payload: { path, value },
});

export const getUser = userId => ({
  type: CONST.GET_USER,
  payload: { userId },
});

export const setUser = user => ({
  type: CONST.SET_USER,
  payload: { user },
});

export const updateUserAddress = address => ({
  type: CONST.UPDATE_USER_ADDRESS,
  payload: { address },
});

export const updateEmail = email => ({
  type: CONST.UPDATE_EMAIL,
  payload: { email },
});

export const updateUserName = ({ lname, fname }) => ({
  type: CONST.UPDATE_USER_NAME,
  payload: { lname, fname },
});

export const addPhoneNumber = phoneNumber => ({
  type: CONST.ADD_PHONE_NUMBER,
  payload: { phoneNumber },
});

export const logoutUser = () => ({
  type: CONST.LOGOUT_USER,
});

export const getKycSessionStatus = () => ({
  type: CONST.GET_KYC_SESSION_STATUS,
});

export const getUserNotices = () => ({
  type: CONST.GET_USER_NOTICES,
});

export const updateUserNotice = (noticeName) => ({
  type: CONST.UPDATE_USER_NOTICE,
  payload: { noticeName },
});

export const updateUserSSN = (ssn) => ({
  type: CONST.UPDATE_USER_SSN,
  payload: { ssn },
});