import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Modal from './modal';
import Button from './button';

const BankInfoModal = properties => {
  const { details, ...props } = properties;

  return (
    <Modal {...props} containerClassName={cn('-white -no-footer', props.containerClassName)}>
      <div className="modal_body -centered">
        <div className="layer -centered">
          <h1>Account is not available</h1>
          <p className="description-list_body" dangerouslySetInnerHTML={{__html: details}} />
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            transparency="full"
            className="modal_footer-control -submit"
            onClick={() => props.onClose()}
          >
            Back to payment configuration
          </Button>
        </div>
      </div>
    </Modal>
  );
};

BankInfoModal.propTypes = {
  details: PropTypes.string,
  containerClassName: PropTypes.string,
  onClose: PropTypes.func
};

export default BankInfoModal;
