import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VALIDATION from '../utils/validation';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';

const BusinessNameModal = (props) => {
  const [businessName, setBusinessName] = useState('');
  const [einDigits, setDigits] = useState('');
  const [error, setError] = useState('');
  const [einValidationError, setEinValidationError] = useState('');

  useEffect(() => {
    if (!props.shown) {
      if(props.name) setBusinessName(`${props.name.fname} ${props.name.lname}`);
    }
  }, [props.shown]);

  const onChange = (value) => {
    setBusinessName(value);
    setError('');
  };

  const onUpdate = (event) => {
    event.preventDefault();

    const { success, message } = VALIDATION.validateBusinessName(businessName);
    const sanitizedDigits = einDigits.split('-').reduce((part, result) => `${part}${result}`, '');
    const einValidation = VALIDATION.validateFullSSN(sanitizedDigits);

    if (!success || !einValidation.success) {
      message && setError(message);
      einValidation.message && setEinValidationError(einValidation.message);
    } else {
      setError('');
      props.updateValues({ businessName, ein: sanitizedDigits });
    }
  };

  const { shown, onClose } = props;

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1 className="js-modal-title">Your Business Name and EIN</h1>
      </div>
      <div className="modal_body">
        <form className="form" onSubmit={onUpdate}>
          <div className="layer form_row -space-down-sm">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="businessName"
                label="Business Name"
                errorMessage={error}
                invalid={!!error}
                value={businessName}
                className="full-width"
                onChange={onChange}
                autoFocus
              />
            </div>
          </div>
          <div className="layer form_row">
            <div className="form-input-container">
              <FormInput
                type="tel"
                transparency="half"
                className="full-width"
                label="EIN"
                inputName="ein"
                value={einDigits}
                onChange={value => {
                  setEinValidationError('');
                  setDigits(value);
                }}
                errorMessage={einValidationError}
                invalid={!!einValidationError}
                mask="99-9999999"
              />
            </div>
          </div>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            disabled={!!error}
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={onUpdate}
          >
              Update Name
          </Button>
        </div>
      </div>
    </Modal>
  );
};

BusinessNameModal.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  updateValues: PropTypes.func,
  name: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string,
  })
};

export default BusinessNameModal;
