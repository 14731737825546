import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import Button from '../button';
import Loader from '../loader';
import { oauthPending } from '../../actions/login-actions';
import { 
  addBankAccountOAuth,
  setField,
  addBankAccountMassive 
} from '../../actions/bank-account-actions';
import { addNotification } from '../../actions/toast-actions';
import { trackEvent } from '../../utils/ganalytics';
import { history } from '../../init-store';
import { YODLEE_ERRORS } from '../../constants/bank-account-constants';
import Modal from '../modal';

const SignUpYodleeModal = ({ 
  setField,
  oauthPending,
  addNotification,
  addBankAccountOAuth,
  yodleeUrl,
  yodleeJWT,
  configName,
  addBankAccountMassive,
  shown,
  onClose,
  endStep,
  ...props
}) => {
  
  useEffect(() => {
    addBankAccountOAuth();
  });

  useEffect(() => {
    if(yodleeUrl && yodleeJWT && configName && shown) {
      startFastlink();
    }
  }, [yodleeUrl, yodleeJWT, configName, shown]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(props.isInitializing);
  }, [props.isInitializing]);

  const storagedParams = localStorage.getItem('oauthParams');
  const [error, setError] = useState(null);
  const showError = error && error.code; // Code field is presented only in yodlee errors 
  const errorText = useRef({
    title: 'Instant verification unavailable',
    message: 'Unfortunately, service for instant account verification is unavailable at the moment. Please try again later or add your bank account manually.',
  });
  const startFastlink = () => {
    setField(['isYodleeDisabled'], true);
    setField(['isInitializing'], true);
  
    window.fastlink.close();
    window.fastlink.open({
      fastLinkURL: yodleeUrl,
      accessToken: `Bearer ${yodleeJWT}`,
      forceIframe: true,
      params: {
        configName: configName,
      },
      onClose: (data) => {
        if (data && data.action === 'exit') {
          if(data.sites && data.sites.length) {
            setIsLoading(true);
            addBankAccountMassive(data.sites, true);
            endStep();
          } 

        }
        trackEvent('ended_yodlee_flow', 'Yodlee flow ended');
      },
      onError: (err) => {
        console.warn(err);

        if(err.code === YODLEE_ERRORS.SESSION_TIMEOUT || err.code === YODLEE_ERRORS.LOGOUT) {
          Object.assign(errorText.current, err);
        }

        setError && setError(err);
      },
      onEvent: (event) => {
        console.log(event);
      },
    },
    'container-fastlink');
  
    let frame = document.querySelector('#container-fastlink iframe');
  
    if (frame) {
      frame.onload = () => {
        setField(['isYodleeDisabled'], false);
        setField(['isInitializing'], false);
      };
    };
  
  };


  const startOver = () => {
    setError(null);
    errorText.current = {
      title: 'Instant verification unavailable',
      message: 'Unfortunately, service for instant account verification is unavailable at the moment. Please try again later or add your bank account manually.', // eslint-disable-line 
    };
    setIsLoading(true);
    startFastlink();
  };

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_body">
        <div className="layer -space-down-xl">
          {
            isLoading && !showError
              ? (<div className="loader-wrapper -yodlee">
                <Loader color="blue" size="sm" /> 
              </div>)
              : null 
          }
          { showError ? (
            <YodleeError 
              error={errorText}
              startOver={startOver}
              endStep={endStep}
            />
          ) : (
            <Fragment>
              <div className="page_header -relative">
                <h1 className="page_title">Add Bank Account</h1>
              </div>
            </Fragment>
          ) }
          <div
            id="container-fastlink"
            className={cn('fastlink-container-outer -with-border', showError ? 'hidden' : '')}
          />
          
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="white"
            xSize="full"
            transparency="full"
            to={`/auth/login${storagedParams ? storagedParams : ''}`} // todo redirect to dashboard or something
            className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const YodleeError = ({ error, startOver, endStep }) => (
  <Fragment>
    <div className="page_header -with-sub">
      <h1 className="js-page-title page_title">{error.current.title}</h1>
      <p className="page_subtitle color-secondary">{error.current.message}</p>
    </div>
    <div className="page_controls -align-top-desktop">
      { error.current.code ? (
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button modal_footer-control -submit"
          onClick={startOver} // eslint-disable-line 
        >
          Start over
        </Button>
      ) : null }
            
      <Button
        color="blue"
        transparency="full"
        xSize="full"
        className="page_control -cancel"
        onClick={endStep}
      >
        Go to Dashboard
      </Button>
    </div>
  </Fragment>
);

YodleeError.propTypes = {
  error: PropTypes.shape({
    current: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
      code: PropTypes.string
    })
  }),
  startOver: PropTypes.func,
  endStep: PropTypes.func
};

SignUpYodleeModal.propTypes = {
  setField: PropTypes.func,
  oauthPending: PropTypes.func,
  addNotification: PropTypes.func,
  addBankAccountOAuth: PropTypes.func,
  yodleeUrl: PropTypes.string,
  yodleeJWT: PropTypes.string,
  configName: PropTypes.string,
  addBankAccountMassive: PropTypes.func,
  isInitializing: PropTypes.bool,
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  endStep: PropTypes.func
};

const mapStateToProps = ({ bankAccount }) => ({
  yodleeUrl: bankAccount.yodleeUrl,
  yodleeJWT: bankAccount.yodleeJWT,
  yodleeServiceId: bankAccount.yodleeServiceId,
  configName: bankAccount.configName,
  isInitializing: bankAccount.isInitializing
});

export default connect(
  mapStateToProps, 
  { 
    setField,
    oauthPending,
    addNotification,
    addBankAccountOAuth,
    addBankAccountMassive
  })(SignUpYodleeModal);

