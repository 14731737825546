import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { 
  sendMoneyReducerTypes,
  EnabledStatesShape,
} from '../../../constants/prop-types';
import {
  getWalletBySearch,
  setField,
  getRate,
} from '../../../actions/send-money-actions';
import FormInput from '../../../components/form-input';
import Button from '../../../components/button';
import VALIDATION from '../../../utils/validation';
import { hasPermission, permissionErrorMessage } from '../../../utils/permissions';
import { userBalanceSelector } from '../../../reducers/wallet-reducer';
import NotificationBadge from '../../notification-badge';

const SendMoneyFindWallet = (props) => {
  const onFieldChange = (field) => {
    return (value) => {
      props.setField('error', '');
      props.setField(field, value);
    };
  };

  const getWallet = () => {
    const { userBalance } = props;
    const { searchString } = props.sendMoney;

    const validated = VALIDATION.validateSearchString(searchString);

    props.setField('error', '');

    if (validated.success && userBalance >= 0) {
      props.getWalletBySearch(searchString);
      props.getRate(true);
    } else {
      props.setField('error', validated.message);
    }
  };

  const checkPermissions = () => {
    const { permissions } = props;

    if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'resides_in_allowed_state')}
        />
      );
    } else if (!hasPermission(permissions, 'can_send_money')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_send_money')}
        />
      );
    }
  };

  const { sendMoney: { searchString, isLoading, error }, userBalance } = props;

  return (
    <Fragment>
      <div className="page_header">
        <h1 className="page_title">
            Send Money
        </h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          {checkPermissions()}
          <div className="form new-transaction">
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  label={`Recipient's email, phone or ${process.env.REACT_APP_DOMAIN_STRING} handle`}
                  color="white"
                  className="full-width"
                  inputName="recipient-email"
                  errorMessage={error}
                  disabled={isLoading}
                  value={searchString}
                  onChange={onFieldChange('searchString')}
                  onPressEnter={getWallet}
                  autoFocus
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          disabled={isLoading || !!error || userBalance < 0}
          onClick={getWallet}
          loading={isLoading}
        >
            Next
        </Button>
      </div>
    </Fragment>
  );
};

SendMoneyFindWallet.propTypes = {
  setField: PropTypes.func,
  getWalletBySearch: PropTypes.func,
  getRate: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  permissions: EnabledStatesShape,
  userBalance: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    sendMoney: state.sendMoney,
    accounts: state.wallet.accounts,
    permissions: state.user.user.enabled_states,
    userBalance: userBalanceSelector(state)
  };
}


export default connect(mapStateToProps, { getWalletBySearch, setField, getRate })(SendMoneyFindWallet);

