export const CONSTANTS = {
  GET_LOAN_OFFER: '@loan/GET_LOAN_OFFER',
  ACCEPT_LOAN_OFFER: '@loan/ACCEPT_LOAN_OFFER',
  CHECK_LOAN_OFFER: '@loan/CHECK_LOAN_OFFER',
  SET_LOAN_OFFER: '@loan/SET_LOAN_OFFER',
  SET_FIELD: '@loan/SET_FIELD',
  CLEAR_LOAN_OFFER: '@loan/CLEAR_LOAN_OFFER',

  // FLOW
  LOAN_NO_OFFER: '@loan-flow/NO_OFFER',
  LOAN_CONFIGURATION: '@loan-flow/CONFIGURATION',
  LOAN_APPROVED: '@loan-flow/LOAN_APPROVED',
  LOAN_DECLINED: '@loan-flow/LOAN_DECLINED',
};

export default CONSTANTS;
