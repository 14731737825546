import { assoc, assocPath, compose, merge, flatten } from 'ramda';
import CONST from '../constants/user-constants';


export const STEP = {
  LOGIN: 'step/login',
  LINK: 'step/link',
  UNLINK: 'step/unlink',
  UNLINK_CONFIRM: 'step/unlink_confirm',
  LINK_COMPLETE: 'step/link_complete',
  LINK_FAILED: 'step/link_failed',
};

const REWARDS_INIT_STATE = {
  level: '',
  points: 0,
  tierPoints: 0,
  pointsYTD: 0,
  nextTierPoints: 0,
  nextTierText: '',
  total: 0,
  referral: '',
  history: [],
  pointsToUse: [],
};

export const GB_INIT_STATE = {
  popupIsOpened: false,
  userName: '',
  step: STEP.LINK,
  error: false,
  isLoading: false,
  accessToken: null,
  validationToken: '',
  isGetting: '',
  emptyData: false,
  isConfirmed: false,
};

const INIT_STATE = {
  user: {},
  notAsked: true,
  isLoading: false,
  isUpdating: false,
  emailModalStep: 0,
  rewards: REWARDS_INIT_STATE,
  gbData: GB_INIT_STATE,
};

const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD: 
      return assocPath(flatten([action.payload.path]), action.payload.value, state);
    case CONST.GET_USER:
      return merge(state, {
        notAsked: false,
        isLoading: true,
      });
    case CONST.SET_USER:
      return merge(state, {
        isLoading: false,
        isUpdating: false,
        user: action.payload.user,
      });
    case CONST.UPDATE_USER_ADDRESS:
      return compose(
        assoc('isUpdating', true),
        assocPath(['user', 'address'], action.payload.address),
      )(state);

    case CONST.UPDATE_USER_NAME:
      return assoc('isUpdating', true, state);
    default:
      return state;
  }
};

export default userReducer;

export const permissionSelector = (state) => state.user.user && state.user.user.enabled_states;