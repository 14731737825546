import { delay } from 'redux-saga';
import { takeEvery, call, put } from 'redux-saga/effects';
import CONST from '../constants/toast-constants';
import { removeNotification } from '../actions/toast-actions';

function* addNotificationFlow(action) {
  try {
    if (action.payload.ttl > 0) {
      yield call(delay, action.payload.ttl);
      yield put(removeNotification(action.payload.id));
    }
  } catch (error) {
    throw error;
  }
}

function* toastSaga() {
  yield takeEvery(CONST.ADD_NOTIFICATION, addNotificationFlow);
}

export default [toastSaga];
