import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const HeaderButton = ({ menuIsOpened, onClick }) => (
  <button
    className={
      cn('header_control -hamburger hamburger', { '-active': menuIsOpened })
    }
    onClick={onClick}
  />
);

HeaderButton.propTypes = {
  menuIsOpened: PropTypes.bool,
  onClick: PropTypes.func,
};

HeaderButton.defaultProps = {
  menuIsOpened: false,
  onClick: () => {
  },
};

export default HeaderButton;
