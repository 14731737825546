import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from './svg';

const NotificationBadge = (props) => {
  const { onClick, type, message, ModalComponent, isSlim, children } = props;

  return (
    <div className={cn('notification-badge', `-${type}`, isSlim ? '-slim' : '', `${onClick ? '-arrow' : ''}`)} onClick={onClick}>
      <Svg name={`notification-${type}`} />
      {
        !!message ? 
          <p>
            <p>{message}</p> 
            {children}
          </p>
          : children
      }
      {ModalComponent}
    </div>
  );
};

NotificationBadge.propTypes = {
  type: PropTypes.oneOf(['permission', 'action', 'notice']),
  message: PropTypes.string,
  onClick: PropTypes.func,
  ModalComponent: PropTypes.elementType,
  isSlim: PropTypes.bool,
  children: PropTypes.elementType
};

export default NotificationBadge;