import React from 'react';
import Svg from '../../components/svg';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';

const SignupStepErrorHelpDesk = () => {
  const goToHelpDesk = () => {
    window.open(process.env.REACT_APP_HELP_DESK_URL, '_blank');
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="circled-cross" className="splash_icon" />
            <h1 className="splash_title">Unable to verify Phone Number</h1>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                  Unfortunately,&nbsp;the&nbsp;number&nbsp;you&nbsp;provided&nbsp;could&nbsp;not&nbsp;be&nbsp;verified.
                <br />
                  Please contact Customer Support for further instructions
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={goToHelpDesk}
        >
            Open Customer Support
        </Button>
      </div>
    </SignupLayout>
  );
};

export default SignupStepErrorHelpDesk;
