import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { TransferDropdownListShape, EnabledStatesShape, RatesShape } from '../../constants/prop-types';
import Button from '../button';

import { hasPermission, permissionAllowedLimit } from '../../utils/permissions';
import { getAccountBalance } from './transfer-selector';

const renderAlertBadge = (dropdownList, dropdownOpened, permissions) => {
  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
  if (maxAchAllowed <= 0) {
    return true;
  }
  if (!hasPermission(permissions, 'can_withdraw_from_br') && dropdownOpened === 'bottom') {
    return true;
  }
};

const TransferSelectorDropdownList = ({ dropdownList, dropdownOpened, setAccount, permissions, currencyInputDisabled, transactionRate }) => (
  dropdownOpened && (
    <div
      className={cn(
        'dropdown dropdown_accounts -with-buttons -to-bottom-start',
        dropdownOpened === 'bottom' && '-to-bottom',
      )}
    >
      <div className="dropdown_body">
        {dropdownList.accounts.map(account => (
          <Button
            color="white"
            xSize="full"
            ySize="xl"
            id={`js-transfer-${account.balance.currency.toLowerCase()}-account-list-item`}
            className="button-group_item"
            key={account.account_id}
            disabled={
              dropdownOpened === 'top' && currencyInputDisabled
            }
            onClick={() => setAccount(account, dropdownOpened)}
          >
            <div className="button_head">
              <div id={`js-transfer-${account.balance.currency.toLowerCase()}-account-list-item-name`} className="button_content-primary">
                {account.name ? account.name : 'Bank'} Account
              </div>
              <div id={`js-transfer-${account.balance.currency.toLowerCase()}-account-list-item-balance`} className="button_content-secondary">
                Balance:&nbsp;{getAccountBalance(account, transactionRate)}
              </div>
            </div>
          </Button>
        ))}
        {(
          (dropdownOpened === 'top' && hasPermission(permissions, 'can_deposit_to_br')) || dropdownOpened === 'bottom'
        ) && dropdownList.bankAccounts.map(bank => (
          <Button
            color="white"
            xSize="full"
            ySize="xl"
            id={`js-transfer-bank-account-${bank.user_description.replace(' ', '-').toLowerCase()}-list-item`}
            className="button-group_item"
            key={bank.bank_account_id}
            onClick={() => setAccount(bank, dropdownOpened)}
            disabled={(dropdownOpened === 'top' && bank.withdraw_status !== 'allowed')}
          >
            <div className="button_head">
              <div className="button_content-primary">
                Bank Account&nbsp;
                {bank.withdraw_status !== 'allowed' && (
                  <div 
                    id={`js-transfer-bank-account-${bank.user_description.replace(' ', '-').toLowerCase()}-list-item-no-withdrawal`}
                    className="label"
                  >
                    No withdrawal
                  </div>
                )}
                {renderAlertBadge(dropdownList, dropdownOpened, permissions) && (
                  <div 
                    id={`js-transfer-bank-account-${bank.user_description.replace(' ', '-').toLowerCase()}-list-item-danger`}
                    className="label -danger">!</div>
                )}
              </div>
              <div 
                id={`js-transfer-bank-account-${bank.user_description.replace(' ', '-').toLowerCase()}-list-item-name`} 
                className="button_content-secondary"
              >
                {bank.user_description || 'Account Number'}:
                {' '}
                <span 
                  id={`js-transfer-bank-account-${bank.user_description.replace(' ', '-').toLowerCase()}-list-item-account-number`}
                  className="account-number-bullets">
                    &bull;&bull;
                </span>
                {' '}
                {bank.account_number}
              </div>
            </div>
          </Button>
        ))}
      </div>
    </div>
  )
);

TransferSelectorDropdownList.propTypes = {
  dropdownList: TransferDropdownListShape,
  dropdownOpened: PropTypes.string,
  setAccount: PropTypes.func,
  permissions: EnabledStatesShape, 
  currencyInputDisabled: PropTypes.bool,
  transactionRate: RatesShape,
};

TransferSelectorDropdownList.defaultProps = {
  dropdownList: {},
  dropdownOpened: null,
  setAccount: () => {},
};

export default TransferSelectorDropdownList;

