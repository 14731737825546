import React, { Fragment, useState } from 'react';
import * as types from '../constants/prop-types';
import CurrencyPromosModal from './currency-promos-modal';
import Svg from './svg';

const CurrencyPromos = ({ promos }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const teaserPromo = promos[0];

  return (
    <Fragment>
      <div className="currency-teaser" onClick={() => setIsModalOpen(true)}>
        <h3>Our partners</h3>
        <div className="currency-teaser__button">
          <span>Explore partners</span>
          <Svg name="arrow-concave" className="currency-teaser__icon -icon" />
        </div>
        <div className="currency-teaser__example">
          <img src={teaserPromo.iconUrl} alt={teaserPromo.name} className="avatar_image-wrapper" />
          <p>{teaserPromo.teaser}</p>
        </div>

      </div>
      <CurrencyPromosModal promos={promos} show={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Fragment>
  );
};

CurrencyPromos.propTypes = {
  promos: types.walletReducerTypes.currencyPromos
};

export default CurrencyPromos;