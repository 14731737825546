import CONST from '../constants/recurrent-payment-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const saveRecurrentPayment = () => ({
  type: CONST.SAVE_RECURRENT_PAYMENT,
});

export const cancelPayment = () => ({
  type: CONST.CANCEL_PAYMENT,
});