import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';
import saga from './saga';

const sagaMiddleware = createSagaMiddleware();

const initStore = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

initStore.runSaga = sagaMiddleware.run;

saga(initStore);

export const history = createBrowserHistory();

export default initStore;
