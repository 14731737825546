export const CONSTANTS = {
  GET_RATE: '@transfer/GET_RATE',
  SET_RATE: '@transfer/SET_RATE',
  MAKE_PAYMENT: '@transfer/MAKE_PAYMENT',
  SET_TRANSACTION: '@transfer/SET_TRANSACTION',
  SET_ERROR_MESSAGE: '@transfer/SET_ERROR_MESSAGE',
  SET_FIELD: '@transfer/SET_FIELD',
  UPDATE_TRANSFER: '@transfer/UPDATE_TRANSFER',
  CLEAR_TRANSACTION: '@transfer/CLEAR_TRANSACTION',

  // Flow steps
  STEP_CONFIG_TRANSFER: '@transfer/STEP_CONFIG_TRANSFER',
  STEP_CONFIRMATION: '@transfer/STEP_CONFIRMATION',
  STEP_COMPLETE: '@transfer/STEP_COMPLETE',
};

export default CONSTANTS;
