import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import LoginLayout from '../../pages/log-in-layout';
import FormInput from '../form-input';
import Button from '../button';
import Checkbox from '../checkbox';
import VALIDATION from '../../utils/validation';

const PartnerSignupStepSignup = (props) => {
  const [messageShown, setMessageShown] = useState(false);
  const [validationError, setValidationError] = useState('');

  const onSubmit = event => {
    const { isLoading, password, consent, gbUser: { email }, handle } = props.partnerSignup;

    const isPartEmail = VALIDATION.isPartOfEmail(password, email);
    const passwordValid = VALIDATION.validatePassword(password, email).success && !isPartEmail;
    const handleValidation = VALIDATION.validateHandle(handle);

    event.preventDefault();

    if (consent && password && passwordValid && !isLoading && handleValidation.success) {
      props.signup();
    } else {
      if (!handleValidation.success) {
        setValidationError(handleValidation.message);
      }
    }
  };

  const generateMessage = (password, email) => {
    const reqs = VALIDATION.getPasswordRequirenments(password);
    const isValid = VALIDATION.validatePassword(password, email).success;

    if (!isValid) {
      return (<div className="color-light-gray input-message">
        <div className="input-message_text">Must be
          <span className={reqs.len ? 'color-green' : 'color-light-gray'}>
            &nbsp;8 or more characters&nbsp;
          </span>
          and include:
        </div>
        <div className="input-message-row">
          <ul className="input-message-column">
            <li className={reqs.lowercase ? 'checked' : 'neutral'}>lowercase letter</li>
            <li className={reqs.uppercase ? 'checked' : 'neutral'}>uppercase letter</li>
          </ul>
          <ul className="input-message-column">
            <li className={reqs.digit ? 'checked' : 'neutral'}>digit</li>
            <li className={reqs.symbol ? 'checked' : 'neutral'}>special symbol</li>
          </ul>
        </div>
      </div>);
    }

    return (<div className="color-blue input-message">✓ Good password</div>);
  };

  const { gbUser: { fname, lname, email }, isLoading, handle, password, consent } = props.partnerSignup;
  const setField = props.setField;


  const isPartEmail = VALIDATION.isPartOfEmail(password, email);
  const passwordValid = VALIDATION.validatePassword(password, email).success && !isPartEmail;

  return (
    <LoginLayout
      bgClassName="-full"
      wrapperClassName="-content-y-centered -page-control-bottom -padding"
      hideNav={true}
    >
      <div className="page_header -space-sm">
        <h1 className="page_title">Create your {process.env.REACT_APP_DOMAIN_STRING} account</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="layer">
            <h5 className="-inline">{`${fname} ${lname}`}</h5>
            <p className="font-size-secondary-responsive color-light-gray -inline">
                , thank you for verifying your GunBroker account. We’ve retrieved your information below. 
                Please confirm this information to continue setting up your new {process.env.REACT_APP_DOMAIN_STRING} account. 
                If this information looks incorrect, we’ll need you to <a href="/signup">sign up here</a>.
            </p>
          </div>
          <form
            className="form -login-page-form"
            onSubmit={onSubmit}
          >
            <input type="submit" className="form-input_invisible"/>
            <div className="layer -space-down-sm">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width -disabled-color"
                  label="Email"
                  type="text"
                  value={email}
                  disabled={true}
                />
              </div>
            </div>

            <div className="layer -space-up-md">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  transparency="half"
                  className="full-width"
                  label={`Create a ${process.env.REACT_APP_DOMAIN_STRING} handle`}
                  type="text"
                  value={handle}
                  onChange={value => {
                    setField('handle', value);
                    setValidationError('');
                  }}
                  errorMessage={validationError}
                  invalid={!!validationError}
                  inputName="handle"
                />
              </div>
            </div>

            <div className="layer -space-down-xxl">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  transparency="half"
                  className="full-width"
                  label={`Create a ${process.env.REACT_APP_DOMAIN_STRING} password`}
                  type="password"
                  value={password}
                  onChange={value => setField('password', value)}
                  inputName="partner-signup-password" 
                  onFocus={() => setMessageShown(true)}
                  errorMessage={isPartEmail ? 'You cannot use any part of your email as a password' : ''}
                  isErrorAbsolute={isPartEmail}
                />
              </div>
              {messageShown && generateMessage(password, email)}
            </div>

            <div className="layer">
              <p className="font-size-secondary-responsive color-light-gray">
                  Prior to granting you access to buy and sell with BitRail Digital Wallet, we require that you agree to our
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                >
                  {' User Agreement'}
                </a>.
                  We take your information and privacy very seriously.
                  We do not share your information to third parties unless you have given us your consent. 
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                >
                  {' '}Need&nbsp;help?
                </a>
              </p>
            </div>

            <div className="layer -space-up-md -space-down-xxl">
              <Checkbox
                inputName="terms-and-condition"
                label={
                  <span className="color-light-gray">
                      I agree with the
                  </span>
                }
                className="-white -bordered"
                checked={consent}
                onChange={({ target: { checked } }) => setField('consent', checked)}
              />
                &nbsp;<a
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              >
                  User Agreement
              </a>
            </div>
          </form>
        </div>
      </div>

      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={onSubmit}
          loading={isLoading}
          disabled={!consent || !password || !passwordValid || isLoading || !!validationError}
        >
            Continue
        </Button>
      </div>
    </LoginLayout>
  );
};

PartnerSignupStepSignup.propTypes = {
  partnerSignup: PropTypes.shape(types.signUpReducerTypes),
  setField: PropTypes.func,
  signup: PropTypes.func,
};

export default PartnerSignupStepSignup;
