import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Svg = ({ className, name, width, height, viewBox }) => (
  <svg className={cn(`icon -${name}`, className)} width={width} height={height} viewBox={viewBox}>
    <use xlinkHref={`#svg-${name}`} />
  </svg>
);

Svg.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string
};

Svg.defaultProps = {
  className: null,
  width: '100%',
  height: '100%',
};

export default Svg;
