import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Modal from './modal';

const OrderDetailsModal = (props) => {
  const { details } = props;

  return (
    <Modal {...props} containerClassName={cn('-white -no-footer', props.containerClassName)}>
      <div className="modal_header">
        <h1>Order Details</h1>
      </div>
      <div className="modal_body">
        <div className="description-list -fixed-title -x-condensed">
          <div className="description-list_body">
            {details && details.map(detail => {
              let { key, value } = detail.Name || detail.Value ? { key: detail.Name, value: detail.Value } : detail;

              if (!key) {
                key = detail.name;
              }

              if (!value) {
                value = detail.value;
              }

              return (<div key={key} className="description-list_item">
                <div className="description-list_title">{key}</div>
                <div className="description-list_value">
                  <div className="description-list_item-primary">{value}</div>
                </div>
              </div>);
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

OrderDetailsModal.propTypes = {
  details: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired),
  containerClassName: PropTypes.string,
};

export default OrderDetailsModal;
