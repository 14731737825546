import React from 'react';
import PropTypes from 'prop-types';
import { NotificationShape } from '../constants/prop-types';
import { connect } from 'react-redux';
import PageNotification from './page-notification';

import { removeNotification } from '../actions/toast-actions';

const Toast = (props) => {
  const { notifications } = props;

  return (<div className="js-toast page_notifications page-notifications">
    {
      notifications.map(notification => (
        <PageNotification
          key={notification.id}
          ttl={notification.ttl}
          status={notification.code === 200 ? 'success' : 'danger'}
          onClose={event => {
            event.stopPropagation();
            props.removeNotification(notification.id);
          }}
          onClick={() => {
            notification.onClick(notification);
            props.removeNotification(notification.id);
          }}
        >
          <div dangerouslySetInnerHTML={{__html: notification.text}} />
        </PageNotification>
      ))
    }
  </div>);
};

Toast.propTypes = {
  removeNotification: PropTypes.func,
  notifications: PropTypes.arrayOf(NotificationShape)
};

const mapStateToProps = ({ toasts: { notifications } }) => ({ notifications });

export default connect(mapStateToProps, { removeNotification })(Toast);
