import React from 'react';
import PropTypes from 'prop-types';
import { userReducerTypes } from '../../constants/prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';

const SignupStepComplete = ({ user: { email_verified: emailVerified }}) => (
  <SignupLayout
    headerClassName="-y-lg"
    wizardStep={4}
  >
    <div className="page_header">
      <h1 className="js-page-title page_title">Bank account requires verification</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <div className="layer -space-up-sm">
          <p className="font-size-secondary-responsive">
            Please follow several simple steps to add and start using your bank account
          </p>
        </div>
        <div className="layer -space-up-sm">
          <ul className="font-size-secondary-responsive -ul-padding">
            { !emailVerified && (<li>Please verify your email address</li>) }
            <li>We will send you several small deposits. <b>Please allow up to 3 banking days for the deposits to show up</b></li>
            <li>Check your bank account to find 2-4 small deposits from <b>BitRail, LLC</b></li>
            <li>Enter transactions amounts on the Bank Accounts page in the app to verify ownership of your account</li>
            <li>Make sure these funds are still available and that your bank account settings allow withdrawal</li>
            <li>Wait for an attempt from us to withdraw these funds. This way we ensure the ability of withdrawal. 
              Otherwise you will only be able to transfer funds to that account.</li>
            
          </ul>
        </div>
      </div>
    </div>
    <div className="page_controls">
      <Button
        color="blue"
        xSize="full"
        className="js-submit-button page_control -submit"
        href="/dashboard"
      >
        Open my Wallet
      </Button>
    </div>
  </SignupLayout>
);

SignupStepComplete.propTypes = {
  user: PropTypes.shape(userReducerTypes)
};

export default SignupStepComplete;
