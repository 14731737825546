import { matchPath } from 'react-router-dom';
import {
  __,
  always,
  any,
  anyPass,
  assoc,
  contains,
  curry,
  evolve,
  filter,
  fromPairs,
  identity,
  is,
  isEmpty,
  isNil,
  map,
  keys,
  merge,
  path as rpath,
  pathEq,
  pipe,
  prop,
  reject,
  replace,
  toPairs,
  when,
  without,
} from 'ramda';

let checkerData = {
  routes: [],
  types: [],
};

const convert = pipe(
  replace(/{\?(.*?)}/gi, ''),
  replace(/{(.*?)}/gi, (_, param) => `:${param}`),
);

const routes = map(route => assoc('url', convert(route.href), route))(checkerData.routes);
const isRejectable = anyPass([isEmpty, isNil]);

const genChecker = curry((member, data) => {
  const { types } = checkerData;

  if (member.flags && member.flags.nullable && data == null) {
    return null;
  }

  if (data == null) {
    return { message: 'its cant be null or undefined', value: data };
  }

  if (member.flags && member.flags.optional && data === '') {
    return null;
  }

  if (member.type === 'enum') {
    return !contains(data, member.enum)
      ? { message: 'its not a listed enum value', value: data, enum: member.enum }
      : null;
  }

  if (member.type === 'array') {
    return pipe(
      map(genChecker(member.arrayType)),
      reject(isRejectable),
    )(data);
  }

  if (member.type === 'object' || types[member.type]) {
    if (!is(Object, data)) {
      return { message: 'It\'s not an object', value: data };
    }

    const checks = merge(types[member.type], member.extra);
    const unknownFields = without(keys(checks), keys(data));

    return pipe(
      map(genChecker),
      toPairs,
      map(([key, propChecker]) => [key, propChecker(prop(key, data))]),
      reject(([, value]) => isRejectable(value)),
      fromPairs,
      isEmpty(unknownFields) ? identity : merge(__, { _unknownFields: unknownFields }),
      when(isRejectable, always(null)),
    )(checks);
  }

  const basicTypes = ['number', 'boolean', 'string'];
  if (contains(member.type, basicTypes)) {
    // eslint-disable-next-line
    return typeof data !== member.type ? { message: `It's not a ${member.type}`, value: data } : null;
  }

  // eslint-disable-next-line no-console
  console.warn(`Unsupported check type ${member.type}`);
  return null;
});


const checkerName = 'APIARY CHECKER';

const findRoutes = (method, path) =>
  filter((route) => {
    if (!any(pathEq(['request', 'method'], method), route.transitions)) {
      return false;
    }

    return matchPath(path, {
      path: route.url,
      exact: true,
      strict: false,
    });
  }, routes);


const checkResponse = async (method, path, response, data) => {
  const foundedRoutes = findRoutes(method, path);

  if (isEmpty(foundedRoutes)) {
    // eslint-disable-next-line no-console
    console.log(`%c ${checkerName}: Can't find [${method} ${path}] in spec`, 'background: #f8c4c4');
    return;
  }

  const foundedByResponseCode = map(evolve({
    transitions: filter(pathEq(['response', 'code'], String(response.status))),
  }))(foundedRoutes);

  const type = rpath([0, 'transitions', 0, 'response'], foundedByResponseCode);

  if (!type) {
    // eslint-disable-next-line no-console
    console.log(`%c ${checkerName}: Can't find responses with code ${response.status} in spec`, 'background: #f8c4c4');
    return;
  }

  const errors = genChecker(type.data, data);

  if (errors) {
    // eslint-disable-next-line no-console
    console.log(`%c ${checkerName}: RESPONSE INVALID [${method} ${path}] errors:\n`, 'background: #f8c4c4', errors);
    return;
  }

  // eslint-disable-next-line no-console
  console.log(`%c ${checkerName}: RESPONSE VALID [${method} ${path}]`, 'background: lightgreen');
};

export default checkResponse;
