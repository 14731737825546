import React, { useEffect, useState, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import cn from 'classnames';
import stickybits from 'stickybits';
import 'smoothscroll-polyfill';
import { contains, reject, append, equals } from 'ramda';
import { parse } from 'qs';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range-creativeco';

import BasicLayout from './basic-layout';
import Svg from '../components/svg';
import Button from '../components/button';
import Checkbox from '../components/checkbox';
import Radio from '../components/radio';
import { OnDesktop, OnMobile } from '../components/breakpoints';
import Modal from '../components/modal';
import {
  getPendingTransactions,
  getCompletedTransactions,
  getNextCompletedTransactions,
  applyFilters,
  clearFilters,
  setField,
  getTransactionDetails,
} from '../actions/transactions-actions';
import TransactionsList from '../components/transactions-list';
import CONST from '../constants/transactions-constants';
import { predefinedTimeFrame } from '../utils/transaction-utils';

const DEFAULT_FILTERS = {
  search: '',
  type: [],
  direction: [],
  timeFrameValue: CONST.TIMEFRAME_INTERVALS.LAST_90,
  timeFrameDates: predefinedTimeFrame(CONST.TIMEFRAME_INTERVALS.LAST_90),
};

const INIT_STATE = {
  isFirstDate: true,
  daysCount: 90,
  transactionShown: null,
  filterVisible: false,
  filterApplied: false,
  searchInputFocused: false,
  searchInputEmpty: true,
  filters: DEFAULT_FILTERS,
  calendarOpened: false,
  range: null,
  disabledDates: {
    minDate: moment().subtract(5, 'years').startOf('week').toDate(),
    maxDate: moment().add(1, 'years').endOf('week').toDate(),
  },
};

const Transactions = (props) => {
  const [state, _setState] = useState(INIT_STATE);
  let stickyBitsInstance = useRef(null);
  let oldRange = useRef(null);
  
  // Now this approach works because there is no stacking of setStates.
  // But if there will be necessity to use consecutive calls of setState then you will have to get rid of it
  const changeState = (value) => _setState({...state, ...value});

  useEffect(() => {
    INIT_STATE.disabledDates.minDate = moment(props.user.user.created_at).startOf('month').toDate();

    _setState(INIT_STATE);

    if (window.location.search) {
      const params = parse(window.location.search, { ignoreQueryPrefix: true });

      if (params.transaction) {
        props.setField('transactionShown', true);
        props.getTransactionDetails(params.transaction);
      }
    } else {
      props.setField('transactionShown', false);
    }

    stickyBitsInstance.current = stickybits('.js-transactions-group-header', {
      useStickyClasses: true,
      stickyBitStickyOffset: 68, // height of both mobile and desktop headers
      stickyClass: '-stuck-visible',
      stuckClass: '-stuck-scrolled',
    });

    window.addEventListener('resize', updateStickyBitsInstance);
    window.addEventListener('mousedown', handleClickOutsideFilterPlate);
    props.getPendingTransactions();
    props.getCompletedTransactions();

    return () => {
      stickyBitsInstance.current.cleanup();
      props.clearFilters();
      window.removeEventListener('resize', updateStickyBitsInstance);
      window.removeEventListener('mousedown', handleClickOutsideFilterPlate);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [props.hasMore, props.transactionsCompleted.isLoading]);

  const onScroll = () => {
    if (window.scrollY + document.body.clientHeight * 2 >= document.body.scrollHeight) {
      getNext();
    }
  };

  const onPressEnter = (e) => {
    e.persist();
    if (e.keyCode === 13) {
      applySearchString();
      e.target.blur();
    }
  };

  const onSearchInputFocus = () => {
    changeState({ searchInputFocused: true });
  };

  const getNext = () => {
    if (props.hasMore && props.transactionsCompleted.isLoading === false) {
      props.getNextCompletedTransactions();
    }
  };

  const getNoTransactions = () => {
    const completedIsLoading = props.transactionsCompleted.isLoading;
    const pendingIsLoading = props.transactionsPending.isLoading;
    const pendingTransactions = props.transactionsPending.data;
    const completedTransactions = props.transactionsCompleted.data;

    return !completedIsLoading &&
      !pendingIsLoading &&
      !completedTransactions.length &&
      !pendingTransactions.length;
  };

  const setSearchFilter = (value) => {
    changeState({
      searchInputEmpty: value.length === 0,
      filters: {
        ...state.filters,
        search: value,
      },
    });
  };

  const setFilterValue = (value, field) => {
    changeState({
      filters: {
        ...state.filters,
        [field]: contains(value, state.filters[field])
          ? reject(equals(value), state.filters[field])
          : append(value, state.filters[field]),
      },
    });
  };

  const openCalendar = () => {
    oldRange.current = state.range;

    changeState({
      calendarOpened: true,
      filterVisible: false,
      isFirstDate: true,
    });
  };

  const setTimeFrameFilter = (timeFrame) => {
    changeState({
      filters: {
        ...state.filters,
        timeFrameValue: timeFrame,
        timeFrameDates: predefinedTimeFrame(timeFrame),
      },
    });
  };

  const handleClickOutsideFilterPlate = (event) => {
    const { filterVisible, searchInputFocused, filters: { search } } = state;
    if ((filterPlateRef.current && !filterPlateRef.current.contains(event.target) && filterVisible) ||
      (searchInputRef.current && !searchInputRef.current.contains(event.target) && searchInputFocused)
    ) {
      changeState({
        filterVisible: false,
        searchInputFocused: false,
        filters: {
          ...props.filters,
          search,
        },
      });
    }
  };

  const applyFilters = () => {
    props.applyFilters(state.filters);

    changeState({
      filterVisible: false,
      filterApplied: true,
      searchInputFocused: false,
      range: state.filters.timeFrameValue !== CONST.TIMEFRAME_INTERVALS.CUSTOM ? null : state.range
    });
  };

  const applySearchString = () => {
    props.applyFilters(state.filters);
    changeState({
      searchApplied: !state.searchInputEmpty,
      filterVisible: false,
      searchInputFocused: false,
    });
  };

  const clearSearchString = () => {
    changeState({
      searchApplied: false,
      searchInputEmpty: true,
      searchInputFocused: false,
      filters: {
        ...state.filters,
        search: '',
      },
    });
    props.applyFilters({
      ...state.filters,
      search: '',
    });
  };

  const clearFilters = () => {
    changeState({
      filterVisible: false,
      filterApplied: false,
      searchInputFocused: false,
      searchInputEmpty: state.searchInputEmpty,
      filters: {
        ...DEFAULT_FILTERS,
        search: state.filters.search,
      },
      calendarOpened: false,
      range: null,
      disabledDates: {
        minDate: INIT_STATE.disabledDates.minDate,
        maxDate: INIT_STATE.disabledDates.maxDate,
      }
    });
    props.applyFilters({
      ...DEFAULT_FILTERS,
      search: state.filters.search,
    });
  };

  const handleFilterTriggerClick = () => {
    changeState({
      filterVisible: !state.filterVisible,
      filters: props.filters,
    });
    handleScrollToSearchTriggerClick();
  };

  const handleScrollToSearchTriggerClick = () => {
    searchInputRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const updateStickyBitsInstance = () => {
    stickyBitsInstance.current.update();
  };

  const setModal = shown => {
    props.setField('transactionShown', shown);
  };

  const setTransaction = transaction => {
    props.setField('transaction', transaction);
  };

  const getCustomFilterLabel = () => {
    if (state.range && state.range.startDate && state.range.endDate) {
      return (<span className="color-blue">
        { moment(state.range.startDate).format('MMM D, YYYY') + ' — ' + moment(state.range.endDate).format('MMM D, YYYY') }
      </span>);
    }

    return 'Custom dates'; 
  };

  const handleSelect = ({ selection }) => {
    if (state.isFirstDate) {
      let minDate = moment(selection.startDate).subtract(89, 'days').toDate();
      let maxDate = moment(selection.startDate).add(89, 'days').toDate();

      if (+minDate < +INIT_STATE.disabledDates.minDate) {
        minDate = INIT_STATE.disabledDates.minDate;
      }

      if (+maxDate > +INIT_STATE.disabledDates.maxDate) {
        maxDate = INIT_STATE.disabledDates.maxDate;
      }

      changeState({
        range: selection,
        isFirstDate: false,
        disabledDates: { minDate, maxDate },
      });
    } else {
      changeState({
        range: selection,
        isFirstDate: true,
        disabledDates: {
          minDate: INIT_STATE.disabledDates.minDate,
          maxDate: INIT_STATE.disabledDates.maxDate,
        }
      });
    }
  };

  const applyDates = () => {
    const range = state.range || {
      endDate: moment().toDate(),
      startDate: moment().toDate(),
      key: 'selection',
    };

    if (range) {
      changeState({
        range: range,
        calendarOpened: false,
        filterVisible: true,
        isFirstDate: true,
        disabledDates: {
          minDate: INIT_STATE.disabledDates.minDate,
          maxDate: INIT_STATE.disabledDates.maxDate,
        },
        filters: {
          ...state.filters,
          timeFrameValue: CONST.TIMEFRAME_INTERVALS.CUSTOM,
          timeFrameDates: {
            from_date: moment(range.startDate).startOf('day').toISOString(),
            to_date: moment(range.endDate).endOf('day').toISOString(),
          },
        },
      });
    } else {
      cancelCalendar();
    }
  };

  const cancelCalendar = () => {
    changeState({
      calendarOpened: false,
      filterVisible: true,
      isFirstDate: true,
      range: oldRange.current,
      disabledDates: {
        minDate: INIT_STATE.disabledDates.minDate,
        maxDate: INIT_STATE.disabledDates.maxDate,
      },
    });
  };

  const completedIsLoading = props.transactionsCompleted.isLoading;
  const pendingIsLoading = props.transactionsPending.isLoading;
  const pendingTransactions = props.transactionsPending.data;
  const completedTransactions = props.transactionsCompleted.data;
  const noTransactions = getNoTransactions();
  const { timeFrameValue, search } = state.filters;
  const selectionRange = state.range ? [state.range] : [{
    endDate: moment().toDate(),
    startDate: moment().toDate(),
    key: 'selection',
  }];

  const transactionsFilterFields = (
    <div className="transactions-filter-items">
      <div className="transactions-filter-items_group">
        <div className="transactions-filter-items_group-header">
          <h4 className="transactions-filter-items_group-title">By Direction</h4>
        </div>
        <div className="transactions-filter-items_group-items">
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Incoming"
              inputName="transaction-direction-incoming"
              className="-like-button"
              value={CONST.TRANSACTION_DIRECTION.INCOMING}
              checked={contains(CONST.TRANSACTION_DIRECTION.INCOMING, state.filters.direction)}
              onChange={e => setFilterValue(e.target.value, 'direction')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Outgoing"
              inputName="transaction-direction-outcoming"
              className="-like-button"
              value={CONST.TRANSACTION_DIRECTION.OUTGOING}
              checked={contains(CONST.TRANSACTION_DIRECTION.OUTGOING, state.filters.direction)}
              onChange={e => setFilterValue(e.target.value, 'direction')}
            />
          </div>
        </div>
      </div>
      <div className="transactions-filter-items_group">
        <div className="transactions-filter-items_group-header">
          <h4 className="transactions-filter-items_group-title">By Type</h4>
        </div>
        <div className="transactions-filter-items_group-items">
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Between your accounts"
              inputName="transaction-type-between-user-accounts"
              className="-like-button"
              value={CONST.TRANSFER}
              checked={contains(CONST.TRANSFER, state.filters.type)}
              onChange={e => setFilterValue(e.target.value, 'type')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Between you and other users"
              inputName="transaction-type-between-users-accounts"
              className="-like-button"
              value={CONST.PAYMENT}
              checked={contains(CONST.PAYMENT, state.filters.type)}
              onChange={e => setFilterValue(e.target.value, 'type')}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Checkbox
              label="Order payments"
              inputName="transaction-type-order-payments"
              className="-like-button"
              value={CONST.ORDER}
              checked={contains(CONST.ORDER, state.filters.type)}
              onChange={e => setFilterValue(e.target.value, 'type')}
            />
          </div>
        </div>
      </div>
      <div className="transactions-filter-items_group">
        <div className="transactions-filter-items_group-header">
          <h4 className="transactions-filter-items_group-title">By Date</h4>
        </div>
        <div className="transactions-filter-items_group-items">
          <div className="transactions-filter-items_group-item">
            <Radio
              label="Today"
              inputName="transaction-date-today"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.TODAY}
              checked={CONST.TIMEFRAME_INTERVALS.TODAY === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Radio
              label="This month"
              inputName="transaction-date-this-month"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.THIS_MONTH}
              checked={CONST.TIMEFRAME_INTERVALS.THIS_MONTH === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
          <div className="transactions-filter-items_group-item">
            <Radio
              label="Last 90 days"
              inputName="transaction-date-last-30-days"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.LAST_90}
              checked={CONST.TIMEFRAME_INTERVALS.LAST_90 === timeFrameValue}
              onChange={e => setTimeFrameFilter(e.target.value)}
            />
          </div>
            
          <div className="transactions-filter-items_group-item">
            <Radio
              label={getCustomFilterLabel()}
              inputName="transaction-date-any-time"
              className="-like-button"
              value={CONST.TIMEFRAME_INTERVALS.CUSTOM}
              checked={CONST.TIMEFRAME_INTERVALS.CUSTOM === timeFrameValue}
              onChange={openCalendar}
              onClick={openCalendar}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const transactionsFilterMobile = (
    <Modal
      show={state.filterVisible}
      onClose={handleFilterTriggerClick}
      closeButton
      containerClassName="-white"
    >
      <div className="modal_header">
        <h1>Filter Transactions</h1>
      </div>

      <div className="modal_body">
        {transactionsFilterFields}
      </div>

      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="gray"
            transparency="full"
            xSize="full"
            onClick={clearFilters}
          >
              Reset filters
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="modal_footer-control -submit"
            onClick={applyFilters}
          >
              Show Matching Transactions
          </Button>
        </div>
      </div>
    </Modal>
  );       

  const rangeRef = useRef(null);

  const renderDateRange = state.calendarOpened && (
    <div
      ref={rangeRef}
      className={cn('search-input_filter-dropdown transactions-filter-dropdown -visible')}
    >
      <div className="transactions-filter-dropdown_body -daterange">
        <DateRangePicker
          hideMonthName={true}
          weekDateFormat={'dd'}
          ranges={selectionRange}
          showMonthArrow={false}
          showMonthAndYearPickers={false}
          staticRanges={[]}
          inputRanges={[]}
          months={1.1}
          monthDisplayFormat={'MMMM, YYYY'}
          showDateDisplay={true}
          dateDisplayFormatter={({startDate, endDate}) => (
            <div className="daterange-header">{moment(startDate).format('MMMM, D')} &mdash; {moment(endDate).format('MMMM, D')}</div>
          )}
          minDate={state.disabledDates.minDate}
          globalMinDate={INIT_STATE.disabledDates.minDate}
          globalMaxDate = {INIT_STATE.disabledDates.maxDate}
          maxDate={state.disabledDates.maxDate}
          renderStaticRangeLabel={() => ({ hasCustomRendering: false })}
          direction="vertical"
          scroll={{ enabled: true }}
          dateDisplayFormat={'MMMM, D YYYY'}
          onChange={handleSelect}
        />
      </div>

      <div className="transactions-filter-dropdown_footer full-width">
        <Button
          color="gray"
          transparency="full"
          xSize="full"
          onClick={cancelCalendar}
        >
            Cancel
        </Button>
          
        <Button
          color="blue"
          xSize="full"
          ySize="xs"
          className="transactions-filter-dropdown_footer-control"
          onClick={applyDates}
        >
            Apply dates
        </Button>
      </div>
    </div>
  );

  const renderDateRangeMobile = state.calendarOpened && (
    <div className="datarange-mobile-wrapper">
      <button onClick={cancelCalendar} className="js-cancel-modal-button modal_close" />
      <div className="daterange-holder">
        <DateRangePicker
          hideMonthName={true}
          weekDateFormat={'dd'}
          ranges={selectionRange}
          showMonthArrow={false}
          showMonthAndYearPickers={false}
          hideDefineRanges={true}
          months={(document.body.clientHeight - 159) / 240}
          showDateDisplay={true}
          monthDisplayFormat={'MMMM, YYYY'}
          dateDisplayFormatter={({startDate, endDate}) => (
            <div className="daterange-header">{moment(startDate).format('MMMM, D')} &mdash; {moment(endDate).format('MMMM, D')}</div>
          )}
          minDate={state.disabledDates.minDate}
          globalMinDate={INIT_STATE.disabledDates.minDate}
          globalMaxDate = {INIT_STATE.disabledDates.maxDate}
          maxDate={state.disabledDates.maxDate}
          renderStaticRangeLabel={() => ({ hasCustomRendering: false })}
          direction="vertical"
          scroll={{ enabled: true }}
          dateDisplayFormat={'MMMM, D YYYY'}
          onChange={handleSelect}
        />
      </div>
      <div className="calendar-bottom-buttons">
        <Button
          color="blue"
          xSize="full"
          ySize="xs"
          className="transactions-filter-dropdown_footer-control"
          onClick={applyDates}
        >
              Apply dates
        </Button>
      </div>
    </div>);

  const filterPlateRef = useRef(null);

  const transactionsFilterDesktop = (
    <div
      ref={filterPlateRef}
      className={cn('search-input_filter-dropdown transactions-filter-dropdown', {
        '-visible': state.filterVisible,
      })}
    >
      <div className="transactions-filter-dropdown_body">
        {transactionsFilterFields}
      </div>

      <div className="transactions-filter-dropdown_footer full-width">
        <Button
          color="gray"
          transparency="full"
          xSize="full"
          ySize="xs"
          onClick={clearFilters}
        >
            Reset filters
        </Button>
        <Button
          color="blue"
          xSize="full"
          ySize="xs"
          className="transactions-filter-dropdown_footer-control"
          onClick={applyFilters}
        >
            Show Matching Transactions
        </Button>
      </div>
    </div>
  );

  const searchInputRef = useRef(null);

  return (
    <BasicLayout wrapperClassName="-x-lg -close-to-header -page-body-max" additionalElement={
      <OnMobile>
        {renderDateRangeMobile}
      </OnMobile>
    }>
      <div className="page_body">
        <div className="page_content">
          <div className={cn('transactions', { '-empty -transparent': noTransactions })}>
            <div
              className={
                cn(
                  'transactions_search search-input',
                  {
                    '-is-focused': state.searchInputFocused,
                    '-is-not-empty': !state.searchInputEmpty,
                  },
                )
              }
              ref={searchInputRef}
            >
              <div className="search-input_input-wrapper">
                <input
                  type="text"
                  value={search}
                  className="search-input_input"
                  placeholder="Transaction Search"
                  onFocus={onSearchInputFocus}
                  onKeyDown={onPressEnter}
                  onChange={e => setSearchFilter(e.target.value)}
                />
                <Svg name="magnifying-glass" className="search-input_icon" />
                {
                  (!state.searchInputEmpty && !state.searchInputFocused) &&
                    <button className="search-input_filter-reset-button" onClick={clearSearchString}>
                      <Svg name="cross" className="search-input_filter-reset-button-icon" />
                    </button>
                }

                {
                  !state.searchInputFocused &&
                    <button
                      className="transactions_trigger -input-filter-trigger search-input_filter-button"
                      onClick={handleFilterTriggerClick}
                    >
                      <Svg name="filter-bars" className="search-input_filter-button-icon" />
                    </button>
                }

                {
                  state.searchInputFocused &&
                    <Fragment>
                      <button className="search-input_input-reset-button" onClick={clearSearchString}>
                        <Svg name="cross" className="search-input_input-reset-button-icon" />
                      </button>
                      <button
                        className="search-input_submit-button"
                        onClick={applySearchString}
                      >
                        Search
                      </button>
                    </Fragment>
                }
              </div>

              <OnMobile>
                {transactionsFilterMobile}
              </OnMobile>

              <OnDesktop>
                {transactionsFilterDesktop}
              </OnDesktop>

              <OnDesktop>
                {renderDateRange}
              </OnDesktop>


            </div>
            {noTransactions && (
              <div className="transactions_body">
                {
                  (state.filterApplied || state.searchApplied)
                    ? (
                      <div className="splash color-light-gray">
                        <Svg name="search" className="splash_icon" />
                        <div className="splash_message font-size-secondary-responsive">
                            No transactions matching criteria found
                        </div>
                      </div>
                    )
                    : (
                      <div className="splash color-light-gray">
                        <Svg name="reload-usd" className="splash_icon" />
                        <div className="splash_message font-size-secondary-responsive">
                            No transactions to show<br/>
                            for last {state.daysCount} days <br/><br/>
                        </div>
                      </div>
                    )
                }
              </div>
            )}
            <div className="transactions_body">
              <TransactionsList
                setModal={setModal}
                setTransaction={setTransaction}
                noTransactions={noTransactions}
                isSeparate
                transactionShown={props.transactionShown}
                transactionToShow={props.transaction}
                handleFilterTriggerClick={handleFilterTriggerClick}
                handleScrollToSearchTriggerClick={handleScrollToSearchTriggerClick}
                completedIsLoading={completedIsLoading}
                pendingIsLoading={pendingIsLoading}
                completed={completedTransactions}
                pending={pendingTransactions}
                hasMore={props.hasMore}
              />
              { !props.hasMore 
                  && !noTransactions
                  && !completedIsLoading
                  && !pendingIsLoading
                  && props.filters.timeFrameValue === CONST.TIMEFRAME_INTERVALS.LAST_90
                  && (
                    <div className={cn('splash color-light-gray')}>
                      <div
                        className="splash_message font-size-secondary-responsive"
                      >
                      No more transactions to show<br/>
                      for last 90 days
                      </div>
                    </div>)}
            </div>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

Transactions.propTypes = {
  user: PropTypes.shape(types.userReducerTypes),
  filters: types.TransactionFiltersShape,
  transactionsCompleted: types.transactionReducerTypes.transactionsCompleted,
  transactionsPending: types.transactionReducerTypes.transactionsPending,
  transactionShown: types.transactionReducerTypes.transactionShown,
  transaction: types.TransactionShape,
  getPendingTransactions: PropTypes.func,
  getNextCompletedTransactions: PropTypes.func,
  getCompletedTransactions: PropTypes.func,
  applyFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  setField: PropTypes.func,
  getTransactionDetails: PropTypes.func,
  hasMore: PropTypes.bool
};


const mapStateToProps = ({
  user,
  transactions: {
    filters,
    transaction,
    transactionsPending,
    transactionsCompleted,
    transactionShown,
  },
}) => {
  const getHasMore = () => {
    if(!!transactionsCompleted) {
      const paging = transactionsCompleted.paging || {};
      return (paging.offset + CONST.COMPLETED_PAGE_LIMIT) < paging.count;
    }
  };

  return { 
    filters, transactionsPending, transactionsCompleted, transaction, transactionShown, user,
    hasMore: getHasMore()
  };
};

export default connect(mapStateToProps, {
  getPendingTransactions,
  getNextCompletedTransactions,
  getCompletedTransactions,
  applyFilters,
  clearFilters,
  setField,
  getTransactionDetails,
})(Transactions);
