import React from 'react';
import PropTypes from 'prop-types';
import { userReducerTypes } from '../../constants/prop-types';
import Svg from '../../components/svg';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';

const SignupStepPhoneVerified = (props) => {
  const verified = props.user.user.phone_verified;

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          {
            verified ? (<div className="page_splash splash">
              <Svg name="check-broken-circle-large" className="splash_icon" />
              <h1 className="js-page-title splash_title">Phone Number Verified</h1>
              <div className="splash_message">
                <p className="font-size-secondary-responsive color-light-gray">
                  Your phone number has been successfully verified. Now it can be used for two-factor authentication.
                </p>
              </div>
            </div>)
              : (<div className="page_splash splash">
                <Svg name="circled-cross" className="splash_icon" />
                <h1 className="js-page-title splash_title">Phone Number Not Verified</h1>
                <div className="splash_message">
                  <p className="font-size-secondary-responsive color-light-gray">
                Your phone number has not been verified. If you need additional assistance, please contact&nbsp;
                    <a // eslint-disable-line jsx-a11y/anchor-is-valid
                      target="_blank"
                      rel="noopener noreferrer"
                      href={process.env.REACT_APP_HELP_DESK_URL}
                    >
                        Customer Support
                    </a>.
                  </p>
                </div>
              </div>)
          }
        </div>
      </div>
      <div className="page_controls">
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          href="/dashboard"
        >
            Go to Dashboard
        </Button>
      </div>
    </SignupLayout>
  );
};

SignupStepPhoneVerified.propTypes = {
  user: PropTypes.shape(userReducerTypes)
};

export default SignupStepPhoneVerified;
