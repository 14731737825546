import React from 'react';

import ErrorLayout from './error-layout';
import Button from '../../components/button';

const Error404 = () => {
  return (
    <ErrorLayout wrapperClassName="-content-y-centered -content-x-centered -x-md -no-page-header">
      <div className="page_body">
        <div className="page_content">
          <div className="page_error">
            <h1>404</h1>
            <h2>Page not found</h2>
            <p>
                The page you are looking for might have been removed,
                had its name changed, or is temporarily unavailable.
            </p>
          </div>
        </div>
      </div>
      <div className="page_controls -content-x-centered">
        <Button
          color="blue"
          ySize="sm"
          xSize="md"
          className="page_control -submit"
          to="/"
        >
            Return to Main Page
        </Button>
      </div>
    </ErrorLayout>
  );
};

export default Error404;
