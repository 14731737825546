import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RecoverPassword from '../components/recover-password';
import RecoverPasswordSent from '../components/recover-password-sent';
import RecoverPasswordErrorTimeout from '../components/recover-password-error-timeout';
import { setField, requestResetLink } from '../actions/recover-password-actions';
import CONST from '../constants/recover-password-constants';

const RecoverPasswordPage = props => {
  switch (props.recoverPasswordData.step) {
    case CONST.STEP_LINK_SENT: return (<RecoverPasswordSent {...props} /> );
    case CONST.STEP_ERROR_LINK_EXPIRED: return (<RecoverPasswordErrorTimeout {...props} /> );
    case CONST.STEP_EMAIL: return (<RecoverPassword {...props} />);
    default: return (<RecoverPassword {...props} />);
  }
};

RecoverPasswordPage.propTypes = {
  recoverPasswordData: PropTypes.shape({
    step: PropTypes.string,
  }),
  setField: PropTypes.func,
};

const mapStateToProps = ({ passwordRecovery }) => ({ recoverPasswordData: passwordRecovery.recoverPassword });

export default connect(
  mapStateToProps,
  { setField, requestResetLink },
)(RecoverPasswordPage);
