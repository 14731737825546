import React from 'react';
import Svg from '../../components/svg';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';

const SignupStepAdditionalVerification = (props) => (
  <SignupLayout
    headerClassName="-y-lg"
    wrapperClassName="-x-sm -content-y-centered -no-page-header"
  >
    <div className="page_body">
      <div className="page_content">
        <div className="page_splash splash">
          <Svg name="circled-cross" className="splash_icon" />
          <h1 className="js-page-title splash_title">Additional Verification Required</h1>
          <div className="splash_message">
            <p className="font-size-secondary-responsive color-light-gray">
                  Unfortunately, we weren't able to automatically verify your identity.
                  Please check your email for additional instructions on how to submit the required documentation.
              <br />
                  Meanwhile you can review your wallet.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="page_controls">
      <Button
        color="blue"
        xSize="full"
        className="js-submit-button page_control -submit"
        href="/dashboard"
      >
            Go to your Wallet
      </Button>
    </div>
  </SignupLayout>
);

export default SignupStepAdditionalVerification;
