import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { transferReducerTypes } from '../constants/prop-types';
import BasicLayout from './basic-layout';
import CONST from '../constants/transfer-constants';
import { clearTransaction, getRate } from '../actions/transfer-actions';
import {
  TransferConfig,
  TransferConfirmation,
  TransferCompleted,
} from '../components/transfer/flow/index';
import NegativeBalanceBadge from '../components/negative-balance-badge';


const Transfer = (props) => {
  useEffect(() => {
    props.getRate();

    return props.clearTransaction;
  }, []);

  const { flowStep, isLoading } = props.transfer;

  return (
    <BasicLayout
      wrapperClassName={
        cn(flowStep === CONST.STEP_COMPLETE && '-content-y-centered -content-x-centered -x-xs -no-page-header')
      }
    >
      {!isLoading && (
        <NegativeBalanceBadge />
      )}
      {flowStep === CONST.STEP_CONFIG_TRANSFER && (
        <TransferConfig />
      )}
      {flowStep === CONST.STEP_CONFIRMATION && (
        <TransferConfirmation />
      )}
      {flowStep === CONST.STEP_COMPLETE && (
        <TransferCompleted />
      )}
    </BasicLayout>
  );
};

Transfer.propTypes = {
  transfer: PropTypes.shape(transferReducerTypes),
  getRate: PropTypes.func,
  clearTransaction: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    transfer: state.transfer,
  };
}


export default connect(mapStateToProps, { clearTransaction, getRate })(Transfer);

