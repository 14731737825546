import CONST from '../constants/set-new-password-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const setNewPassword = token => ({
  type: CONST.SET_NEW_PASSWORD,
  payload: token,
});
