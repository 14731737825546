import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setField, endStep, openPopup, setError } from '../actions/login-actions';
import { cancelOrderPayment } from '../actions/send-money-actions';
import { trackEvent } from '../utils/ganalytics';
import CONST from '../constants/login-constants';
import { cancelPayment } from '../actions/recurrent-payment-actions';
import LoginStepEmailPassword from '../components/login/login-step-email-password';
import LoginStepSentLink from '../components/login/login-step-sent-link';
import LoginStepPhoneNumber from '../components/login/login-step-phone-number';
import LoginStepErrorHelpDesk from '../components/login/login-step-error-helpdesk';
import LoginStepErrorTryAgain from '../components/login/login-step-error-try-again';
import LoginStepUnableToLogin from '../components/login/login-step-unable-to-login';
import LoginStepConsent from '../components/login/login-step-consent';
import LoginStepPayphoneAutodetect from '../components/login/login-step-payphone-autodetect';
import LoginStepOTP from '../components/login/login-step-otp';

const LoginPage = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);

    switch (props.login.step) {
      case CONST.STEP_UNABLE_TO_LOGIN: trackEvent('unable_to_login', 'Unable to login screen'); break;
      default: break;
    }
  }, [props.login.step]);

    
  switch (props.login.step) {
    case CONST.STEP_EMAIL_PASSWORD:
      return <LoginStepEmailPassword {...props} />;
    case CONST.STEP_PHONE_NUMBER: return <LoginStepPhoneNumber {...props} />;
    case CONST.STEP_SENT_LINK: return <LoginStepSentLink {...props} />;
    case CONST.STEP_UNABLE_TO_LOGIN: return <LoginStepUnableToLogin {...props} />;
    case CONST.STEP_ERROR_HELPDESK: return <LoginStepErrorHelpDesk {...props} />;
    case CONST.STEP_ERROR_TRY_AGAIN: return <LoginStepErrorTryAgain {...props} />;
    case CONST.STEP_CONSENT: return <LoginStepConsent {...props} />;
    case CONST.STEP_PAYPHONE_AUTODETECT: return <LoginStepPayphoneAutodetect {...props} />;
    case CONST.STEP_OTP: return <LoginStepOTP />;

    default: return <LoginStepEmailPassword {...props} />;
  }
};

LoginPage.propTypes = {
  login: PropTypes.shape({
    step: PropTypes.string,
    phoneNumber: PropTypes.string,
    agreement: PropTypes.bool,
  }),
};

const mapStateToProps = ({ login }) => ({ login });

export default connect(mapStateToProps, { setField, endStep, cancelOrderPayment, openPopup, setError, cancelPayment })(LoginPage);
