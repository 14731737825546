import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { 
  userReducerTypes,
  walletReducerTypes,
  sendMoneyReducerTypes,
  EnabledStatesShape
} from '../../../constants/prop-types';
import { find, filter, propEq, prop, path, merge } from 'ramda';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  setField,
  clearSearchedWallet,
  cancelOrderPayment,
  makePreflight
} from '../../../actions/send-money-actions';
import { setPaymentPopup } from '../../../actions/app-actions';

import Svg from '../../../components/svg';
import Button from '../../button';
import Checkbox from '../../checkbox';
import OrderDetailsModal from '../../order-details-modal';
import Loader from '../../loader';
import CONST from '../../../constants/send-money-constants';
import PERMISSION_CONST from '../../../constants/permission-constants';
import {
  balance,
  calcRewards,
  canAccountBeUsed,
  truncatedValue,
  toOrdinalDecimal,
  rewardsFormat,
  _convertCurrency,
  withCurrency
} from '../../../utils/send-money-utils';
import { pluralize } from '../../../utils/string-utils';
import {
  hasPermission,
  permissionErrorMessage,
  permissionAllowedLimit
} from '../../../utils/permissions';
import NotificationBadge from '../../notification-badge';
import { isBankAccountsDisabledSelector, isCreditCardsDisabledSelector } from '../../../reducers/app-reducer';
import CreditCardBackup from '../../credit-cards/credit-card-backup';
import CreditCardAddModal from '../../credit-cards/credit-card-add-modal';
import CreditCardErrorModal from '../../credit-cards/credit-card-error-modal';
import PaymentSource from './payment-source';

const EMPTY_REWARD = {
  rewardAmountTransactionCurrency: '0.00',
  pointsRequired: 0,
};

const isPaymentMethodUseRewards = (paymentSourceType) => {
  switch (paymentSourceType) {
    case 'banksAvailabilty': 
      return true;

    case 'recentTransfers':
      return false;

    case 'accounts': 
      return true;

    default:
      return true;
  }
};

const canUseRewards = (useRewards, paymentSourceType) => {
  if (useRewards) {
    return isPaymentMethodUseRewards(paymentSourceType);
  }

  return false;
};

/**
 * 
 * @param {number} pointsBalance 
 * @param {object} reward 
 * @param {enumerable} currency ["FC", "USD"]
 * @param {string} amount "10.00"
 * @param {object} USD rates
 * @returns {number}
 */

export const findNearestRewardAmount = (pointsBalance, reward, currency, amount, rates) => {
  let rewardCurrencyAmount = Math.floor(pointsBalance / reward.points_required) * reward.amount.value;
  let amountInRewardCurrency = toOrdinalDecimal(amount);

  if (reward.amount.currency !== currency) {
    rewardCurrencyAmount = _convertCurrency(reward.amount.currency, currency, rewardCurrencyAmount, rates);
  }

  const rewardsAmountUsed = Math.min(amountInRewardCurrency.ordinal, rewardCurrencyAmount);

  return rewardsAmountUsed;
};

/**
 * 
 * @param {array} pointsToUse array of reward object
 * @param {enumerable} currency ["FC", "USD"]
 * @param {string} amount "10.00"
 * @param {object} USD rates
 * @param {number} pointsBalance 
 * @returns {object}
 */

export const findPointsToUse = (pointsToUse, currency, amount, rates, pointsBalance) => {
  if (pointsToUse.length) {
    let rewardToUse = Object.assign(pointsToUse[0]);
    let rewardAmount = null;

    const nearestRewardsAmount = findNearestRewardAmount(pointsBalance, rewardToUse, currency, amount, rates);
    const nearestRewardsAmountInPoints = (nearestRewardsAmount * rewardToUse.points_required) 
      / _convertCurrency(rewardToUse.amount.currency, currency, rewardToUse.amount.value, rates);
    const nearestRewardAmountOrdinalDecimal = toOrdinalDecimal(nearestRewardsAmount);

    rewardAmount = balance(nearestRewardAmountOrdinalDecimal.ordinal, nearestRewardAmountOrdinalDecimal.decimal);

    rewardToUse.rewardAmountTransactionCurrency = rewardAmount;
    
    rewardToUse.pointsRequired = nearestRewardsAmountInPoints;
    rewardToUse.amount.ordinal = nearestRewardAmountOrdinalDecimal.ordinal;
    rewardToUse.amount.decimal = nearestRewardAmountOrdinalDecimal.decimal;

    return [rewardToUse];
  } else {
    return [];
  }
};

const OrderSourceConfig = (props) => {
  const {
    sendMoney: {
      paymentAmount,
      paymentCurrency,
      foundWallet,
      transactionRate,
      isPayment,
      preflightIsLoading,
      attributes,
      orderDescription,
      orderID,
      cardAvailability = [],
      preflightAccounts = [[], []],
      rewardsUsed,
      paymentSourceType,
      paymentAccountId,
      isInvoice,
      backup_card_id,
      paymentBankAccount,
      isCardErrorModalOpen
    },
    wallet: {
      accounts,
      cardAccountsActive,
    },
    permissions,
    rewards,
    user: {
      user,
    },
    isBankAccountsDisabled,
    isCreditCardsDisabled,
    makePreflight
  } = props;

  useEffect(() => {
    if(!backup_card_id && Array.isArray(cardAccountsActive) && cardAccountsActive.length > 0 && !isCreditCardsDisabled) {
      const defaultCard = cardAccountsActive.find((card) => card.is_default_backup === true) || cardAccountsActive[0];

      props.setField('backup_card_id', defaultCard.card_account_id);
      makePreflight();
    } else {
      makePreflight();
    }
  }, [cardAccountsActive]);

  const backupCard = Array.isArray(cardAccountsActive) && cardAccountsActive.length > 0 ? 
    backup_card_id ? merge(
      find((card) => card.card_account_id === backup_card_id)(cardAccountsActive),
      Array.isArray(cardAvailability) ? find((card) => card.card_account_id === backup_card_id)(cardAvailability) : {}
    ) :
      { card_account_id: null } :
    undefined;

  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isMorePaymentMethodsOpen, setIsMorePaymentMethodsOpen] = useState(false);


  const toggleMorePaymentMethods = () => {
    setIsMorePaymentMethodsOpen(!isMorePaymentMethodsOpen);
  };

  const isRewardsApplicable = canUseRewards(rewardsUsed, paymentSourceType);

  const checkPermissions = () => {
    const {
      sendMoney: { paymentAmount },
      wallet: { bankAccounts },
      permissions
    } = props;
    const banks = filter(b => b.amounts_verified && b.withdraw_status === 'allowed')(bankAccounts);
    const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
    const maxOrderAmount = permissionAllowedLimit(permissions, 'max_order_amount');
  
    if (!hasPermission(permissions, 'can_order')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_order')}
        />
      );
    } else if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'resides_in_allowed_state')}
        />
      );
    } else if (banks.length && !hasPermission(permissions, 'can_transfer')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={path(['can_transfer', 'message'], permissions) || PERMISSION_CONST.DEFAULT_ERROR_MESSAGES.max_ach_allowed}
        />
      );
    } else if (banks.length && parseFloat(maxAchAllowed) <= 0) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'max_ach_allowed')}
        />
      );
    } else if ((parseFloat(paymentAmount) > maxOrderAmount)) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={`${maxOrderAmount} USD max for a single transaction`}
        />
      );
    } else if (!banks.length) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
        >
          <p>
            No verified Bank account connected. To add funds to the wallet,
            please <a href="/bank-accounts" target="_blank">connect and verify a bank account</a>
          </p>
        </NotificationBadge>
      );
    }
  };

  const setRewardsUsed = (value, paymentType, paymentId) => {
    const canRewardsBeUsed = isPaymentMethodUseRewards(paymentType);

    if (canRewardsBeUsed && selectedSource && selectedSource.pointsRequired) {
      props.setField('rewardsUsed', value);
    } else {
      props.setField('rewardsUsed', false);
    };

    if (paymentType === 'accounts' && !value) {
      const accSource = find(propEq('account_id', paymentId), accounts);

      if (!canAccountBeUsed(accSource, paymentAmount, paymentCurrency, transactionRate, selectedSource, value)) {
        props.setField('paymentSourceType', '');
        props.setField('paymentAccountId', '');
      }
    }
  };

  const setPaymentSourceType = value => {
    props.setField('paymentSourceType', value);
  };

  const setPaymentAccountId = value => {
    props.setField('paymentAccountId', value);
  };

  const setPaymentBankAccount = value => {
    props.setField('paymentBankAccount', value);
  };

  const setIsCardErrorModalOpen = value => {
    props.setField('isCardErrorModalOpen', value);
  };

  const confirmation = () => {
    const allPoints = findPointsToUse(rewards.pointsToUse, paymentCurrency, paymentAmount, transactionRate, rewards.points);

    let selectedSource = allPoints.length ? allPoints[0] : EMPTY_REWARD;
    let left = paymentAmount;

    if (isRewardsApplicable && user.loyalty_member) {
      left = calcRewards(
        paymentAmount,
        paymentCurrency,
        selectedSource,
        transactionRate,
      );

      props.setField('rewardsSource', selectedSource);
    } else {
      props.setField('rewardsSource', EMPTY_REWARD);
    };

    props.setField('accLeft', truncatedValue(left));
    props.setField('wizardStep', CONST.STEP_CONFIRMATION);
  };

  const getRewardsText = (rewardsUsed) => {
    const canRewardsBeUsed = isPaymentMethodUseRewards(paymentSourceType);
    const text = `Apply ${rewardsFormat(selectedSource.pointsRequired)} reward ${pluralize('point', selectedSource.pointsRequired)} for this purchase`;
    const rewardCurrencyAmount = selectedSource.amount ? parseFloat(selectedSource.amount.value) : 0;
    const rewardCurrency = selectedSource.amount ? selectedSource.amount.currency : paymentCurrency;
    let amountInRewardCurrency = rewardCurrency === paymentCurrency ? paymentAmount :
      _convertCurrency(rewardCurrency, paymentCurrency, paymentAmount, transactionRate);

    

    if (canRewardsBeUsed && user.loyalty_member && rewardCurrencyAmount > 0) {
      return (
        <div className="rewards-label">
          {text}<br />
          {rewardsUsed && (
            <span>
              {`${withCurrency(selectedSource.rewardAmountTransactionCurrency, paymentCurrency)} will be discounted from your payment amount`}
            </span>
          )}
        </div>
      );
    } else {
      if (!canRewardsBeUsed && user.loyalty_member) {
        return `Can’t use Reward Points with Payment method selected`;
      }
    }

    if (selectedSource.pointsRequired === 0 && rewardCurrencyAmount > parseFloat(amountInRewardCurrency)) {
      return `Can’t use Reward Points for current Payment amount`;
    }

    if (selectedSource.pointsRequired === 0 || rewardCurrencyAmount === 0) {
      return `Not enough Reward Points to use`;
    }

    return (
      <div className="rewards-label">
        {text}
      </div>
    );
  };

  let left = paymentAmount;
  let selectedSource = null;

  if (user.loyalty_member) {
    const allPoints = findPointsToUse(rewards.pointsToUse, paymentCurrency, paymentAmount, transactionRate, rewards.points);
    selectedSource = allPoints.length ? allPoints[0] : EMPTY_REWARD;

    if (isRewardsApplicable && rewardsUsed) {
      left = calcRewards(
        paymentAmount,
        paymentCurrency,
        selectedSource,
        transactionRate,
      );
    };

    useEffect(() => {
      isPaymentMethodUseRewards(paymentSourceType) && selectedSource.pointsRequired > 0 && props.setField('rewardsUsed', true);
    }, []);
  }

  const renderPaymentSources = (paymentSources) => paymentSources.map((paymentSource) => {

    const isDisabledByAccount = paymentSource.sourceType === 'account' ?
      !canAccountBeUsed(paymentSource, paymentAmount, paymentCurrency, transactionRate, selectedSource, rewardsUsed) :
      paymentSource.sourceType === 'bank account' ? paymentSource.mode === 'invalid' : false;

    const isDisabledByPermission = !hasPermission(permissions, 'resides_in_allowed_state') ||
    (!hasPermission(permissions, 'can_send_money') && !isPayment) ||
    (parseFloat(permissionAllowedLimit(permissions, 'max_order_amount')) < paymentAmount);

    if(isBankAccountsDisabled && paymentSource.sourceType === 'bank account') {
      return null;
    }

    const accountName =  paymentSource.name ? paymentSource.name : paymentSource.balance ? paymentSource.balance.currency : null;
    const balanceText = paymentSource.balance ? withCurrency(
      _convertCurrency(
        paymentSource.balance.currency, 'USD',
        balance(paymentSource.balance.ordinal, paymentSource.balance.decimal, paymentSource.balance.sign), transactionRate
      ),
      'USD'
    ) : null;

    return (
      <div className="description-list_item -condensed -payment-source" key={paymentSource.id}>
        <PaymentSource
          key={paymentSource.id}
          className="-height-small"
          sourceType={paymentSource.sourceType}
          onClick={() => {
            if(paymentSource.mode !== 'invalid') {
              props.setField('paymentSourceInfo', paymentSource);
              setPaymentSourceType(paymentSource.sourceType);
              setPaymentAccountId(paymentSource.id);
              if(paymentSource.sourceType === 'bank') {
                setPaymentBankAccount(paymentSource);
                if(!isCreditCardsDisabled) {
                  setIsCardModalOpen(true);
                  if (backupCard && backupCard.card_account_id) {
                    props.setField('paymentMode', backupCard.mode || paymentSource.mode);
                  } 
                } else {
                  props.setField('paymentMode', paymentSource.mode);
                  confirmation();
                }

              } else {
                props.setField('paymentMode', 
                  paymentSource.sourceType === 'account' || paymentSource.sourceType === 'recentTransfer' 
                    ? 'instant' 
                    : paymentSource.mode
                );
                confirmation();
              }

            }
          }}
          disabled={isDisabledByAccount || isDisabledByPermission || paymentSource.mode === 'invalid'}
          accountName={accountName}
          balanceText={balanceText}
          {...paymentSource}                          
        />
      </div>
    );
  }); 

  return (
    <Fragment>
      <div className="page_body">
        <div className="page_content">
          {checkPermissions()}
          <div className="form new-transaction">
            <div className="new-transaction_description description-list -fixed-title full-width">
              <div className="description-list_body">
                <div className="description-list_item">
                  <div className="description-list_value -fit-content">
                    <div className="description-list_item-primary -flex">
                      <span className="color-primary font-weight-bold">{withCurrency(paymentAmount, paymentCurrency)}</span>
                    </div>
                    <div className="description-list_item-secondary">
                        Payment
                    </div>
                  </div>
                  <div className="description-list_item_arrow">
                    <Svg name="arrow" className="button-group_icon" />
                  </div>
                  <div className="description-list_value">
                    <div className="description-list_item-primary">
                      <span className="color-primary font-weight-bold">{foundWallet.name}</span>
                    </div>
                    <div className="description-list_item-secondary">
                      <span>{isInvoice ? 'Invoice' : 'Order'}: {orderDescription || orderID}</span>
                      {Object.keys(attributes).length > 0 && (
                        <Button
                          className="-has-arrow"
                          color="blue"
                          transparency="full"
                          xSize="sm"
                          ySize="min"
                          onClick={() => setShowOrderDetails(!showOrderDetails)}
                        >
                          <div className="button_head">
                            Show {isInvoice ? 'Invoice' : 'Order'} Details
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Temporary disable rewards */}
            { !preflightIsLoading && user.loyalty_member && false === true &&
                (<div className="new-transaction_description description-list -fixed-title full-width">
                  <div className="description-list_body">
                    <div className="description-list_item rewards-wrapper">
                      <Checkbox
                        inputName="use-reward-points"
                        disabled={
                          !isPaymentMethodUseRewards(paymentSourceType) ||
                          selectedSource.pointsRequired === 0
                        }
                        className={cn('button-group_item -like-button')}
                        label={getRewardsText(rewardsUsed)}
                        checked={rewardsUsed}
                        onChange={({ target: { checked } }) => {
                          setRewardsUsed(checked, paymentSourceType, paymentAccountId);
                        }}
                      />
                    </div>
                  </div>
                </div>)
            } 

            { preflightIsLoading && 
                <div className="transactions-log_loader-wrapper -lower">
                  <Loader size={'sm'} color={'blue'} />
                  <p className="color-gray">Loading payment sources</p>
                </div>
            }

            { !preflightIsLoading && (
              <Fragment>
                <h2 className="new-transaction__header">Payment methods</h2>
                <div className="new-transaction_description source-list description-list -fixed-title full-width">
                  <div className="description-list_body -transparent">
                    { Array.isArray(preflightAccounts) && Array.isArray(preflightAccounts[0]) &&  renderPaymentSources(preflightAccounts[0]) }
                  </div>
                </div>
              </Fragment>
            ) 
            }
            {
              !preflightIsLoading && Array.isArray(preflightAccounts) && Array.isArray(preflightAccounts[1]) && preflightAccounts[1].length > 0 && (
                <h2 
                  className={cn('new-transaction__header', '-clickable', { '-open': isMorePaymentMethodsOpen })} 
                  onClick={() => toggleMorePaymentMethods()}
                >
                  More payment methods
                </h2>
              )
            }

            { !preflightIsLoading && isMorePaymentMethodsOpen && (
              <div className="new-transaction_description source-list description-list -fixed-title full-width">
                <div className="description-list_body -transparent">
                  { Array.isArray(preflightAccounts) && Array.isArray(preflightAccounts[1]) &&  renderPaymentSources(preflightAccounts[1]) }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {!preflightIsLoading && preflightAccounts[0].length > 0 && (
        <div className="layer -space-up-md text-align-center">
          <span className="font-size-secondary-responsive">
          By continuing this operation you agree with
            <br />
          the <a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            >
            User Agreement
            </a>
          </span>
        </div>
      )}

      <OrderDetailsModal
        show={showOrderDetails}
        onClose={() => setShowOrderDetails(!showOrderDetails)}
        closeButton
        details={attributes || []}
      />

      <CreditCardBackup 
        show={isCardModalOpen}
        closeButton={false}
        onClose={() => setIsCardModalOpen(false)}
        setAsBackup={(card) => {
          setIsCardModalOpen(false);  
          setTimeout(() => {
            const paymentMode = card && card.card_account_id
              ? prop('mode')(find((item) => card.card_account_id === item.card_account_id)(cardAvailability)) 
              : paymentBankAccount.mode;
            props.setField('backup_card_id', card.card_account_id); 
            props.setField('paymentMode', paymentMode);
            makePreflight();
            confirmation();  
          }, 0);
        }}
        addCard={() => {
          setIsCardModalOpen(false);
          setIsAddCardModalOpen(true);
        }}
        cardAccounts={cardAccountsActive}
      />

      <CreditCardAddModal 
        show={isAddCardModalOpen}
        closeButton={true}
        onClose={() => setIsAddCardModalOpen(false)}
        addAndUse={(backup_card_id) => {
          props.setField('backup_card_id', backup_card_id);
          props.setField('paymentMode', paymentBankAccount.mode);
          setIsAddCardModalOpen(false);
          confirmation();
        }}
      />
      <CreditCardErrorModal 
        show={isCardErrorModalOpen}
        closeButton={false}
        onClose={() => setIsCardErrorModalOpen(false)}
        handleChooseAnother={() => {
          setIsCardErrorModalOpen(false);
          setIsCardModalOpen(true);
        }}
        handleContinue={() => {
          setIsCardErrorModalOpen(false);
          props.setField('backup_card_id', null); 
          props.setField('paymentMode',  paymentBankAccount.mode);
          confirmation();
        }}
        bankClears={paymentBankAccount ? paymentBankAccount.clears : ''}
      />
    </Fragment>
  );
};

OrderSourceConfig.propTypes = {
  setField: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  cancelOrderPayment: PropTypes.func,
  wallet: PropTypes.shape(walletReducerTypes),
  permissions: EnabledStatesShape,
  user: PropTypes.shape(userReducerTypes),
  rewards: userReducerTypes.rewards,
  isBankAccountsDisabled: PropTypes.bool,
  isCreditCardsDisabled: PropTypes.bool,
  makePreflight: PropTypes.func
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  wallet: state.wallet,
  permissions: state.user.user.enabled_states,
  rewards: state.user.rewards,
  user: state.user,
  isBankAccountsDisabled: isBankAccountsDisabledSelector(state),
  isCreditCardsDisabled: isCreditCardsDisabledSelector(state)
});

export default connect(mapStateToProps, {
  setField,
  clearSearchedWallet,
  cancelOrderPayment,
  setPaymentPopup,
  makePreflight
})(OrderSourceConfig);