import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const portalRoot = document.getElementById('portal-root');

export const Portal = ({ children }) => {
  const target = useRef(document.createElement('div'));

  useEffect(() => {
    portalRoot.appendChild(target.current);

    return () => portalRoot.removeChild(target.current);
  }, []);

  return createPortal(
    children,
    target.current
  );
};
