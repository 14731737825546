import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import LoginLayout from '../log-in-layout';
import Button from '../../components/button';
import Svg from '../../components/svg';
import Loader from '../../components/loader';
import { formatTime } from '../../utils/string-utils';
import { connect } from 'react-redux';
import { setField, oauthPending, generateNewLink } from '../../actions/login-actions';
import { setAuthToken } from '../../utils/api';
import { addNotification } from '../../actions/toast-actions';

const OAuthPendingPage = ({ setField, oauthPending, addNotification, generateNewLink }) => {
  const { tfaemail, tfaphone, expires, avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const initialMinute = parseInt(expires / 60, 10);
  const initialSeconds = expires % 60;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [ seconds, setSeconds ] =  useState(initialSeconds);
  const [ isExpired, setIsExpired ] = useState(false);

  useEffect(() => {
    const startTime = new Date().getTime();
    setAuthToken(avi);
    oauthPending();

    let timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const diff = Math.ceil((currentTime - startTime)/1000);
      const secondsLeft = (seconds || 59) - diff % 60;
      const minutesLeft = Math.floor((minutes * 60 - diff) / 60);


      if (startTime + expires * 1000 - currentTime <= 0) {
        clearInterval(timer);
        setIsExpired(true);
        setField('payfoneIsLoading', false);
        setMinutes(initialMinute);
        setSeconds(initialSeconds);
      }

      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 100);

    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if(params.msg) {
      setTimeout(() => {
        addNotification(params.msg);
      }, 1000);
    }

    return function() {
      clearInterval(timer);
    };
  }, []);

  const storagedParams = localStorage.getItem('oauthParams');

  return (
    <LoginLayout
      wrapperClassName="-x-sm -content-y-centered -page-control-bottom -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          {isExpired ? (
            <div className="page_splash splash">
              <Svg name="circled-cross" className="splash_icon" />
              <h1 className="js-page-title splash_title">Authentication Link Expired</h1>
              <div className="splash_message">
                <p className="font-size-secondary-responsive color-light-gray">
                  Authentication links are only valid for {(expires / 60).toFixed()} minutes.
                  <br />
                  Please provide your phone number again to generate a new link and retry authentication.
                </p>
              </div>
            </div>
          ) : (
            <div className="page_splash splash">
              {tfaphone
                ? (
                  <Fragment>
                    <Loader size="xxs" className="splash_loader -chat-bubble" />
                    <Svg name="chat-bubble" className="splash_icon" />
                  </Fragment>
                )
                : (
                  <Fragment>
                    <Loader size="xxs" className="splash_loader" />
                    <Svg name="letter" className="splash_icon" />
                  </Fragment>
                )
              }
              <div className="js-sent-verification-alert splash_message">
                We have sent
                { tfaphone && tfaemail ? ' authentication links ' : ' an authentication link ' }
                to
              </div>
              { tfaphone && (
                <div className="splash_message -phone-mask">
                  <div
                    className="js-sent-verification-phone phone-mask"
                  >
                    Phone (&middot;&middot;&middot;) &middot;&middot;&middot; &ndash; {tfaphone}
                  </div>
                </div>
              )}
              { tfaemail && (
                <div className="splash_message -phone-mask">
                  <div
                    className="js-sent-verification-email phone-mask"
                  >
                    Email {tfaemail}
                  </div>
                </div>
              )}
              <div className="layer -space-up-xl splash_message">Link expires in {formatTime(minutes)}:{formatTime(seconds)}</div>
            </div>
          )}
          
        </div>
      </div>
      <div className="page_controls">
        <div className="page_control color-light-gray">
          {!isExpired ? (
            <p>
            Click on the link we’ve sent you. You’ll be moved through the log in process automatically.
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
            &nbsp;Need&nbsp;help?
              </a>
            </p>
          ) : null}
        </div>
        <Button
          xSize="full"
          transparency="full"
          to={`/auth/login${storagedParams ? storagedParams : ''}`}
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
          Cancel
        </Button>
        {isExpired ? (
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button page_control -submit"
            onClick={() => { generateNewLink(); }}
          >
            Generate New Link
          </Button>
        ) : null}
      </div>
    </LoginLayout>
  );
};


OAuthPendingPage.propTypes = {
  setField: PropTypes.func,
  oauthPending: PropTypes.func,
  addNotification: PropTypes.func,
  generateNewLink: PropTypes.func
};

export default connect(null, { setField, oauthPending, addNotification, generateNewLink })(OAuthPendingPage);

