import CONST from '../constants/send-money-constants';

export const setField = (key, value) => ({
  type: CONST.SET_FIELD,
  payload: { key, value },
});

export const setFields = payload => ({
  type: CONST.SET_FIELDS,
  payload,
});

export const retryPreflight = transaction => ({
  type: CONST.RETRY_PREFLIGHT,
  payload: transaction,
});

export const payOrder = () => ({
  type: CONST.PAY_ORDER,
});


export const payPredefinedOrder = () => ({
  type: CONST.PAY_PREDEFINED_ORDER,
});

export const getWalletBySearch = searchString => ({
  type: CONST.GET_WALLET_BY_SEARCH,
  payload: { searchString },
});

export const setSearchedWallet = wallet => ({
  type: CONST.SET_SEARCHED_WALLET,
  payload: { wallet },
});

export const getRate = (dontCleanErorrs = false) => ({
  type: CONST.GET_RATE,
  payload: { dontCleanErorrs }
});

export const confirmRefund = (accounts, transaction, permissions) => ({
  type: CONST.CONFIRM_REFUND,
  accounts,
  transaction,
  permissions,
});

export const setRate = (rate, dontCleanErorrs = false) => ({
  type: CONST.SET_RATE,
  payload: { rate, dontCleanErorrs },
});

export const clearSearchedWallet = () => ({
  type: CONST.CLEAR_SEARCHED_WALLET,
});

export const makePayment = () => ({
  type: CONST.MAKE_PAYMENT,
});

export const makeRefund = transaction => ({
  type: CONST.MAKE_REFUND,
  transaction
});

export const getRefund = transaction => ({
  type: CONST.GET_REFUND,
  transaction
});

export const fetchHandle = () => ({
  type: CONST.FETCH_HANDLE,
});

export const setTransaction = transactionResult => ({
  type: CONST.SET_TRANSACTION,
  payload: { transactionResult },
});

export const setErrorMessage = message => ({
  type: CONST.SET_ERROR_MESSAGE,
  payload: { message },
});

export const clearTransaction = () => ({
  type: CONST.CLEAR_TRANSACTION,
});

export const cancelOrderPayment = () => ({
  type: CONST.CANCEL_ORDER_PAYMENT,
});

export const setFieldsFromQueryParams = (queryParams) => ({
  type: CONST.SET_FIELDS_FROM_QUERY_PARAMS,
  payload: { queryParams }
});

export const closeOrderPayment = () => ({
  type: CONST.CLOSE_ORDER_PAYMENT
});

export const makePreflight = () => ({
  type: CONST.MAKE_PREFLIGHT
});