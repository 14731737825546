import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const FormTextArea = props => {
  const {
    className,
    type,
    inputName,
    value: val,
    color,
    disabled,
    transparency,
    ySize,
    autoFocus,
    style,
    label,
  } = props;

  const [filled, setFilled] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    setFilled(val);
  }, [val]);

  const onChange = (e) => {
    const { onChange } = props;
    const { target: { value } } = e;

    setFilled(value);

    if (onChange) {
      e.persist();
      onChange(value, e);
    }
  };

  const onFocus = (e) => {
    const { onFocus } = props;
    if (onFocus) {
      e.persist();
      onFocus(e);
    }
  };

  const onBlur = (e) => {
    const { onBlur } = props;
    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  };

  const onKeyDown = (e) => {
    e.persist();
    const { onPressEnter } = props;
    if (onPressEnter && e.keyCode === 13) {
      onPressEnter(e);
    }
    const { onKeyDown } = props;
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <div className={
      cn(
        'form-input form-textarea', {
          [`-${color}`]: !!color,
          [`-y-${ySize}`]: !!ySize,
          [`-transparency-${transparency}`]: !!transparency,
        },
        className,
      )
    }
    style={style}
    >
      <textarea
        ref={textAreaRef}
        className={cn('form-input_input', { '-filled': filled })}
        value={val}
        disabled={disabled}
        id={inputName}
        name={inputName}
        placeholder=" "
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        autoComplete="off"
        autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
      />
      <label htmlFor={inputName} className="form-input_label">{label}</label>
    </div>
  );
};

FormTextArea.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'light-blue']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
  transparency: PropTypes.string,
  ySize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  style: PropTypes.shape(),
};

FormTextArea.defaultProps = {
  mask: null,
  hideMask: false,
  className: null,
  color: null,
  errorMessage: null,
  disabled: false,
  invalid: false,
  label: null,
  type: 'text',
  value: null,
  transparency: null,
  suffix: null,
  ySize: null,
  style: null,
};

export default FormTextArea;
