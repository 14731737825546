export const CONSTANTS = {
  RESET_PASSWORD: '@recover-password/RESET_PASSWORD',
  SET_FIELD: '@recover-password/SET_FIELD',
  REQUEST_RESET_LINK: '@recover-password/REQUEST_RESET_LINK',
  SET_VALIDATION_ERROR: '@recover-password/SET_VALIDATION_ERROR',
  STEP_ERROR_LINK_EXPIRED: '@recover-password/STEP_ERROR_LINK_EXPIRED',
  STEP_LINK_SENT: '@recover-password/STEP_LINK_SENT',
  STEP_EMAIL: '@recover-password/STEP_EMAIL',
};

export default CONSTANTS;
