import React, {useEffect, Fragment} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FormTextArea from './form-textarea';
import Modal from './modal';
import Button from './button';
import UserCard from './user-card';
import ExchangeMoneyInput from './send-money/exchange-money-input';
import {
  balance,
  currencyFormat, 
  num2words,
  _convertCurrency,
  withCurrency
} from '../utils/send-money-utils';
import { getSafe } from '../utils/string-utils';
import CONST from '../constants/send-money-constants';
import { confirmRefund } from '../actions/send-money-actions';
import { SendMoneyTransactionCompleted } from './send-money/wizard';
import { walletReducerTypes, EnabledStatesShape, sendMoneyReducerTypes } from '../constants/prop-types';
import { setPaymentPopup } from '../actions/app-actions';

const ModalRefundTransaction = props => {
  const { makeRefund, confirmRefund, onClose, setField, refundConfirmationModalShown, transaction, accounts, permissions,
    sendMoney: {
      paymentAmount,
      paymentCurrency,
      isLoading,
      paymentNotes,
      refundStep,
      transactionRate,
      refundSources,
      error,
    }
  } = props;

  useEffect(() => {
    if (refundConfirmationModalShown) {
      setField('refundStep', CONST.STEP_CONFIRMATION);
    }
  }, [refundConfirmationModalShown]);

  const closeModal = () => {
    clearData();
    onClose();
  };

  const clearData = () => {
    setField('paymentNotes', '');
    setField('paymentAmount', '');
    setField('isRefund', false);
    setField('refundStep', CONST.STEP_SOURCE_CONFIG);
  };

  const stepBack = () => {
    setField('refundStep', CONST.STEP_SOURCE_CONFIG);
  };

  const grossAmount = () => {
    const ordinal = getSafe(() => transaction.gross_amount.ordinal) || '0';
    const decimal = getSafe(() => transaction.gross_amount.decimal) || '00';
    return balance(ordinal, decimal);
  };

  const validate = () => {
    if (parseFloat(paymentAmount) > grossAmount()) {
      setField('error', 'Amount should be less than transaction amount');
      return false;
    }

    if (parseFloat(paymentAmount) <= 0) {
      setField('error', 'Amount should be greater than 0.00');
      return false;
    }

    setField('error', '');

    return true;
  };

  const showSourceAmount = (source, viewMode) => {
    const amount = balance(source.amount.ordinal || '0', source.amount.decimal || '00');
    if (source.amount.currency !== paymentCurrency) {
      return _convertCurrency(source.amount.currency, paymentCurrency, amount, transactionRate);
    }
    return viewMode ? currencyFormat(amount) : amount;
  };

  const prepareRefundSources = (sources) => {
    sources.forEach((source, index, obj) => {
      if (balance(source.amount.ordinal || '0', source.amount.decimal || '00') === '0.00') {
        obj.splice(index, 1);
      } else {
        if (source.account_id === 'Fee Account') {
          source.account = 'Fee';
        } else {
          const account = accounts.find(({account_id}) => account_id === source.account_id);
          if (account && account.type === 'usd') {
            source.account = 'USD';
          } else if (account && account.type === 'freedomcoin') {
            source.account = 'FC';
          } else {
            source.account = '';
          }
        }
      }
    });
    return sources;
  };

  const recipientName = getSafe(() => transaction.destination.name);
  const recipientType = getSafe(() => transaction.destination.name);
  const recipientAvatar = getSafe(() => transaction.destination.avatar_image_url);
  const recipientId = getSafe(() => transaction.destination.user_specified_id);

  const  confirmation = () => {
    if (validate()) {
      confirmRefund(accounts, transaction, permissions);
    }
  };

  return (
    <Modal {...props}>
      <div
        className={cn('modal_body',
          (refundStep === CONST.STEP_CONFIRMATION) ? '-centered' :
            (refundStep === CONST.STEP_COMPLETED) ? '-centered-content' : '')}
      >
        {refundStep === CONST.STEP_COMPLETED && (
          <SendMoneyTransactionCompleted />
        )}
        {refundStep === CONST.STEP_CONFIRMATION && (
          <Fragment>
            <div className="page_header">
              <h1 className="page_title">Confirmation</h1>
            </div>
            <div className="page_body">
              <div className="page_content">
                <form className="form new-transaction" action="">
                  { error &&
                <div className="layer -space-down-sm">
                  <div className="badge -as-form-message -danger full-width">{error}</div>
                </div>
                  }
                  <div className="description-list -fixed-title full-width">
                    <div className="description-list_body">
                      <div className="description-list_item">
                        <div className="description-list_title">Recipient</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">{recipientName}</div>
                        </div>
                      </div>
                      <div className="description-list_item">
                        <div className="description-list_title">Amount</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary font-weight-bold">
                            {withCurrency(currencyFormat(paymentAmount), paymentCurrency)}
                          </div>
                          { paymentCurrency === 'USD' && (
                            <div className="description-list_item-secondary">
                              {num2words(paymentAmount.split('.')[0])} dollars&nbsp;
                              {num2words((paymentAmount.split('.')[1] || '0').substring(0, 2))} cents
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="description-list_item">
                        <div className="description-list_title">Notes</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">{paymentNotes}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="description-list -fixed-title full-width">
                    <div className="description-list_header">
                      <div className="description-list_header-item">Payment sources</div>
                    </div>

                    <div className="description-list_body">
                      {prepareRefundSources(refundSources).map(source => (
                        <div className="description-list_group" key={source.account_id}>
                          <div className="description-list_item">
                            <div className="description-list_title">
                              {source.account} Account
                            </div>
                            <div className="description-list_value">
                              <div className="description-list_item-primary">
                                <span className="font-weight-bold">
                                  {withCurrency(showSourceAmount(source, true), paymentCurrency)}
                                </span>
                              </div>
                              {paymentCurrency === 'USD' && (
                                <div className="description-list_item-secondary">
                                  {num2words(showSourceAmount(source).split('.')[0])} dollars&nbsp;
                                  {num2words((showSourceAmount(source).split('.')[1] || '0').substring(0, 2))} cents
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="layer -space-up-md text-align-center">
                    <span className="font-size-secondary-responsive">
                  By continuing this operation you agree with
                      <br />
                  the <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                      >
                    User Agreement
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </Fragment>)}
        {refundStep === CONST.STEP_SOURCE_CONFIG && (<Fragment>
          <div className="layer -centered">
            <h1 className="js-user-text-title">Refund a transaction</h1>
          </div>
          <div className="layer -space-up-md">
            <UserCard
              avatarSrc={recipientAvatar}
              avatarType={recipientType}
              className="user-list_item"
              contentMain={recipientName}
              contentSecondary={recipientId}
              onClick={() => clearData()}
            />
          </div>
          <div className="layer -space-up-md-mobile -space-up-md-desktop">
            <ExchangeMoneyInput
              label="Refund Amount"
              inputName="transfer-amount"
              className="-has-switch"
              invalid={!!error}
              errorMessage={error}
              amount={paymentAmount}
              currency={paymentCurrency}
              autoFocus
              onShowLimits={() => setPaymentPopup(true, false)}
              onChangeInput={v => setField('paymentAmount', v)}
              currencyList={[paymentCurrency, paymentCurrency]}
              disableCurrencyChange
            />
          </div>
          <div className="layer -space-up-md">
            <Button className="js-transfer-use-whole-balance-button" color="pale-blue" ySize="xs" onClick={()=> {
              setField('paymentAmount', grossAmount());
              setField('error', '');

            }}>Full refund</Button>
          </div>
          <div className="layer -space-up-md">
            <FormTextArea
              color="white"
              type="text"
              inputName="report-label"
              label="Refund reason"
              value={paymentNotes}
              className="full-width"
              onChange={(val) => setField('paymentNotes', val)}
            />
          </div>
        </Fragment>)}
      </div>
      <div className="modal_footer">
        { refundStep === CONST.STEP_SOURCE_CONFIG && (
          <div className="modal_footer-controls">
            <Button
              xSize="full"
              transparency="full"
              className="js-remove-button modal_footer-control -cancel"
              onClick={()=> closeModal()}
            >
                Close
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="js-remove-button modal_footer-control -submit"
              onClick={() => confirmation()}
              loading={isLoading}
            >
                Next
            </Button>
          </div>
        )}
        { refundStep === CONST.STEP_CONFIRMATION && (
          <div className="modal_footer-controls">
            <Button
              xSize="full"
              transparency="full"
              className="page_control -cancel"
              disabled={isLoading}
              onClick={stepBack}
            >
              Cancel
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="page_control -submit"
              onClick={()=> makeRefund(transaction)}
              loading={isLoading}
              disabled={isLoading || !!error}
            >
              Send {withCurrency(paymentAmount, paymentCurrency)}
            </Button>
          </div>
        )}
        { refundStep === CONST.STEP_COMPLETED && (
          <div className="modal_footer-controls">
            <Button
              color="blue"
              xSize="full"
              className="js-remove-button modal_footer-control -submit"
              onClick={()=> closeModal()}
              href="/dashboard"
            >
                Go to Dashboard
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

ModalRefundTransaction.propTypes = {
  makeRefund: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  refundConfirmationModalShown: PropTypes.bool,
  transaction: PropTypes.shape({
    destination: PropTypes.shape({
      avatar_image_url: PropTypes.string,
      type: PropTypes.string,
      name: PropTypes.string,
      user_specified_id: PropTypes.string,
    }),
    gross_amount: PropTypes.shape({
      currency: PropTypes.string,
      ordinal: PropTypes.string,
      decimal: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
  onClose: PropTypes.func,
  confirmRefund: PropTypes.func,
  setField: PropTypes.func,
  accounts: walletReducerTypes.accounts,
  permissions: EnabledStatesShape

};

ModalRefundTransaction.defaultProps = {
  makeRefund: () => {},
  refundConfirmationModalShown: false,
  transaction: {
    destination: {
      avatar_image_url: '',
      type: '',
      name: '',
      user_specified_id: '',
    },
    gross_amount: {
      currency: 'USD',
      ordinal: '0',
      decimal: '00',
    },
  },
  className: null,
  onClose: () => {},
};

export default connect(null, { confirmRefund, setPaymentPopup }) (ModalRefundTransaction);
