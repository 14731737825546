import { assocPath, always, evolve } from 'ramda';
import CONST from '../constants/recover-password-constants';

const INIT_STATE = {
  isLoading: false,
  email: '',
  step: CONST.STEP_EMAIL,
  validationErrors: {
    email: null,
  },
};

const recoverPasswordReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.message, state);
    case CONST.SET_FIELD:
      return evolve({
        [action.payload.field]: always(action.payload.value),
        validationErrors: {
          [action.payload.field]: always(null),
        },
      }, state);
    default:
      return state;
  }
};

export default recoverPasswordReducer;
