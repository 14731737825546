import { assocPath, flatten } from 'ramda';
import CONST from '../constants/recurrent-payment-constants';

const INIT_STATE = {
  isLoading: true,
  paymentInProcess: false,
  step: CONST.STEP_LOADING,
  agreementToken: '',
  destWalletHandle: '',
  frequency: '',
  amount: '',
  currency: '',
  description: '',
  eventId: '',
  rate: { 
    FC: {
      USD: '1',
    },
    USD: {
      FC: '1',
    },
  },
  selectedBankAccount: {},
  selectedAccount: { balance: {} },
  usdAccIsUsed: false,

};

const recurrentPaymentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assocPath(flatten([action.payload.field]), action.payload.value, state);
    default:
      return state;
  }
};

export default recurrentPaymentReducer;
