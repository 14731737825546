import React from 'react';
import PropTypes from 'prop-types';

import CurrencyInput from '../../components/currency-input';
import CurrencySwitch from '../../components/currency-switch';

const ExchangeMoneyInput = ({
  className,
  label,
  inputName,
  amount,
  currency,
  disabled,
  onEnter,
  onChangeInput,
  onChangeCurrency,
  disableCurrencyChange,
  currencyList,
  ...props
}) => (
  <div className="form-input-container full-width">
    <CurrencyInput
      label={label}
      color="white"
      disabled={disabled}
      inputName={inputName}
      className={className}
      value={amount}
      autoFocus
      onChange={onChangeInput}
      onPressEnter={onEnter}
      currency={currency}
      {...props}
    />
    <CurrencySwitch
      currency={currency}
      currencyList={currencyList} 
      onToggle={onChangeCurrency} 
      disabled={disabled || disableCurrencyChange} 
    />
  </div>
);

ExchangeMoneyInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  inputName: PropTypes.string,
  amount: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  onEnter: PropTypes.func,
  onChangeInput: PropTypes.func,
  onChangeCurrency: PropTypes.func,
  disableCurrencyChange: PropTypes.bool,
  currencyList: PropTypes.arrayOf(PropTypes.string)
};

export default ExchangeMoneyInput;
