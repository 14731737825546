import CONST from '../constants/partner-signup-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const startSignup = () => ({
  type: CONST.START_SIGNUP,
});

export const autheticateAcc = () => ({
  type: CONST.AUTHETICATE_ACC,
});

export const signup = () => ({
  type: CONST.SIGNUP,
});

export const phoneNumberEntered = () => ({
  type: CONST.PHONE_NUMBER_ENTERED,
});

export const openPopup = () => ({
  type: CONST.OPEN_POPUP,
});