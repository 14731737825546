import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginLayout from './log-in-layout';
import moment from 'moment';
import { maintenancesSelector } from '../reducers/app-reducer';

const MaintenanceScreen = ({ maintenances, fname }) => {
  const isMobile = window.innerWidth < 380;
  const maintenance = Array.isArray(maintenances) && maintenances.find((item) => {
    return new Date(item.start_at).valueOf() <= new Date().valueOf();
  });
  const startTime = maintenance ? moment(maintenance.start_at).format('MMMM D, h:mma') : null;
  const endTime = maintenance ? moment(maintenance.end_at).format('MMMM D, h:mma') : null;

  return (
    <LoginLayout
      headerClassName="-y-lg"
      bgClassName="-full"
      wrapperClassName="-x-md -content-y-centered"
      twoLiner={!isMobile}
    >
      <div className="maintenance">
        <h2>System shut down for maintenance</h2>
        {!!maintenance ? (
          <div className="maintenance-schedule">
            <h4>Maintenance scheduled</h4>
            <p>From <span>{startTime}</span></p>
            <p>To <span>{endTime}</span></p>
          </div>
        ) : null}
        <p className="text-align-center">
          This maintenance is necessary for continued server performance and availability. 
          During this maintenance, {process.env.REACT_APP_DOMAIN_STRING} will be inaccessible.
          All platform operation will resume when the maintenance is completed.
        </p>
        <a 
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_HELP_DESK_URL}>
            Contact support
        </a>
      </div>
    </LoginLayout>
  );
};

MaintenanceScreen.propTypes = {
  fname: PropTypes.string,
  maintenances: PropTypes.arrayOf(
    PropTypes.shape({
      maintenance_id: PropTypes.string,
      start_at: PropTypes.string,
      end_at: PropTypes.string,
      summary: PropTypes.string,
      message: PropTypes.string
    })
  )
};

// Maintenance object example
// {
//   "maintenance_id": "40111abcde00000fghijk",
//   "start_at": "2022-12-16T13:40:08.711181Z",
//   "end_at": "2022-12-16T14:00:00.0Z",
//   "summary": "Routine Maintenance",
//   "message": "The BitRail API will be down approximately twenty minutes for routine maintenance.",
// }

const mapStateToProps = (state) => ({
  fname: state.user.user.fname,
  maintenances: maintenancesSelector(state)  
});

export default connect(mapStateToProps)(MaintenanceScreen);