import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { loanOfferReducerTypes } from '../../constants/prop-types';
import cn from 'classnames';

import Modal from '../../components/modal';

const LoanExpandableItem = ({ content }) => {
  const [ expanded, setExpanded ] = useState(false);
  return (
    <div className={cn('loan-expaneded-list_item', expanded && '-expanded', !content.text_content && '-no-arrow')}>
      <span onClick={() => !!content.text_content && setExpanded(!expanded)}>
        {content.title}
      </span>
      <div
        className="loan-expaneded-list_item_description"
        dangerouslySetInnerHTML={{ __html: content.text_content }}
      />
    </div>
  );
};

LoanExpandableItem.propTypes = {
  content: PropTypes.instanceOf(Element) // TODO not sure about this
};

const ModalLoanFaq = (props) => {  
  const { currentOffer: { text_content } } = props;
  return (
    <Modal {...props}>
      <div className="modal_header">
        <h1 className="js-modal-title">FAQ and Legal Information</h1>
      </div>
      <div className="modal_body">
        {text_content.map((tc, idx) => 
          <Fragment key={idx + tc.title}>
            <h2>{tc.title}</h2>
            {tc.sub_content.length > 0 && (
              <div className="loan-expaneded-list">
                {tc.sub_content.map((sc, idx) => (
                  <LoanExpandableItem content={sc} key={idx} />
                ))}
              </div>
            )}
          </Fragment>
        )}
      </div>        
    </Modal>
  );
};

ModalLoanFaq.propTypes = {
  currentOffer: loanOfferReducerTypes.currentOffer,
  onClose: PropTypes.func,
};

ModalLoanFaq.defaultProps = {
  currentOffer: {},
};

export default ModalLoanFaq;
