import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { noticeShape } from '../constants/prop-types';
import Modal from './modal';
import Checkbox from './checkbox';
import Button from './button';
import { urlRegex } from '../utils/string-utils';

const ModalNotice = (props) => {
  const { className, notice, isLoading, onNoticeClose } = props;
  const { notice_summary, notice_text } = notice.notice;
  const [isAcknowledged, setAcknowledged] = useState(false);
  const onChange = () => setAcknowledged(!isAcknowledged);
  const noticeHtml = notice_text
    .replaceAll('\n', '<br />')
    .replaceAll(urlRegex, '<a href="$&" target="_blank">$&</a>');
  const onClose = () => {
    onNoticeClose(isAcknowledged);
    setAcknowledged(false);
  }; 

  return (
    <Modal {...props} containerClassName={cn('-x-sm -closebutton-top', className)} onClose={onClose}>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">{notice_summary}</h1>
      </div>
      <div className="modal_body -centered">
        <div className="layer -space-down-xl">
          <div dangerouslySetInnerHTML={{__html: noticeHtml}} />
        </div>
        <div className="layer">
          <Checkbox
            inputName="is-acknowledged"
            label={
              <span>
              Do not show again
              </span>
            }
            className="-bordered"
            checked={isAcknowledged}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button color="transparent"  xSize="full"  className="modal_footer-control" onClick={() => onClose(isAcknowledged)} disabled={isLoading}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalNotice.propTypes = {
  className: PropTypes.string,
  notice: noticeShape,
  onNoticeClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

ModalNotice.defaultProps = {
  className: null,
  onConfirm: () => {},
  onNoticeClose: () => {},
  isLoading: false,
};

export default ModalNotice;
