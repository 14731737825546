import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, propEq } from 'ramda';

import Button from '../../components/button';
import Svg from '../../components/svg';
import OrderDetailsModal from '../order-details-modal';
import { pluralize } from '../../utils/string-utils';

import CONST from '../../constants/send-money-constants';
import { setField, payPredefinedOrder } from '../../actions/send-money-actions';
import { currencyFormat, rewardsFormat, withCurrency, _convertCurrency, formatNumberText } from '../../utils/send-money-utils';
import { sendMoneyReducerTypes, walletReducerTypes } from '../../constants/prop-types';

const paymentSourceText = (paymentSourceType, accounts, paymentAccountId) => {
  switch (paymentSourceType) {
    case 'accounts': {
      const acc = find(propEq('account_id', paymentAccountId), accounts);

      return `${acc.name} Account`;
    }
    case 'banksAvailabilty': return 'Bank Account';
    case 'recentTransfers': return 'Recent Transfer';
    default: return '';
  }
};

const PredefinedOrderConfirmation = (props) => {
  const {
    foundWallet,
    paymentAmount,
    paymentCurrency,
    transactionRate,
    isLoading,
    orderDescription,
    orderID,
    attributes,
    rewardsUsed,
    paymentSourceType,
    paymentAccountId,
    accLeft,
    rewardsSource,
  } = props.sendMoney;

  const { 
    accounts
  } = props.wallet;

  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const confirm = () => {      
    props.payPredefinedOrder();
  };

  const stepBack = () => {
    props.setField('wizardStep', CONST.STEP_ORDER_CONFIG);
    props.setField('error', null);
  };

  if (!foundWallet) {
    return 'Wallet not found';
  }


  const showSourceAmount = () => {
    const account = paymentSourceType === 'accounts' ? find(propEq('account_id', paymentAccountId))(accounts) : 'bank';
    const accountCurrency = (account && account.balance && account.balance.currency) || 'USD';
    const sourceAmountText = paymentCurrency !== accountCurrency 
      ? `${_convertCurrency(paymentCurrency, accountCurrency, accLeft, transactionRate)} ${accountCurrency}`
      : `${accLeft} ${paymentCurrency}`;
    const secondaryText = paymentCurrency === accountCurrency 
      ? paymentCurrency === 'USD'
        ? formatNumberText()
        : null
      : (
        <p>{withCurrency(accLeft, paymentCurrency)}</p>
      );

    return (
      <Fragment>
        <div className="description-list_item-primary">
          <span className="font-weight-bold">
            -{sourceAmountText}
          </span>
        </div>
        <div className="description-list_item-secondary">
          {secondaryText}
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="page_body">
        <div className="page_content">
          <div className="form new-transaction">
            <div className="new-transaction_description description-list -fixed-title full-width">
              <div className="description-list_body">
                <div className="description-list_item">
                  <div className="description-list_value">
                    <div className="description-list_item-primary -flex">
                      <span className="color-primary font-weight-bold">{withCurrency(paymentAmount, paymentCurrency)}</span>
                      <div className="description-list_item_arrow">
                        <Svg name="arrow" className="button-group_icon" />
                      </div>
                    </div>
                    <div className="description-list_item-secondary">
                        Payment
                    </div>
                  </div>
                  <div className="description-list_value">
                    <div className="description-list_item-primary">
                      <span className="color-primary font-weight-bold">{foundWallet && foundWallet.name}</span>
                    </div>
                    <div className="description-list_item-secondary">
                        Order: {orderDescription || orderID}
                      {attributes && Object.keys(attributes).length > 0 && (
                        <Button
                          className="-has-arrow"
                          color="blue"
                          transparency="full"
                          xSize="sm"
                          ySize="min"
                          onClick={() => setShowOrderDetails(!showOrderDetails)}
                        >
                          <div className="button_head">
                              Show Order Details
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="description-list -fixed-title full-width">
              <div className="description-list_header">
                <div className="description-list_header-item">Payment sources</div>
              </div>
              <div className="description-list_body">
                <div className="description-list_group">
                  <div className="description-list_item">
                    <div className="description-list_title">
                      {
                        paymentSourceText(paymentSourceType, accounts, paymentAccountId)
                      }
                    </div>
                    <div className="description-list_value">
                      {showSourceAmount()}
                    </div>
                  </div>
                </div>
                { rewardsUsed && rewardsSource && rewardsSource.reward_id && (
                  <div className="description-list_group">
                    <div className="description-list_item">
                      <div className="description-list_title">
                        Reward points
                      </div>
                      <div className="description-list_value">
                        <div className="description-list_item-primary">
                          <span className="font-weight-bold">
                            {withCurrency(rewardsSource.rewardAmountTransactionCurrency, paymentCurrency)}
                          </span>
                        </div>
                        <div className="description-list_item-secondary">
                          {rewardsFormat(rewardsSource.pointsRequired)} Reward {pluralize('Point', rewardsSource.pointsRequired)}
                        </div>
                      </div>
                    </div>
                  </div>)
                }
              </div>
            </div>

            <div className="layer -space-up-md text-align-center">
              <span className="font-size-secondary-responsive">
                  By continuing this operation you agree with
                <br />
                  the <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                >
                    User Agreement
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -cancel"
          disabled={isLoading}
          onClick={stepBack}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          onClick={confirm}
          loading={isLoading}
          disabled={isLoading}
        >
          Pay {currencyFormat(paymentAmount)}&nbsp;{paymentCurrency}
        </Button>
      </div>
      <OrderDetailsModal
        show={showOrderDetails}
        onClose={() => setShowOrderDetails(!showOrderDetails)}
        closeButton
        details={attributes || []}
      />
    </Fragment>
  );
};

PredefinedOrderConfirmation.propTypes = {
  setField: PropTypes.func,
  payPredefinedOrder: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes),
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  wallet: state.wallet,
});

export default connect(mapStateToProps, { setField, payPredefinedOrder })(PredefinedOrderConfirmation);
