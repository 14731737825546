import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { AccountShape, RatesShape, CurrencyOptionShape } from '../constants/prop-types';
import Button from './button';
import Avatar from './avatar';
import { getConvertibleTotal, withCurrency, balance } from '../utils/send-money-utils';
import { Link } from 'react-router-dom';
import { find, propEq, isEmpty, path } from 'ramda';
import CurrencyIcon from '../components/currency-icon';
import Svg from './svg';
import { BREAKPOINTS } from './breakpoints';
import { isSafari } from '../utils/string-utils';
import ModalAccountsDisclaimer from './modal-accounts-disclaimer';

const Account = ({ account, rates, currencyOptions }) => {
  const { balance: { currency, ordinal, decimal, sign }, type } = account;
  const formatType = (type) => (
    type.includes('coin') ? 
      [type.slice(0, type.indexOf('coin'))].concat(type.slice(type.indexOf('coin'))) :
      [type]
  ).reduce(
    (result, str) => `${result}${str.charAt().toUpperCase()}${str.slice(1)}`,
    '');
  const currencySettings = !isEmpty(currencyOptions) ? find(propEq('code', currency))(currencyOptions) : {};
  const currencyName = currencySettings ? currencySettings.name : formatType(type);
  const convertibleTotal = path([currency, 'USD'], rates) ?
    `${withCurrency(getConvertibleTotal(account, rates), 'USD')}` : `${withCurrency(balance(ordinal, decimal, sign), 'USD')}`;
  const isMobile = window.innerWidth < BREAKPOINTS.desktop;

  return (
    <Link className="account-widgets__account" to={`/account/${type}`}>
      <Avatar className={cn('account-widgets__icon')}>
        <CurrencyIcon 
          currency={currency.toLowerCase()}
          ext="svg"
          color="color"
          width={isMobile ? '42' : '50'}
          height={isMobile ? '42' : '50'}
          className="avatar_status-icon"
          fallback={
            <Svg 
              name='currency-placeholder-small'
              className={cn('avatar_status-icon', isSafari && 'avatar_status-icon--safari')}
              viewBox="-16 -15 56 56" 
            />
          }
        />
      </Avatar>
      <div className="account-widgets__balance">
        <p>{currencyName}</p>
      </div>
      <div className="account-widgets__convertible">
        {convertibleTotal}
      </div>
    </Link>
  );
};

Account.propTypes = {
  account: AccountShape,
  rates: RatesShape,
  currencyOptions: PropTypes.arrayOf(CurrencyOptionShape),
};

const AccountWidgets = ({ accounts, rates, onTransfer, currencyOptions, userBalance, isTransferDisabled }) => {
  const [isDisclaimerShown, setIsDisclaimerShown] = useState(false);
  
  return (
    <Fragment>
      <div className="dashboard_account-widgets account-widgets">
        <div className="account-widgets__header">
          <div className="account-widgets__total">
            <p>{withCurrency(userBalance, 'USD')}</p>
          </div>
          <div className="account-widgets__buttons">
            {!isTransferDisabled && (
              <Button
                color="blue"
                className="button"
                xSize="full"
                onClick={onTransfer}
              >
              Transfer
              </Button>
            )}
          </div>
          <p className="account-widgets__disclaimer">
            Funds held in accounts are represented in <a onClick={() => setIsDisclaimerShown(true)}>USD equivalent.</a>
          </p>
        </div>
        {accounts.map((account, index) => (
          <Account
            key={`account-${index}`}
            account={account} 
            rates={rates} 
            currencyOptions={currencyOptions} 
          />
        ))}
      </div>
      <ModalAccountsDisclaimer 
        shown={isDisclaimerShown}
        onClose={() => setIsDisclaimerShown(false)}
      />
    </Fragment>
  );
};

AccountWidgets.propTypes = {
  accounts: PropTypes.arrayOf(AccountShape),
  rates: RatesShape,
  onTransfer: PropTypes.func,
  currencyOptions: PropTypes.arrayOf(CurrencyOptionShape),
  userBalance: PropTypes.string,
  isTransferDisabled: PropTypes.bool
};

export default AccountWidgets;