import React, { Fragment, useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userReducerTypes } from '../constants/prop-types';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import qs from 'qs';
import { CSSTransition } from 'react-transition-group';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { logoutUser } from '../actions/user-actions';
import { setField as setDashboardField } from '../actions/dashboard-actions';
import { BREAKPOINTS, OnMobile, OnDesktop } from './breakpoints';
import Version from '../components/version';
import Svg from './svg';
import HeaderButton from './header-button';
import { shouldGetUser } from '../application';
import { hasLoanOffer } from '../utils/permissions';
import { isSendMoneyDisabledSelector, isBankAccountsDisabledSelector } from '../reducers/app-reducer';
import { getLogoUrl } from '../utils/api';
import PoweredBy from '../components/powered-by';
import { isCreditCardsDisabledSelector } from '../reducers/app-reducer';

const Header = (props) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [dropdownDesktopMenuIsOpen, setDropdownDesktopMenuIsOpen] = useState(false);
  const [windowScrolled, setWindowScrolled] = useState(false);
  const _isMounted = useRef(false);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleMenuOpened);
    _isMounted.current = true;

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleMenuOpened);
      clearAllBodyScrollLocks();
      _isMounted.current = false;
    };
  }, []);

  const { isCreditCardsDisabled } = props;

  const handleClick = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  const handleScroll = () => {
    const contentHeight = document.body.scrollHeight;
    const viewportHeight = window.innerHeight;

    // prevents color change of header when user tries to scroll page whose content doesn't overflow viewport
    // such behaviour manifests only when user uses track-pad
    if (contentHeight > viewportHeight) {
      if (window.pageYOffset > 10) {
        setWindowScrolled(true);
        return;
      }

      setWindowScrolled(false);
    }
  };

  const headerMenuRef = useRef(null);

  const handleMenuOpened = () => {
    const isMobile = window.innerWidth < BREAKPOINTS.desktop;
    const mobileMenuOpen = mobileMenuIsOpen;

    // we need to make sure that there are no open modals and it's safe to release body scroll lock
    if (isMobile && mobileMenuOpen) {
      disableBodyScroll(headerMenuRef);
    } else {
      enableBodyScroll(headerMenuRef);
    }
  };

  useEffect(handleMenuOpened, [mobileMenuIsOpen]);

  const checkUserName = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    return 'Profile & Settings';
  };

  const generateLogo = (isBigLogo, isColored, tenantLogos) => {
    const { avi } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const size = isBigLogo ? 'large' : 'small';
    const color = isColored ? '' : '-white';
    const style = `${size}${color}`;
    const type = 'svg';

    const logoUrl = tenantLogos && tenantLogos[style] && tenantLogos[style][type] ? tenantLogos[style][type] : getLogoUrl(avi, style, type);

    return <img src={logoUrl} alt="branding logo" className={cn('header_logo', `-${size}`, `-${type}`)} />;
  };

  const {
    user: { user, rewards },
    className,
    hideNav,
    isBigLogo,
    coloredLogo,
    isSendMoneyDisabled,
    isBankAccountsDisabled,
    poweredByType,
    isCustom,
    tenantLogos
  } = props;

  useEffect(() => {
    if(poweredByType === 'full' && isCustom) {
      document.body.classList.add('-custom-branding');
    }

    if(poweredByType !== 'full' || !isCustom) {
      document.body.classList.remove('-custom-branding');
    }

  }, [isCustom, poweredByType]);

  const permissions = user ? user.enabled_states : {};

  return (
    <div className={
      cn(
        'header js-header',
        {
          '-menu-opened': mobileMenuIsOpen,
          '-window-scrolled': windowScrolled,
          '-padded-top': poweredByType === 'full' && isCustom,
        },
        className,
      )
    }
    >
      <div className={cn('header_wrapper', { '-column': poweredByType === 'sign' && isBigLogo })}>
        {generateLogo(isBigLogo, (windowScrolled || coloredLogo), tenantLogos)}
        {poweredByType ? 
          <PoweredBy isFullWidth={poweredByType === 'full'} className="header_wrapper__powered-by" /> : null}
        
        <HeaderButton onClick={handleClick} menuIsOpened={mobileMenuIsOpen} />
        { shouldGetUser() && !props.hideNav && user.loyalty_member && <OnMobile>
          <div className="header-menu_item -rewards">
            <div className="rewards-container" onClick={() => props.setDashboardField('rewardsModalShown', true)}>
              { 
                windowScrolled
                  ? <Svg name="rewards-icon" className="-incoming" />
                  : <Svg name="rewards-icon-white" className="-incoming" />
              }
                  &nbsp;{(rewards.points || 0).toLocaleString('en')}&nbsp;points
            </div>
          </div>
        </OnMobile>
        }
        <CSSTransition
          in={mobileMenuIsOpen}
          timeout={300}
          classNames=""
        >
          <div className="header_menu header-menu" ref={headerMenuRef}>
            <div className="header-menu_wrapper">
              <Svg name="bg-waves-sm" className="header-menu_bg" />
              <div className={cn('header-menu_content', hideNav && '-align-right')}>
                {!props.hideNav && (
                  <nav className="header-menu_body -primary">
                    <NavLink to="/dashboard" className="header-menu_item -dashboard" activeClassName="-active">
                      <Svg name="bars" className="header-menu_item-icon" />
                        Dashboard
                    </NavLink>

                    {!isBankAccountsDisabled && (
                      <NavLink to="/bank-accounts" className="header-menu_item -accounts" activeClassName="-active">
                        <Svg name="banknote" className="header-menu_item-icon" />
                        Bank Accounts
                      </NavLink>
                    )}

                    {!isCreditCardsDisabled && (
                      <NavLink className="header-menu_item -credit-cards -accounts" activeClassName="-active" to="/credit-cards">
                        <Svg name="credit-card" className="header-menu_item-icon" />
                        Credit Cards
                      </NavLink>
                    )}

                    <NavLink to="/transactions" className="header-menu_item -transactions" activeClassName="-active">
                      <Svg name="arrows-fat" className="header-menu_item-icon" />
                        Transactions
                    </NavLink>

                    <NavLink
                      to="/wallet-settings"
                      className="header-menu_item -wallet-settings"
                      activeClassName="-active"
                    >
                      <Svg name="filter-bars" className="header-menu_item-icon" />
                        Wallet Settings
                    </NavLink>

                    {hasLoanOffer(permissions) && (
                      <NavLink
                        to="/loan-offer"
                        className="header-menu_item -wallet-settings"
                        activeClassName="-active"
                      >
                        <span className="header-menu_item-icon -offer">$</span>
                          Loan Offer
                      </NavLink>
                    )}
                    {!isSendMoneyDisabled && (
                      <NavLink to="/send-money" className="header-menu_item -send-money" activeClassName="-active">
                        Send money
                      </NavLink>
                    )}
                    
                  </nav>
                )}
                {!props.hideNav && user.loyalty_member && (
                  <OnDesktop>
                    <div className="rewards-container" onClick={() => props.setDashboardField('rewardsModalShown', true)}>
                      { 
                        windowScrolled
                          ? <Svg name="rewards-icon" className="-incoming" />
                          : <Svg name="rewards-icon-white" className="-incoming" />
                      }
                        &nbsp;{(rewards.points || 0).toLocaleString('en')}&nbsp;points
                    </div>
                  </OnDesktop>)
                }
                { props.hideNav ? (
                  <Fragment>
                    <nav className="header-menu_body -primary">
                      <span className="header-menu_item -user-name">
                        <Svg name="avatar-stub" className="header-menu_item-icon -no-stroke" />
                        {`${user.fname} ${user.lname}`}
                      </span>
                      <a
                        href={process.env.REACT_APP_HELP_DESK_URL}
                        className="header-menu_item -help-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Svg name="help-desk" className="header-menu_item-icon" />
                          Customer Support
                      </a>
                    </nav>
                    <nav className="header-menu_body -secondary -right">
                      <button className="header-menu_logout" onClick={props.logoutUser}>
                        {`${user.fname} ${user.lname}`}
                        <Svg name="logout" />
                      </button>
                      <CSSTransition
                        in={dropdownDesktopMenuIsOpen}
                        timeout={120}
                        classNames=""
                      >
                        <div className="header-menu_body-content">
                          <a href={process.env.REACT_APP_HELP_DESK_URL} className="header-menu_item -sub">
                              Customer Support
                          </a>
                          <a href="#logout" className="header-menu_item -sub" onClick={props.logoutUser}>
                              Log Out
                          </a>
                        </div>
                      </CSSTransition>
                    </nav>
                  </Fragment>
                ) : (
                  <nav
                    className="header-menu_body -secondary"
                    onMouseEnter={() => setDropdownDesktopMenuIsOpen(true)}
                    onMouseLeave={() => setDropdownDesktopMenuIsOpen(false)}
                    onClick={() => {
                      setDropdownDesktopMenuIsOpen(true);
                      const clickListener = document.body.addEventListener('click', () => {
                        _isMounted.current && setDropdownDesktopMenuIsOpen(false);
                        document.body.removeEventListener('click', clickListener, false);
                      }, false);
                    }}
                  >
                    <button className="header-menu_secondary-dropdown-trigger">
                      {checkUserName(user.fname, user.lname)}
                    </button>
                    <div className="sidebar-version-wrapper">
                      <OnMobile>
                        <Version />
                      </OnMobile>
                    </div>
                    <CSSTransition
                      in={dropdownDesktopMenuIsOpen}
                      timeout={120}
                      classNames=""
                    >
                      <div className="header-menu_body-content">
                        {!isBankAccountsDisabled && (
                          <NavLink className="header-menu_item -accounts" to="/bank-accounts">
                            <Svg name="banknote" className="header-menu_item-icon" />
                            Bank Accounts
                          </NavLink>
                        )}
                        {!isCreditCardsDisabled && (
                          <NavLink className="header-menu_item -credit-cards -accounts" activeClassName="-active" to="/credit-cards">
                            <Svg name="credit-card" className="header-menu_item-icon" />
                            Credit Cards
                          </NavLink>
                        )}
                        <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/wallet-settings">
                          <Svg name="filter-bars" className="header-menu_item-icon" />
                            Wallet Settings
                        </NavLink>
                        {hasLoanOffer(permissions) && (
                          <NavLink className="header-menu_item -accounts js-menu-item-wallet-settings" to="/loan-offer">
                            <span className="header-menu_item-icon -offer">$</span>
                              Loan Offer
                          </NavLink>
                        )}
                        <a 
                          href={process.env.REACT_APP_HELP_DESK_URL} 
                          rel="noopener noreferrer"
                          target="_blank" 
                          className="header-menu_item -sub"
                        >
                            Customer Support
                        </a>
                        <a 
                          href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL} 
                          target="_blank"
                          rel="noopener noreferrer"
                          className="header-menu_item -sub"
                        >
                            User Agreement
                        </a>
                        <a href="#logout" className="header-menu_item -sub" onClick={props.logoutUser}>
                            Log Out
                        </a>
                      </div>
                    </CSSTransition>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

Header.propTypes = {
  user: PropTypes.shape(userReducerTypes),
  className: PropTypes.string,
  hideNav: PropTypes.bool,
  logoutUser: PropTypes.func,
  setDashboardField: PropTypes.func,
  isBigLogo: PropTypes.bool,
  coloredLogo: PropTypes.bool,
  isSendMoneyDisabled: PropTypes.bool,
  isBankAccountsDisabled: PropTypes.bool,
  poweredByType: PropTypes.oneOf(['full', 'sign']),
  isCustom: PropTypes.bool,
  tenantLogos: PropTypes.shape({
    large: PropTypes.shape({}),
    small: PropTypes.shape({})
  }),
  isCreditCardsDisabled: PropTypes.bool
};

Header.defaultProps = {
  user: {
    user: {},
  },
  className: null,
  logoutUser: () => {},
  paymentLogo: false,
  configuration: {}
};

const mapStateToProps = (state) => ({ 
  user: state.user, 
  isSendMoneyDisabled: isSendMoneyDisabledSelector(state),
  isBankAccountsDisabled: isBankAccountsDisabledSelector(state),
  isCustom: state.application && state.application.ui ? state.application.ui.is_custom : null,
  tenantLogos: state.application && state.application.ui ? state.application.ui.logo_url : null,
  isCreditCardsDisabled: isCreditCardsDisabledSelector(state),
});

export default connect(mapStateToProps, { logoutUser, setDashboardField })(Header);
