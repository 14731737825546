import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loanOfferReducerTypes } from '../../constants/prop-types';
import Button from '../../components/button';
import Modal from '../../components/modal';
import FormInput from '../../components/form-input';
import CurrencySwitch from '../../components/currency-switch';

import VALIDATION from '../../utils/validation';
import { REGEXP_INTEGER } from '../../utils/regexp';
import { formatNumberUSD } from '../../utils/string-utils';

class ModalAmountConfig extends Component {
  state = {
    amount: '',
    validationError: null,
  };

  static getDerivedStateFromProps(props, state) {
    if (!props.show) {
      return props;
    }
    return state;
  }

  closeModal = () => {
    this.props.onClose();

    this.setState({
      validationError: null,
    });
  }

  onFieldChange = field =>
    (value) => {
      if (REGEXP_INTEGER.test(value) || value === '') {
        this.setState({
          [field]: value,
          validationError: null,
        });
      }
      
    }

  saveAmount = (amount) => {
    const { currentOffer } = this.props; 
    const maxLoanAmount = currentOffer.max_amount;
    const minLoanAmount = currentOffer.min_amount;
    
    if (!amount) {
      this.setState({
        validationError: 'Amount cannot be blank',
      });
    }
    const amountValidation = VALIDATION.validateLoanOfferAmount(minLoanAmount, maxLoanAmount, amount * 100);
    if (amountValidation.success) {
      this.props.changeAmount(amount);
    } else {
      this.setState({
        validationError: amountValidation.message,
      });
    }
  };

  render() {    
    const { amount, validationError } = this.state;
    const { currentOffer: { max_amount } } = this.props; 

    return (
      <Modal {...this.props}>
        <div className="modal_header">
          <h1 className="js-modal-title">Loan Amount</h1>
        </div>
        <div className="modal_body">
          <div className="form">
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  inputName="loan-amount"
                  label="Amount"
                  value={amount.toString()}
                  className="full-width"
                  onPressEnter={() => this.saveAmount(amount)}
                  onChange={this.onFieldChange('amount')}
                  errorMessage={validationError}
                  additionalMessage={`Up to $${formatNumberUSD(parseFloat(max_amount))}`}
                  invalid={!!validationError}
                  autoFocus
                />
                <CurrencySwitch hideSwitcher currency="USD" disabled />
              </div>
            </div>
          </div>
        </div>
        <div className="modal_footer">
          <div className="modal_footer-controls">
            <Button
              xSize="full"
              transparency="full"
              className="js-cancel-button modal_footer-control -cancel"
              disabled={this.props.isLoading}
              onClick={this.closeModal}
            >
              Cancel
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="js-submit-button modal_footer-control -submit"
              onClick={() => this.saveAmount(amount)}
              disabled={this.props.isLoading || !!this.state.validationError}
              loading={this.props.isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ModalAmountConfig.propTypes = {
  amount: PropTypes.number,
  applyAmount: PropTypes.func,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool,
  currentOffer: loanOfferReducerTypes.currentOffer,
  changeAmount: PropTypes.func
};

ModalAmountConfig.defaultProps = {
  isLoading: false,
  amount: 0,
};

export default ModalAmountConfig;
