import React from 'react';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST from '../../constants/sign-up-constants';

const SignupStepPhoneNumber = ({ setField, setValidationError, endStep, signUp }) => {
  return (<SignupLayout
    headerClassName="-y-lg"
    wizardStep={0}
  >
    <div className="page_header -space-sm">
      <h1 className="js-page-title page_title">Verify your Phone Number</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <form className="form" onSubmit={(e) => { e.preventDefault(); endStep(CONST.STEP_PHONE_NUMBER); }}>
          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              For your security, we need to make sure it&apos;s really you.
              We need your phone number to associate your account with
              your mobile phone for security and identity verification.
              Please enter your mobile phone number to continue.
            </p>
          </div>

          <div className="layer -space-down-xxl ">
            <div className="form-input-container">
              <FormInput
                transparency="half"
                className="full-width"
                label="Phone"
                type="tel"
                inputName="phone-number"
                value={signUp.phoneNumber || ''}
                onChange={(value) => {
                  setValidationError('phoneNumber', '');
                  setField('phoneNumber', value.replace(/[-()+\s_]/gi, ''));
                }}
                errorMessage={signUp.validationErrors.phoneNumber}
                invalid={!!signUp.validationErrors.phoneNumber}
                mask={process.env.REACT_APP_APPLICATION_ENV !== 'development' ? '(999) 999-9999' : '+9 (999) 999-9999'}
                autoFocus
              />
            </div>
          </div>

          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              We will send an authentication link to your phone.
              <a
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
                &nbsp;Need&nbsp;help?
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
    <div className="page_controls">
      <Button
        color="dark-gray"
        transparency="full"
        xSize="full"
        className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        onClick={() => {
          endStep(CONST.STEP_PHONE_NUMBER_SKIP);
        }}
      >
        I do not want to use mobile phone
      </Button>
      <Button
        color="blue"
        xSize="full"
        disabled={!!signUp.validationErrors.phoneNumber}
        className="js-submit-button page_control -submit"
        loading={signUp.isLoading}
        onClick={() => {
          endStep(CONST.STEP_PHONE_NUMBER);
        }}
      >
        Continue
      </Button>
    </div>
  </SignupLayout>);
};

SignupStepPhoneNumber.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  setValidationError: PropTypes.func,
  signUp: PropTypes.shape({
    phoneNumber: PropTypes.string,
    isLoading: PropTypes.bool,
    validationErrors: PropTypes.shape({
      phoneNumber: PropTypes.string,
    }),
  }),
};

export default SignupStepPhoneNumber;
