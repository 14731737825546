import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';

const IdentityVerificationModal = ({ onSubmitDocumentation, onClose, onPhoneNumberClick, shown, isLoading }) => {
  return (
    <Modal containerClassName="-white -x-sm -closebutton-top" closeButton show={shown} onClose={onClose}>
      <div className="modal_header">
        <h1 className="js-modal-title">Please choose how to verify your identity</h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-up-sm">
          <h4>Submit documentation</h4>
          <p className="font-size-secondary-responsive">
            In order to continue verification you will need to provide the following list of documentation:
          </p>
          <ul className="font-size-secondary-responsive -ul-padding">
            <li>Government Photo ID, complete copy</li>
            <li>Recent Utility Bill/PayStub, complete copy</li>
          </ul>
          <Button
            color="blue"
            xSize="full"
            loading={isLoading}
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={() => onSubmitDocumentation()}
          >
            Submit Documentation
          </Button>
          <p className="font-size-secondary-responsive font-weight-bold text-align-center color-red">
            Documentation review takes up to 3 business days
          </p>
        </div>

        <div className="layer -space-up-4xl">
          <h4>Verify with your phone number</h4>
          <ul className="font-size-secondary-responsive -ul-padding">
            <li>Use your own personal phone number for the security reasons</li>
          </ul>
          <Button
            color="blue"
            xSize="full"
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={() => onPhoneNumberClick()}
            disabled={isLoading}
          >
            Verify with Phone Number
          </Button>
          <p className="font-size-secondary-responsive font-weight-bold text-align-center color-green">
            Phone number verification is instant in most cases
          </p>
        </div>
      </div>
      <div className="layer -space-up-lg">
      </div>
    </Modal>
  );
};

IdentityVerificationModal.propTypes = {
  onSubmitDocumentation: PropTypes.func, 
  onClose: PropTypes.func,
  onPhoneNumberClick: PropTypes.func,
  shown: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default IdentityVerificationModal;
