import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import { trackEvent } from '../../utils/ganalytics';
import { canAddBankAccountSelector } from '../../reducers/app-reducer';
import CONST from '../../constants/sign-up-constants';
import SignUpYodleeModal from './sign-up-yodlee-modal';

const SignUpStepWalletSuccess = (props) => {
  const { isLoading } = props.signUp;
  const { canAddBankAccount, endStep } = props;
  const [yodleeModalShown, setYodleeModalShown] = useState(false);

  const addBankAccountInstant = () => {
    trackEvent('started_yodlee_flow', 'Button "Add Bank Account Instantly" clicked');
    setYodleeModalShown(true);
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wizardStep={3}
    >
      <div className="page_header -space-sm">
        <h1 className="js-page-title page_title">Wallet created</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="layer -space-down-xxl -space-up-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
          Congratulations!<a // eslint-disable-line jsx-a11y/anchor-is-valid
                href={'/'}
              >
          &nbsp;Your&nbsp;{process.env.REACT_APP_DOMAIN_STRING}&nbsp;wallet&nbsp;
              </a>has been created.
            </p>
          </div>
          {canAddBankAccount !== false && (<Fragment>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                We recommend you to add a Bank account. 
                Adding Bank Account will allow you to complete transactions and withdraw funds to your accounts. 
              </p>
            </div>
          </Fragment>)}
        </div>
        
      </div>
      <div className="page_controls">
        {canAddBankAccount !== false || true ? ( // TODO: REMOVE AFTER DAVID FIX OPTIONS ENDPOINT
          <Fragment>
            <Button
              color="dark-gray"
              xSize="full"
              className="js-submit-button -no-border font-weight-bold"
              transparency="full"
              onClick={() => endStep(CONST.STEP_WALLET_SUCCESS)}
              disabled={isLoading}
            >
              Skip for now
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="js-submit-button page_control -submit -column"
              onClick={addBankAccountInstant}
              loading={isLoading}
              disabled={isLoading}
            >
              Add New Bank Account Instantly
            </Button>


            <SignUpYodleeModal
              shown={yodleeModalShown}
              onClose={() => setYodleeModalShown(false)}
              endStep={() => endStep(CONST.STEP_WALLET_SUCCESS)}
            />
          </Fragment>
        ) : (
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button page_control -submit -column"
            onClick={() => endStep(CONST.STEP_WALLET_SUCCESS)}
            loading={isLoading}
            disabled={isLoading}
          >
            Go to your Wallet
          </Button>
        )}
        
      </div>
    </SignupLayout>
  );
};

SignUpStepWalletSuccess.propTypes = {
  signUp: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  canAddBankAccount: PropTypes.bool,
  endStep: PropTypes.func
};

const mapStateToProps = state => ({
  userId: state.user.user.user_id,
  canAddBankAccount: canAddBankAccountSelector(state)
});

export default connect(mapStateToProps, {})(SignUpStepWalletSuccess);