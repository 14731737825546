import { any, path, find, pathEq, propEq, reject, isNil, isEmpty, filter, prop } from 'ramda';
import moment from 'moment';
import { getStoreItem } from './string-utils';
import CONST from '../constants/transactions-constants';

export const findAccountByCurrency = (transaction, currency) =>
  find(pathEq(['account_amount', 'currency'], currency))(transaction.transaction_details.all);

export const findAccountBySign = (transaction, sign) =>
  find(pathEq(['account_amount', 'sign'], sign))(transaction.transaction_details.all);

export const getTransactionType = (transaction) => {
  return transaction.type === CONST.TRANSFER && 
    !any(propEq('account_type', 'bankaccount'), transaction.transaction_details.all)
    ? CONST.EXCHANGE
    : transaction.type;
};

export const getClearDate = ({ attributes, status }) => {
  const projectedClearDate = find(propEq('name', 'projected_clear_date'), attributes);

  if (projectedClearDate) {
    return moment(projectedClearDate.value).format('MMM D');
  } else {
    return '';
  }
};
export const getIsIncoming = transaction =>
  transaction.type !== CONST.TRANSFER && 
  (
    path(['destination', 'wallet_id'], transaction) === getStoreItem('walletID') 
    || path(['destination', 'vault_id'], transaction) === getStoreItem('walletID')
  );

export const getWalletToShow = (transaction, sign = '-') =>
  transaction[CONST.TRANSACTION_MAPPER[sign].PATH];

const findWithStatus = status => transaction =>
  find(propEq('status', status), transaction.transaction_details.all) ||
  (transaction.status === status ? transaction : null);

const findBAStatus = status => transaction =>
  find(propEq('status', status), filter(propEq('account_type', 'bankaccount'), transaction.transaction_details.all));

export const findRejected = findWithStatus(CONST.TRANSACTION_STATUSES.FAILED);
export const findInProgress = findWithStatus(CONST.TRANSACTION_STATUSES.PENDING);
export const findSuspended = findWithStatus(CONST.TRANSACTION_STATUSES.SUSPENDED);

export const findRejectedBA = findBAStatus(CONST.TRANSACTION_STATUSES.FAILED);
export const findInProgressBA = findBAStatus(CONST.TRANSACTION_STATUSES.PENDING);

export const getDescription = (transaction) => {
  const type = getTransactionType(transaction);
  const hasBankAccount = !!find(propEq('account_type', 'bankaccount'), transaction.transaction_details.all);
  const error = findRejectedBA(transaction);
  // const isIncoming = getIsIncoming(transaction);
  const clearDate = getClearDate(transaction);

  if (transaction.status === CONST.TRANSACTION_STATUSES.FAILED && type === CONST.TRANSFER) {
    return error && CONST.BANK_TRANSFER_FAILED;
  }

  if (transaction.status === CONST.TRANSACTION_STATUSES.SUSPENDED) {
    return CONST.TRANSACTION_SUSPENDED;
  }

  if (transaction.status === CONST.TRANSACTION_STATUSES.COMPLETE) {
    return clearDate ? `Transaction cleared on ${clearDate}.` : null;
  }

  if (transaction.status === CONST.TRANSACTION_STATUSES.PENDING) {
    if (error) {
      if (hasBankAccount) {
        return CONST.BANK_TRANSFER_FAILED;
      }
    } else {
      if (hasBankAccount) {
        return clearDate ? `Transaction clears on ${clearDate}.` : CONST.BANK_TRANSFER_IN_PROGRESS;
      } else {
        return clearDate ? `Transaction clears on ${clearDate}.` : null;
      }
    }
  }

  return null;
};

export const formatTransactionGroupTimeDate = (date) => {
  const currentYear = moment().year();
  const groupYear = moment(date, 'dddd, MMMM D, Y').year();

  return currentYear !== groupYear
    ? moment(date, 'dddd, MMMM D, Y').format('MMMM D, Y')
    : moment(date, 'dddd, MMMM D, Y').format('dddd, MMMM D');
};

export const formatTransactionTimeDate = (date) => {
  const currentYear = moment().year();
  const groupYear = moment(date).year();
  return currentYear !== groupYear ? moment(date).format('MMM D, Y h:mma') : moment(date).format('MMM D, h:mma');
};

export function predefinedTimeFrame(timeFrame) {
  switch (timeFrame) {
    case CONST.TIMEFRAME_INTERVALS.CUSTOM:
    case CONST.TIMEFRAME_INTERVALS.ANY:
      return {};
    case CONST.TIMEFRAME_INTERVALS.LAST_90:
      return {
        from_date: moment().startOf('day').subtract(89, 'days').toISOString(),
        to_date: moment().endOf('day').toISOString(),
      };
    case CONST.TIMEFRAME_INTERVALS.LAST_30:
      return {
        from_date: moment().startOf('day').subtract(29, 'days').toISOString(),
        to_date: moment().endOf('day').toISOString(),
      };
    case CONST.TIMEFRAME_INTERVALS.LAST_WEEK:
      return {
        from_date: moment().startOf('day').subtract(6, 'days').toISOString(),
        to_date: moment().endOf('day').toISOString(),
      };
    case CONST.TIMEFRAME_INTERVALS.THIS_MONTH:
      return {
        from_date: moment().startOf('month').toISOString(),
        to_date: moment().endOf('month').toISOString(),
      };
    case CONST.TIMEFRAME_INTERVALS.LAST_YEAR:
      return {
        from_date: moment().startOf('day').subtract(1, 'years').add(1, 'days')
          .toISOString(),
        to_date: moment().endOf('day').toISOString(),
      };
    case CONST.TIMEFRAME_INTERVALS.TODAY:
      return {
        from_date: moment().startOf('day').toISOString(),
        to_date: moment().endOf('day').toISOString(),
      };
    default:
      return {};
  }
}

export function rejectEmptyParams(params) {
  return reject(param => isNil(param) || isEmpty(param), params);
}

export const getAccountName = (transactionAccount = {}, accounts = []) => {
  if(prop('account_type', transactionAccount) === 'account') {
    const account = find(propEq('account_id', transactionAccount.account_id), accounts);

    return prop('name', account) || 'Bank';
  }
  
  return 'Bank';
};