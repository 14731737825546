import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as types from '../../../constants/prop-types';
import { find, filter, map, sort, compose, path } from 'ramda';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  setField,
  clearSearchedWallet,
  cancelOrderPayment,
} from '../../../actions/send-money-actions';
import Modal from '../../modal';

import { setPaymentPopup } from '../../../actions/app-actions';

import Svg from '../../../components/svg';
import UserCard from '../../../components/user-card';
import Button from '../../../components/button';
import PaymentSourceModal from '../payment-source-modal';
import OrderDetailsModal from '../../order-details-modal';

import CONST from '../../../constants/send-money-constants';
import PERMISSION_CONST from '../../../constants/permission-constants';
import {
  balance,
  calcBalance,
  calcPaymentSource,
  calcLeftToSource,
  leftAmountMinusBonus,
  currencyFormat,
  _convertCurrency,
  withCurrency
} from '../../../utils/send-money-utils';
import {
  hasPermission,
  permissionErrorMessage,
  permissionAllowedLimit
} from '../../../utils/permissions';
import NotificationBadge from '../../notification-badge';
import Badge from '../../badge';
import { canUseACHSelector } from '../../../reducers/app-reducer';

const EMPTY_REWARD = {
  rewardAmountTransactionCurrency: '0.00',
};

const LimitationModal = props => (
  <Modal  show={props.show} onClose={props.onClose} closeButton>
    <div className="modal_header">
      <h1>Transaction payment sources limitations</h1>
    </div>
    <div className="modal_body">
      <p>
          You can't use BitRail and Bank Account as payment sources at the same time.
          This limitation applies to all transactions above ${props.limitations}.
      </p>
    </div>
  </Modal>);

LimitationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  limitations: PropTypes.string
};

const BonusRewardsModal = (props) => {
  const minimalReward = props.rewards.pointsToUse[0] || EMPTY_REWARD;
  const leftToSource = leftAmountMinusBonus(props.leftToSource, props.selectedReward.rewardAmountTransactionCurrency);

  return (<Modal show={props.show} onClose={props.onClose} closeButton className="-rewards">
    <div className="modal_header">
      <h1>Payment Source</h1>
    </div>
    <div className="modal_body -white">
      <div className="description-list -fixed-title -x-condensed">
        <div className="description-list_body">
          { !props.pointsToUse.length 
            ? (<div className="layer -full-transparent">
              <p className="font-weight-bold reward-text">
                Transaction amount should be {minimalReward.rewardAmountTransactionCurrency} {props.paymentCurrency} or more to use rewards points.
              </p>
            </div>)
            : (<Fragment>
              <div className="layer modal_section -full-width -white">

                <p>You can use rewards points to pay for your order.
                  Please pick the amount of points you would like to exchange to BitRail Stable Coin to pay for this order.
                </p>
                <p>You have:
                  <span className="-like-h4"> {props.rewards.points.toLocaleString()} rewards points</span>
                </p>
              </div>
              <div className="layer modal_section -full-width -white">
                <span className="font-weight-bold font-size-secondary-responsive">
                  Left to source: {withCurrency(props.selectedReward.rewardAmountTransactionCurrency, props.paymentCurrency)}&nbsp;of
                  &nbsp;{withCurrency(leftToSource, props.paymentCurrency)} 
                </span>
                <div className="button-group -titled -top-space full-width space">
                  <div className="button-group_header">
                    <div className="button-group_header-item">Points to use</div>
                    <div className="button-group_header-item">Amount</div>
                  </div>
                  <div className="button-group_body">
                    {
                      props.pointsToUse.map(
                        reward => (
                          <Button
                            key={reward.reward_id}
                            color="white"
                            xSize="full"
                            ySize="xl"
                            className={cn('button-group_item -rewards', props.selectedReward.reward_id === reward.reward_id ? '-selected' : '')}
                            onClick={() => props.onClick(reward)}
                            disabled={leftToSource < parseFloat(reward.rewardAmountTransactionCurrency)}
                          >
                            <div className="button_head">
                              <div className="button_content-primary">
                                {reward.points_required.toLocaleString()} = 
                                {currencyFormat(balance(reward.amount.ordinal, reward.amount.decimal))} {reward.amount.currency}
                              </div>
                            </div>
                            <div className="button_tail nowrap">
                              {currencyFormat(reward.rewardAmountTransactionCurrency)} {props.paymentCurrency}
                            </div>
                          </Button>
                        )
                      )
                    }
                  </div>
                </div>
              </div>
            </Fragment>)
          }
        </div>
      </div>
    </div>
    { 
      !!props.pointsToUse.length && (
        <div className="modal_footer -space-down-lg">
          <div className="modal_footer-controls">
            <Button
              xSize="full"
              transparency="full"
              className="modal_footer-control -cancel"
              onClick={() => props.onClick(EMPTY_REWARD)}
            >
              Don't use rewards points
            </Button>
          </div>
        </div>
      )
    }
  </Modal>);
};

BonusRewardsModal.propTypes = {
  rewards: types.userReducerTypes.rewards,
  leftToSource: PropTypes.string,
  selectedReward: PropTypes.shape({
    rewardAmountTransactionCurrency: PropTypes.string,
    reward_id: PropTypes.string,
  }),
  pointsToUse: types.userReducerTypes.rewards.pointsToUse,
  paymentCurrency: types.CurrenciesEnum,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  show: PropTypes.bool,
};

const RewardsButton = ({ paymentCurrency, rewardsModalShown, onCloseModal, onClick, paymentBonusSource, rewards, setPoints, leftToSource }) => (
  <Fragment>
    <BonusRewardsModal
      show={rewardsModalShown}
      onClose={onCloseModal}
      pointsToUse={paymentBonusSource.allSources}
      paymentCurrency={paymentCurrency}
      rewards={rewards}
      selectedReward={paymentBonusSource.selectedSource}
      onClick={reward => setPoints(reward)}
      leftToSource={leftToSource}
    />

    <Button
      color="white"
      xSize="full"
      ySize="xl"
      className="button-group_item -has-arrow -arrow-blue"
      onClick={onClick}
    >
      <div className="button_head">
        <div className="button_content-primary">
            Rewards Points
        </div>
        <div className="button_content-secondary">
            Balance:&nbsp;
          {rewards.points.toLocaleString()}&nbsp;
          {'points\n'}
        </div>
      </div>
      <div className="button_tail nowrap">
        {paymentBonusSource.selectedSource && currencyFormat(paymentBonusSource.selectedSource.rewardAmountTransactionCurrency)} {paymentCurrency}
      </div>
    </Button>
  </Fragment>
);

RewardsButton.propTypes = {
  paymentCurrency: types.CurrenciesEnum, 
  rewardsModalShown: PropTypes.bool,
  onCloseModal: PropTypes.func,
  onClick: PropTypes.func,
  paymentBonusSource: types.BonusSourceShape,
  rewards: types.userReducerTypes.rewards,
  setPoints: PropTypes.func,
  leftToSource: PropTypes.string
};

const findPointsToUse = (pointsToUse, currency, amount, rates) => compose(
  sort((rewardA, rewardB) => {
    return parseFloat(rewardB.rewardAmountTransactionCurrency) - parseFloat(rewardA.rewardAmountTransactionCurrency);
  }),
  filter(reward => {
    return parseFloat(amount) - parseFloat(reward.rewardAmountTransactionCurrency) >= 0;
  }),
  map(reward => {
    let rewardAmount = null;
    
    if (reward.amount.currency === currency) {
      rewardAmount = balance(reward.amount.ordinal, reward.amount.decimal);
    } else {
      rewardAmount = _convertCurrency(reward.amount.currency, currency, balance(reward.amount.ordinal, reward.amount.decimal), rates);
    }

    reward.rewardAmountTransactionCurrency = rewardAmount;

    return reward;
  })
)(pointsToUse);

const SendMoneySourceConfig = (props) => {
  const [paymentAccount, setPaymentAccount] = useState('');
  const [paymentAccountType, setPaymentAccountType] = useState('account');
  const [showPaymentSource, setShowPaymentSource] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [rewardsModalShown, setRewardsModalShown] = useState(false);
  const [limitationsModalShown, setLimitationsModalShown] = useState(false);
  const [activeTab, _setActiveTab] = useState('INSTANT');

  const calculateInitialTabs = (tab, isInitial) => {
    const { paymentAmount, paymentCurrency, isPayment, transactionRate } = props.sendMoney;
    const { accounts, bankAccounts } = props.wallet;
    const { pointsToUse } = props.rewards;
    const { permissions, user: { user } } = props;
    const banks = filter(b => b.amounts_verified && b.withdraw_status === 'allowed')(bankAccounts);

    const defferedPaymentLimit = path(['deferred_payment_limit', 'value'], permissions) / 100;

    const isDeffered = paymentCurrency !== 'USD' 
      ? defferedPaymentLimit >= parseFloat(_convertCurrency(paymentCurrency, 'USD', paymentCurrency, transactionRate))
      : defferedPaymentLimit >= parseFloat(paymentAmount);

    const isDefferedPaymentAllowed = isDeffered || (banks && banks.length === 0);


    if (isPayment && user.loyalty_member) {
      const allPoints = findPointsToUse(pointsToUse, paymentCurrency, paymentAmount, transactionRate);

      let selectedSource = allPoints.length ? allPoints[0] : EMPTY_REWARD;

      if (!isDefferedPaymentAllowed && tab === 'BANK') {
        selectedSource = EMPTY_REWARD;
      }

      props.setField('paymentBonusSource', {
        allSources: allPoints,
        selectedSource,
      });

      props.setField(
        'paymentSource',
        calcPaymentSource({
          amount: paymentAmount,
          transactionCurrency: paymentCurrency,
          accounts,
          banks,
          transactionRate,
          permissions,
          bonusAmount: allPoints.length && allPoints[0].rewardAmountTransactionCurrency,
          accountType: tab
        }),
      );
    } else {
      props.setField(
        'paymentSource',
        calcPaymentSource({
          amount: paymentAmount,
          transactionCurrency: paymentCurrency,
          accounts,
          banks,
          transactionRate,
          permissions,
          bonusAmount: null,
          accountType: tab
        }),
      );
    }

    if (isInitial) {
      const allPoints = findPointsToUse(pointsToUse, paymentCurrency, paymentAmount, transactionRate);
      const selectedSource = allPoints.length ? allPoints[0] : EMPTY_REWARD;

      const left = calcPaymentSource({
        amount: paymentAmount,
        transactionCurrency: paymentCurrency,
        accounts,
        banks, 
        transactionRate,
        permissions,
        bonusAmount: isPayment && user.loyalty_member ? allPoints.length && allPoints[0].rewardAmountTransactionCurrency : null,
        accountType: 'INSTANT'
      });

      const onLeft = calcLeftToSource({
        paymentSource: left,
        paymentBonusSource: { selectedSource: isPayment && user.loyalty_member ? selectedSource : null },
        paymentAmount,
        paymentCurrency,
        transactionRate,
      });

      if (parseFloat(onLeft) > 0) {
        setActiveTab('BANK');
      }
    }
  };

  useEffect(() => {
    calculateInitialTabs(activeTab, true);
  }, []);

  const onFieldChange = (field) => {
    return (value) => {
      props.setField(field, value);
    };
  };

  const onCloseModal = () => {
    setShowPaymentSource(false);
  };

  const stepBack = () => {
    const { isLoading } = props.sendMoney;
    if (!isLoading) {
      props.setField('wizardStep', CONST.STEP_SET_TRANSACTION_AMOUNT);
    }
  };

  const validate = () => {
    const { sendMoney: { paymentAmount, transactionRate, paymentCurrency }, permissions, wallet: { bankAccounts } } = props;

    const banks = filter(b => b.amounts_verified && b.withdraw_status === 'allowed')(bankAccounts);
    const defferedPaymentLimit = path(['deferred_payment_limit', 'value'], permissions) / 100;

    const isDeffered = paymentCurrency !== 'USD' 
      ? defferedPaymentLimit >= parseFloat(_convertCurrency(paymentCurrency, 'USD', paymentAmount, transactionRate))
      : defferedPaymentLimit >= parseFloat(paymentAmount);

    const isDefferedPaymentAllowed = isDeffered || (banks && banks.length === 0);

    return calcLeftToSource(
      props.sendMoney,
      null,
      !isDefferedPaymentAllowed && activeTab === 'BANK'
    ) === '0.00';
  };

  const paymentSourceAmount = (sources, id) => {
    const source = find(s => s.id === id)(sources);
    return source ? withCurrency(source.amount, source.currency) : withCurrency('0.00', paymentCurrency);
  };

  const showPaymentSourceAmount = (account) => {
    const { paymentSource, transactionRate } = props.sendMoney;
    const source = find(s => s.id === account.account_id)(paymentSource);
    const currency = 'USD';
    if (!source) {
      return `${withCurrency(0, currency)}`;
    }
    if (source.currency !== currency) {
      return `${withCurrency(_convertCurrency(source.currency, currency, source.amount, transactionRate), currency)}`;
    }
    return `${withCurrency(source.amount, currency)}`;
  };

  const onCloseRewardsModal = () => {
    setRewardsModalShown(false);
  };

  const showPaymentSourceModal = (accountId, type) => {
    setPaymentAccount(accountId);
    setPaymentAccountType(type);
    setShowPaymentSource(true);
  };

  const confirmation = () => {
    props.setField('wizardStep', CONST.STEP_CONFIRMATION);
  };

  const clear = () => {
    const { isLoading } = props.sendMoney;
    if (!isLoading) {
      props.clearSearchedWallet();
    }
  };

  const toggleOrderDetailsModal = () => {
    setShowOrderDetails(!showOrderDetails);
  };

  const checkPermissions = () => {
    const {
      sendMoney: { isPayment, paymentAmount },
      wallet: { bankAccounts },
      permissions,
    } = props;
    const banks = filter(b => b.amounts_verified && b.withdraw_status === 'allowed')(bankAccounts);

    const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
    const maxSendAmount = permissionAllowedLimit(permissions, 'max_send_amount');
    const maxOrderAmount = permissionAllowedLimit(permissions, 'max_order_amount');

    if (!hasPermission(permissions, 'resides_in_allowed_state')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'resides_in_allowed_state')}
        />
      );
    } else if (!hasPermission(permissions, 'can_send_money') && !isPayment) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'can_send_money')}
        />
      );
    } else if (banks.length && !hasPermission(permissions, 'can_transfer')) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={path(['can_transfer', 'message'], permissions) || PERMISSION_CONST.DEFAULT_ERROR_MESSAGES.max_ach_allowed}
        />
      );
    } else if (banks.length && parseFloat(maxAchAllowed) <= 0) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={permissionErrorMessage(permissions, 'max_ach_allowed')}
        />
      );
    } else if ((parseFloat(paymentAmount) > maxSendAmount) && !isPayment) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={`$${maxSendAmount} max for a single transaction`}
        />
      );
    } else if ((parseFloat(paymentAmount) > maxOrderAmount) && isPayment) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
          message={`$${maxOrderAmount} max for a single transaction`}
        />
      );
    } else if (!banks.length && isPayment) {
      return (
        <NotificationBadge 
          type="permission"
          isSlim={true}
        >
          <p>
            No verified Bank account connected. To add funds to the wallet,
            please <a href="/bank-accounts" target="_blank">connect and verify a bank account</a>
          </p>
        </NotificationBadge>
      );
    }
  };

  const setActiveTab = tab => {
    const _tab = isBankAccountsDisabled === true ? 'INSTANT' : tab;
    _setActiveTab(_tab);

    if (tab === 'BANK') {
      const { pointsToUse } = props.rewards;
      const { paymentAmount, paymentCurrency, transactionRate } = props.sendMoney;

      const allPoints = findPointsToUse(pointsToUse, paymentCurrency, paymentAmount, transactionRate);
      const selectedSource = EMPTY_REWARD;

      props.setField('paymentBonusSource', {
        allSources: allPoints,
        selectedSource: selectedSource,
      });
    }

    calculateInitialTabs(_tab);
  };

  const {
    sendMoney: {
      searchString,
      paymentAmount,
      paymentCurrency,
      paymentSource,
      paymentNotes,
      foundWallet,
      transactionRate,
      isLoading,
      isPayment,
      attributes,
      orderDescription,
      orderID,
      paymentBonusSource,
      isInvoice
    },
    wallet: {
      accounts,
      bankAccounts,
    },
    permissions,
    rewards,
    isBankAccountsDisabled
  } = props;

  const banks = filter(b => b.amounts_verified && b.withdraw_status === 'allowed')(bankAccounts);
  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
  const defferedPaymentLimit = path(['deferred_payment_limit', 'value'], permissions) / 100;

  const isDeffered = paymentCurrency !== 'USD' 
    ? defferedPaymentLimit >= parseFloat(_convertCurrency(paymentCurrency, 'USD', paymentAmount, transactionRate))
    : defferedPaymentLimit >= parseFloat(paymentAmount);

  const isDefferedPaymentAllowed = isDeffered || (banks && banks.length === 0);

  return (
    <Fragment>
      <div className="page_header">
        <h1 className="page_title">
          {isPayment
            ? `${isInvoice ? 'Invoice' : 'Order'} payment`
            : <Fragment>
                Send Money
              <Svg name="arrow-right-long" className="page_title-icon" />
            </Fragment>
          }
        </h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          {checkPermissions()}
          <div className="form new-transaction">
            {isPayment
              ? (
                <div className="new-transaction_description description-list -fixed-title full-width">
                  <div className="description-list_body">
                    <div className="description-list_item">
                      <div className="description-list_title">Amount</div>
                      <div className="description-list_value">
                        <div className="description-list_item-primary">
                          {withCurrency(paymentAmount, paymentCurrency)}
                        </div>
                      </div>
                    </div>
                    <div className="description-list_item">
                      <div className="description-list_title">Recipient</div>
                      <div className="description-list_value">
                        <div className="description-list_item-primary">{foundWallet.name}</div>
                      </div>
                    </div>
                    <div className="description-list_item">
                      <div className="description-list_title">Order</div>
                      <div className="description-list_value">
                        <div className="description-list_item-primary">{orderDescription || orderID}</div>
                        {Object.keys(attributes).length > 0 && (
                          <div className="description-list_item-secondary">
                            <div className="layer -space-up-xs">
                              <Button
                                className="-has-arrow"
                                color="blue"
                                transparency="full"
                                xSize="condensed-full"
                                ySize="min"
                                onClick={toggleOrderDetailsModal}
                              >
                                <div className="button_head">
                                  Show {isInvoice ? 'Invoice' : 'Order'} Details
                                </div>
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className="new-transaction_user user-list">
                    <UserCard
                      avatarSrc={foundWallet.avatar_image_url}
                      avatarType={foundWallet.type}
                      className="user-list_item"
                      contentMain={foundWallet.name}
                      contentSecondary={searchString}
                      onClick={clear}
                    />
                  </div>
                  <button
                    type="button"
                    className="new-transaction_description description-list -fixed-title full-width"
                    onClick={stepBack}
                  >
                    <div className="description-list_body">
                      <div className="description-list_item">
                        <div className="description-list_title">Amount</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">
                            {withCurrency(paymentAmount, paymentCurrency)}
                          </div>
                        </div>
                      </div>
                      <div className="description-list_item">
                        <div className="description-list_title">Notes</div>
                        <div className="description-list_value">
                          <div className="description-list_item-primary">{paymentNotes}</div>
                        </div>
                      </div>
                    </div>
                  </button>
                </Fragment>
              )}
            { !isDefferedPaymentAllowed ?
              (<Fragment>
                <LimitationModal
                  show={limitationsModalShown}
                  onClose={() => setLimitationsModalShown(false)}
                  limitations={currencyFormat(defferedPaymentLimit)}
                />
                <div className="layer -space-down-sm">
                  <div>
                    <div className={cn('tabs', isBankAccountsDisabled ? '-hidden' : '')}>
                      <div
                        className={cn('tabs_item', { '-active': activeTab === 'INSTANT' })}
                        onClick={() => setActiveTab('INSTANT')}
                      >
                        Instant Transfer
                      </div>
                      <div
                        className={cn('tabs_item', { '-active': activeTab === 'BANK' })}
                        onClick={() => setActiveTab('BANK')}
                      >
                        From Bank account
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="button-group -titled -has-arrows full-width">
                  <div className="button-group_body">
                    {paymentBonusSource.selectedSource
                    && isPayment
                    && !!rewards.pointsToUse.length
                    && activeTab !== 'BANK'
                    &&
                  (<RewardsButton
                    paymentCurrency={paymentCurrency}
                    rewardsModalShown={rewardsModalShown}
                    onCloseModal={onCloseRewardsModal}
                    onClick={() => setRewardsModalShown(true)}
                    leftToSource={calcLeftToSource(props.sendMoney)}
                    paymentBonusSource={paymentBonusSource}
                    rewards={rewards}
                    setPoints={reward => {
                      props.setField('paymentBonusSource', {
                        ...paymentBonusSource,
                        selectedSource: reward,
                      });
                      onCloseRewardsModal();
                    }}
                  />)
                    }
                  
                    {
                      activeTab === 'INSTANT' ? (
                        <Fragment>
                          {
                            accounts.map((account, i, arr) => (
                              <Button
                                color="white"
                                xSize="full"
                                ySize="xl"
                                disabled={arr.length === 1}
                                className="button-group_item -has-arrow -arrow-blue"
                                key={account.account_id}
                                onClick={() => showPaymentSourceModal(account.account_id, 'account')}
                              >
                                <div className="button_head">
                                  <div className="button_content-primary">
                                    {account.name ? account.name : account.balance.currency} Account
                                  </div>
                                  <div className="button_content-secondary">
                                    Balance:
                                    {calcBalance(
                                      paymentCurrency,
                                      account.balance.currency,
                                      account.balance.ordinal,
                                      account.balance.decimal,
                                      transactionRate,
                                    )}
                                  </div>
                                </div>
                                <div className="button_tail nowrap">
                                  {showPaymentSourceAmount(account)}
                                </div>
                              </Button>
                            ))}
                          <Badge status="pending" className="layer cursor-pointer -space-up-sm" onClick={() => setLimitationsModalShown(true)}>
                            <div>{`Learn about payment source limitations.`}</div>
                          </Badge>
                        </Fragment>
                      ) : (
                        <Fragment>
                          {
                            banks.map(bank => (
                              <Button
                                color="white"
                                xSize="full"
                                ySize="xl"
                                className="button-group_item -has-arrow -arrow-blue"
                                disabled={maxAchAllowed <= 0 || !hasPermission(permissions, 'can_transfer')}
                                key={bank.bank_account_id}
                                onClick={() =>
                                  (maxAchAllowed > 0 && hasPermission(permissions, 'can_transfer'))
                          && showPaymentSourceModal(bank.bank_account_id, 'bank')
                                }
                              >
                                <div className="button_head">
                                  <div className="button_content-primary">Bank Account</div>
                                  <div className="button_content-secondary">
                                    {bank.user_description || 'Account Number'}:
                                    {' '}
                                    <span className="nowrap">
                                      <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
                                    </span>
                                  </div>
                                </div>
                                <div className="button_tail nowrap">
                                  {paymentSourceAmount(paymentSource, bank.bank_account_id)}
                                </div>
                              </Button>)
                            )
                          }
                          <Badge status="alert" className="layer -space-up-sm">
                            {`Funds will be delivered once the bank transfer is complete. It could take up to 3 business days.`}
                            <br />
                            <span 
                              className="-pending" 
                              onClick={() => setLimitationsModalShown(true)}
                            >{`Learn about payment source limitations.`}</span>
                          </Badge>
                        </Fragment>)}
                  </div>
                </div>
              </Fragment>)
              : (
                <div className="button-group -titled -has-arrows full-width">
                  <div className="button-group_header">
                    <div className="button-group_header-item">Payment sources</div>
                    <div className="button-group_header-item">Amount</div>
                  </div>
                  <div className="button-group_body">
                    {paymentBonusSource.selectedSource && isPayment && !!rewards.pointsToUse.length && 
                  (
                    <RewardsButton
                      paymentCurrency={paymentCurrency}
                      rewardsModalShown={rewardsModalShown}
                      onCloseModal={onCloseRewardsModal}
                      onClick={() => setRewardsModalShown(true)}
                      leftToSource={calcLeftToSource(props.sendMoney)}
                      paymentBonusSource={paymentBonusSource}
                      rewards={rewards}
                      setPoints={reward => {
                        props.setField('paymentBonusSource', {
                          ...paymentBonusSource,
                          selectedSource: reward,
                        });
                        onCloseRewardsModal();
                      }}
                    />
                  )
                    }
                    {accounts.map(account => (
                      <Button
                        color="white"
                        xSize="full"
                        ySize="xl"
                        disabled={balance(account.balance.ordinal, account.balance.decimal, account.balance.sign) < 0}
                        className="button-group_item -has-arrow -arrow-blue"
                        key={account.account_id}
                        onClick={() => showPaymentSourceModal(account.account_id, 'account')}
                      >
                        <div className="button_head">
                          <div className="button_content-primary">
                            {account.name ? account.name : account.balance.currency} Account
                          </div>
                          <div className="button_content-secondary">
                              Balance:&nbsp;
                            {currencyFormat(balance(account.balance.ordinal, account.balance.decimal, account.balance.sign))}&nbsp;
                            {account.balance.currency + '\n'}
                            {calcBalance(
                              paymentCurrency,
                              account.balance.currency,
                              account.balance.ordinal,
                              account.balance.decimal,
                              transactionRate,
                            )}
                          </div>
                        </div>
                        <div className="button_tail nowrap">
                          {showPaymentSourceAmount(account)}
                        </div>
                      </Button>
                    ))}
                    {banks.map(bank => (
                      <Button
                        color="white"
                        xSize="full"
                        ySize="xl"
                        className="button-group_item -has-arrow -arrow-blue"
                        disabled={maxAchAllowed <= 0 || !hasPermission(permissions, 'can_transfer')}
                        key={bank.bank_account_id}
                        onClick={() =>
                          (maxAchAllowed > 0 && hasPermission(permissions, 'can_transfer'))
                          && showPaymentSourceModal(bank.bank_account_id, 'bank')
                        }
                      >
                        <div className="button_head">
                          <div className="button_content-primary">Bank Account</div>
                          <div className="button_content-secondary">
                            {bank.user_description || 'Account Number'}:
                            {' '}
                            <span className="nowrap">
                              <span className="account-number-bullets">&bull;&bull;</span>{bank.account_number}
                            </span>
                          </div>
                        </div>
                        <div className="button_tail nowrap">
                          {paymentSourceAmount(paymentSource, bank.bank_account_id)}&nbsp;{paymentCurrency}
                        </div>
                      </Button>
                    ))}
                  </div>
                </div>
              )
            }

            {!validate() && (
              <div className="layer -space-up-lg text-align-center">
                <span className="font-weight-bold font-size-secondary-responsive">
                  {withCurrency(currencyFormat(calcLeftToSource(
                    props.sendMoney, null, !isDefferedPaymentAllowed && activeTab === 'BANK'
                  )), paymentCurrency)} more required
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        {isPayment ? (
          <Button
            xSize="full"
            transparency="full"
            className="page_control -cancel"
            onClick={props.cancelOrderPayment}
          >
              Choose other payment method
          </Button>
        ) : (
          <Button
            xSize="full"
            transparency="full"
            className="page_control -cancel"
            to="/dashboard"
          >
              Cancel
          </Button>
        )}
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit"
          disabled={
            !validate() ||
              !hasPermission(permissions, 'resides_in_allowed_state') ||
              (!hasPermission(permissions, 'can_send_money') && !isPayment) ||
              (!isPayment && parseFloat(permissionAllowedLimit(permissions, 'max_send_amount')) < paymentAmount) ||
              (isPayment && parseFloat(permissionAllowedLimit(permissions, 'max_order_amount')) < paymentAmount)
          }
          loading={isLoading}
          onClick={confirmation}
        >
          {isPayment ? 'Payment Confirmation' : 'Next'}
        </Button>
      </div>
      <PaymentSourceModal
        show={showPaymentSource}
        accountId={paymentAccount}
        accountType={paymentAccountType}
        onSave={onFieldChange('paymentSource')}
        onClose={onCloseModal}
      />

      <OrderDetailsModal
        show={showOrderDetails}
        onClose={toggleOrderDetailsModal}
        closeButton
        details={attributes || []}
      />
    </Fragment>
  );
};

SendMoneySourceConfig.propTypes = {
  setField: PropTypes.func,
  sendMoney: PropTypes.shape(types.sendMoneyReducerTypes),
  clearSearchedWallet: PropTypes.func,
  cancelOrderPayment: PropTypes.func,
  wallet: PropTypes.shape(types.walletReducerTypes),
  permissions: types.EnabledStatesShape,
  user: PropTypes.shape(types.userReducerTypes),
  rewards: types.userReducerTypes.rewards,
  isBankAccountsDisabled: PropTypes.bool
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  wallet: state.wallet,
  permissions: state.user.user.enabled_states,
  rewards: state.user.rewards,
  user: state.user,
  isBankAccountsDisabled: canUseACHSelector(state)
});

export default connect(mapStateToProps, {
  setField,
  clearSearchedWallet,
  cancelOrderPayment,
  setPaymentPopup,
})(SendMoneySourceConfig);
