import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { RatesShape, AccountShape, BankAccountShape } from '../../constants/prop-types';
import * as types from '../../constants/prop-types';
import { isEmpty, path } from 'ramda';

import Button from '../button';
import Svg from '../svg';

import { hasPermission, permissionAllowedLimit } from '../../utils/permissions';
import { calcBalance, withCurrency, balance } from '../../utils/send-money-utils';


const renderAlertBadge = (permissions, isDestAccount) => {
  const maxAchAllowed = permissionAllowedLimit(permissions, 'max_ach_allowed');
  if (maxAchAllowed <= 0) {
    return true;
  }
  if (!hasPermission(permissions, 'can_withdraw_from_br') && isDestAccount) {
    return true;
  }
};

export const getAccountBalance = (account, transactionRate) => {
  return account.balance.currency !== 'USD' && !isEmpty(transactionRate) && !!path([account.balance.currency, 'USD'], transactionRate) ? calcBalance(
    'USD',
    account.balance.currency,
    account.balance.ordinal,
    account.balance.decimal,
    transactionRate,
  ) : withCurrency(
    balance(
      account.balance.ordinal,
      account.balance.decimal,
      account.balance.sign,
    ), 'USD'
  );
};

const choosenAccount = (isLoading, account, transactionRate, permissions, isDestAccount) => {
  const prefix = isDestAccount ? 'To' : 'From';

  if (!isEmpty(account)) {
    return account.type ? (
      <div className="button_head">
        <div className="button_content-primary">
          {prefix} {account.name} Account
        </div>
        <div className="button_content-secondary">
          Balance:&nbsp;{getAccountBalance(account, transactionRate)}
        </div>
      </div>
    ) : (
      <div className="button_head">
        <div className="button_content-primary">
          {prefix} Bank Account&nbsp;
          {renderAlertBadge(permissions, isDestAccount) && (
            <div className="label -danger">!</div>
          )}
        </div>
        <div className="button_content-secondary">
          {account.user_description || 'Account Number'}:&nbsp;
          <span className="account-number-bullets">&bull;&bull;</span>
          {account.account_number}
        </div>
      </div>
    );
  }
  return (
    <div className="button_head">
      <div className="button_content-primary">
        { prefix }
      </div>
    </div>
  );
};

const TransferSelector = (props) => {
  const {
    children,
    sourceAccount,
    destinationAccount,
    toggleDropdown,
    isLoading,
    transactionRate,
    permissions,
    currencyInputDisabled,
  } = props;

  let isDestAccount = false;

  return (
    <div className="layer full-width -relative">
      <Button
        color="white"
        xSize="full"
        ySize="xl"
        className={cn(
          'button -has-arrow -relative -arrow-down'
        )}
        disabled={isLoading || (currencyInputDisabled && !hasPermission(permissions, 'can_deposit_to_br'))}
        onClick={() => toggleDropdown('top')}
      >
        {choosenAccount(isLoading, sourceAccount, transactionRate, permissions, isDestAccount)}
      </Button>
      <div className="button-group_hint -transfer">
        <Svg name="arrow-down-small" />
      </div>
      <Button
        color="white"
        xSize="full"
        ySize="xl"
        className={cn(
          'button -has-arrow -relative -arrow-down'
        )}
        disabled={isLoading || currencyInputDisabled}
        onClick={() => toggleDropdown('bottom')}
      >
        {choosenAccount(
          isLoading,
          destinationAccount,
          transactionRate,
          permissions,
          isDestAccount = true,
        )}
      </Button>
      {children}
    </div>
  );
};

TransferSelector.propTypes = {
  children: PropTypes.node,
  sourceAccount: PropTypes.oneOfType([BankAccountShape, AccountShape]),
  destinationAccount: PropTypes.oneOfType([BankAccountShape, AccountShape]),
  toggleDropdown: PropTypes.func,
  transactionRate: RatesShape,
  isLoading: PropTypes.bool,
  permissions: types.EnabledStatesShape,
  currencyInputDisabled: PropTypes.bool,
};

export default TransferSelector;
