import Decimal from 'decimal.js';
import { path, find, propEq, prop } from 'ramda';
import qs from 'qs';
import store from '../init-store';
import { withCurrency } from '../utils/send-money-utils';

export const formatFunc = value => value.replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const pluralize = (word, count) => {
  switch (count) {
    case 0: return `${word}s`;
    case 1: return `${word}`;

    default: return `${word}s`;
  }
};

export const formatErrorMessage = error => !error.detail
  ? `There was an error: ${error.message}.`
  : `There was an error: ${error.message}. ${error.detail}.`;

export const formatMaskedEmail = value => value && value.replace(/\*/gi, '·');

export const formatPhoneNumber = value => {
  if (value) {
    let cleaned = ('' + value).replace(/\D/g, '');

    let match;

    if (process.env.REACT_APP_APPLICATION_ENV !== 'development') {
      match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    } else {
      match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    }

    if (match) {
      if (process.env.REACT_APP_APPLICATION_ENV !== 'development') {
        return `(${match[1]}) ${match[2]} - ${match[3]}${match[4]}`;
      } else {
        return `+${match[1]} (${match[2]}) ${match[3]} - ${match[4]}${match[5]}`;
      }
    }
  }

  return 'Can`t format';
};

export const formatTransaction = ({ sign, ordinal, decimal, currency }) => {
  return `${sign}${withCurrency(Decimal([ordinal, decimal].join('.')).toFixed(2, 1), currency)}`;
};

export const formatTransactionByAccount = (transaction, accountId) => {
  const sources = path(['transaction_details', 'source'])(transaction);
  const destinations = path(['transaction_details', 'destination'])(transaction);
  const account = find(propEq('account_id', accountId))(sources) || find(propEq('account_id', accountId))(destinations);

  return account && account.account_amount 
    ? formatTransaction({ ...prop('account_amount')(account) })
    : '';
};

export const formatNumberUSD = (value) => {
  if (isNaN(value)) {
    return 0;
  }

  return formatFunc(Decimal.div(value, 100).toFixed(2, 1));
};

export const capitalize = ([first, ...rest]) => first.toUpperCase() + rest.join('').toLowerCase();

export const isZeroValue = ({ ordinal = 0, decimal = 0 }) =>
  Decimal([ordinal, decimal].join('.')).isZero(0);

export const getLocaleThousandSeparator = () => {
  const num = 1000.1;
  return num.toLocaleString().substring(1, 2);
};

export const getLocaleDecimalSeparator = () => {
  const num = 1000.1;
  return num.toLocaleString().substring(5, 6);
};

export const getSafe = (fn) => {
  try {
    return fn();
  } catch (e) {
    return '';
  }
};

export const getStoreItem = (item) => {
  const currentState = store.getState();
  switch (item) {
    case 'wallet':
      return path(['wallet', 'wallet'], currentState);
    case 'walletID':
      return path(['wallet', 'wallet', 'wallet_id'], currentState);
    case 'user':
      return path(['user', 'user'], currentState);
    case 'userID':
      return path(['user', 'user', 'user_id'], currentState);
    default:
      return currentState;
  }
};

export const formatTime = time => {
  if (time < 10) {
    return '0' + time;
  }

  return time;
};

export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gm;

export const qsParse = (queryString, options) => {
  let query = qs.parse(queryString, options);

  if(query.from && query.from.includes('?')) {
    const { from, ...queryWithoutFrom } = query;
    query = {
      from: `${from.slice()}&${qs.stringify(queryWithoutFrom)}`
    };
  }

  return query;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const luhnCheck = num => {
  const arr = num.replaceAll(' ', '')
    .replaceAll('_', '')
    .split('')
    .reverse()
    .map(x => parseInt(x, 10));
  
  const lastDigit = arr.shift();
  let sum = arr.reduce(
    (acc, val, i) => (i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val)),
    0
  );

  sum += lastDigit;

  return sum % 10 === 0;
};

export const formatExpiration = (expiration, onlyForUI) => {
  if(expiration && expiration !== '__ / __') {

    if(expiration.includes('-')) {
      return `${expiration.split('-')[1]} / ${expiration.split('-')[0].slice(2)}`;
    } else if (expiration.includes('/') && !onlyForUI) {
      return `${expiration.split('/')[0].trim()}-20${expiration.split('/')[1].trim()}`;
    }
  }

  return expiration;
};