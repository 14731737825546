import { assoc, assocPath, compose, sort, merge, isEmpty } from 'ramda';
import CONST from '../constants/loan-offer-constants';

const INIT_STATE = {
  currentOffer: {},
  step: CONST.LOAN_CONFIGURATION,
  acceptedAmount: undefined,
  errorMessage: null,
  isLoading: false,
  isChecking: false,
  waitingApproval: false,
  approvedLoan: null,
};

const loanOfferReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assoc(action.payload.field, action.payload.value, state);
    case CONST.GET_LOAN_OFFER:    
      return assocPath(['isLoading'], true, state);
    case CONST.ACCEPT_LOAN_OFFER:
      return assocPath(['waitingApproval'], true, state);
    case CONST.CHECK_LOAN_OFFER:
      return assocPath(['isChecking'], true, state);
    case CONST.SET_LOAN_OFFER:
      const sortedRates = action.payload.loanOffer.rates
        ? sort((f, s) => f.term - s.term, action.payload.loanOffer.rates)
        : [];    
      const currentOffer = !isEmpty(action.payload.loanOffer)
        ? merge(action.payload.loanOffer, { rates: sortedRates })
        : {};
      return compose(
        assocPath(['currentOffer'], currentOffer),
        assocPath(['isChecking'], false),
        assocPath(['isLoading'], false),
      )(state);
    case CONST.CLEAR_LOAN_OFFER:
      return INIT_STATE;  
    default:
      return state;
  }
};

export default loanOfferReducer;
