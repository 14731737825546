import React from 'react';
import PropTypes from 'prop-types';

import LoginLayout from '../../pages/log-in-layout';
import Button from '../button';
import CONST from '../../constants/login-constants';
import Checkbox from '../checkbox';

const LoginStepConsent = (props) => {
  const { login, setField, endStep } = props;

  return (
    <LoginLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <div className="js-page-title splash_title">User Agreement Has Been Updated</div>
          </div>
          <div className="layer -space-up-xxl">
            <p className="font-size-secondary-responsive color-light-gray">
                Prior to granting you access to buy and sell with {process.env.REACT_APP_DOMAIN_STRING}, we require that you agree to our&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
              >
                    User Agreement
              </a>.
                    We take your information and privacy very seriously.
                    We do not share your information to third parties unless you have given us your consent.
                    Please contact&nbsp;
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
                  Customer Support
              </a>&nbsp;if you need assistance.
            </p>
          </div>
          <div className="layer -space-up-md -space-down-xxl">
            <Checkbox
              inputName="terms-and-condition"
              label={
                <span className="color-light-gray">
                      I agree with the
                </span>
              }
              className="-white -bordered"
              checked={login.agreement}
              onChange={({ target: { checked } }) => setField('agreement', checked)}
            />
                &nbsp;<a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            >
                  User Agreement
            </a>
          </div> 
        </div>
      </div>
      <div className="page_controls">
        <Button
          xSize="full"
          transparency="full"
          onClick={() => setField('step', CONST.STEP_EMAIL_PASSWORD)}
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
            Back to Login
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          disabled={!login.agreement}
          onClick={() => endStep(CONST.STEP_CONSENT)}
        >
            Continue
        </Button>
      </div>
    </LoginLayout>
  );
};

LoginStepConsent.propTypes = {
  endStep: PropTypes.func,
  setField: PropTypes.func,
  login: { 
    agreement: PropTypes.bool
  }
};

export default LoginStepConsent;
