import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from '../button';
import Modal from '../modal';
import FormInput from '../form-input';
import { CreditCardShape } from '../../constants/prop-types';

const CreditCardEdit = ({
  isLoading,
  cardDetails: {
    account_number,
    verification_code,
    expiration,
    holder_fname,
    holder_lname,
  },
  containerClassName,
  updateCard,
  validationErrors,
  setValidationError,
  setField,
  ...props
}) => (
  <Modal {...props} containerClassName={cn('-white', containerClassName)}>
    <div className="modal_header">
      <h1 className="js-modal-title">Update card details</h1>
    </div>
    <div className="modal_body">
      <form className="form" action="">
        <div className="layer full-width">
          <div className="form-input-container">
            <FormInput
              color="white"
              inputName="account_number"
              label="Card Number"
              value={`••${account_number}`}
              disabled={true}
              className="-disabled"
            />
          </div>
        </div>
        <div className="layer full-width">
          <div className="form-input-container">
            <FormInput
              color="white"
              inputName="holder-fname"
              label="Name on card"
              value={`${holder_fname} ${holder_lname}`}
              disabled={true}
              className="-disabled"
            />
          </div>
        </div>
        <div className="layer full-width">
          <div className="form-input-container">
            <FormInput
              autoFocus={true}
              color="white"
              inputName="expiration"
              label="Valid through"
              onChange={value => {
                setValidationError('expiration', '');
                setField(['cardDetails','expiration'], value);
              }}
              value={expiration}
              errorMessage={validationErrors.expiration}
              invalid={!!validationErrors.expiration}
              disabled={isLoading}
              mask="99 / 99"
            />
          </div>
        </div>

        <div className="layer full-width">
          <div className="form-input-container">
            <FormInput
              color="white"
              inputName="verification_code"
              label="Security code"
              onChange={value => {
                setValidationError('verification_code', '');
                setField(['cardDetails','verification_code'], value);
              }}
              value={verification_code}
              errorMessage={validationErrors.verification_code}
              invalid={!!validationErrors.verification_code}
              disabled={isLoading}
              mask="9999"
              hideMask={true}
              type="password"
            />
          </div>
        </div>
      </form>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          xSize="full"
          transparency="full"
          className="js-cancel-button modal_footer-control -cancel"
          disabled={isLoading}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button modal_footer-control -submit"
          onClick={() => updateCard()}
          disabled={isLoading || validationErrors.expiration || validationErrors.verification_code || !expiration || !verification_code}
          loading={isLoading}
        >
            Update Card
        </Button>
      </div>
    </div>
  </Modal>
);

CreditCardEdit.propTypes = {
  cardDetails: CreditCardShape,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  containerClassName: PropTypes.string,
  toggleEditCardLabelModal: PropTypes.func,
  setAsDefault: PropTypes.func,
  updateCard: PropTypes.func,
  setValidationError: PropTypes.func,
  validationErrors: PropTypes.shape({
    expiration: PropTypes.string,
    verification_code: PropTypes.string
  }),
  setField: PropTypes.func
};

CreditCardEdit.defaultProps = {
  cardDetails: {
    account_number: null,
    bank_name: null,
    routing_number: null,
    amounts_verified: false,
    withdraw_status: 'pending',
    user_description: null,
  },
  containerClassName: null,
  isLoading: false,
  onClose: () => {},
  updateCard: () => {},
  setField: () => {},
  validationErrors: {}
};

export default CreditCardEdit;
