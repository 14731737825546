import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EnabledStatesShape } from '../constants/prop-types';
import Modal from './modal';
import Button from './button';
import { formatNumberUSD } from '../utils/string-utils';
import { setPaymentPopup } from '../actions/app-actions';

const TransactionInfoModal = ({ states, isOpened, isPaymentSource, setPaymentPopup }) => {
  const getLimit = (amount = 0) => `$${formatNumberUSD(amount)}`;

  return (
    <Modal higher show={isOpened} onClose={() => setPaymentPopup(false)} closeButton>
      <div className="modal_header">
        {isPaymentSource ? 
          <Fragment>
            <Button
              className="-has-arrow-before -no-border"
              color="blue"
              transparency="full"
              xSize="condensed-full"
              ySize="min"
              onClick={() => setPaymentPopup(false)}
            >
              <div className="button_head">
                Payment Source
              </div>
            </Button>
            <div className="layer -space-up-xxl">
              <h1 className="js-modal-title">Transactions limits</h1>
            </div>
          </Fragment>
          :
          <h1 className="js-modal-title">Transactions limits</h1>
        }
      </div>
      <div className="modal_body">
        <div className="layer form_row">
          <h4>Daily maximum</h4>
          <p>
            {getLimit(states.max_daily_send_amount)} total for transactions
            performed during a single day. All types of
            transactions are accounted for in total.
          </p>
        </div>
        <div className="layer form_row -space-up-xxl">
          <h4>Transfer to or from a bank account</h4>
          { !states.can_transfer.value || states.max_ach_allowed.value === 0
            ? (<p>
              Tranfers are not allowed.
            </p>)
            : (<p>
              {getLimit(states.max_ach_allowed && states.max_ach_allowed.value)} for a single transaction
              involving a bank acccount.
            </p>)
          }
        </div>
        <div className="layer form_row -space-up-xxl">
          <h4>Maximum for sending funds</h4>
          {
            !states.can_send_money.value || states.max_send_amount === 0
              ? (<p>
              Sending funds is not allowed.
              </p>)
              : (<p>
                {getLimit(states.max_send_amount)} total for a single
              transaction to other {process.env.REACT_APP_DOMAIN_STRING} users.
              </p>)
          }
        </div>
        <div className="layer form_row -space-up-xxl">
          <h4>Maximum order value</h4>
          {
            !states.can_order || states.max_order_amount === 0
              ? (<p>
              Order payments are not allowed.
              </p>)
              : (<p>
                {getLimit(states.max_order_amount)} for a single order payment.
              </p>)
          }
        </div>
      </div>
      <div className="modal_footer">
      </div>
    </Modal>
  );
};

TransactionInfoModal.propTypes = {
  states: EnabledStatesShape,
  isOpened: PropTypes.bool,
  isPaymentSource: PropTypes.bool,
  setPaymentPopup: PropTypes.func,
};

const mapStateToProps = state => {
  const states = state.user.user.enabled_states || {};

  const {
    max_daily_send_amount,
    max_ach_allowed,
    max_send_amount,
    max_order_amount,
    can_transfer,
    can_send_money,
    can_order,
  } = states;

  return {
    isOpened: state.application.paymentPopup.isOpened,
    isPaymentSource: state.application.paymentPopup.isPaymentSource,
    states: {
      max_daily_send_amount,
      max_ach_allowed: max_ach_allowed || {},
      max_send_amount,
      max_order_amount,
      can_send_money: can_send_money || {},
      can_transfer: can_transfer || {},
      can_order: can_order || {},
    },
    rates: state.sendMoney.transactionRates || {},
  };
};

export default connect(mapStateToProps, { setPaymentPopup })(TransactionInfoModal);
