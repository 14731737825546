import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST from '../../constants/login-constants';
import { setField, endStep, sendOtp, resendOtp } from '../../actions/login-actions';
import { formatTime } from '../../utils/string-utils';

const LoginStepOTP = ({ setField, endStep, login, resendOtp }) => {

  const { resendTimer, securityCodeError, isLoading } = login;

  const [ minutes, setMinutes ] = useState(-1);
  const [ seconds, setSeconds ] =  useState(-1);

  useEffect(() => {
    setField('resendTimer', CONST.OTP_RESEND_TIMER);
  }, []);

  useEffect(() => {
    if(!!resendTimer) {
      const startTime = new Date().getTime();
      let timer = setInterval(() => {
        const currentTime = new Date().getTime();
        const _minutes = minutes < 0 ? parseInt(resendTimer / 60, 10) : minutes;
        const _seconds = seconds < 0 ? resendTimer % 60 : seconds;
        const diff = Math.ceil((currentTime - startTime)/1000);
        const secondsLeft = Math.floor((_minutes * 60 + _seconds - diff) % 60);
        const minutesLeft = Math.floor((_minutes * 60 + _seconds - diff) / 60);

        if (startTime + resendTimer * 1000 - currentTime <= 0) {
          setField('resendTimer', false);
          clearInterval(timer);
        }

        setMinutes(minutesLeft);
        setSeconds(secondsLeft);

       
      }, 100);

      return () => {
        setField('resendTimer', false);
        clearInterval(timer);
      };

    }
  }, [resendTimer]);

  const onSubmit = (e) => {
    e.preventDefault(); 
    if((!securityCodeError && login.securityCode && login.securityCode.replace('_', '').length > 6) || isLoading) {
      endStep(CONST.STEP_OTP);
    }
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
    >
      <div className="page_header -space-sm">
        <h1 className="js-page-title page_title">Enter security code</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={onSubmit}>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                We sent you a security code to your phone and email
              </p>
            </div>

            <div className="layer -space-down-xxl ">
              <div className="form-input-container">
                <FormInput
                  transparency="half"
                  className="full-width"
                  label="Security code"
                  type="tel"
                  inputName="security-code"
                  value={login.securityCode || ''}
                  onChange={(value) => {
                    setField('securityCodeError', '');
                    setField('securityCode', value);
                  }}
                  hideMask={true}
                  errorMessage={securityCodeError}
                  invalid={!!securityCodeError}
                  mask="99999999"
                  autoFocus
                />
              </div>
            </div>

            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                <Button
                  transparency="full"
                  ySize="min"
                  className="js-cancel-button button-clean page_control -no-border -cancel font-weight-bold"
                  onClick={resendOtp}
                  disabled={!!resendTimer}
                >
                  {!resendTimer || minutes < 0 || seconds < 0 ? 'Resend the code' : `You can resend the code in ${formatTime(minutes)}:${formatTime(seconds)}`}
                </Button>
              </p>
              <p className="color-light-gray">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_HELP_DESK_URL}
                >
                  Can’t access the code?
                </a>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          onClick={() => { setField('step', CONST.STEP_EMAIL_PASSWORD);  }}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          disabled={!!securityCodeError || !login.securityCode || login.securityCode.replace('_', '').length < 6}
          className="js-submit-button page_control -submit"
          loading={login.isLoading}
          onClick={() => endStep(CONST.STEP_OTP)}
        >
          Continue
        </Button>
      </div>
    </SignupLayout>
  );
};

LoginStepOTP.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  resendOtp: PropTypes.func,
  login: PropTypes.shape({
    securityCode: PropTypes.string,
    isLoading: PropTypes.bool,
    securityCodeError: PropTypes.string,
    resendTimer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  })
};

const mapStateToProps = (state) => ({
  login: state.login
});

export default connect(mapStateToProps, {
  setField,
  endStep,
  sendOtp,
  resendOtp,
})(LoginStepOTP);
