import { assocPath, keys, compose } from 'ramda';
import CONST from '../constants/bank-account-constants';

const INIT_STATE = {
  isLoading: false,
  bankAccountRouting: '',
  bankAccountNumber: '',
  bankAccountLabel: '',
  bankAccountType: 'checking',
  bankAccountClass: 'individual',
  validationErrors: {
    bankAccountClass: null,
    bankAccountRouting: null,
    bankAccountNumber: null,
    bankAccountLabel: null,
    bankAccountType: null,
    bankAccountIsUniq: null,
  },
  yodleeUrl: '',
  yodleeJWT: '',
  yodleeServiceId: '',
  bankAccountCreated: false,
  yodleeModalShown: false,
  isYodleeDisabled: false,
  configName: '',
  errorMessage: null,
};

const bankAccountReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.message, state);
    case CONST.SET_FIELD:
      if (keys(INIT_STATE.validationErrors).indexOf(action.payload.field) !== -1) {
        return compose(
          assocPath([action.payload.field], action.payload.value),
          assocPath(['validationErrors', action.payload.field], null),
          // APPS-815: Account uniqueness validation
          assocPath(['validationErrors', 'bankAccountIsUniq'],
            ['bankAccountNumber', 'bankAccountRouting'].indexOf(action.payload.field) !== -1
              ? null
              : state.validationErrors.bankAccountIsUniq
          ),
        )(state);
      }
      return assocPath([action.payload.field], action.payload.value, state);
    case CONST.CLEAR_FORM:
      return INIT_STATE;

    default:
      return state;
  }
};

export default bankAccountReducer;
