import React from 'react';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST from '../../constants/sign-up-constants';

const SignupStepSSN = ({ setField, endStep, setValidationError, signUp }) => (
  <SignupLayout
    headerClassName="-y-lg"
    wizardStep={2}
  >
    <div className="page_header">
      <h1 className="js-page-title page_title">Identity Verification</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <form className="form" onSubmit={(e) => { e.preventDefault(); endStep(CONST.STEP_SSN); }}>
          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              Please provide the last four digits of your social security number to verify your identity.
            </p>
          </div>
          <div className="layer -space-down-xxl ">
            <div className="form-input-container">
              <FormInput
                type="tel"
                transparency="half"
                className="full-width"
                label="Last 4 digits of SSN"
                inputName="ssn"
                value={signUp.ssnDigits}
                onChange={value => {
                  setValidationError('phoneNumber', '');
                  setField('ssnDigits', value);
                }}
                errorMessage={signUp.validationErrors.ssnDigits}
                invalid={!!signUp.validationErrors.ssnDigits}
                mask="9999"
                autoFocus
              />
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="page_controls">
      <Button
        color="dark-gray"
        xSize="full"
        className="page_control -no-border -cancel font-weight-bold"
        transparency="full"
        onClick={() => setField('step', CONST.STEP_VERIFICATION_SELECT)}
      >
        Choose another verification method
      </Button>
      <Button
        color="blue"
        xSize="full"
        className="js-submit-button page_control -submit"
        loading={signUp.isLoading}
        disabled={!!signUp.validationErrors.ssnDigits}
        onClick={() => endStep(CONST.STEP_SSN)}
      >
      Continue
      </Button>
    </div>
  </SignupLayout>
);

SignupStepSSN.propTypes = {
  setField: PropTypes.func,
  endStep: PropTypes.func,
  setValidationError: PropTypes.func,
  signUp: PropTypes.shape({
    ssnDigits: PropTypes.string,
    agreement: PropTypes.bool,
    isLoading: PropTypes.bool,
    validationErrors: PropTypes.shape({
      ssnDigits: PropTypes.string,
    }),
  }),
};

export default SignupStepSSN;
