import React from 'react';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import { signUpReducerTypes } from '../../constants/prop-types';
import CONST from '../../constants/sign-up-constants';
import Loader from '../loader';

const VerificationService = ({ onClick, description, isLoading }) => description ? (
  <div className="layer -space-down-xxl font-size-secondary-responsive">
    <h2 className="verification-service-title">{description.header}</h2>
    <p className="color-light-gray">{description.text}</p>
    <Button
      color="blue"
      xSize="full"
      className="s-submit-button page_control -submit"
      onClick={onClick}
      disabled={isLoading}
      loading={isLoading}
    >
      {description.label.toUpperCase()}
    </Button>
  </div>
) : null;

VerificationService.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  description: PropTypes.shape({
    header: PropTypes.string,
    text: PropTypes.string,
    label: PropTypes.string
  }),
};

const SERVICES_DESCRIPTION = {
  html: {
    header: 'Photo ID pictures in app',
    text: 'Instant verification providing pictures of your photo ID in app',
    label: 'Verify instantly with photo ID'
  },
  last4: {
    header: 'Phone and SSN number',
    text: 'Instant verification with your phone number and last 4 digits of your SSN number',
    label: 'Verify instantly with phone and SSN'
  },
  support: {
    header: 'Email documents to support',
    text: 'Email scanned documents to Support, which may take several business days to verify',
    label: 'Verify manually'
  }
};

const SignupStepVerificationSelect = ({ signUp, setField, endStep, signUp: { isLoading } }) => {
  const toPhoneVerification = (e) => { 
    e.preventDefault();
    setField('step', CONST.STEP_PHONE_NUMBER);
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
      bgClassName="-full"
      wrapperClassName="-x-sm"
      wizardStep={1}
    >
      <div className="page_header -space-sm -with-sub">
        <h1 className="js-page-title page_title">Please verify your identity</h1>
        <p className="page_subtitle">
          In order to be able to complete payments with BitRail Digital Wallet, you need to verify your identity. Please choose verification method below.
        </p>
      </div>
      <div className="page_body">
        <div className="page_content">
          {Array.isArray(signUp.verificationServices) ? signUp.verificationServices
            .sort((a, b) => a.priority - b.priority)
            .map((service) => (
              <VerificationService
                key={`service-${service.id}`}
                isLoading={isLoading}
                onClick={() => {
                  setField('chosenVerificationService', service);
                  endStep(CONST.STEP_VERIFICATION_SELECT);
                }}
                description={SERVICES_DESCRIPTION[service.form]}
              />
            )) : (
            <div className="signup__loader-wrapper">
              <Loader size="md" color="gray" />    
            </div>
          )}
          {!signUp.phoneNumber && !signUp.lastPhoneDigits ? (
            <p>
              For more verification methods&nbsp;
              <a className="cursor-pointer font-weight-bold" onClick={toPhoneVerification}>please verify your phone.</a>
            </p>
          ) : null}
        </div>
      </div>
      <div className="page_controls -flex-end">
        <Button
          color="dark-gray"
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          onClick={() => {
            setField('step', CONST.STEP_WALLET_TYPE);
          }}
        >
          Cancel
        </Button>
      </div>
    </SignupLayout>
  );
};

SignupStepVerificationSelect.propTypes = {
  signUp: PropTypes.shape(signUpReducerTypes),
  setField: PropTypes.func, 
  endStep: PropTypes.func,
};

export default SignupStepVerificationSelect;