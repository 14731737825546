import { assoc, reject, prop, append, pipe } from 'ramda';
import CONST from '../constants/toast-constants';

const INIT_STATE = {
  notifications: [],
};

const toastReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.ADD_NOTIFICATION:
      if (action.payload.code !== 500 && action.payload.code !== 401) {
        return assoc(
          'notifications',
          append(
            action.payload,
            state.notifications,
          ), state,
        );
      } else {
        return assoc('notifications',
          pipe(
            reject(
              notif => notif.code === action.payload.code && notif.id !== action.payload.id,
            ),
            append(action.payload),
          )(state.notifications)
        )(state);
      }

    case CONST.CLEAN_NOTIFICATIONS:
      return assoc('notifications', INIT_STATE.notifications, state);

    case CONST.REMOVE_NOTIFICATION:
      return assoc('notifications', reject(
        ({ id }) => id === action.payload.id,
        prop('notifications', state),
      ), state);

    default:
      return state;
  }
};

export default toastReducer;
