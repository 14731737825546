import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Badge = ({ children, className, status, onClick }) => (
  <div className={cn('badge', { [`-${status}`]: status }, className)} onClick={onClick}>
    {children}
  </div>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  status: PropTypes.oneOf(['success', 'alert', 'danger', 'pending']),
  onClick: PropTypes.func,
};

Badge.defaultProps = {
  className: null,
  status: null,
  onClick: () => {},
};

export default Badge;
