import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import { currencyFormat, balance } from '../utils/send-money-utils';
import Badge from './badge';
import { history } from '../init-store';
import { Decimal } from 'decimal.js';
import { reduce, filter, path } from 'ramda';
import { getStoreItem } from '../utils/string-utils';
import { userBalanceSelector } from '../reducers/wallet-reducer';
import { permissionSelector } from '../reducers/user-reducer';
import { hasPermission } from '../utils/permissions';
import CONST from '../constants/transactions-constants';
import { TransactionShape, EnabledStatesShape } from '../constants/prop-types';

const NegativeBalanceBadge = ({ userBalance, transactionsPending = { data: [] }, withRedirect = false, permissions }) => {
  const canDepositToBr = hasPermission(permissions, 'can_deposit_to_br');
  const onClick = withRedirect && canDepositToBr ? () => { history.push('/transfer'); } : null;
  const pendingAmount = reduce(
    (acc, value) => {
      acc = acc.plus(balance(value.net_amount.ordinal, value.net_amount.decimal, value.net_amount.sign));

      return acc;
    },
    new Decimal(0),
    filter(
      (transaction) => 
        path(['destination', 'wallet_id'], transaction) === getStoreItem('walletID') &&
        path(['transaction_amount', 'sign'], transaction.transaction_details.all.find((detail) => detail.account_type === 'account')) === CONST.PLUS,
      transactionsPending.data 
    )
  ).toFixed(2,3);

  if (userBalance < 0) {
    if (Decimal(userBalance).plus(pendingAmount).greaterThanOrEqualTo(0)) {
      return (
        <Badge
          status="danger"
          className={cn('layer -space-down-lg full-width')}
        >
            Your negative balance will be resolved once funds transfer is complete.
        </Badge>
      );
    } 

    if(canDepositToBr) {
      return (
        <Badge
          status="danger"
          className={cn('layer -space-down-lg full-width', !!onClick && 'cursor-pointer -clickable')}
          onClick={onClick}
        >
            Add&nbsp;
          <b>
              ${
              currencyFormat(Decimal(userBalance).abs())
            }
          </b>&nbsp;to your account to continue using the system
        </Badge>
      );
    } else {
      return (
        <Badge
          status="danger"
          className={cn('layer -space-down-lg full-width')}
        >
          To resolve your negative balance, please&nbsp;
          <a 
            target="_blank"
            rel="noopener noreferrer"
            href={process.env.REACT_APP_HELP_DESK_URL}>
              contact support
          </a> as soon as possible
        </Badge>
      );
    }

  } else {
    return null;
  }
};

NegativeBalanceBadge.propTypes = {
  userBalance: PropTypes.string,
  transactionsPending: PropTypes.shape({
    data: PropTypes.arrayOf(TransactionShape),
    isLoading: PropTypes.bool
  }),
  withRedirect: PropTypes.bool,
  permissions: EnabledStatesShape
};

function mapStateToProps(state) {
  return {
    accounts: state.wallet.accounts,
    transactionsPending: state.transactions.transactionsPending,
    userBalance: userBalanceSelector(state),
    permissions: permissionSelector(state)
  };
}

export default connect(mapStateToProps)(NegativeBalanceBadge);

