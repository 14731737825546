import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import stickybits from 'stickybits';

export const createStickybitsComponent = (selector, opts) => {
  const Stickybits = (props) => {
    const stickyBitsInstance = useRef(null);
    const updateStickyBitsInstance = () => {
      stickyBitsInstance.current.update();
    };
    useEffect(() => {
      stickyBitsInstance.current = stickybits(selector, opts);
      window.addEventListener('resize', updateStickyBitsInstance);

      return () => {
        stickyBitsInstance.current.cleanup();
        window.removeEventListener('resize', updateStickyBitsInstance);
      };
    }, []);

    return props.children;
  };

  Stickybits.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return Stickybits;
};

const stickybitsHoc = (selector, opts) => {
  const Stickybits = createStickybitsComponent(selector, opts);

  return WrappedComponent => props => (
    <Stickybits>
      <WrappedComponent {...props} />
    </Stickybits>
  );
};

export default stickybitsHoc;
