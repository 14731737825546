import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputCurrency from 'react-currency-masked-input';
import { currencyFormat } from '../utils/send-money-utils';

const CurrencyInput = (props) => {
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    setFilled(!!props.value);
  }, []);

  useEffect(() => {
    setFilled(!!props.value);
  }, [props.value]);


  const onChange = (e, value) => {
    const { onChange } = props;
    setFilled(!!value);
    if (value.length < 17) {
      onChange(value, e);
    }
  };

  const onFocus = (e) => {
    const { onFocus, onChange, value } = props;
    if (!value) {
      onChange('0.00');
    }
    if (onFocus) {
      e.persist();
      onFocus(e);
    }
  };

  const onBlur = (e) => {
    const { onBlur } = props;
    if (onBlur) {
      e.persist();
      onBlur(e);
    }
  };

  const onKeyDown = (e) => {
    e.persist();
    const { onPressEnter } = props;
    if (onPressEnter && e.keyCode === 13) {
      onPressEnter(e);
    }
    const { onKeyDown } = props;
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const inputRef = props.inputRef ? props.inputRef : useRef(null);

  const {
    className, label, inputName, value, color, disabled, invalid, errorMessage, transparency, autoFocus, showLimits, onShowLimits,
  } = props;

  return (
    <div className={
      cn(
        'form-input -has-switch', {
          [`-${color}`]: !!color,
          [`-transparency-${transparency}`]: !!transparency,
          '-is-invalid': invalid,
        },
        className,
      )
    }
    >
      <InputCurrency
        ref={inputRef}
        className={cn('js-transfer-amount-input form-input_input', { '-filled': filled })}
        value={value ? currencyFormat(value) : null}
        type="tel"
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
      />
      <label htmlFor={inputName} className="form-input_label">{label}</label>
      {
        errorMessage &&
          <Fragment>
            <div className="form-input_message -error">
              {errorMessage} {
                showLimits && 
              <span onClick={onShowLimits} className="color-blue cursor-pointer">
                Show all limits
              </span>
              }
            </div>
          </Fragment>
      }
    </div>
  );
};

CurrencyInput.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['white', 'light-blue']),
  errorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  inputRef: PropTypes.func,
  autoFocus: PropTypes.bool,
  transparency: PropTypes.string,
  showLimits: PropTypes.bool,
  onShowLimits: PropTypes.func,
};

CurrencyInput.defaultProps = {
  className: null,
  color: null,
  errorMessage: null,
  disabled: false,
  invalid: false,
  label: null,
  value: null,
  transparency: null,
};

export default CurrencyInput;
