import React from 'react';
import PropTypes from 'prop-types';

import LoginLayout from '../../pages/log-in-layout';
import FormInput from '../form-input';
import Button from '../button';
import CONST, { PHONE_PROMPT_STATUSES } from '../../constants/login-constants';

const LoginStepPhoneNumber = ({ setField, setError, endStep, login }) => (
  <LoginLayout
    headerClassName="-y-lg"
  >
    <div className="page_header -space-sm">
      <h1 className="js-page-title page_title">Verify your Phone Number</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <form className="form" onSubmit={(e) => { e.preventDefault(); endStep(CONST.STEP_PHONE_NUMBER); }}>
          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              For your security, we need to make sure it&apos;s really you.
              We need your phone number to associate your account with 
              your mobile phone for security and identity verification.
              Please enter your mobile phone number to continue. 
            </p>
          </div>

          <div className="layer -space-down-xxl ">
            <div className="form-input-container">
              <FormInput
                transparency="half"
                className="full-width"
                label="Phone"
                type="tel"
                inputName="phone-number"
                value={login.phoneNumber || ''}
                onChange={(value) => {
                  setError('phoneNumber', '');
                  setField('phoneNumber', value.replace(/[-()+\s_]/gi, ''));
                }}
                errorMessage={login.errors.phoneNumber}
                invalid={!!login.errors.phoneNumber}
                mask={process.env.REACT_APP_APPLICATION_ENV !== 'development' ? '(999) 999-9999' : '+9 (999) 999-9999'}
                autoFocus
              />
            </div>
          </div>

          <div className="layer -space-down-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
              We will send an authentication link to your phone.
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_HELP_DESK_URL}
              >
                &nbsp;Need&nbsp;help?
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
    <div className="page_controls">
      {login.phonePrompt !== PHONE_PROMPT_STATUSES.FORCE
        && <Button
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          onClick={() => {
            setField('phoneNumber', null);
            endStep(CONST.STEP_PHONE_NUMBER);
          }}
        >
          Skip
        </Button>
      }
      <Button
        color="blue"
        xSize="full"
        disabled={!!login.errors.phoneNumber}
        className="js-submit-button page_control -submit"
        loading={login.isLoading}
        onClick={() => {
          endStep(CONST.STEP_PHONE_NUMBER);
        }}
      >
        Continue
      </Button>
    </div>
  </LoginLayout>
);

LoginStepPhoneNumber.propTypes = {
  setField: PropTypes.func,
  setError: PropTypes.func,
  endStep: PropTypes.func,
  login: PropTypes.shape({
    phoneNumber: PropTypes.string,
    phonePrompt: PropTypes.string,
    isLoading: PropTypes.bool,
    errors: PropTypes.shape({
      phoneNumber: PropTypes.string,
    }),
  }),
};

export default LoginStepPhoneNumber;
