import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import {
  addBankAccountInstant,
} from '../../actions/bank-account-actions';
import { getUser } from '../../actions/user-actions';
import { trackEvent } from '../../utils/ganalytics';
import { canAddBankAccountSelector } from '../../reducers/app-reducer';
import ModalACH from '../modal-ach';

const signupStepSuccess = (props) => {
  const { isLoading } = props.signUp;
  const { canAddBankAccount } = props;
  const [achModalShown, setAchModalShown] = useState(false);


  const toggleAchModal = () => {
    setAchModalShown(!achModalShown);
  };  


  const addBankAccountInstant = () => {
    trackEvent('started_yodlee_flow', 'Button "Add Bank Account Instantly" clicked');
    props.getUser(props.userId);
    props.addBankAccountInstant();
  };

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wizardStep={3}
    >
      <div className="page_header -space-sm">
        <h1 className="js-page-title page_title">Wallet created</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="layer -space-down-xxl -space-up-xxl font-size-secondary-responsive">
            <p className="color-light-gray">
          Congratulations!<a // eslint-disable-line jsx-a11y/anchor-is-valid
                href={'/'}
              >
          &nbsp;Your&nbsp;{process.env.REACT_APP_DOMAIN_STRING}&nbsp;wallet&nbsp;
              </a>has been created.
            </p>
          </div>
          {canAddBankAccount !== false && (<Fragment>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                We recommend you to add a Bank account. 
                Adding Bank Account will allow you to complete transactions and withdraw funds to your accounts. 
              </p>
            </div>
            <div className="layer -space-down-xxl font-size-secondary-responsive">
              <p className="color-light-gray">
                By adding a bank account, you agree with the 
                <a
                  className="info-link -blue"
                  onClick={() => toggleAchModal()}
                >
                  &nbsp;ACH Authorization
                </a>
              </p>
            </div>
          </Fragment>)}
        </div>
        
      </div>
      <div className="page_controls">
        {canAddBankAccount !== false ? (
          <Fragment>
            <Button
              color="dark-gray"
              xSize="full"
              className="js-submit-button -no-border font-weight-bold"
              transparency="full"
              to="/dashboard"
              loading={isLoading}
              disabled={isLoading}
            >
              Skip for now
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="js-submit-button page_control -submit -column"
              onClick={addBankAccountInstant}
              loading={isLoading}
              disabled={isLoading}
            >
              Add New Bank Account Instantly
            </Button>


            <ModalACH
              shown={achModalShown}
              onClose={() => toggleAchModal()}
            />
          </Fragment>
        ) : (
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button page_control -submit -column"
            to="/dashboard"
            loading={isLoading}
            disabled={isLoading}
          >
            Go to your Wallet
          </Button>
        )}
        
      </div>
    </SignupLayout>
  );
};

signupStepSuccess.propTypes = {
  addBankAccountInstant: PropTypes.func,
  getUser: PropTypes.func,
  signUp: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  userId: PropTypes.string,
  canAddBankAccount: PropTypes.bool
};

const mapStateToProps = state => ({
  userId: state.user.user.user_id,
  canAddBankAccount: canAddBankAccountSelector(state)
});

export default connect(mapStateToProps, { addBankAccountInstant, getUser })(signupStepSuccess);