import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';
import VALIDATION from '../utils/validation';

const EmailModal = ({ shown, onClose, isUpdating, updateValues, step, setStep }) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: null });

  useEffect(()=>{
    if (shown) {
      setStep(0);
      setEmail('');
    }
  }, [
    shown
  ]);

  const onUpdate = event => {
    setErrors({ email: null });

    const validation = VALIDATION.validateEmail(email);

    event.preventDefault();


    if (!validation.success) {
      setErrors({ email: validation.message});
    } else {
      updateValues(email);
    }
  };

  const onChange = (email) => {
    setErrors({ email: null });
    setEmail(email);
  };

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      { step === 0 && 
      (<div className="modal_header">
        <h1 className="js-modal-title">Change your email</h1>
      </div>)
      }
      {
        step === 1 &&
      (<div className="modal_header">
        <h1 className="js-modal-title">Please verify new email</h1>
      </div>)
      }
      <div className="modal_body">
        { step === 0 && 
          (<form className="form" onSubmit={onUpdate}>
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  color="white"
                  className="full-width"
                  label="New email address"
                  inputName="email"
                  errorMessage={errors.email}
                  invalid={!!errors.email}
                  value={email}
                  onChange={val => onChange(val)}
                  autoFocus
                />
              </div>
            </div>
            <div className="layer form_row -space-up-md">
              <p>
                Once you complete email verification, you will start
                receiving authentication links on your new email address
                and you will be able to use it for login.
              </p>
            </div>
            <input type="submit" style={{ display: 'none' }} />
          </form>)
        }

        {
          step === 1 && 
          (
            <div className="layer">
              <div className="form-input-container">
                <div className="layer form_row">
                  <p>
                    We have sent an authentication link to your new email address. Please follow the link to confirm your new email
                    address and to start using it for login and authentication.
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          { step === 0 && 
          (<Button
            color="blue"
            xSize="full"
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={onUpdate}
            disabled={isUpdating || !!errors.phone}
            loading={isUpdating}
          >
            Send Authentication Link
          </Button>)
          }
          { step === 1 && 
          (<Button
            xSize="full"
            transparency="full"
            className="js-cancel-button modal_footer-control -cancel"
            disabled={isUpdating}
            onClick={onClose}
          >
            Close
          </Button>)
          }
        </div>
      </div>
    </Modal>
  );
};

EmailModal.propTypes = {
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updateValues: PropTypes.func.isRequired,
};

export default EmailModal;
