import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const WizardSteps = ({ className, currentStep, steps }) => (
  <div className={cn('wizard-steps', className)}>
    {
      (
        steps.map((stepName, stepNum) => (
          <div
            key={stepName}
            className={cn(
              'wizard-steps_item',
              {
                '-current js-step-current': stepNum === currentStep,
                '-finished js-step-finished': stepNum < currentStep,
              },
            )}
          >
            <div className="wizard-steps_item-name">{stepName}</div>
          </div>
        ))
      )
    }
  </div>
);

WizardSteps.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

WizardSteps.defaultProps = {
  className: null,
  currentStep: 1,
};

export default WizardSteps;
