import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { isEmpty } from 'ramda';

import {
  getLoanOffer,
  clearLoanOffer,
} from '../actions/loan-offer-actions';

import CONST from '../constants/loan-offer-constants';

import LoanOfferSuccess from '../components/loan-offer/loan-offer-success';
import LoanOfferFailure from '../components/loan-offer/loan-offer-failure';
import LoanOfferConfiguration from '../components/loan-offer/loan-offer-configuration';

import BasicLayout from './basic-layout';
import Loader from '../components/loader';
import EmptyPlate from '../components/emptyPlate';

const LoanOffer = (props) => {

  useEffect(() => {
    props.getLoanOffer();

    return props.clearLoanOffer;

  }, []);

  const { loanOffer: { currentOffer, isLoading, step } } = props;

  if (isEmpty(currentOffer)) {
    return (
      <BasicLayout>
        <div className="page_header">
          <h1 className="page_title">Configure Your Loan</h1>
        </div>
        <div className="page_body">
          <div className="page_content">
            {isLoading ? (
              <div className="loan-offer_loading">
                <Loader size="sm" color="blue" />
              </div>
            ) : (
              <EmptyPlate
                className="-no-loan-offer"
                plateColor="white"
                iconName="bank-building"
                iconClassName="splash_icon"
                placeholderText="There is no loan offer for you"
              />
            )}
          </div>
        </div>
      </BasicLayout>
    );
  }
    
  return (
    <Fragment>        
      {step === CONST.LOAN_APPROVED && (
        <LoanOfferSuccess />
      )}
      {step === CONST.LOAN_DECLINED && (
        <LoanOfferFailure />
      )}
      {step === CONST.LOAN_CONFIGURATION && (
        <LoanOfferConfiguration />
      )}
    </Fragment>
  );
};

LoanOffer.propTypes = {
  loanOffer: PropTypes.shape(types.loanOfferReducerTypes),
  getLoanOffer: PropTypes.func,
  clearLoanOffer: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    loanOffer: state.loanOffer,
    permissions: state.user.user.enabled_states,
  };
}

export default connect(mapStateToProps, {
  getLoanOffer,
  clearLoanOffer,
})(LoanOffer);
