import { takeLatest, put, call, select } from 'redux-saga/effects';
import { setField, setValidationError } from '../actions/recover-password-actions';
import { addNotification } from '../actions/toast-actions';

import CONST from '../constants/recover-password-constants';
import REQUESTS from '../utils/requests';
import VALIDATION from '../utils/validation';

function* resetPasswordFlow() {
  const { email } = yield select(state => state.user.user);
  const { success } = yield call(REQUESTS.RECOVER_PASSWORD, email);

  if (success) {
    yield put(addNotification('We’ve sent you an email with a link to reset your password', 200));  
  }
}

function* nextStep() {
  const { email } = yield select(state => state.passwordRecovery.recoverPassword);
  const emailValidation = VALIDATION.validateEmail(email);

  if (emailValidation.success) {
    yield put(setField('isLoading', true));
    const { success } = yield call(REQUESTS.RECOVER_PASSWORD, email);

    if (success) {
      yield put(setField('step', CONST.STEP_LINK_SENT));
    }

    yield put(setField('isLoading', false));
  } else {
    yield put(setValidationError('email', emailValidation.message));
  }
}

function* recoverPasswordSaga() {
  yield takeLatest(CONST.REQUEST_RESET_LINK, nextStep);
  yield takeLatest(CONST.RESET_PASSWORD, resetPasswordFlow);
}

export default [recoverPasswordSaga];
