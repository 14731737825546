import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { merge, omit } from 'ramda';
import { Link, NavLink } from 'react-router-dom';

const ButtonBase = (props) => {
  useEffect(() => {
    if (props.autoFocus && ref) {
      ref.focus();
    }
  }, []);

  const ref = useRef(null);

  if (props.innerRef) {
    props.innerRef(ref);
  }

  const {
    component = 'button',
    children,
    className,
    active,
    activeClassName,
    to,
    href,
    type = 'button',
    role,
    disabled,
    loading,
    hover,
  } = props;

  let Element = component;
  const elementProps = merge({
    className: cn({
      [className]: true,
      '-disabled': disabled,
      '-hover': hover,
      '-loading': loading,
      '-active': active,
      [activeClassName]: activeClassName && active,
    }),
    children,
    ref,
  }, omit([
    'className',
    'component',
    'children',
    'active',
    'activeClassName',
    'to',
    'href',
    'type',
    'role',
    'disabled',
    'loading',
    'hover',
    'autoFocus',
    'innerRef',
  ], props));

  if (href) {
    Element = 'a';
    elementProps.href = href;
  } else if (to) {
    if (activeClassName && active === undefined) {
      Element = NavLink;
      elementProps.activeClassName = activeClassName;
    } else {
      Element = Link;
    }
    elementProps.to = to;
  }

  if (Element === 'button') {
    elementProps.type = type;
    elementProps.disabled = disabled;
  } else if (Element !== 'a') {
    elementProps.role = role || 'button';
  }

  return <Element {...elementProps} />;
};

ButtonBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  autoFocus: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  role: PropTypes.string,
  tabIndex: PropTypes.number,
  innerRef: PropTypes.func,
  // State
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  hover: PropTypes.bool,
  // Handlers
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyboardFocus: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default ButtonBase;
