import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from '../../utils/string-utils';
import Button from '../button';
import CONST from '../../constants/sign-up-constants';
import Svg from '../../components/svg';
import SignupLayout from '../../pages/sign-up-layout';
import Loader from '../loader';

const SignupStepLinkSent = ({ signUp, setField }) => {
  const initialMinute = parseInt(signUp.expiration / 60, 10);
  const initialSeconds = signUp.expiration % 60;
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [ seconds, setSeconds ] =  useState(initialSeconds);


  useEffect( () => {
    const startTime = new Date().getTime();

    let timer = setInterval(() => {
      const currentTime = new Date().getTime();
      const diff = Math.ceil((currentTime - startTime)/1000);
      const secondsLeft = (seconds || 59) - diff % 60;
      const minutesLeft = Math.floor((minutes * 60 - diff) / 60);

      if (startTime + signUp.expiration * 1000 - currentTime <= 0) {
        clearInterval(timer);
        setField('step', CONST.STEP_ERROR_TRY_AGAIN);
        setField('payfoneIsLoading', false);
      }

      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }, 100);

    return function() {
      clearInterval(timer);
    };
  }, []);

  return (
    <SignupLayout
      headerClassName="-y-lg"
      wizardStep={0}
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            {
              signUp.lastPhoneDigits
                ? (
                  <Fragment>
                    <Loader size="xxs" className="splash_loader -chat-bubble" />
                    <Svg name="chat-bubble" className="splash_icon" />
                  </Fragment>
                )
                : (
                  <Fragment>
                    <Loader size="xxs" className="splash_loader" />
                    <Svg name="letter" className="splash_icon" />
                  </Fragment>
                )
            }
            {
              signUp.lastPhoneDigits && signUp.maskedEmail
                ? <div className="js-sent-verification-alert splash_message">We have sent authentication links to</div>
                : <div className="js-sent-verification-alert splash_message">We have sent an authentication link to</div>
            }
            { signUp.lastPhoneDigits && (
              <div className="splash_message -phone-mask">
                <div
                  className="js-sent-verification-phone phone-mask"
                >
                  Phone (&middot;&middot;&middot;) &middot;&middot;&middot; &ndash; {signUp.lastPhoneDigits}
                </div>
              </div>
            )}
            { signUp.maskedEmail && (
              <div className="splash_message -phone-mask">
                <div
                  className="js-sent-verification-email phone-mask"
                >
                  Email {signUp.maskedEmail}
                </div>
              </div>
            )}
            <div className="layer -space-up-xl splash_message">Link expires in {formatTime(minutes)}:{formatTime(seconds)}</div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <div className="page_control color-light-gray">
          <p>
            Click on the link we’ve sent you. You’ll be moved through the sign up process automatically.
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={process.env.REACT_APP_HELP_DESK_URL}
            >
            &nbsp;Need&nbsp;help?
            </a>
          </p>
        </div>
        <Button
          xSize="full"
          transparency="full"
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
          href="/login"
        >
          Cancel
        </Button>
      </div>
    </SignupLayout>
  );
};

SignupStepLinkSent.propTypes = {
  setField: PropTypes.func,
  signUp: PropTypes.shape({
    phoneNumber: PropTypes.string,
    maskedEmail: PropTypes.string,
    isLoading: PropTypes.bool,
    expiration: PropTypes.number,
    lastPhoneDigits: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
};

export default SignupStepLinkSent;
