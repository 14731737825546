import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from './modal';
import moment from 'moment';
import { addNotification, removeNotification } from '../actions/toast-actions';
import { userReducerTypes } from '../constants/prop-types';
import Svg from './svg';

const formatPoints = points => points < 0 
  ? (<span className="font-weight-bold">{points.toLocaleString('en')}</span>)
  : (<span className="color-green font-weight-bold">+{points.toLocaleString('en')}</span>);

const RewardsModal = ({ shown, onClose, rewards, addNotification, removeNotification }) => {
  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1 className="js-modal-title">Rewards Program</h1>
        <p>Get reward points for purchases or by referring friends. Convert points to BitRail Stable Coins when paying for orders.&nbsp;
          <a 
            href={process.env.REACT_APP_REWARDS_URL} 
            target="_blank" 
            rel="noopener noreferrer"
            className="modal_header-link">Learn more</a>
        </p>
        
      </div>
      <div className="modal_body">
        <div className="layer modal_section -full-width -white">
          <div className="form_range range">
            <div className="range_title -half">You have</div>
            <h3 className="range-rewards">
              <Svg name="rewards-icon" className="-incoming" />&nbsp;{(rewards.points || 0).toLocaleString('en')}&nbsp;points
            </h3>
          </div>
        </div>

        <div className="layer modal_section -full-width -white">
          <p className="color-secondary font-size-secondary-responsive rewards-text">
              Get points for every new user registered with this link
          </p>
        </div>

        <div className="layer -space-up-lg -space-down-lg">
          <h4>Rewards History</h4>
        </div>

        <div className="layer modal_section -full-width -white -rewards">
          { rewards.history.length
            ? (<table className="rewards-table">
              <thead>
                <tr className="rewards-table-delimeter">
                  <td className="rewards-table-column color-secondary font-size-secondary-responsive">Date</td>
                  {/* <td className="rewards-table-column color-secondary font-size-secondary-responsive">Activity</td> */}
                  <td className="rewards-table-column color-secondary align-right font-size-secondary-responsive">Points</td>
                </tr>
              </thead>
              <tbody>
                { rewards.history.map(
                  (historyItem, index) => (
                    <tr className="rewards-table-delimeter" key={`history-item-${historyItem.loyalty_transaction_id}-${index}`}>
                      <td className="rewards-table-column color-secondary">{moment(historyItem.created_at).format('MMMM D, Y')}</td>
                      {/* <td className="rewards-table-column">{historyItem.transaction_type ? historyItem.transaction_type : ''}</td> */}
                      <td className="rewards-table-column align-right">{formatPoints(historyItem.amount)}</td>
                    </tr>
                  )
                ) }
              </tbody>
            </table>)
            : (<p className="font-size-secondary-responsive color-secondary rewards-table-text">
              No history to show
            </p>)
          }
            
        </div>
      </div>
    </Modal>
  );
};

RewardsModal.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  addNotification: PropTypes.func,
  removeNotification: PropTypes.func,
  rewards: userReducerTypes.rewards,
};

RewardsModal.defaultProps = {
};

const mapStateToProps = ({ user }) => ({
  rewards: user.rewards,
});

export default connect(mapStateToProps, { addNotification, removeNotification })(RewardsModal);
