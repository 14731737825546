import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { sendMoneyReducerTypes } from '../../../constants/prop-types';
import { connect } from 'react-redux';
import { withCurrency } from '../../../utils/send-money-utils';
import { trackEvent } from '../../../utils/ganalytics';
import { closeOrderPayment } from '../../../actions/send-money-actions';
import Button from '../../../components/button';
import Svg from '../../../components/svg';
import TransactionModal from '../../transaction-modal';

const SendMoneyTransactionCompleted = (props) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const onWindowClose = () => {
    props.closeOrderPayment();
  };

  const toggleModal = () => {
    trackEvent('transaction_view','View transaction details');
    setShowTransactionModal(!showTransactionModal);
  };

  const { sendMoney: { transactionResult, isPayment, isRefund, isInvoice } } = props;

  return (
    <Fragment>
      <div className="page_body">
        <div className="page_content">
          <form className="form new-transaction" action="">
            <div className="transaction-result -success">
              <Svg name="send-success" className="transaction-result_icon" />
              <div className="transaction-result_body">
                <div className="transaction-result_amount">
                  <span className="transaction-result_value">
                    {withCurrency(
                      `${transactionResult.gross_amount.ordinal}.${transactionResult.gross_amount.decimal}`, 
                      transactionResult.gross_amount.currency
                    )}
                  </span>
                </div>
                <div className="transaction-result_comment">
                    sent to <span className="word-break-word">{transactionResult.destination.name}</span>
                  {
                    !isPayment && (
                      <Fragment><br />
                        <span className="word-break-word">{transactionResult.destination.user_specified_id}</span>
                      </Fragment>)
                  }
                </div>
                <Button
                  color="blue"
                  xSize="full"
                  transparency="full"
                  onClick={toggleModal}
                >
                    Show Transaction Details
                </Button>
                <TransactionModal
                  shown={showTransactionModal}
                  transaction={transactionResult}
                  onClose={toggleModal}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      { !isRefund && (
        <div className="page_controls">
          {isPayment && !isInvoice && window._isIFrameCall && (
            <Button
              color="blue"
              xSize="full"
              className="page_control -submit"
              onClick={onWindowClose}
            >
                Close
            </Button>
          )}
          {((!isPayment && !isInvoice) || !window._isIFrameCall) && (
            <Button
              color="blue"
              xSize="full"
              className="page_control -submit"
              to="/dashboard"
            >
                Go To Dashboard
            </Button>
          )}
          {isInvoice && (
            <h4 className="text-align-center layer -space-down-lg">
              This window can now be closed
            </h4>
          )}
        </div>)
      }
    </Fragment>
  );
};

SendMoneyTransactionCompleted.propTypes = {
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  closeOrderPayment: PropTypes.func,
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
});

export default connect(mapStateToProps, { closeOrderPayment })(SendMoneyTransactionCompleted);
