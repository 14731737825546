import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LoginLayout from '../../pages/log-in-layout';
import Loader from '../loader';
import Svg from '../svg';

const PartnerSignupStepLoading = (props) => {
  useEffect(() => {
    props.startSignup();
  }, []);

  return (
    <LoginLayout
      headerClassName="-logo-big -y-xl -streamlined"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
      additionalLogo={(
        <div className="header_logo">
          <Svg name="gb-logo" className="header_logo-icon -gb" />
        </div>
      )}
    >
      <div className="page_body">
        <div className="page_content">
          <Loader size="sm" color="blue" className="-centered" />
        </div>
      </div>
      <div className="page_controls"></div>
    </LoginLayout>
  );
};

PartnerSignupStepLoading.propTypes = {
  startSignup: PropTypes.func
};

export default PartnerSignupStepLoading;
