import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  setField,
  unlinkGBUser,
  loginGBUser,
  skipLinking,
  getUnlinkConfirmation,
  getUserGBStatus,
} from '../../actions/user-actions';
import Svg from '../svg';
import Button from '../button';
import Modal from '../modal';
import FormInput from '../form-input';
import { STEP, GB_INIT_STATE } from '../../reducers/user-reducer';
import Loader from '../loader';


const LinkComplete = ({ onClose, isLoading }) => {
  return (<Fragment>
    <div className="modal_header" />
    <div className="modal_body">
      <div className="form">
        <div className="layer -space-up-xxl text-align-center">
          <div className="transaction-result -success">
            <Svg name="check-broken-circle" className="transaction-result_icon -large" />
          </div>
        </div>
        <div className="layer -space-up-md text-align-center">
          <h1>Linking complete</h1>
        </div>
        <div className="layer -space-up-md text-align-center">
          <p>
            Your <b>{process.env.REACT_APP_DOMAIN_STRING}</b> wallet has been successfully associated with your <b>GunBroker</b> seller's account.
            Your current items are now available for purchase via <b>{process.env.REACT_APP_DOMAIN_STRING}</b>,
             and all new items will be available to be sold using <b>{process.env.REACT_APP_DOMAIN_STRING}</b>.
          </p>
        </div>
      </div>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          xSize="full"
          transparency="full"
          loading={isLoading}
          disabled={isLoading}
          onClick={onClose}
          className="modal_footer-control -submit"
        >
            Close
        </Button>
      </div>
    </div>
  </Fragment>);
};

LinkComplete.propTypes = {
  onClose: PropTypes.func,
  isLoading: PropTypes.bool
};

const ConfirmUnlinkModalBody = ({ unlink, isLoading }) => {
  return (<Fragment>
    <div className="modal_header">
      <h1>Linked GunBroker account</h1>
    </div>
    <div className="modal_body">
      <div className="form">
        <div className="layer -space-up-md ">
          <p>
            Are you sure you want to unlink your GunBroker account? You can link account again later at any time.
          </p>
        </div>
      </div>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          xSize="full"
          transparency="full"
          loading={isLoading}
          disabled={isLoading}
          className="modal_footer-control -cancel"
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          onClick={unlink}
          loading={isLoading}
          disabled={isLoading}
          className="modal_footer-control -submit"
        >
            Unlink
        </Button>
      </div>
    </div>
  </Fragment>);
};

ConfirmUnlinkModalBody.propTypes = {
  unlink: PropTypes.func,
  isLoading: PropTypes.bool
};

const UnlinkModalBody = ({ unlink, isLoading, userName, walletHandle }) => {
  return (<Fragment>
    <div className="modal_header">
      <h1>Linked GunBroker account</h1>
    </div>
    <div className="modal_body">
      <div className="form">
        <div className="layer -space-up-xl">
          <p>
            Your {process.env.REACT_APP_DOMAIN_STRING} wallet is linked to the GunBroker account shown below. 
            <a target="_blank" rel="noopener noreferrer" href={'https://support.bitrail.io/hc/en-us/articles/360031036372'}>Learn more</a>
          </p>
        </div>
        <div className="layer -space-up-md">
          <div className="button-group -collapsed -exchange full-width">
            <div className="button-group_hint">
              <Svg name="linked" className="button-group_icon" />
            </div>
            <div className="button-group_body">
              <Button
                color="white"
                xSize="full"
                ySize="xl"
                className="button-group_item -no-hover"
              >
                <div className="button_head">
                  <div className="button_content-secondary">GunBroker Account</div>
                  <div className="button_content-primary">{userName}</div>
                </div>
              </Button>

              <Button
                color="white"
                xSize="full"
                ySize="xl"
                className="button-group_item -no-hover"
              >
                <div className="button_head">
                  <div className="button_content-secondary">{process.env.REACT_APP_DOMAIN_STRING} Wallet</div>
                  <div className="button_content-primary">{walletHandle}</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="layer -space-up-md">
          <p className="color-secondary font-size-secondary-responsive">
            To unlink your GunBroker account, please follow&nbsp;
            <a
              href={'https://support.bitrail.io/hc/en-us/articles/360046413831'}
              target="_blank"
              rel="noopener noreferrer"
            >
              the instructions
            </a>
          </p>
        </div>
      </div>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        {/*<Button
          onClick={unlink}
          loading={isLoading}
          disabled={isLoading}
          color="blue"
          xSize="full"
          className="modal_footer-control -submit"
        >
            Unlink
        </Button>*/}
      </div>
    </div>
  </Fragment>);
};

UnlinkModalBody.propTypes = {
  unlink: PropTypes.func,
  isLoading: PropTypes.bool, 
  userName: PropTypes.string, 
  walletHandle: PropTypes.string,
};

const LinkedModalBody = ({ userName, walletHandle, isLoading, skipGBLinking, setField }) => {
  return (<Fragment>
    <div className="modal_header">
      <div className="modal_header_logo">
        <Svg name="gb-logo" className="header_logo-icon -gb" />
      </div>
    </div>
    <div className="modal_body">
      <div className="form">
        <div className="layer -space-up-xl">
          <h1>Link your Account to Accept {process.env.REACT_APP_DOMAIN_STRING}</h1>
        </div>
        <div className="layer -space-up-md ">
          <div className="button-group -collapsed -exchange full-width">
            <div className="button-group_hint">
              <Svg name="arrow" className="button-group_icon" />
              <Svg name="arrow" className="button-group_icon -reversed" />
            </div>
            <div className="button-group_body">
              <Button
                color="white"
                xSize="full"
                ySize="xl"
                className="button-group_item -no-hover"
              >
                <div className="button_head">
                  <div className="button_content-secondary">GunBroker Account</div>
                  <div className="button_content-primary">{userName}</div>
                </div>
              </Button>

              <Button
                color="white"
                xSize="full"
                ySize="xl"
                className="button-group_item -no-hover"
              >
                <div className="button_head">
                  <div className="button_content-secondary">{process.env.REACT_APP_DOMAIN_STRING} Wallet</div>
                  <div className="button_content-primary">{walletHandle}</div>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="layer -space-up-md -space-down-md">
          <p>Your GunBroker account will be accepting {process.env.REACT_APP_DOMAIN_STRING} as payment method.</p>
        </div>
      </div>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          xSize="full"
          transparency="full"
          className="modal_footer-control -cancel"
          loading={isLoading}
          disabled={isLoading}
          onClick={skipGBLinking()}
        >
          Skip Linking
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="modal_footer-control -submit"
          onClick={() => setField(['gbData', 'step'], STEP.LOGIN)}
          loading={isLoading}
          disabled={isLoading}
        >
          Link Accounts
        </Button>
      </div>
    </div>
  </Fragment>);
};

LinkedModalBody.propTypes = {
  userName: PropTypes.string,
  walletHandle: PropTypes.string,
  isLoading: PropTypes.bool,
  skipGBLinking: PropTypes.func,
  setField: PropTypes.func
};

const WelcomeModalBody = ({ email, password, setEmail, setPassword, submit, error, isLoading, onClose }) => {
  return (<Fragment>
    <div className="modal_header">
      <div className="modal_header_logo">
        <Svg name="gb-logo" className="header_logo-icon -gb" />
      </div>
    </div>
    <div className="modal_body">
      <form className="form" onSubmit={event => {
        event.preventDefault();
        submit()();
      }} autoComplete={'off'}>
        <div className="layer -space-up-xl">
          <h1>Login to GunBroker</h1>
          <p>
            Please login to your GunBroker account to link it with {process.env.REACT_APP_DOMAIN_STRING}. 
            <a target="_blank" rel="noopener noreferrer" href={'https://support.bitrail.io/hc/en-us/articles/360031036372'}>Learn more</a>
          </p>
        </div>
        <div className="layer -space-up-md ">
          <div className="form-input-container">
            <FormInput
              color="white"
              label="User Name (or email address)"
              className="full-width"
              type="text"
              inputName="account"
              value=""
              style={{width: 0, height: 0}}
            />
            <FormInput
              color="white"
              label="Password"
              inputName="password"
              className="full-width"
              type="password"
              autoComplete="password"
              value=""
              style={{width: 0, height: 0}}
            />
            <FormInput
              color="white"
              label="User Name (or email address)"
              className="full-width"
              type="text"
              inputName="account-2"
              value={email}
              onChange={value => setEmail(value)}
            />
          </div>
        </div>
        <div className="layer -space-up-md -space-down-md">
          <div className="form-input-container">
            <FormInput
              color="white"
              label="Password"
              inputName="password-2"
              className="full-width"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={value => setPassword(value)}
            />
          </div>
        </div>
        { error && <div className="layer -space-down-sm">
          <div className="badge -as-form-message -danger full-width">The username or password you entered is incorrect</div>
        </div>
        }
        <input type="submit" style={{ display: 'none' }} />
      </form>
    </div>
    <div className="modal_footer">
      <div className="modal_footer-controls">
        <Button
          xSize="full"
          transparency="full"
          className="modal_footer-control -cancel"
          loading={isLoading}
          disabled={isLoading}
          onClick={onClose}
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          loading={isLoading}
          disabled={isLoading || error || !password || !email}
          className="modal_footer-control -submit"
          onClick={submit()}
        >
            Log In
        </Button>
      </div>
    </div>
  </Fragment>);
};

WelcomeModalBody.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string,
  setEmail: PropTypes.func,
  setPassword: PropTypes.func,
  submit: PropTypes.func,
  error: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func
};

const ModalLinkGB = ({
  show,
  onClose: onClosePopup,
  unlinkGBUser,
  setField,
  loginGBUser,
  skipLinking, 
  isLoading,
  step,
  error,
  userName,
  walletHandle,
  getUnlinkConfirmation,
  getUserGBStatus,
  isGetting,
  isConfirmed,
  emptyData,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    setField(['gbData', 'error'], false);
  }, [
    email, password
  ]);

  useEffect(() => {
    if(show) {
      setEmail(userName);
      setPassword('');
      getUserGBStatus();
    }
  }, [show]);

  useEffect(() => {
    setEmail(userName);
  }, [userName]);

  const onClose = () => {
    setEmail('');
    setPassword('');
    setField('gbData', Object.assign(GB_INIT_STATE, { userName, isConfirmed, emptyData }));
    onClosePopup();
  };

  const submit = () => {
    loginGBUser(email, password);
  };

  return (
    <Fragment>
      { isGetting && <Modal show={show} onClose={() => onClose()} closeButton>
        <Loader size="sm" color="blue" className="-centered" />
      </Modal>
      }
      {
        step === STEP.LOGIN && 
        <Modal show={show} onClose={() => onClose()} closeButton>
          <WelcomeModalBody
            email={email}
            password={password}
            setEmail={setEmail}
            setPassword={setPassword}
            submit={() => submit}
            skipGBLinking={() => skipLinking}
            isLoading={isLoading}
            onClose={() => onClose()}
            error={error}
          />
        </Modal>
      }
      { step === STEP.LINK && 
        <Modal show={show} onClose={() => onClose()} closeButton>
          <LinkedModalBody
            userName={email}
            walletHandle={walletHandle}
            isLoading={isLoading}
            setField={setField}
            skipGBLinking={() => skipLinking}
            onClose={() => onClose()}
          /> 
        </Modal>
      }
      { step === STEP.UNLINK && 
        <Modal show={show} onClose={() => onClose()} closeButton>
          <UnlinkModalBody
            userName={userName}
            walletHandle={walletHandle}
            unlink={getUnlinkConfirmation}
          />
        </Modal>
      }
      { step === STEP.UNLINK_CONFIRM && 
        <Modal show={show} onClose={() => onClose()} closeButton>
          <ConfirmUnlinkModalBody
            unlink={unlinkGBUser}
            isLoading={isLoading}
          /> 
        </Modal>
      }
      {
        step === STEP.LINK_COMPLETE &&
        <Modal show={show} onClose={() => onClose()} closeButton>
          <LinkComplete 
            onClose={() => onClose()}
            isLoading={isLoading}
          />
        </Modal>
      }
    </Fragment>

  );
};

ModalLinkGB.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  unlinkGBUser: PropTypes.func,
  setField: PropTypes.func,
  loginGBUser: PropTypes.func,
  skipLinking: PropTypes.func, 
  isLoading: PropTypes.bool,
  step: PropTypes.string,
  error: PropTypes.bool,
  userName: PropTypes.string,
  walletHandle: PropTypes.string,
  getUnlinkConfirmation: PropTypes.func,
  getUserGBStatus: PropTypes.func,
  isGetting: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isConfirmed: PropTypes.bool,
  emptyData: PropTypes.bool,
};

const mapStateToProps = ({ user, wallet }) => {
  return {
    isLoading: user.gbData.isLoading,
    isGetting: user.gbData.isGetting,
    step: user.gbData.step,
    error: user.gbData.error,
    userName: user.gbData.userName,
    walletHandle: wallet.wallet.handle,
    isConfirmed: user.gbData.isConfirmed,
    emptyData: user.gbData.emptyData,
  };
};

export default connect(mapStateToProps, {
  loginGBUser, setField, unlinkGBUser, skipLinking, getUnlinkConfirmation, getUserGBStatus
})(ModalLinkGB);
