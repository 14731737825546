import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import LoginLayout from '../pages/log-in-layout';
import Button from '../components/button';
import FormInput from '../components/form-input';
import VALIDATION from '../utils/validation';

const SetNewPassword = (props) => {
  const [messageShown, setMessageShown] = useState(false);
  const [errorShown, setErrorShown] = useState(false);

  const submit = (e) => {
    e.preventDefault();

    if (
      props.setNewPasswordData.password
        && props.setNewPasswordData.passwordConfirmation
        && VALIDATION.validatePassword(props.setNewPasswordData.password, null).success
    ) {
      setErrorShown(false);

      if (props.setNewPasswordData.password !== props.setNewPasswordData.passwordConfirmation) {
        setErrorShown(true);
        setMessageShown(false);
      } else {
        props.setNewPassword(props.token);
      }
    }
  };

  const generateMessage = (password) => {
    const reqs = VALIDATION.getPasswordRequirenments(password);
    const isValid = VALIDATION.validatePassword(password, null).success;

    if (!isValid) {
      return (<div className="color-light-gray input-message">
        <div className="input-message_text">Must be
          <span className={reqs.len ? 'color-green' : 'color-light-gray'}>
            &nbsp;8 or more characters&nbsp;
          </span>
          and include:
        </div>
        <div className="input-message-row">
          <ul className="input-message-column">
            <li className={reqs.lowercase ? 'checked' : 'neutral'}>lowercase letter</li>
            <li className={reqs.uppercase ? 'checked' : 'neutral'}>uppercase letter</li>
          </ul>
          <ul className="input-message-column">
            <li className={reqs.digit ? 'checked' : 'neutral'}>digit</li>
            <li className={reqs.symbol ? 'checked' : 'neutral'}>special symbol</li>
          </ul>
        </div>
      </div>);
    }

    return (<div className="color-blue input-message">✓ Good password</div>);
  };

  return (
    <LoginLayout
      bgClassName="-full"
      headerClassName="-logo-big -y-xl"
      wrapperClassName="-x-sm -content-y-centered -page-control-bottom"
      twoLiner
    >
      <div className="page_header -x-centered-desktop">
        <h1 className="page_title">Set New Password</h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <form className="form" onSubmit={submit}>
            <input type="submit" className="form-input_invisible" onSubmit={submit} />
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  value={props.setNewPasswordData.password}
                  onChange={value => {
                    props.setField('password', value);
                    setErrorShown(false);
                    setMessageShown(true);
                  }}
                  inputName="password-old"
                  label="New Password"
                  type="password"
                  transparency="half"
                  onFocus={() => {
                    setErrorShown(false);
                    setMessageShown(true);
                  }}
                  className="full-width"
                />
              </div>
            </div>
            <div className="layer">
              <div className="form-input-container">
                <FormInput
                  value={props.setNewPasswordData.passwordConfirmation}
                  onChange={value => {
                    props.setField('passwordConfirmation', value);
                    setErrorShown(false);
                    setMessageShown(true);
                  }}
                  inputName="password-new"
                  label="Confirm New Password"
                  type="password"
                  transparency="half"
                  isErrorAbsolute={errorShown}
                  errorMessage={errorShown ? 'Passwords do not match' : ''}
                  onFocus={() => {
                    setErrorShown(false);
                    setMessageShown(true);
                  }}
                  className="full-width"
                />
              </div>
              {messageShown && generateMessage(props.setNewPasswordData.password)}
            </div>
          </form>
        </div>
      </div>
      <div className="page_controls -relative">
        <div className="page_control -submit">
          <Button
            color="blue"
            xSize="full"
            className={
              cn(
                '-submit page_control-absolute',
                errorShown ? '-shifted-xl' : null,
              )
            }
            loading={props.setNewPasswordData.isLoading}
            disabled={
              !props.setNewPasswordData.password
                || !props.setNewPasswordData.passwordConfirmation
                || !VALIDATION.validatePassword(props.setNewPasswordData.password, null).success
            }
            onClick={submit}
          >
              Set New Password
          </Button>
        </div>
      </div>
    </LoginLayout>
  );
};

SetNewPassword.propTypes = {
  setNewPasswordData: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    password: PropTypes.string.isRequired,
    passwordConfirmation: PropTypes.string.isRequired,
    validationErrors: PropTypes.shape(), 
  }),
  setField: PropTypes.func,
  setNewPassword: PropTypes.func,
  token: PropTypes.string
};

export default SetNewPassword;
