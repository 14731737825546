import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import initStore from './init-store';
import App from './application';
import { setInitializedMethod } from './utils/api';

setInitializedMethod();

ReactDOM.render(
  <Provider store={initStore}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
