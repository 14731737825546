import { takeLatest, put, select } from 'redux-saga/effects';
import qs from 'qs';
import CONST from '../constants/app-constants';
import REQUESTS from '../utils/requests';
import { setField } from '../actions/app-actions';
import { setNamesForAccounts } from '../utils/send-money-utils';
import { setWalletAccounts } from '../actions/wallet-actions';


function* consoleLogVersion(action) {
  try {
    yield console.log('App is in the popup?: ', action.payload.isPopup); // eslint-disable-line no-console
  } catch (error) {
    throw error;
  }
}

function* getOptions() {
  try {
    const { clientui } = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || localStorage.getItem('clientui');

    const { success, errors, data } = yield REQUESTS.OBTAIN_APP_OPTIONS(clientui);
    const { accounts } = yield select(state => state.wallet);
    if(success && Array.isArray(data) && data.length > 0) {
      const { currencies, ui } = data[0];
      const configuration = data[0].transaction_options || {};
      const maintenances = data[0].maintenances || [];

      yield put(setField('options', data));
      yield put(setField('configuration', configuration));
      yield put(setField('maintenances', maintenances));
      yield put(setField('ui', ui || {}));
      yield put(setWalletAccounts(setNamesForAccounts(accounts, currencies)));
    } else {
      console.error(errors); 
    }
  } catch(e) {
    console.error(e);
  }
}

function* appSaga() {
  yield takeLatest(CONST.SET_APP_OPENED_IN_POPUP, consoleLogVersion);
  yield takeLatest(CONST.GET_OPTIONS, getOptions);
}

export default [appSaga];
