import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LoginLayout from '../log-in-layout';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import Svg from '../../components/svg';
import qs from 'qs';
import { groupBy, assocPath, mergeDeepWith, concat, path } from 'ramda';
import { endStep, setField } from '../../actions/sign-up-actions';
import CONST from '../../constants/sign-up-constants';
import { addNotification } from '../../actions/toast-actions';

const scopesMapping = {
  'admin': false,
  'admin.auth':	'Authorization',
  'admin.ach': 'ACH',
  'admin.manage':	'Administrative Management',
  'admin.users': 'User Administration',
  'admin.transactions': 'Transaction Administration',
  'admin.wallets': 'Wallet Administration',
  'user.edit': 'Edit your profile information',
  'wallet.edit': 'Edit your wallet settings',
  'wallet.banks.edit': 'Add, remove and edit your Bank accounts',
  'transaction.create': 'Initiate transactions with your wallet',
  'transaction.refund': 'Initiate Refunds',
  'agreement.create': 'Create new Recurring payments',
  'agreement.charge': 'Allow to charge your account for subscription payments',
  'invoice.create': 'Create Invoices',
  'user.pii': 'Read your date of birth, SSN or EIN',
  'user.rewards.read': 'Read your rewards balance',
  'wallet.accounts.balance': 'Read your account balance',
  'wallet.definition': 'Read account names and ID\'s',
  'agreement.read': 'Read existing Recurring payments',
  'invoice.read': 'Read existing invoices',
  'user.profile': 'Read your profile information',
  'history.user': 'Profile information history ',
  'history.transactions': 'Transactions history',
  'history.agreements': 'Recurring payments history',
  'history.invoices': 'Invoices history',
  'history.wallets': 'Wallet settings history',
  'history.wallets.accounts': 'Accounts history',
  'history.wallets.banks': 'Bank accounts history',
  'wallet.profile': 'Read wallet handle and name',
  'email': false,
  'wallet': false,
  'user': false,
  'transaction': false,
  'invoice': false,
  'history': false,
  'everything': false,
  'agreement': false
};

const OAuthScopePage = ({ endStep, setField, addNotification }) => {

  useEffect(() => {
    const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if(params.msg) {
      setTimeout(() => {
        addNotification(params.msg);
      }, 1000);
    }
  }, []);

  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const serviceName = queryParams.client_name;
  const { email, scopes } = queryParams;
  const parsedScopes = groupBy((scope) => {
    const scopePath = scope.split('.');
    return scope.includes('history') ?
      scopePath[1] && scopePath[1].replace(/s$/, '') :
      scopePath[0];
  })(scopes.split(' '));

  const groupedScopes = Object.values(parsedScopes)
    .reduce(
      (result, item) => {
        item.forEach((scope) => {
          if(!!scopesMapping[scope]) {
            const path = scope.split('.');

            result = mergeDeepWith(concat,
              result, 
              assocPath(path, 
                {
                  name: scopesMapping[scope],
                  key: scope
                }, {})
            );
          }
        });

        return result;
      }
      , {});

  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState(Object.values(parsedScopes)
    .reduce((acc, cur) => concat(acc, cur) ,[])
    .reduce((result, item) => {
      return item.split('.').length > 1 && !!path(item.split('.'), groupedScopes) ? Object.assign(result, { [item]: true }) : result;
    }, {}));

  const togglePermission = (permission) => () => {
    values[permission] = !values[permission];

    setValues(Object.assign({}, values));
  };

  const deny = () => {
    queryParams.allowed_scopes = 'off';
    queryParams.deny = 'deny';

    setField('oauthScopes', queryParams);
    endStep(CONST.STEP_OAUTH_SCOPES);
  };

  const submit = () => {
    queryParams.allow = 'allow';

    queryParams.allowed_scopes = Object.keys(values)
      .filter((item) => values[item])
      .join(' ');
    queryParams.allowed_scopes = `${queryParams.allowed_scopes} email `;

    Object.values(parsedScopes).forEach((group) => {
      const groupName = group.find((scope) => !scope.includes('.'));
      const _group = group.filter((scope) => scope.includes('.'));
      const isEveryScopeChecked = _group.length > 0 && 
        _group.every((scope) => queryParams.allowed_scopes.includes(`${scope} `));
      
      if(isEveryScopeChecked && !!groupName) {
        queryParams.allowed_scopes = `${queryParams.allowed_scopes}${groupName} `;
        
      }
    });

    delete queryParams.scopes;

    setField('oauthScopes', queryParams);
    endStep(CONST.STEP_OAUTH_SCOPES);
  };

  const renderGroup = (group) => {
    const groupScopes = parsedScopes[group].filter((scope) => values[scope] !== undefined);
    const groupNames = {
      'admin': 'Admin',
      'agreement': 'Recurring payments',
      'wallet': 'Wallet',
      'user': 'User profile',
      'invoice': 'Invoices',
      'transaction': 'Transactions'
    };
    return groupScopes.length > 0 && (
      <div key={group} className="oauth-scopes__permissions-group">
        <h3>{groupNames[group]}</h3>
        {groupScopes.map((scope) => (
          <div className="oauth-scopes__permissions-checkbox">
            <Checkbox
              key={scope}
              checked={values[scope]}
              onChange={togglePermission(scope)}
              label={path(scope.split('.'), groupedScopes).name}
              inputName={`${scope}Checkbox`}
            />
            {(scope.includes('edit') || scope.includes('create')) && (
              <span status="danger" className="oauth-scopes__permissions-badge">
                <Svg name="alert-sign" />
                Sensitive permission
              </span>
            )}
          </div>
        ))}
      </div>
    );
  };

  const sensitiveScopesText = Object.values(parsedScopes)
    .map((group) => group.filter(
      (item) => !!scopesMapping[item] && (item.includes('edit') || item.includes('create'))
    ))
    .filter((group) => group.length > 0)
    .map(
      (group, I, groups) => group.map(
        (scope, i, scopes) => `${scopesMapping[scope]}${I !== groups.length - 1 || i !== scopes.length - 1 ? ', ' : '.'}`
      )
    );

  const readScopesText = Object.values(parsedScopes)
    .map((group) => group.filter(
      (item) => !!scopesMapping[item] && (!item.includes('edit') && !item.includes('create'))
    ))
    .filter((group) => group.length > 0)
    .map(
      (group, I, groups) => group.map(
        (scope, i, scopes) => `${scopesMapping[scope]}${I !== groups.length - 1 || i !== scopes.length - 1 ? ', ' : '.'}`
      )
    );

  return (
    <LoginLayout wrapperClassName="-content-y-centered -content-x-centered -x-md -no-page-header">
      <div className="page_body">
        <div className="page_content oauth-scopes">
          <p>
            <strong>{serviceName}</strong><br /> 
            wants to access your BitRail Digital Wallet information.<br />
            Your email and UserID will be shared with the service.
          </p>
          <div className="oauth-scopes__account">{email} <span>at</span> BitRail</div>
          {!isEditing ? (
            <div className="oauth-scopes__list">
              <div className="oauth-scopes__list-sensitive">
                <div>
                  {sensitiveScopesText && sensitiveScopesText.length > 0 ? (
                    <Fragment>
                      <h4>The app will be able to change information:</h4>
                      <p>
                        {sensitiveScopesText}
                      </p>
                    </Fragment>
                  ) : (
                    <h4>This app is not requesting any permissions that allow to change your information</h4>
                  )}
                </div>
                <Svg name="alert-sign" classname="oauth-scopes__list-svg" viewBox="2 0 12 12" />
              </div>
              <div className="oauth-scopes__list-regular">
                {readScopesText && readScopesText.length > 0 ? (
                  <Fragment>
                    <h4>The app will be able to read information:</h4>
                    <p>{readScopesText}</p>
                  </Fragment>
                ) : (
                  <h4>This app is not requesting any permissions that allow to read your information</h4>
                )}
                
                <Button
                  color="blue"
                  className="oauth-scopes__edit-permissions"
                  onClick={() => setIsEditing(true)}
                >
                  Edit permissions
                </Button>
              </div>
            </div> 
          ) : (
            <div className="oauth-scopes__permissions">
              <h4>The app will be able to change the following:</h4>
              <div className="oauth-scopes__permissions-editing">
                {Object.keys(parsedScopes).map((group) => renderGroup(group))}
              </div>
            </div> 
          )}
           
        </div>
      </div>
      <div className="page_controls -content-x-centered">
        <Button
          color="transparent"
          ySize="md"
          xSize="full"
          className="page_control -submit"
          onClick={deny}
        >
          Deny all
        </Button>
        <Button
          color="blue"
          ySize="md"
          xSize="full"
          className="page_control -submit"
          onClick={submit}
        >
          Allow selected items
        </Button>
      </div>
    </LoginLayout>
  );
};

OAuthScopePage.propTypes = {
  endStep: PropTypes.func,
  setField: PropTypes.func,
  addNotification: PropTypes.func
};

export default connect(() => ({}), { endStep, setField, addNotification })(OAuthScopePage);