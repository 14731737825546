import CONST from '../constants/card-account-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const getCardAccounts = () => ({
  type: CONST.GET_CARD_ACCOUNTS,
});

export const addCardAccount = (callback) => ({
  type: CONST.ADD_CARD_ACCOUNT,
  payload: { callback },
});

export const updateCardAccount = (cardAccountId, cardAccount, callback) => ({
  type: CONST.UPDATE_CARD_ACCOUNT,
  payload: { cardAccountId, cardAccount, callback },
});

export const removeCardAccount = (cardAccountId, callback) => ({
  type: CONST.REMOVE_CARD_ACCOUNT,
  payload: { cardAccountId, callback },
});

export const clearForm = () => ({
  type: CONST.CLEAR_FORM,
});

export const getCardService = () => ({
  type: CONST.GET_CARD_SERVICE
});

export const getCardDetails = (cardAccountId) => ({
  type: CONST.GET_CARD_DETAILS,
  payload: { cardAccountId },
});
