import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { parse } from 'qs';

import { 
  setField, endStep, setPopup, setValidationError, authLinkFlow,
  openPopup, consentAgreement, submitDocumentation, getSingularKey
} from '../actions/sign-up-actions';
import CONST from '../constants/sign-up-constants';
import { trackEvent } from '../utils/ganalytics';

import SignUpStepEmailPassword from '../components/sign-up/sign-up-step-email-password';
import SignUpStepPayphoneDetectPassword from '../components/sign-up/sign-up-step-payphone-detect-password';
import SignUpStepPhoneNumber from '../components/sign-up/sign-up-step-phone-number';
import SignUpStepSSN from '../components/sign-up/sign-up-step-ssn';
import SignUpStepWalletType from '../components/sign-up/sign-up-step-wallet-type';
import SignUpStepAddBankAccount from '../components/sign-up/sign-up-step-add-bank-account';
import SignUpStepComplete from '../components/sign-up/sign-up-step-complete';
import SignUpStepLinkSent from '../components/sign-up/sign-up-step-link-sent';
import SignUpStepErrorTryAgain from '../components/sign-up/sign-up-step-error-try-again';
import SignUpStepErrorHelpDesk from '../components/sign-up/sign-up-step-error-helpdesk';
import SignUpStepErrorWentWrong from '../components/sign-up/sign-up-step-error-went-wrong';
import SignUpStepAdditionalVerification from '../components/sign-up/sign-up-step-additional-verification';
import SignupStepErrorUnable from '../components/sign-up/sign-up-step-error-unable';
import SignUpStepVerifyEmail from '../components/sign-up/sign-up-step-verify-email';
import SignupStepPhoneNumberVerified from '../components/sign-up/signup-step-phone-verified';
import SignupStepSuccess from '../components/sign-up/signup-step-success';
import SignupStepVerificationSelect from '../components/sign-up/sign-up-step-verification-select';
import SignUpStepSupport from '../components/sign-up/sign-up-step-support';
import SignUpStepSingularKey from '../components/sign-up/sign-up-step-singular-key';

const Step = props => {
  switch (props.signUp.step) {
    case CONST.STEP_EMAIL_PASSWORD: return <SignUpStepEmailPassword {...props} />;
    case CONST.STEP_PAYPHONE_AUTODETECT: return <SignUpStepPayphoneDetectPassword {...props} />;
    case CONST.STEP_PHONE_NUMBER: return <SignUpStepPhoneNumber {...props} />;
    case CONST.STEP_LINK_SENT: return <SignUpStepLinkSent {...props} />;
    case CONST.STEP_SUPPORT_VERIFICATION: return <SignUpStepSupport {...props} />;
    case CONST.STEP_SSN: return <SignUpStepSSN {...props} />;
    case CONST.STEP_WALLET_TYPE: return <SignUpStepWalletType {...props} />;
    case CONST.STEP_SIGNUP_SUCCESS: return <SignupStepSuccess {...props} />;
    case CONST.STEP_BANK_ACCOUNT: return <SignUpStepAddBankAccount {...props} />;
    case CONST.STEP_COMPLETE: return <SignUpStepComplete {...props} />;
    case CONST.STEP_ERROR_HELPDESK: return <SignUpStepErrorHelpDesk {...props} />;
    case CONST.STEP_ERROR_TRY_AGAIN: return <SignUpStepErrorTryAgain {...props} />;
    case CONST.STEP_ERROR_WENT_WRONG: return <SignUpStepErrorWentWrong {...props} />;
    case CONST.STEP_ADDITIONAL_VERIFICATION_HELPDESK: return <SignUpStepAdditionalVerification {...props} />;
    case CONST.STEP_ERROR_UNABLE: return <SignupStepErrorUnable {...props} />;
    case CONST.STEP_VERIFY_EMAIL: return <SignUpStepVerifyEmail {...props} />;
    case CONST.STEP_PHONE_NUMBER_VERIFIED: return <SignupStepPhoneNumberVerified {...props} />;
    case CONST.STEP_VERIFICATION_SELECT: return <SignupStepVerificationSelect {...props} />;
    case CONST.STEP_SINGULAR_KEY: return <SignUpStepSingularKey {...props} />;

    default: return <SignUpStepEmailPassword {...props} />;
  }
};

Step.propTypes = {
  signUp: PropTypes.shape(types.signUpReducerTypes)
};

const SignUpPage = (props) => {

  useEffect(() => {
    const search = window.location.href;

    const params = search.split('?');
    const queryParams = parse(params[1]);

    if(props.signUp.step === CONST.STEP_EMAIL_PASSWORD && queryParams.avi) {
      props.setField('isOAuth', true);
      props.setField('oauthParams', queryParams);
    }

    if(window.location.pathname.includes('setup') && queryParams.avi) {
      props.setField('isOAuth', true);
      props.setField('oauthParams', queryParams);
      props.setField('step', CONST.STEP_PHONE_NUMBER);
    }

    if (params && params.length > 1) {
      const querystring = parse(params[1], { decoder: str => str });
      const email = querystring.email;
      const referral = querystring.referral;

      props.setField('email', email || '');
      props.setField('referral', referral || null);
    } 
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    switch (props.signUp.step) {
      case CONST.STEP_ERROR_WENT_WRONG: trackEvent('something_went_wrong', 'Something went wrong screen'); break;
      case CONST.STEP_VERIFY_EMAIL: trackEvent('unable_to_signup', 'Unable to signup screen'); break;
      default: break;
    }
  }, [props.signUp.step]);

  return (
    <Step {...props} />
  );
};

SignUpPage.propTypes = {
  signUp: PropTypes.shape(types.signUpReducerTypes),
  setField: PropTypes.func,
  endStep: PropTypes.func,
  setPopup: PropTypes.func,
  setValidationError: PropTypes.func,
  authLinkFlow: PropTypes.func,
  openPopup: PropTypes.func
};

const mapStateToProps = ({ signUp, wallet, user }) => ({ signUp, wallet, user });

export default connect(mapStateToProps, {
  setField,
  endStep,
  setPopup,
  setValidationError,
  authLinkFlow,
  openPopup,
  consentAgreement,
  submitDocumentation,
  getSingularKey
})(SignUpPage);
