import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Svg from './svg';

const EmptyPlate = ({ className, plateColor, iconName, iconClassName, placeholderText }) => (
  <div className={cn('splash', plateColor && `-${plateColor}`, className)}>
    {iconName && <Svg name={iconName} className={iconClassName} />}
    {placeholderText && <div className="splash_message -sm">{placeholderText}</div>}
  </div>
);

EmptyPlate.propTypes = {
  className: PropTypes.string,
  plateColor: PropTypes.string,
  iconName: PropTypes.string,
  iconClassName: PropTypes.string,
  placeholderText: PropTypes.string,
};

EmptyPlate.defaultProps = {
  className: null,
  plateColor: 'white',
  iconName: null,
  iconClassName: null,
  placeholderText: null,
};

export default EmptyPlate;
