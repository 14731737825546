import React from 'react';
import PropTypes from 'prop-types';
import SignupLayout from '../../pages/sign-up-layout';
import Button from '../button';
import { signUpReducerTypes } from '../../constants/prop-types';
import CONST from '../../constants/sign-up-constants';

const SignUpStepSupport = ({ submitDocumentation, signUp, setField }) => (
  <SignupLayout
    headerClassName="-y-lg"
    bgClassName="-full"
    wrapperClassName="-x-sm"
    wizardStep={1}
  >
    <div className="page_header -space-sm">
      <h1 className="js-page-title page_title">Submit documentation</h1>
    </div>
    <div className="page_body">
      <div className="page_content">
        <div className="layer -space-up-sm">
          <p className="font-size-secondary-responsive">
              In order to continue verification you will need to provide the following list of documentation:
          </p>
          <ul className="font-size-secondary-responsive -ul-padding">
            <li>Government Photo ID, complete copy</li>
            <li>Recent Utility Bill/PayStub, complete copy</li>
          </ul>
          <Button
            color="blue"
            xSize="full"
            className="s-submit-button page_control -submit"
            loading={signUp.isLoading}
            onClick={submitDocumentation}
          >
              Submit Documentation
          </Button>
          <p className="font-size-secondary-responsive font-weight-bold text-align-center">
              Documentation review takes up to 3 business days
          </p>
        </div>
      </div>
    </div>
    <div className="page_controls">
      <Button
        color="dark-gray"
        xSize="full"
        className="page_control -no-border -cancel font-weight-bold"
        transparency="full"
        onClick={() => setField('step', CONST.STEP_VERIFICATION_SELECT)}
      >
        Choose another verification method
      </Button>
    </div>
  </SignupLayout>
);

SignUpStepSupport.propTypes = {
  signUp: PropTypes.shape(signUpReducerTypes),
  submitDocumentation: PropTypes.func,
  setField: PropTypes.func
};

export default SignUpStepSupport;


