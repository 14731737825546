import { takeLatest, put, select } from 'redux-saga/effects';
import REQUESTS from '../utils/requests';
import CONST from '../constants/loan-offer-constants';

import {
  setLoanOffer,
  setField,
} from '../actions/loan-offer-actions';

// TODO: Remove before release
// import { GET_LOAN_OFFER, PUT_LOAN_OFFER } from '../constants/loan-offer-responces';

function* getOffer() {
  try {
    const { user_id } = yield select(state => state.user.user);
    const loanOffer = yield REQUESTS.GET_LOAN_OFFER(user_id);

    if (loanOffer.success && loanOffer.data) {
      yield put(setLoanOffer(loanOffer.data[0]));
    } else {
      yield put(setLoanOffer([]));
      // TODO: Remove before release
      // yield put(setLoanOffer(GET_LOAN_OFFER.data[0]));
    }
  } catch (error) {
    throw error;
  }
}

function* checkOffer({ payload }) {
  try {
    const { user_id } = yield select(state => state.user.user);
    const loanOffer = yield REQUESTS.CHECK_LOAN_OFFER(user_id, payload.amount);

    if (loanOffer.success && loanOffer.data) {
      yield put(setLoanOffer(loanOffer.data[0]));
    } else {
      yield put(setLoanOffer([]));
      // TODO: Remove before release
      // yield put(setLoanOffer(PUT_LOAN_OFFER.data[0]));
    }
  } catch (error) {
    throw error;
  }
}


function* acceptOffer({ payload }) {
  try {
    const { user_id } = yield select(state => state.user.user);
    const loanOffer = yield REQUESTS.ACCEPT_LOAN_OFFER(user_id, payload.rateId);

    if (loanOffer.success) {
      yield put(setField(['step'], CONST.LOAN_APPROVED));
      yield put(setField(['waitingApproval'], false));
      yield put(setField(['approvedLoan'], loanOffer.data[0]));
    } else {
      yield put(setField(['step'], CONST.LOAN_DECLINED));
      yield put(setField(['waitingApproval'], false));
    }
  } catch (error) {
    throw error;
  }
}

function* loanOfferSaga() {  
  yield takeLatest(CONST.GET_LOAN_OFFER, getOffer);
  yield takeLatest(CONST.CHECK_LOAN_OFFER, checkOffer);
  yield takeLatest(CONST.ACCEPT_LOAN_OFFER, acceptOffer);
}

export default [loanOfferSaga];
