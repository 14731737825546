import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { RatesShape, transferReducerTypes } from '../../../constants/prop-types';
import { connect } from 'react-redux';
import { trackEvent } from '../../../utils/ganalytics';

import Button from '../../button';

import CONST from '../../../constants/transfer-constants';
import { setField, makePayment } from '../../../actions/transfer-actions';
import { currencyFormat, withCurrency } from '../../../utils/send-money-utils';

const TransferConfirmation = (props) => {
  const confirm = () => {
    trackEvent('transfer_confirmed', 'Confirm Transfer');
    props.makePayment();
  };

  const {
    transfer: {
      sourceAccount,
      destinationAccount,
      amount,
      type,
    },
    isLoading,
  } = props;

  const accountName = sourceAccount.name ? sourceAccount.name : 'Bank';
  const destinationAccountName = destinationAccount.name ? destinationAccount.name : 'Bank';
  const isBankTransfer = sourceAccount.bank_account_id || destinationAccount.bank_account_id;
  const usdAmount = withCurrency(currencyFormat(amount), 'USD');


  const buttonText = `Transfer ${usdAmount}`;

  return (
    <Fragment>
      <div className="page_header">
        <h1 className="js-page-title page_title">
            Confirm {type}
        </h1>
      </div>
      <div className="page_body">
        <div className="page_content">
          <div className="description-list -fixed-title full-width">
            <div className="description-list_body">
              <div className="description-list_item">
                <div className="description-list_title">From</div>
                <div className="description-list_value">
                  <div className="js-transfer-from-text description-list_item-primary">
                    {accountName} Account
                  </div>
                  {sourceAccount.bank_account_id && (
                    <div className="js-transfer-from-bank-account-text description-list_item-secondary">
                      {sourceAccount.user_description}
                      {' '}
                      <span className="nowrap">
                        <span className="js-transfer-from-bank-account-number-text account-number-bullets">&bull;&bull;</span>
                        {sourceAccount.account_number}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">To</div>
                <div className="description-list_value">
                  <div className="js-transfer-to-text description-list_item-primary">
                    {destinationAccountName} Account
                  </div>
                  {destinationAccount.bank_account_id && (
                    <div className="description-list_item-secondary">
                      {destinationAccount.user_description}
                      {' '}
                      <span className="nowrap">
                        <span className="account-number-bullets">&bull;&bull;</span>
                        {destinationAccount.account_number}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="description-list_item">
                <div className="description-list_title">Amount</div>
                <div className="description-list_value">
                  <div className="js-transfer-amount-transaction-text description-list_item-primary font-weight-bold">
                    {usdAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isBankTransfer && (
            <div className="layer -space-up-md">
              <p className="font-size-secondary-responsive color-secondary text-align-center">
                  Bank account transfer takes up to 5 business days
              </p>
            </div>
          )}
          <div className="layer -space-up-md">
            <p className="font-size-secondary-responsive color-secondary text-align-center">
                By continuing this operation you agree with
              <br />
                the
              {' '}
              <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}>User Agreement</a>
            </p>
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          transparency="full"
          xSize="full"
          className="js-cancel-button page_control -cancel"
          onClick={() => props.setField('flowStep', CONST.STEP_CONFIG_TRANSFER)}
          disabled={isLoading}
        >
            Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button page_control -submit"
          onClick={confirm}
          disabled={isLoading}
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </div>
    </Fragment>
  );
};

TransferConfirmation.propTypes = {
  transfer: transferReducerTypes.data,
  transactionRate: RatesShape,
  isLoading: PropTypes.bool,
  setField: PropTypes.func,
  makePayment: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    transfer: state.transfer.data,
    transactionRate: state.transfer.transactionRate,
    isLoading: state.transfer.isLoading,
    wallet: state.wallet,
  };
}


export default connect(mapStateToProps, { setField, makePayment })(TransferConfirmation);
