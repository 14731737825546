import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Modal from './modal';
import Button from './button';

const ModalDeletionConfirmation = (props) => {
  const { className, confirmationText, isLoading, onConfirm, onClose } = props;

  return (
    <Modal {...props} confirmation containerClassName={cn('-white -x-sm', className)}>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">{confirmationText}</h1>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="red"
            ySize="sm"
            className="modal_footer-control js-remove"
            onClick={onConfirm}
            loading={isLoading}
            disabled={isLoading}
          >
              Remove
          </Button>
          <Button color="gray" ySize="sm" className="modal_footer-control" onClick={onClose} disabled={isLoading}>
              Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalDeletionConfirmation.propTypes = {
  className: PropTypes.string,
  confirmationText: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
};

ModalDeletionConfirmation.defaultProps = {
  className: null,
  confirmationText: 'Are you sure?',
  onConfirm: () => {},
  onClose: () => {},
  isLoading: false,
};

export default ModalDeletionConfirmation;
