import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../components/svg';
import SignupLayout from '../pages/sign-up-layout';
import Button from '../components/button';
import CONST from '../constants/recover-password-constants';

const expiration = 900;

const RecoverPasswordErrorTimeout = (props) => {
  return (
    <SignupLayout
      headerClassName="-y-lg"
      wrapperClassName="-x-sm -content-y-centered -no-page-header"
    >
      <div className="page_body">
        <div className="page_content">
          <div className="page_splash splash">
            <Svg name="circled-cross" className="splash_icon" />
            <h1 className="js-page-title splash_title">Recover Link Expired</h1>
            <div className="splash_message">
              <p className="font-size-secondary-responsive color-light-gray">
                Links are only valid for {(expiration / 60).toFixed()} minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls">
        <Button
          xSize="full"
          transparency="full"
          onClick={() => {
            props.setField('step', CONST.STEP_EMAIL);
            props.setField('sent', false);
          }}
          className="js-cancel-button page_control -no-border -cancel font-weight-bold"
        >
          Cancel
        </Button>
      </div>
    </SignupLayout>
  );
};

RecoverPasswordErrorTimeout.propTypes = {
  setField: PropTypes.func,
};

export default RecoverPasswordErrorTimeout;
