import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { contains } from 'ramda';

import Svg from './svg';

const AVATAR_IMAGE = {
  business: 'tower',
  individual: 'avatar-stub',
  brokenCircle: 'check-broken-circle',
  alertCircle: 'alert-circle',
  rejectedCircle: 'rejected-circle',
};

const Avatar = ({ children, className, imageSrc, label, type }) => {
  const hasContent = !!children || !!imageSrc;
  const iconShouldFill = contains(type, ['brokenCircle', 'alertCircle', 'rejectedCircle']);

  return (
    <div className={cn('avatar', { '-empty': !hasContent, '-no-bg': iconShouldFill }, className)}>
      <div className="avatar_image-wrapper">
        {children || (imageSrc
          ? <img src={imageSrc} alt="" className="avatar_image" />
          : <Svg
            name={AVATAR_IMAGE[type || 'individual']}
            className={cn('avatar_status-icon', { '-fill': iconShouldFill })}
          />
        )}
      </div>
      {label}
    </div>
  );
};

Avatar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.node,
};

Avatar.defaultProps = {
  children: null,
  className: null,
  imageSrc: null,
  label: null,
};

export default Avatar;
