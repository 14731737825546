import CONST from '../constants/recover-password-constants';

export const resetPassword = () => ({
  type: CONST.RESET_PASSWORD,
});

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const requestResetLink = () => ({
  type: CONST.REQUEST_RESET_LINK,
});
