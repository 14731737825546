export const CONSTANTS = {
  GET_USER: '@user/GET_USER',
  GET_USER_SIMPLE: '@user/GET_USER_SIMPLE',
  SET_USER: '@user/SET_USER',
  UPDATE_USER_ADDRESS: '@user/UPDATE_USER_ADDRESS',
  UPDATE_USER_NAME: '@user/UPDATE_USER_NAME',
  ADD_PHONE_NUMBER: '@user/ADD_PHONE_NUMBER',
  UPDATE_EMAIL: '@user/UPDATE_EMAIL',
  LOGOUT_USER: '@user/LOGOUT_USER',
  SET_FIELD: '@user/SET_FIELD',
  LOGIN_GB_USER: '@user/LOGIN_GB_USER',
  UNLINK_GB_USER: '@user/UNLINK_GB_USER',
  SKIP_LINKING: '@user/SKIP_LINKING',
  SET_UNLINK_CONFIRMATION: '@user/SET_UNLINK_CONFIRMATION',
  GET_USER_GB_STATUS: '@user/GET_USER_GB_STATUS',
  GET_KYC_SESSION_STATUS: '@user/GET_KYC_SESSION_STATUS',
  GET_USER_NOTICES: '@user/GET_USER_NOTICES',
  UPDATE_USER_NOTICE: '@user/UPDATE_USER_NOTICE',
  UPDATE_USER_SSN: '@user/UPDATE_USER_SSN',
};

export const KYC_STATUS = {
  ERROR: 'error',
  PENDING: 'pending'
};

export default CONSTANTS;
