import CONST from '../constants/loan-offer-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const setLoanOffer = loanOffer => ({
  type: CONST.SET_LOAN_OFFER,
  payload: { loanOffer },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const getLoanOffer = () => ({
  type: CONST.GET_LOAN_OFFER,
});

export const acceptLoanOffer = rateId => ({
  type: CONST.ACCEPT_LOAN_OFFER,
  payload: { rateId },
});

export const checkLoanOffer = amount => ({
  type: CONST.CHECK_LOAN_OFFER,
  payload: { amount },
});

export const clearLoanOffer = () => ({
  type: CONST.CLEAR_LOAN_OFFER,
});
