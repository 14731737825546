import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const InfoText = ({ className, children }) => (
  <div className={cn('info-text', className)}>
    {children}
  </div>
);

InfoText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

InfoText.defaultProps = {
  children: null,
  className: null,
};

export default InfoText;