import CONST from '../constants/app-constants';

export const setAppOpenedInPopup = isPopup => ({
  type: CONST.SET_APP_OPENED_IN_POPUP,
  payload: { isPopup },
});

export const setPaymentPopup = (isOpened, isPaymentSource = false) => ({
  type: CONST.SET_PAYMENT_POPUP,
  payload: { isOpened, isPaymentSource },
});

export const getOptions = () => ({
  type: CONST.GET_OPTIONS,
});

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});
