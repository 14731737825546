import React from 'react';
import LoginLayout from '../log-in-layout';
import Button from '../../components/button';

const OAuthRedirectPage = () => {
  const handleRedirect = () => {};

  return (
    <LoginLayout wrapperClassName="-content-y-centered -content-x-centered -x-md -no-page-header">
      <div className="page_body">
        <div id="two-factor-pending" class="content-inner">
          <span class="content-logo">
            <svg viewBox="0 0 103 102" width="103" height="102" class="icon" id="arrows-big" />
          </span>
          <h3>Two-Factor Authentication</h3>
          <p>Authentication will continue in a new window.</p>
          <a id="secure-page-article" target="_blank">
          Is the authentication page secure?
          </a>
          <p>
            <a target="_blank" class="js-helpdesk-url" href="<%= process.env.REACT_APP_HELP_DESK_URL %>">Need&nbsp;help?</a>
          </p>
        </div>
      </div>
      <div className="page_controls -content-x-centered">
        <Button
          color="blue"
          ySize="sm"
          xSize="md"
          className="page_control -submit"
          onClick={handleRedirect}
        >
          Continue Authentication
        </Button>
      </div>
    </LoginLayout>
  );
};

export default OAuthRedirectPage;