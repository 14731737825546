import { assocPath, keys, compose } from 'ramda';
import CONST from '../constants/sign-up-constants';

const INIT_STATE = {
  isLoading: false,
  isPayfone: false,
  payfoneIsLoading: false,
  referral: null,
  userId: null,
  walletId: null,
  email: '',
  password: '',
  mnoLink: '',
  step: CONST.STEP_EMAIL_PASSWORD,
  phoneNumber: '',
  expiration: 0,
  ssnDigits: '',
  lastPhoneDigits: '',
  maskedEmail: '',
  agreement: false,
  businessName: '',
  name: {
    fname: '',
    lname: '',
  },
  homeAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
  },
  popups: {
    popupIsLoading: false,
    //
    namePopupShown: false,
    addressPopupShown: false,
    businessNamePopupShown: false,
  },
  agreementModalShown: false,
  isIndividual: null,
  bankAccountRouting: '',
  bankAccountNumber: '',
  bankAccountLabel: '',
  bankAccountType: 'checking',
  bankAccountClass: 'individual',
  yodleeUrl: '',
  yodleeJWT: '',
  yodleeServiceId: '',
  configName: '',
  isYodleeDisabled: false,
  addedAccountsLength: 0,
  isInitializing: false,
  validationErrors: {
    serverError: null,
    email: null,
    password: null,
    phoneNumber: null,
    ssnDigits: null,
    name: null,
    homeAddress: null,
    bankAccountRouting: null,
    bankAccountNumber: null,
    bankAccountLabel: null,
    bankAccountType: null,
  },
};

const signUpReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.CLEAR_STATE:
      return INIT_STATE;
    case CONST.SET_POPUP:
      return assocPath(['popups', action.payload.field], action.payload.state, state);
    case CONST.SET_VALIDATION_ERROR:
      return assocPath(['validationErrors', action.payload.field], action.payload.message, state);
    case CONST.SET_FIELD:
      if (keys(INIT_STATE.validationErrors).indexOf(action.payload.field) !== -1) {
        return compose(
          assocPath([action.payload.field], action.payload.value),
          assocPath(['validationErrors', action.payload.field], null),
        )(state);
      }
      return assocPath([action.payload.field], action.payload.value, state);


    default:
      return state;
  }
};

export default signUpReducer;
