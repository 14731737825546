import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';
import { history } from '../init-store';

const YodleeModal = ({ onClose, shown }) => {	
  const onClick = () => {
    history.push('/bank-accounts/new');
  };

  return (
    <Modal containerClassName="-white -x-sm -closebutton-top" closeButton show={shown} onClose={onClose}>
      <div className="modal_header">
        <h1 className="js-modal-title">Something went wrong</h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-up-sm">
          <p className="font-size-secondary-responsive">
            An error occured while adding a bank account with Yodlee. You can try to add a bank account manually.
          </p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            className="js-submit-button modal_footer-control -submit"
            onClick={onClick}
          >
              Add bank account manually
          </Button>
        </div>
        <div className="modal_footer-controls">
          <Button
            color="gray"
            transparency="full"
            xSize="full"
            className="page_control -cancel"
            onClick={onClose}
          >
              Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

YodleeModal.propTypes = {
  onClose: PropTypes.func,
  shown: PropTypes.bool,
};

export default YodleeModal;
