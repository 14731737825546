import { takeLatest, put, select } from 'redux-saga/effects';
import { addNotification } from '../actions/toast-actions';
import { setField } from '../actions/dashboard-actions';
import REQUESTS from '../utils/requests';
import CONST from '../constants/dashboard-constants';

function* sendVerificationEmailFlow() {
  try {
    yield put(setField('isLoading', true));

    const { user_id: userId } = yield select(state => state.user.user);

    const { success } = yield REQUESTS.RESEND_VERIFICATION_EMAIL(userId);

    if (success) {
      yield put(addNotification('The link has been sent to your email', 200));  
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* sendDocumentationEmailFlow() {
  try {
    yield put(setField('isLoading', true));

    const { user_id: userId } = yield select(state => state.user.user);

    const { success } = yield REQUESTS.SEND_DOCUMENTATION_EMAIL(userId);

    if (success) {
      yield put(addNotification('Instructions has been sent to your email', 200));  
    } else {
      yield put(addNotification('Something went wrong', 400));  
    }

    yield put(setField('isLoading', false));
  } catch (error) {
    yield put(setField('isLoading', false));
  }
}

function* dashboardSaga() {
  yield takeLatest(CONST.SEND_VERIFICATION_EMAIL, sendVerificationEmailFlow);
  yield takeLatest(CONST.SEND_DOCUMENTATION_EMAIL, sendDocumentationEmailFlow);
}

export default [dashboardSaga];
