import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { 
  walletReducerTypes,
  sendMoneyReducerTypes,
} from '../../../constants/prop-types';
import { connect } from 'react-redux';
import { find, propEq } from 'ramda';

import { trackEvent } from '../../../utils/ganalytics';
import Button from '../../../components/button';
import Svg from '../../../components/svg';
import OrderDetailsModal from '../../order-details-modal';
import { pluralize } from '../../../utils/string-utils';

import CONST from '../../../constants/send-money-constants';
import { setField, payOrder } from '../../../actions/send-money-actions';
import { 
  rewardsFormat,
  withCurrency,
  num2words
} from '../../../utils/send-money-utils';
import { getIconName } from '../../../constants/card-account-constants';

const paymentSourceText = (paymentSourceType, accounts, paymentAccountId) => {

  switch (paymentSourceType) {
    case 'account':
      const acc = find(propEq('account_id', paymentAccountId), accounts);

      return `${acc.name ? acc.name : acc.balance.currency} Account`;
    case 'bank': return 'Bank Account';
    case 'card': return 'Credit Card';
    case 'recentTransfer': return 'Recent Transfer';
    default: return '';
  }
};

const OrderConfirmation = (props) => {
  const {
    foundWallet,
    paymentAmount,
    paymentCurrency,
    isLoading,
    orderDescription,
    orderID,
    attributes,
    rewardsUsed,
    paymentSourceType,
    paymentAccountId,
    accLeft,
    rewardsSource,
    isInvoice,
    backup_card_id,
    paymentMode,
    paymentBankAccount,
    paymentSourceInfo
  } = props.sendMoney;

  const { 
    accounts,
    cardAccounts
  } = props.wallet;

  const [showOrderDetails, setShowOrderDetails] = useState(false);

  const backupCard = backup_card_id ? cardAccounts.find((card) => card.card_account_id === backup_card_id) : null;

  const confirm = () => {
    trackEvent('send_money_confirmed', 'Confirm Send Money');
      
    props.payOrder();
  };

  const stepBack = () => {
    props.setField('wizardStep', CONST.STEP_SOURCE_CONFIG);
    props.setField('error', null);
  };

  const showSourceAmount = () => {
    const sourceAmountText = `${withCurrency(accLeft, paymentCurrency)}`;
    const withDescription = paymentSourceInfo.sourceType === 'bank' || paymentSourceInfo.sourceType === 'card';

    return (
      <div className={cn('description-list_value', !withDescription && '-align-center')}>
        {withDescription ? (
          <Fragment>
            {paymentSourceInfo.sourceType === 'card' ? (
              <Fragment>
                <span className="font-weight-bold" style={{ marginBottom: '2px'}}>
                  {paymentSourceInfo.description || 'Credit Card'}
                </span>
                <div style={{ marginBottom: '4px'}}>
                  <Svg name={getIconName(paymentSourceInfo.bank_name)} className="icon cards-page__icon" />
                  <span className="font-weight-bold font-size-secondary-responsive">
                    <span className="account-number-bullets">&bull;&bull;</span>{paymentSourceInfo.last4}
                  </span>
                </div>
              </Fragment>
            ) : (
              <span className="font-weight-bold">
                <span>
                  {paymentSourceInfo.name}&nbsp;&nbsp;
                  <span class="account-number-bullets">••</span>
                  {paymentSourceInfo.last4}
                </span>
              </span>
            )}
            
            <span className="description-list_item-secondary">
              {sourceAmountText}
            </span>
            <span className="description-list_item-secondary -row">
              <span className="-capitalize">{num2words(paymentAmount.split('.')[0])}&nbsp;</span>dollars&nbsp;
              {num2words((paymentAmount.split('.')[1] || '0').substring(0, 2))} cents
            </span>
          </Fragment>
        ) : (
          <span className="font-weight-bold">
            -{sourceAmountText}
          </span>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="page_body -payment">
        <div className="page_content">
          <div className="form new-transaction">

            <h2 className="new-transaction__header">Confirmation</h2>

            <div className="new-transaction_description description-list -fixed-title full-width">
              <div className="description-list_body">
                <div className="description-list_item">

                  <div className="description-list_value description-list_item-secondary">
                    Recipient
                  </div>
                  <div className="description-list_value">
                    <span className="color-primary font-weight-bold">{foundWallet.name}</span>
                  </div>
                </div>
                <div className="description-list_item">
                  <div className="description-list_value description-list_item-secondary">
                    Amount
                  </div>
                  <div className="description-list_value">
                    <span className="color-primary font-weight-bold">{withCurrency(paymentAmount, paymentCurrency)}</span>
                    <span className="description-list_item-secondary -row">
                      <span className="-capitalize">{num2words(paymentAmount.split('.')[0])}&nbsp;</span>dollars&nbsp;
                      {num2words((paymentAmount.split('.')[1] || '0').substring(0, 2))} cents
                    </span>
                  </div>
                </div>
                <div className="description-list_item">
                  <div className="description-list_value description-list_item-secondary">
                    {isInvoice ? 'Invoice' : 'Order'}
                  </div>
                  <div className="description-list_value">
                    <div className="description-list_item-secondary">
                      {Object.keys(attributes).length > 0 && (
                        <Button
                          className="-has-arrow"
                          color="blue"
                          transparency="full"
                          xSize="sm"
                          ySize="min"
                          onClick={() => setShowOrderDetails(!showOrderDetails)}
                        >
                          <div className="button_head">
                            <span>{orderDescription || orderID}</span>
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="new-transaction_description description-list -fixed-title full-width">
              <div className="description-list_header">
                <div className="description-list_header-item">Payment sources</div>
              </div>
              <div className="description-list_body">
                <div className="description-list_group">
                  <div className="description-list_item">
                    <div className="description-list_value description-list_item-secondary">
                      {
                        paymentSourceText(paymentSourceType, accounts, paymentAccountId)
                      }
                    </div>
                    
                    {showSourceAmount()}
                  </div>
                </div>
                { rewardsUsed && rewardsSource && rewardsSource.reward_id && (
                  <div className="description-list_group">
                    <div className="description-list_item">
                      <div className="description-list_title">
                        Reward points
                      </div>
                      <div className="description-list_value">
                        <div className="description-list_item-primary">
                          <span className="font-weight-bold">
                            {withCurrency(rewardsSource.rewardAmountTransactionCurrency, paymentCurrency)}
                          </span>
                        </div>
                        <div className="description-list_item-secondary">
                          {rewardsFormat(rewardsSource.pointsRequired)} Reward {pluralize('Point', rewardsSource.pointsRequired)}
                        </div>
                      </div>
                    </div>
                  </div>)
                }
              </div>
            </div>

            {backupCard && paymentSourceType === 'bank' && (
              <div className="new-transaction_description description-list -fixed-title full-width order-backup__confirmation">
                <div className="description-list_header">
                  <div className="description-list_header-item">Backup payment method</div>
                </div>
                <div className="description-list_body">
                  <div className="description-list_group">
                    <div className="description-list_item">
                      <div className="description-list_value description-list_item-secondary">
                        Credit card
                      </div>
                      <div className="description-list_value">
                        <span className="font-weight-bold" style={{ marginBottom: '2px'}}>
                          {backupCard.user_description || 'Credit Card'}
                        </span>
                        <div>
                          <Svg name={getIconName(backupCard.bank_name)} className="icon cards-page__icon" />
                          <span className="font-weight-bold font-size-secondary-responsive">
                            <span className="account-number-bullets">&bull;&bull;</span>{backupCard.account_number}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            

            <div className="layer -space-up-md text-align-center">
              <span className="font-size-secondary-responsive">
                  By continuing this operation you agree with
                <br />
                  the <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
                >
                    User Agreement
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="page_controls -align-top-desktop">
        <Button
          xSize="full"
          transparency="full"
          className="page_control -cancel"
          disabled={isLoading}
          onClick={stepBack}
        >
          Cancel
        </Button>
        <Button
          color="blue"
          xSize="full"
          className="page_control -submit -flex-column"
          onClick={confirm}
          loading={isLoading}
          disabled={isLoading}
        >
          <div>Pay {withCurrency(paymentAmount, paymentCurrency)}</div>
          <div className={
            cn('label', paymentMode !== 'instant' ? '-alert' : '-success')}
          >
            {
              paymentMode === 'instant' ?
                'Instant payment' :
                paymentBankAccount && paymentBankAccount.clears ? `Money delivered: ${paymentBankAccount.clears}` : 'Money delivered in 3-5 business days'
            }
          </div>
        </Button>
      </div>
      <OrderDetailsModal
        show={showOrderDetails}
        onClose={() => setShowOrderDetails(!showOrderDetails)}
        closeButton
        details={attributes || []}
      />
    </Fragment>
  );
};

OrderConfirmation.propTypes = {
  setField: PropTypes.func,
  payOrder: PropTypes.func,
  sendMoney: PropTypes.shape(sendMoneyReducerTypes),
  wallet: PropTypes.shape(walletReducerTypes),
};

const mapStateToProps = state => ({
  sendMoney: state.sendMoney,
  wallet: state.wallet,
});

export default connect(mapStateToProps, { setField, payOrder })(OrderConfirmation);
