import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as types from '../constants/prop-types';
import { trackEvent } from '../utils/ganalytics';
import { keys, find, compose } from 'ramda';
import { setField, addBankAccount, clearForm, setValidationError } from '../actions/bank-account-actions';

import Badge from '../components/badge';
import BasicLayout from './basic-layout';
import Button from '../components/button';
import FormInput from '../components/form-input';
import BankAccountCreated from '../components/bank-accounts/bank-account-created';
import Radio from '../components/radio';
import { REGEXP_ALPHANUM } from '../utils/regexp';


const AddBankAccount = (props) => {
  useEffect(() => {
    props.clearForm();
  }, []);

  const inputRefs = {
    bankAccountRouting: useRef(null),
    bankAccountNumber: useRef(null),
    bankAccountLabel: useRef(null),
  };

  useEffect(() => {
    const { validationErrors } = props.bankAccount;

    compose(
      find((ref) => {
        if(validationErrors[ref]) {
          const inputRef = inputRefs[ref].current;
          const inputElement = inputRef instanceof HTMLElement ? inputRef : inputRef.getInputDOMNode();
          const isInViewport = inputElement.getBoundingClientRect().y > 0;
  
          if(!isInViewport) {
            inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return true;
          }
        }
  
        return false;
      }),
      keys
    )(inputRefs);
  }, [props.bankAccount.validationErrors]);

  const addBankAccount = () => {
    trackEvent('create_bank_account', 'Click on Add Account button');
    props.addBankAccount();
  };

  const checkAccountNumber = (value) => {
    if (!REGEXP_ALPHANUM.test(value)) {
      return null;
    }

    props.setValidationError('bankAccountNumber', '');

    return props.setField('bankAccountNumber', value);
  };

  const {
    bankAccountRouting,
    bankAccountNumber,
    bankAccountLabel,
    bankAccountType,
    validationErrors,
    isLoading,
    bankAccountCreated,
    bankAccountClass,
  } = props.bankAccount;

  return (
    <BasicLayout>
      {!bankAccountCreated ? (
        <Fragment>
          <div className="page_header -with-sub">
            <h1 className="js-page-title page_title">Add Bank Account</h1>
            <p className="page_subtitle color-secondary">
            Add and verify bank account in order to make transactions between your bank account and your Bitrail Digital Wallet.
                We will send 2-4 small deposits to your bank for verification.
              <span className="font-weight-bold"> Please note that this process may take up to 3 business days to complete.</span>
            </p>
          </div>
          <div className="page_body">
            <div className="page_content">
              <form className="form" action="">
                <div className="layer full-width">
                  <div className="form-input-container">
                    <FormInput
                      color="white"
                      inputName="rounting-number"
                      label="Routing Number"
                      inputRef={inputRefs.bankAccountRouting}
                      type="tel"
                      onChange={value => {
                        props.setValidationError('bankAccountRouting', '');
                        props.setField('bankAccountRouting', value);
                      }}
                      value={bankAccountRouting}
                      errorMessage={validationErrors.bankAccountRouting}
                      invalid={!!validationErrors.bankAccountRouting || !!validationErrors.bankAccountIsUniq}
                      mask="999999999"
                      autoFocus
                    />
                  </div>
                </div>
                <div className="layer full-width">
                  <div className="form-input-container">
                    <FormInput
                      color="white"
                      inputName="account-number"
                      label="Account Number"
                      inputRef={inputRefs.bankAccountNumber}
                      onChange={value => checkAccountNumber(value)}
                      value={bankAccountNumber}
                      errorMessage={validationErrors.bankAccountNumber || validationErrors.bankAccountIsUniq}
                      invalid={!!validationErrors.bankAccountNumber || !!validationErrors.bankAccountIsUniq}
                    />
                  </div>
                </div>
                <div className="layer full-width">
                  <div className="form-input-container">
                    <FormInput
                      color="white"
                      inputName="account-label"
                      inputRef={inputRefs.bankAccountLabel}
                      label="Nickname"
                      onChange={value => {
                        props.setValidationError('bankAccountLabel', '');
                        props.setField('bankAccountLabel', value);
                      }}
                      value={bankAccountLabel}
                      errorMessage={validationErrors.bankAccountLabel}
                      invalid={!!validationErrors.bankAccountLabel}
                    />
                  </div>
                </div>
                <div className="layer -space-up-xl -space-down-sm">
                  <div className="button-group -titled full-width">
                    <div className="button-group_header">
                        Bank Account Type
                    </div>
                    <div className="button-group_body">
                      <Radio
                        className={
                          cn(
                            'button button-group_item -x-full',
                            '-transparency-no',
                            '-color-white',
                            '-like-button',
                            'font-weight-regular',
                          )
                        }
                        label="Checking"
                        value="checking"
                        inputName="isChecking"
                        checked={bankAccountType === 'checking'}
                        onChange={e => {
                          props.setValidationError('bankAccountType', '');
                          props.setField('bankAccountType', e.target.value);
                        }}
                        invalid={!!validationErrors.bankAccountType}
                      />
                      <Radio
                        className={
                          cn(
                            'button button-group_item -x-full',
                            '-transparency-no',
                            '-color-white',
                            '-like-button',
                            'font-weight-regular',
                          )
                        }
                        label="Savings"
                        value="savings"
                        inputName="isSavings"
                        checked={bankAccountType === 'savings'}
                        onChange={e => {
                          props.setValidationError('bankAccountType', '');
                          props.setField('bankAccountType', e.target.value);
                        }}
                        invalid={!!validationErrors.bankAccountType}
                      />
                      {!!validationErrors.bankAccountType && (
                        <label htmlFor="accountType" className="form-input_message -error">
                          {validationErrors.bankAccountType}
                        </label>
                      )}
                    </div>
                  </div>
                </div>

                <div className="layer -space-up-xl -space-down-lg">
                  <div className="button-group -titled full-width">
                    <div className="button-group_header">
                        Bank Account Class
                    </div>
                    <div className="button-group_body">
                      <Radio
                        className={
                          cn(
                            'button button-group_item -x-full',
                            '-transparency-no',
                            '-color-white',
                            '-like-button',
                            'font-weight-regular',
                          )
                        }
                        label="Individual"
                        value="individual"
                        inputName="isIndividual"
                        checked={bankAccountClass === 'individual'}
                        onChange={e => {
                          props.setValidationError('bankAccountClass', '');
                          props.setField('bankAccountClass', e.target.value);
                        }}
                        invalid={!!validationErrors.bankAccountClass}
                      />
                      <Radio
                        className={
                          cn(
                            'button button-group_item -x-full',
                            '-transparency-no',
                            '-color-white',
                            '-like-button',
                            'font-weight-regular',
                          )
                        }
                        label="Business"
                        value="business"
                        inputName="isBusiness"
                        checked={bankAccountClass === 'business'}
                        onChange={e => {
                          props.setValidationError('bankAccountClass', '');
                          props.setField('bankAccountClass', e.target.value);
                        }}
                        invalid={!!validationErrors.bankAccountClass}
                      />
                      {!!validationErrors.bankAccountClass && (
                        <label htmlFor="accountType" className="form-input_message -error">
                          {validationErrors.bankAccountClass}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="page_controls -align-top-desktop">
            <Badge status="alert" className="page_control -submit -margined">
                Please note that this process may take up to 3 banking days
            </Badge>
            <Button
              xSize="full"
              transparency="full"
              className="js-cancel-button page_control -cancel"
              to="/bank-accounts"
              disabled={isLoading}
            >
                Cancel
            </Button>
            <Button
              color="blue"
              xSize="full"
              className="js-submit-button page_control -submit"
              onClick={addBankAccount}
              disabled={
                isLoading ||
                  !!validationErrors.bankAccountRouting ||
                  !!validationErrors.bankAccountNumber ||
                  !!validationErrors.bankAccountLabel ||
                  !!validationErrors.bankAccountType
              }
              loading={isLoading}
            >
                Add Account
            </Button>
          </div>
        </Fragment>
      ) : (
        <BankAccountCreated />
      )}
    </BasicLayout>
  );
};

AddBankAccount.propTypes = {
  bankAccount: PropTypes.shape(types.bankAccountReducerTypes),
  setField: PropTypes.func,
  clearForm: PropTypes.func,
  addBankAccount: PropTypes.func,
  setValidationError: PropTypes.func
};

function mapStateToProps(state) {
  return {
    bankAccount: state.bankAccount,
  };
}

export default connect(mapStateToProps, { setField, addBankAccount, clearForm, setValidationError })(AddBankAccount);
