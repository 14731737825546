import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { transferReducerTypes } from '../../../constants/prop-types';
import { connect } from 'react-redux';
import { currencyFormat, withCurrency } from '../../../utils/send-money-utils';
import { trackEvent } from '../../../utils/ganalytics';
import Button from '../../../components/button';
import Svg from '../../../components/svg';
import TransactionModal from '../../transaction-modal';

const TransferCompleted = (props) => {
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const { transfer: { transactionResult, data: { sourceAccount, destinationAccount } } } = props;
  const { gross_amount } = transactionResult;
  const isBankTransfer = sourceAccount.bank_account_id || destinationAccount.bank_account_id;

  const toggleModal = () => {
    if (!showTransactionModal) {
      trackEvent('transaction_view','View transaction details');
    }

    setShowTransactionModal(!showTransactionModal);
  };

  const transferIcon = () => {
    const { destinationAccount } = props.transfer.data;
    
    if(destinationAccount.bank_account_id) {
      return <Svg name="bank-success" className="transaction-result_icon" />;
    } 
      
    return <Svg name="exchange-success" className="transaction-result_icon" />;
  };

  const transferComment = () => {
    if (isBankTransfer) {
      return sourceAccount.type
        ? (
          <div className="transaction-result_comment">
            is being transferred to Bank Account<br />
            {destinationAccount.user_description}&nbsp;
            <span className="account-number-bullets">&bull;&bull;</span>{destinationAccount.account_number}
          </div>
        )
        : (
          <div className="transaction-result_comment">
            is being transferred from Bank Account<br />
            {sourceAccount.user_description}&nbsp;
            <span className="account-number-bullets">&bull;&bull;</span>{sourceAccount.account_number}
          </div>
        );
    }
    return (
      <div className="transaction-result_comment">
        added to your<br /> {destinationAccount.name} Account
      </div>
    );
  };


  const amount = gross_amount.currency !== 'USD' 
    ? (parseInt(gross_amount.usd_equivalent, 10) / 100).toFixed(2) 
    : currencyFormat(`${gross_amount.ordinal}.${gross_amount.decimal}`);

  return (
    <Fragment>
      <div className="page_body">
        <div className="page_content">
          <form className="form new-transaction" action="">
            <div className="transaction-result -success">
              {transferIcon()}
              <div className="transaction-result_body">
                <div className="js-transfer-transaction-result-text transaction-result_amount">
                  <span className="transaction-result_value">
                    {withCurrency(amount, 'USD')}
                  </span>
                </div>
                {transferComment()}
                {isBankTransfer && (
                  <div className="transaction-result_comment">
                      Bank account transfer<br />
                      takes up to 5 business days
                  </div>
                )}
                <Button
                  color="blue"
                  xSize="full"
                  transparency="full"
                  className="js-transfer-show-transaction-details-button"
                  onClick={toggleModal}
                >
                    Show Transaction Details
                </Button>
                <TransactionModal
                  shown={showTransactionModal}
                  transaction={transactionResult}
                  onClose={toggleModal}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="page_controls">
        <Button color="blue" xSize="full" className="js-submit-button page_control -submit" to="/dashboard">Go To Dashboard</Button>
      </div>
    </Fragment>
  );
};

TransferCompleted.propTypes = {
  transfer: PropTypes.shape(transferReducerTypes),
};

function mapStateToProps(state) {
  return {
    transfer: state.transfer,
  };
}


export default connect(mapStateToProps, null)(TransferCompleted);

