import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';

import Header from '../components/header';

const BasicLayout = (props) => {
  const {
    children,
    headerClassName,
    wrapperClassName,
    hideNav,
    additionalElement,
    innerWrapperClassName,
    poweredByType
  } = props;

  return (
    <div className="page -basic">
      { 
        additionalElement
      }
      <Header 
        className={headerClassName}
        hideNav={hideNav}
        coloredLogo={true}
        poweredByType={poweredByType}
      />
      <div className={cn('page_wrapper', wrapperClassName)}>
        <div className={cn('page_wrapper-inner', innerWrapperClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node,
  headerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  hideNav: PropTypes.bool,
  innerWrapperClassName: PropTypes.string,
  additionalElement: PropTypes.node,
  poweredByType: PropTypes.oneOf(['full', 'sign']),
};

BasicLayout.defaultProps = {
  children: null,
  headerClassName: null,
  wrapperClassName: null,
  poweredByType: null,
};

export default withRouter(connect(null, null)(BasicLayout));
