import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as types from '../../constants/prop-types';
import { parse } from 'qs';

import { 
  setField, endStep, setPopup, setValidationError,
  openPopup, submitDocumentation, getSingularKey
} from '../../actions/sign-up-actions';
import { setField as setUserField } from '../../actions/user-actions';
import CONST from '../../constants/sign-up-constants';

import SignUpStepSSN from '../../components/sign-up/sign-up-step-ssn';
import SignUpStepWalletType from '../../components/sign-up/sign-up-step-wallet-type';
import SignUpStepAddBankAccount from '../../components/sign-up/sign-up-step-add-bank-account';
import SignUpStepAdditionalVerification from '../../components/sign-up/sign-up-step-additional-verification';
import SignupStepVerificationSelect from '../../components/sign-up/sign-up-step-verification-select';
import SignUpStepSupport from '../../components/sign-up/sign-up-step-support';
import SignUpStepSingularKey from '../../components/sign-up/sign-up-step-singular-key';
import SignUpStepWalletSuccess from '../../components/sign-up/sign-up-step-wallet-success';
import { addNotification } from '../../actions/toast-actions';

const Step = props => {
  switch (props.signUp.step) {
    case CONST.STEP_VERIFICATION_SELECT: return <SignupStepVerificationSelect {...props} />;
    case CONST.STEP_SUPPORT_VERIFICATION: return <SignUpStepSupport {...props} />;
    case CONST.STEP_SINGULAR_KEY: return <SignUpStepSingularKey {...props} />;
    case CONST.STEP_SSN: return <SignUpStepSSN {...props} />;
    case CONST.STEP_WALLET_TYPE: return <SignUpStepWalletType {...props} />;
    case CONST.STEP_BANK_ACCOUNT: return <SignUpStepAddBankAccount {...props} />;
    case CONST.STEP_ADDITIONAL_VERIFICATION_HELPDESK: return <SignUpStepAdditionalVerification {...props} />;
    case CONST.STEP_WALLET_SUCCESS: return <SignUpStepWalletSuccess {...props} />;

    default: return <SignupStepVerificationSelect {...props} />;
  }
};

Step.propTypes = {
  signUp: PropTypes.shape(types.signUpReducerTypes)
};

const OAuthSetupPage = (props) => {

  useEffect(() => {
    const { search } = window.location;

    const queryParams = parse(search, { ignoreQueryPrefix: true });

    props.setField('isOAuth', true);
    props.setField('oauthParams', queryParams);
    props.setField('userId', queryParams.uid);
    props.setField('email', queryParams.email);
    props.setUserField(['user','user_id'], queryParams.uid);
    props.setUserField(['user', 'email'], queryParams.email);

    props.setField('step', CONST.STEP_PHONE_NUMBER_SKIP);
    props.endStep(CONST.STEP_PHONE_NUMBER_SKIP);

    if (search && search.length > 1) {
      const querystring = parse(search, { decoder: str => str });
      const email = querystring.email;
      const referral = querystring.referral;

      props.setField('email', email || '');
      props.setField('referral', referral || null);
    } 

    if(queryParams.msg) {
      setTimeout(() => {
        addNotification(queryParams.msg);
      }, 1000);
    }
  }, []);

  return (
    <Step {...props} />
  );
};

OAuthSetupPage.propTypes = {
  signUp: PropTypes.shape(types.signUpReducerTypes),
  setField: PropTypes.func,
  endStep: PropTypes.func,
  setPopup: PropTypes.func,
  openPopup: PropTypes.func,
  setUserField: PropTypes.func,
  addNotification: PropTypes.func
};

const mapStateToProps = ({ signUp, wallet, user }) => ({ signUp, wallet, user });

export default connect(mapStateToProps, {
  setField,
  endStep,
  setPopup,
  setValidationError,
  openPopup,
  submitDocumentation,
  getSingularKey,
  setUserField,
  addNotification
})(OAuthSetupPage);
