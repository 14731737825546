import { assoc } from 'ramda';
import CONST from '../constants/dashboard-constants';

const INIT_STATE = {
  isLoading: false,
  rewardsModalShown: false,
  yodleeInProgress: false,
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONST.SET_FIELD:
      return assoc(action.payload.field, action.payload.value, state);
    default:
      return state;
  }
};

export default dashboardReducer;