import React from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';
import Button from './button';

const ModalACH = ({ onClose, shown }) => {
  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header js-user-text">
        <h1 className="js-user-text-title">ACH Debit / Credit Authorization</h1>
      </div>
      <div className="modal_body">
        <div className="layer -space-down-xl">
          <p>
            Creation and verification of a bank account authorizes BitRail to 
            initiate electronic debit or credit entries through the ACH system 
            to the specified bank account, in order to complete fund transfers 
            and/or to recover any overpayments. This authorization is valid as 
            long as the user maintains a bank account in the wallet and the user's 
            account remains active.
          </p>
        </div>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button 
            color="blue"
            xSize="full"
            className="modal_footer-control"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ModalACH.propTypes = {
  onClose: PropTypes.func, 
  shown: PropTypes.bool,
};

ModalACH.defaultProps = {
  onClose: () => {},
  shown: false,
};

export default ModalACH;
