export const CONSTANTS = {
  SET_FIELD: '@bank_account/SET_FIELD',
  ADD_BANK_ACCOUNTS_MASSIVE: '@bank_account/ADD_BANK_ACCOUNTS_MASSIVE',
  SET_VALIDATION_ERROR: '@bank_account/SET_VALIDATION_ERROR',
  GET_BANK_ACCOUNTS: '@bank_account/GET_BANK_ACCOUNTS',
  ADD_BANK_ACCOUNT: '@bank_account/ADD_BANK_ACCOUNT',
  VERIFY_BANK_ACCOUNT: '@bank_account/VERIFY_BANK_ACCOUNT',
  UPDATE_BANK_ACCOUNT: '@bank_account/UPDATE_BANK_ACCOUNT',
  REMOVE_BANK_ACCOUNT: '@bank_account/REMOVE_BANK_ACCOUNT',
  CLEAR_FORM: '@bank_account/CLEAR_FORM',
  ADD_BANK_ACCOUNT_INSTANT: '@bank_account/ADD_BANK_ACCOUNT_INSTANT',
  ADD_BANK_ACCOUNT_OAUTH: '@bank_account/ADD_BANK_ACCOUNT_OAUTH'
};

export const YODLEE_ERRORS = {
  SESSION_TIMEOUT: 'N503',
  LOGOUT: 'N505'
};

export default CONSTANTS;
