import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import BasicLayout from './basic-layout';
import Loader from '../components/loader';
import { addBankAccountMassive, setField } from '../actions/bank-account-actions';
import Button from '../components/button';
import cn from 'classnames';
import { trackEvent } from '../utils/ganalytics';
import { history } from '../init-store';
import { YODLEE_ERRORS } from '../constants/bank-account-constants';

const AddBankAccountInstant = props => {
  const { yodleeUrl, yodleeJWT, configName, setField, addBankAccountMassive } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const showError = error && error.code; // Code field is presented only in yodlee errors 
  const errorText = useRef({
    title: 'Instant verification unavailable',
    message: 'Unfortunately, service for instant account verification is unavailable at the moment. Please try again later or add your bank account manually.',
  });
  const startFastlink = () => {
    setField(['isYodleeDisabled'], true);
    setField(['isInitializing'], true);
  
    window.fastlink.close();
    window.fastlink.open({
      fastLinkURL: yodleeUrl,
      accessToken: `Bearer ${yodleeJWT}`,
      forceIframe: true,
      params: {
        configName: configName,
      },
      onClose: (data) => {
        if (data && data.action === 'exit') {
          if(data.sites && data.sites.length) {
            setIsLoading(true);
            addBankAccountMassive(data.sites);
          } else {
            history.push('/bank-accounts');
          }
        }
        trackEvent('ended_yodlee_flow', 'Yodlee flow ended');
      },
      onError: (err) => {
        console.warn(err);

        if(err.code === YODLEE_ERRORS.SESSION_TIMEOUT || err.code === YODLEE_ERRORS.LOGOUT) {
          Object.assign(errorText.current, err);
        }

        setError && setError(err);
      },
      onEvent: (event) => {
        console.log(event);
      },
    },
    'container-fastlink');
  
    let frame = document.querySelector('#container-fastlink iframe');
  
    if (frame) {
      frame.onload = () => {
        setField(['isYodleeDisabled'], false);
        setField(['isInitializing'], false);
      };
    };
  
  };

  if (!props.yodleeUrl && !props.yodleeJWT) {
    return <Redirect to="/bank-accounts" />;
  }

  useEffect(() => {
    startFastlink();
  }, []);

  useEffect(() => {
    setIsLoading(props.isInitializing);
  }, [props.isInitializing]);

  const startOver = () => {
    setError(null);
    errorText.current = {
      title: 'Instant verification unavailable',
      message: 'Unfortunately, service for instant account verification is unavailable at the moment. Please try again later or add your bank account manually.', // eslint-disable-line 
    };
    setIsLoading(true);
    startFastlink();
  };

  return (
    <BasicLayout>
      {
        isLoading && !showError
          ? (<div className="loader-wrapper">
            <Loader color="blue" size="sm" /> 
          </div>)
          : null 
      }
      { showError ? (
        <YodleeError 
          error={errorText}
          startOver={startOver}
        />
      ) : (
        <Fragment>
          <div className="page_header -relative">
            <h1 className="page_title">Add Bank Account</h1>
            <Button
              xSize="xs"
              ySize="min"
              color="blue"
              className="-right-button"
              transparency="full"
              to="/bank-accounts"
            >
              Cancel
            </Button>
          </div>
        </Fragment>
      ) }
      <div
        id="container-fastlink"
        className={cn('fastlink-container-outer', showError ? 'hidden' : '')}
      />
    </BasicLayout>
  );
};

const YodleeError = ({ error, startOver }) => (
  <Fragment>
    <div className="page_header -with-sub">
      <h1 className="js-page-title page_title">{error.current.title}</h1>
      <p className="page_subtitle color-secondary">{error.current.message}</p>
    </div>
    <div className="page_controls -align-top-desktop">
      { error.current.code ? (
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button modal_footer-control -submit"
          onClick={startOver} // eslint-disable-line 
        >
          Start over
        </Button>
      ) : null }
      {error.current.code ? (
        null
      ) : (
        <Button
          color="blue"
          xSize="full"
          className="js-submit-button modal_footer-control -submit"
          to="/bank-accounts/new"
        >
          Add bank account manually
        </Button>
      )}
            
      <Button
        color="gray"
        transparency="full"
        xSize="full"
        className="page_control -cancel"
        to="/bank-accounts"
      >
        Return to bank account list
      </Button>
    </div>
  </Fragment>
);

YodleeError.propTypes = {
  error: PropTypes.shape({
    current: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
      code: PropTypes.string
    })
  }),
  startOver: PropTypes.func,
};

AddBankAccountInstant.propTypes = {
  yodleeUrl: PropTypes.string,
  yodleeJWT: PropTypes.string,
  configName: PropTypes.string,
  addBankAccountMassive: PropTypes.func,
  setField: PropTypes.func,
  isInitializing: PropTypes.bool
};

const mapStateToProps = props => ({
  yodleeUrl: props.bankAccount.yodleeUrl,
  yodleeJWT: props.bankAccount.yodleeJWT,
  yodleeServiceId: props.bankAccount.yodleeServiceId,
  configName: props.bankAccount.configName,
  isInitializing: props.bankAccount.isInitializing,
});

export default connect(mapStateToProps, { addBankAccountMassive, setField })(AddBankAccountInstant);
