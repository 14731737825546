import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { assocPath, compose } from 'ramda';
import Modal from './modal';
import Button from './button';
import FormInput from './form-input';
import VALIDATION from '../utils/validation';

const NameModal = (props) => {
  const [name, setName] = useState({ fname: '', lname: '' });
  const [errors, setErrors] = useState({ fname: '', lname: '' });

  useEffect(() => {
    if(!props.shown) {
      setName(props.name);
      setErrors({ fname: '', lname: '' });
    }
  }, [props.shown]);

  const onChange = field =>
    (value) => {
      const changedName = compose(
        assocPath([field], value),
      )(name);
      const changedErrors = compose(
        assocPath([field], ''),
      )(errors);

      setName(changedName);
      setErrors(changedErrors);
    };

  const onUpdate = (event) => {
    const { fname, lname } = name;

    const validationLname = VALIDATION.validateLastName(lname);
    const validationFname = VALIDATION.validateFirstName(fname);

    event.preventDefault();

    if (validationLname.success && validationFname.success) {
      props.updateValues({ fname, lname });
    } else {
      setErrors({
        lname: validationLname.message,
        fname: validationFname.message,
      });
    }
  };

  const { shown, onClose, isUpdating } = props;
  const { lname, fname } = name;

  return (
    <Modal show={shown} onClose={onClose} closeButton>
      <div className="modal_header">
        <h1 className="js-modal-title">Your Name</h1>
      </div>
      <div className="modal_body">
        <form className="form" onSubmit={onUpdate}>
          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="fname"
                label="First Name"
                errorMessage={errors.fname}
                invalid={!!errors.fname}
                value={fname}
                className="full-width"
                onChange={onChange('fname')}
                autoFocus
              />
            </div>
          </div>

          <div className="layer">
            <div className="form-input-container">
              <FormInput
                color="white"
                inputName="lname"
                label="Last Name"
                value={lname}
                errorMessage={errors.lname}
                invalid={!!errors.lname}
                className="full-width"
                onChange={onChange('lname')}
              />
            </div>
          </div>
          <input type="submit" style={{ display: 'none' }} />
        </form>
      </div>
      <div className="modal_footer">
        <div className="modal_footer-controls">
          <Button
            color="blue"
            xSize="full"
            className="js-submit-modal-button modal_footer-control -submit"
            onClick={onUpdate}
            disabled={isUpdating || !!errors.lname || !!errors.fname}
            loading={isUpdating}
          >
              Update Name
          </Button>
        </div>
      </div>
    </Modal>
  );
};

NameModal.propTypes = {
  shown: PropTypes.bool,
  onClose: PropTypes.func,
  updateValues: PropTypes.func,
  isUpdating: PropTypes.bool,
  name: PropTypes.shape({
    fname: PropTypes.string,
    lname: PropTypes.string
  })
};

NameModal.defaultProps = {
  isUpdating: false,
};

export default NameModal;
