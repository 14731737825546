import CONST from '../constants/bank-account-constants';

export const setField = (field, value) => ({
  type: CONST.SET_FIELD,
  payload: { field, value },
});

export const addBankAccountInstant = () => ({
  type: CONST.ADD_BANK_ACCOUNT_INSTANT,
});

export const addBankAccountMassive = (bankAccounts, withoutRedirect) => ({
  type: CONST.ADD_BANK_ACCOUNTS_MASSIVE,
  payload: { bankAccounts, withoutRedirect },
});

export const setValidationError = (field, message) => ({
  type: CONST.SET_VALIDATION_ERROR,
  payload: { field, message },
});

export const getBankAccounts = () => ({
  type: CONST.GET_BANK_ACCOUNTS,
});

export const addBankAccount = () => ({
  type: CONST.ADD_BANK_ACCOUNT,
});

export const verifyBankAccount = (bankAccountId, amountValues) => ({
  type: CONST.VERIFY_BANK_ACCOUNT,
  payload: { bankAccountId, amountValues },
});

export const updateBankAccountLabel = (bankAccountId, label) => ({
  type: CONST.UPDATE_BANK_ACCOUNT,
  payload: { bankAccountId, label },
});

export const removeBankAccount = bankAccountId => ({
  type: CONST.REMOVE_BANK_ACCOUNT,
  payload: { bankAccountId },
});

export const clearForm = () => ({
  type: CONST.CLEAR_FORM,
});

export const addBankAccountOAuth = () => ({
  type: CONST.ADD_BANK_ACCOUNT_OAUTH
});