import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { loanOfferReducerTypes } from '../../constants/prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import Slider from 'rc-slider';
import { map, addIndex, find, findIndex, path } from 'ramda';

import DEFAULT_INPUT_RANGE_CLASS_NAMES from 'react-input-range/src/js/input-range/default-class-names';

import {
  acceptLoanOffer,
  checkLoanOffer,
  setField,
} from '../../actions/loan-offer-actions';

import { currencyFormat } from '../../utils/send-money-utils';

import BasicLayout from '../../pages/basic-layout';
import Button from '../button';
import Checkbox from '../checkbox';

import ModalAmountConfig from './modal-amount-config';
import ModalLoanFaq from './modal-loan-faq';

const INIT_STATE = {
  modalOpen: false,  
  amount: 0,
  rateId: 0,
  rateTerm: 0,
  rateIdx: 0,
  agreement: false,
  faqModalOpened: false,
  amountModalOpened: false,
};

class LoanOfferConfiguration extends Component {
  constructor(props) {
    super(props);

    const rateId = path(['loanOffer', 'currentOffer', 'accepted_rate_id'], props);
    const rates = path(['loanOffer', 'currentOffer', 'rates'], props);
    const currentRate = (rateId && rates) ? find(rate => rate.rate_id === rateId , rates) : null;
    const idx = (rateId && rates) ? findIndex(rate => rate.rate_id === rateId , rates) : 0;
    const amount = currentRate ? parseInt(currentRate.amount_financed, 10) * 100 : INIT_STATE.amount;

    this.state = {
      ...INIT_STATE,
      amount: amount,
      rateId: currentRate
        ? currentRate.rate_id : 0,
      rateTerm: currentRate
        ? currentRate.term : 0,
      rateIdx: idx,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loanOffer.currentOffer !== this.props.loanOffer.currentOffer) {
      const newRate = path(['loanOffer', 'currentOffer', 'rates'], this.props)
        ? find(rate => rate.term === this.state.rateTerm, this.props.loanOffer.currentOffer.rates)
        : null;

      if (newRate) {
        this.setState({
          rateId: newRate.rate_id,
          rateIdx: findIndex(rate => rate.term === this.state.rateTerm, this.props.loanOffer.currentOffer.rates)
        });
      } else {
        if (this.state.rateIdx > 0) {
          this.setState({
            rateId: this.props.loanOffer.currentOffer.rates ? this.props.loanOffer.currentOffer.rates[
              this.props.loanOffer.currentOffer.rates.length - 1
            ].rate_id : 0,
            rateIdx: this.props.loanOffer.currentOffer.rates.length - 1,
            rateTerm: this.props.loanOffer.currentOffer.rates ? this.props.loanOffer.currentOffer.rates[
              this.props.loanOffer.currentOffer.rates.length - 1
            ].term : 0,
          });
        } else {
          this.setState({
            rateId: this.props.loanOffer.currentOffer.rates ? this.props.loanOffer.currentOffer.rates[0].rate_id : 0,
            rateIdx: 0,
            rateTerm: this.props.loanOffer.currentOffer.rates ? this.props.loanOffer.currentOffer.rates[0].term : 0,
          });
        }
      }     
    }
  }

  onChangeAmount = value => {
    this.setState({
      amount: value * 100,
    });
  }

  onFieldChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  chooseDuration = (number = 0) => {
    const { currentOffer } = this.props.loanOffer;

    this.setState({
      rateId: currentOffer.rates[number] ? currentOffer.rates[number].rate_id : undefined,
      rateTerm: currentOffer.rates[number] ? currentOffer.rates[number].term : undefined,
      rateIdx: number,
    });
  }

  changeAmount = (amount) => {
    this.setState({
      amount: parseFloat(amount) * 100,
      amountModalOpened: false,
    });
    this.checkLoanOffer(amount);
  }

  checkLoanOffer = (amount) => {
    this.props.checkLoanOffer(amount);
  }

  acceptLoanOffer = () => {
    const { amount, rateId } = this.state;
    this.props.setField('acceptedAmount', amount);
    this.props.acceptLoanOffer(rateId);
  }

  durationMarks = () => {
    const { currentOffer: { rates } } = this.props.loanOffer;
    const marks = [];
    const idxMap = addIndex(map);
    idxMap((rate, idx) => marks.push({ value: idx, label: rate.term }), rates);
    return marks;
  }

  render() {
    const { currentOffer, isLoading, isChecking, waitingApproval } = this.props.loanOffer;
    const { agreement, amount, rateIdx } = this.state;

    const currentAmount = amount / 100;
    const maxLoanAmount = currentOffer.max_amount / 100;
    const minLoanAmount = currentOffer.min_amount / 100;
    const maxAmount = currencyFormat(maxLoanAmount);
    
    const durationMarks = this.durationMarks();
    const currentDuration = currentOffer.rates[rateIdx];

    return (
      <BasicLayout>
        <div className="page_header">
          <h1 className="page_title">Configure Your Loan</h1>
        </div>
        <div className="page_body">
          <div className="page_content">
            <div className="layer">
              <div className="form">
                <div className="layer -space-down-lg">
                  <p className="font-size-secondary-responsive">
                    You are pre-qualified for a ${maxAmount} loan.<br/>
                    <span
                      className="color-blue cursor-pointer"
                      onClick={() => this.onFieldChange('faqModalOpened', true)}
                    >
                      Offer FAQ and Terms of Loan
                    </span>
                  </p>
                </div>

                <div className="loan-offer-configuration">
                  <div className="form_range range">
                    <div className="range_titles">
                      <div className="range_title">Loan amount</div>
                    </div>

                    <div className="range_values loan-offer_range-values">
                      <div className="range_value -row">
                        <span
                          className="range_value-input -like-input"
                          onClick={() => this.onFieldChange('amountModalOpened', true)}
                        >
                          ${currencyFormat(currentAmount)}
                        </span>
                      </div>                      
                    </div>

                    <InputRange
                      classNames={{
                        ...DEFAULT_INPUT_RANGE_CLASS_NAMES,
                        inputRange: 'range_control input-range',
                      }}
                      minValue={minLoanAmount}
                      step={100}
                      maxValue={maxLoanAmount}
                      value={currentAmount}
                      onChange={val => this.onChangeAmount(val)}
                      onChangeComplete={this.checkLoanOffer}
                    />
                    
                  </div>
                  <div className="form_range range layer -space-up-md">
                    <div className="range_titles">
                      <div className="range_title">Loan duration</div>
                    </div>

                    <div className="range_values loan-offer_range-values">
                      <div className="range_value -row">
                        <div className="range_value-title color-text font-weight-bold">
                          {currentDuration && currentDuration.term}&nbsp;months
                        </div>
                      </div>                      
                    </div>

                    <Slider
                      defaultValue={0}
                      step={null}
                      marks={durationMarks}
                      onChange={v => this.chooseDuration(v)}
                      value={rateIdx}
                      min={0}
                      max={currentOffer.rates.length - 1}
                    />
                    
                  </div>
                </div>

                <div className="layer font-size-secondary-responsive -space-up-lg">
                  <p className="font-weight-bold">
                    {currentDuration && currentDuration.description}
                  </p>
                  <p>
                    Rate:&nbsp;{currentDuration && currentDuration.rate}%, Monthly&nbsp;payment:&nbsp;
                    ${currentDuration && currentDuration.monthly_payment}
                    Total&nbsp;payments:&nbsp;${currentDuration && currentDuration.total_of_payments}
                  </p>
                </div>

                <div className="layer -space-up-xxl">
                  <Checkbox
                    className="-darken"
                    checked={agreement}
                    onChange={() => this.onFieldChange('agreement', !agreement)}
                    label="I agree with"
                    inputName="default-checkbox"
                  />
                  &nbsp;<span
                    className="color-blue cursor-pointer"
                    onClick={() => this.onFieldChange('faqModalOpened', true)}
                  >
                      Terms of Loan
                  </span>
                </div>
              </div>
            </div>
            <ModalLoanFaq
              className="loan-offer-modal"            
              currentOffer={currentOffer}
              show={this.state.faqModalOpened}
              containerClassName={cn({ '-top': this.state.faqModalOpened })}              
              onClose={() => this.onFieldChange('faqModalOpened', false)}
              closeButton
            />
            <ModalAmountConfig
              currentOffer={currentOffer}
              amount={currentAmount}
              show={this.state.amountModalOpened}
              containerClassName={cn({ '-top': this.state.amountModalOpened })}
              changeAmount={this.changeAmount}
              onClose={() => this.onFieldChange('amountModalOpened', false)}
              isLoading={isLoading}
            />
          </div>
        </div>      
        <div className="page_controls -align-top-desktop">
          <Button to="/dashboard" transparency="full" xSize="full" className="page_control -cancel">
            Back to Wallet
          </Button>
          <Button
            color="blue"
            xSize="full"
            className="page_control -submit"
            disabled={!agreement || isChecking || waitingApproval}
            loading={isChecking || waitingApproval}
            onClick={this.acceptLoanOffer}
          >
            Accept Offer
          </Button>
        </div>
      </BasicLayout>
    );
  }
}

LoanOfferConfiguration.propTypes = {
  loanOffer: PropTypes.shape(loanOfferReducerTypes),
  acceptLoanOffer: PropTypes.func,
  checkLoanOffer: PropTypes.func,
  setField: PropTypes.func
};

function mapStateToProps(state) {
  return {
    loanOffer: state.loanOffer,
  };
}

export default connect(mapStateToProps, {
  acceptLoanOffer,
  checkLoanOffer,
  setField,
})(LoanOfferConfiguration);
